angular.module("app").controller("MyTodoListController", function($scope, $timeout, $http, $filter, $location, SessionService, NgTableParams, moment){
    $scope.visibility = false;
    $scope.haveResult = true;
    $scope.sortType='';
    $scope.sortOrder=undefined;
    $scope.searchText={};
    $scope.searchText.val='';
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.todolist = {};
    $scope.listitem = {};
    $scope.selectenable = false;
    $scope.checkAll = {};
    $scope.showloader = true;
    $scope.due_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    $scope.markValArr = [];
    $scope.selectedTodolist = {
        todolistArr: []
    };
    $scope.selectedAction = 0;

    $scope.pageNum = 1;
    $scope.getToDoList = function() {
      $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType='created_date';
            $scope.sortOrder=-1;
        }    
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/gettodolist", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    owner_id: SessionService.currentUser._id,
                    role: 'employee',
                    location:SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.allTodos = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allTodos;
                })
            }
        });
    }
    $scope.getToDoList();
    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.getToDoList()
              }
          }
    );
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.getToDoList()
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    $scope.goToDoList = function(toDoList){
        angular.forEach($scope.todoItemList, function(x) {
            if (x.done && !x.completion_date){
                x.done = false;;
            }
        });
        $scope.listdetails = toDoList;
        $scope.todoItemList = [];
        $scope.todoItemList = toDoList.todo_items;
    }

    $scope.strikethrough = function(completiondate){
        if(completiondate){
            return 'striketext';
        }else{
            return '';
        }
    }

    $scope.completeToDo = function(task, listId, listdetails){
        $scope.completelistjson = {};
        $scope.completelistjson.todolistId = listId;
        $scope.completelistjson.completion_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        $scope.completelistjson.todo_item = task;
        $scope.completelistjson.completeBy = SessionService.currentUser.first_name+' '+SessionService.currentUser.last_name;
        $http.post("/api/completeTaskInList", $scope.completelistjson).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Updated successfully.");
                $scope.getToDoList();
                $scope.listdetails = response.data.data;
                $scope.todoItemList = [];
                $scope.todoItemList = response.data.data.todo_items;
            }else{
                toastr.error("Somthing Wrong!");
            }
        });
    }

    if(SessionService.currentUser.roles[0] == 'manager'){
        $http.post("/api/getmanagerempl",{'manager_id' : SessionService.currentUser._id}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                });
                $scope.modernWebBrowsers = response.data.data;
            }
            else {
                console.log('400');
            }
        });
    }
    $scope.checkAll.val= false;
});