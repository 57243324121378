angular.module("app").directive("monthpicker", function() {
    return {
        restrict: 'C',
        require: 'ngModel',
        link: function (scope, el, attr, ngModel) {
            $(el).datepicker({
                format: 'MM yy',
                changeMonth : true,
                changeYear : true,
                showButtonPanel:true,
                onClose: function(dateText, inst) { 
                    var startDate = new Date(inst.selectedYear, inst.selectedMonth, 1);
                    $(this).datepicker('setDate', startDate);
                    scope.$apply(function () {
                        ngModel.$setViewValue(startDate);
                    });
                }
            });
        }
    };
});