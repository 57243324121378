angular.module("app").controller("DashboardController", function($scope, $rootScope, $filter, $route, ports, NotificationService, SessionService,AuthenticationService,TimeSlotResource, $location, $timeout, SocketService, sidepanelactiveService, getweekService, $http, $q, $window, User, moment) {
	$("[data-toggle=popover]").popover();
	$scope.showloader = true;
    $scope.popover = {
       "title": "Title",
       "content": "Hello Popover<br />This is a multiline message!"
    };   
      
    $scope.welcomemessage = true;
    $scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $timeout(function() {
        $rootScope.welcomemessagestatus = true;
    }, 5000);
    $scope.questionProcedure = {};
    $scope.ticketForm = {};
    $scope.notesNamePattern = /^[a-zA-Z0-9\'\#\@\_\-\`\%\=\&\$\.]+( [a-zA-Z0-9\'\#\@\_\-\`\%\=\&\$\.]+)*$/;
    $scope.shifts = [
      {name: "Morning", value: "Morning"},
      {name: "Afternoon", value: "Afternoon"},
      {name: "Night", value: "Night"},
      {name: "Late Night", value: "Late Night"}
    ];
	$scope.humanReadableToMinutes = function humanReadableToMinutes(time)
	{
	    var parts = time.split(/ |:/);
	    return (parts[2] == 'pm' * 12 * 60) + parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
	}
	// On First Time Login
	var locVal = $location.search();
	if( "true" === locVal.loggedin )
	sidepanelactiveService.test();	
    $scope.backendsiteurl = $location.protocol()+'://'+$location.host();
	$scope.role = '';
    $scope.fullName = SessionService.currentUser.first_name + ' ' + SessionService.currentUser.last_name;
	$scope.userFullData = SessionService.currentUser;
	$scope.password_change = SessionService.currentUser.password_change;
	$scope.prof_image = SessionService.currentUser.prof_image || "test";
	$scope.user = SessionService.currentUser;	
	$scope.pagetitle = function(pagetitle){
		$scope.title = pagetitle;
		$scope.selected = $location.path(); 
	};
	
	$scope.$on('fullName',function(evt,data){ 
		$scope.fullName = SessionService.currentUser.first_name + ' ' + SessionService.currentUser.last_name;
		$scope.userFullData = SessionService.currentUser;
		$scope.password_change = SessionService.currentUser.password_change;
		$scope.prof_image = SessionService.currentUser.prof_image || "test";
		$scope.user = SessionService.currentUser;
	});
	
	if($location.path() === '/dashboard'){
		$scope.title = 'Dashboard';
	}
	else if($location.path() === '/myavailability'){
		$scope.title = 'My Availability';
	}
	else if($location.path() === '/viewschedule'){
		$scope.title = 'My Schedule';
	}
	else if($location.path() === '/memolist'){
		$scope.title = 'Memos';
	}
	else if($location.path() === '/timeslots'){
		$scope.title = 'Time Slot';
	}
	else if($location.path() === '/eventlist'){
		$scope.title = 'Events';
	}
	else if($location.path() === '/reoccurringaccount'){
		$scope.title = 'Regular Accounts';
	}
	else if($location.path() === '/empschedule'){
		$scope.title = 'Employee Availability';
	}
	else if($location.path() === '/emptoschedule'){
		$scope.title = 'Employees Available to Schedule';
	}
	else if($location.path() === '/empAvlscheduled'){
		$scope.title = 'Employee Schedule/Availability';
	}
	else if($location.path() === '/schedule'){
		$scope.title = 'Employee Scheduling';
	}
	else if($location.path() === '/schedulereport'){
		$scope.title = 'Schedule Report';
	}
	else if($location.path() === '/reports'){
		$scope.title = 'Availability Report';
	}
	else if($location.path() === '/totalhoursreport'){
		$scope.title = 'Total Hours Report';
	}
	else if($location.path() === '/timeslotbuffer'){
		$scope.title = 'Weekly Time Slot Buffer';
	}
	else if($location.path() === '/timeofflist'){
		$scope.title = 'Time Off Requests';
	}
	else if($location.path() === '/viewticket'){
		$scope.title = 'Ticket System';
	}
	else if($location.path() === '/viewpayrolllist'){
		$scope.title = 'Payrolls';
	}
	else if($location.path() === '/viewadminemployee' || $location.path() === '/viewmanageremployee'){
		$scope.title = 'Employees';
	}
	else if($location.path() === '/todolist' || $location.path() === '/todocalendar'){
		$scope.title = 'To Do';
	}
	else if($location.path() === '/mytodolist' || $location.path() === '/mytodocalendar'){
		$scope.title = 'My To Do Lists';
	}
	else if($location.path() === '/ticketnotes' || $location.path() === '/ticketmonthlynotes'){
		$scope.title = 'Ticket System Report';
	}
	else if($location.path() === '/ticketcomments'){
		$scope.title = 'Ticket System Comments';
	}
	else if($location.path() === '/payrollreport'){
		$scope.title = 'Payroll Report';
	}
	else if($location.path() === '/missedpunchoutreport'){
		$scope.title = 'Missed PunchOut Report';
	}
	else if($location.path() === '/profitlossreport'){
		$scope.title = 'Profit/Loss Report';
	}
	else if($location.path() === '/hoursworkedreport'){
		$scope.title = 'Hours Worked Report';
	}
	else if($location.path() === '/trackclosereport'){
		$scope.title = 'Clocking Report';
	}
	else if($location.path() === '/tradeAdminRequests' || $location.path() === '/tradedetail' || $location.path() === '/adminRejectedTrades' || $location.path() === '/rejectedtradedetail'){
		$scope.title = 'Trade Requests';
	}
	$scope.isActive = function(route) {
		var path = $location.path();
		return route === $location.path() || path.indexOf(route) != -1;
	};
	$scope.isMainActive = function (group) {
		var path = $location.path();
		switch(group) {
			case "employee_info":
				var childRoutes = ["/viewadminemployee", "/addemployee", "/employee/edit", "/empschedule", "/emptoschedule","/empAvlscheduled","/memolist", "/addmemo", "/memo", "/timeofflist", "/timeoffdetail", "/clocking", "/admintimesheet", "/whiteboard", "/employee/availibility", "/viewschedule", "/tradeAdminRequests", "/adminRejectedTrades", "/tradedetail", "/rejectedtradedetail", "/scheduleMsg"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "SchedulingMenu":
				var childRoutes = ["/schedule", "/reportSchedule", "/timeslots", "/timeslotbuffer", "/schCalendar"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "RegularAccounts":
				var childRoutes = ["/reoccurringaccount", "/addaccount", "/eventlist", "/addevent", "/event/edit", "/eventcalendar", "/accountcalendar", "/questionlist"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "ReportsMenu":
				var childRoutes = ["/reports", "/ShiftAcknowledgement", "/ticketnotes", "/ticketcomments", "/ticketmonthlynotes", "/viewticket", "/addticket", "/profitlossreport","/missedpunchoutreport","/hoursworkedreport","/payrollreport","/overlapreport","/trackclosereport","/totalhoursreport"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "MiscMenu":
				var childRoutes = ["/todolist", "/todocalendar", "/documentslib", "/zipinitials", "/locations"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "RecognitionMenu":
				var childRoutes = ["/rankRecog", "/evalquestions", "/evaluation", "/empranking"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break; 
			case "PersonalMenu":
				var childRoutes = ["/mytodolist", "/mytodocalendar","/myavailability", "/mytimeoffrequest","/myschedule"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break; 
		}
	}	


  	$scope.$on('event:auth-loginRequired', function() {
  		$rootScope.sessionErr = false;
	  	if(SessionService.currentUser){
		    AuthenticationService.logoutUser().then(function() {
		      $scope.email    = "";
		      $scope.password = "";
		      NotificationService.notify("Your session has expired.");
		      window.location = "/login";
		    });
		}
  	});

	if(SessionService.currentUser.roles.indexOf('admin') > -1){
		$scope.role = 'admin';
		$scope.viewprofilelink = "/viewadminprofile";
	}
	else if(SessionService.currentUser.roles.indexOf('manager') > -1){
		$scope.role = 'manager';
		$scope.viewprofilelink = "/viewmanagerprofile";
	}
	else if(SessionService.currentUser.roles.indexOf('employee') > -1){
		$scope.role = 'employee';
		$scope.viewprofilelink = "/viewemployeeprofile";
	}
	else if(SessionService.currentUser.roles.indexOf('hr') > -1){
		$scope.role = 'hr';
		$scope.viewprofilelink = "/viewhrprofile";
	}
		
	// Code for realtime notifications
	if (SessionService.currentUser._id) {
		//Trade Request Notification
		SocketService.emit('get trade notifications', {user_id: SessionService.currentUser._id, location:SessionService.currentUser.location});
		SocketService.on('trade notification data', function(data){
			$scope.tradenotificationCount = data;
		});

		//Memo Notification
		SocketService.emit('get notifications', {user_id: SessionService.currentUser._id});
		SocketService.on('MemoNotification',function(data){
			SocketService.emit('get notifications', {user_id: SessionService.currentUser._id});
		});
		SocketService.on('notification data', function(data){
				$scope.notifications = data;
				$scope.notificationCount = data.length;
		});

		//Missed Punch Report Notification
		SocketService.on('sendMissedPunchOutNotification', function(data){
			if(SessionService.currentUser.roles[0] == 'admin'){
				SocketService.removeAllListeners();
				toastr.success("New record is added on report.");
			}
		});

		//Whiteboard Notification
		SocketService.on('sendWhiteBoardPostNotification', function(data){
			SocketService.removeAllListeners();
			toastr.success("New post is added on whiteboard.");
			//$scope.$broadcast('NewPostAdded');
		});

		//Schedule Notification (Shift Acknowledgement Icon)
		SocketService.emit('get availability notification', {user_id: SessionService.currentUser._id, location : SessionService.currentUser.location});
	/*	SocketService.on('scheduleNotification',function(data){
			SocketService.emit('get availability notification', {user_id: SessionService.currentUser._id, location : SessionService.currentUser.location});
		});*/
		SocketService.on('notification icon data', function(data){
				$rootScope.availnotifications = data;
		});
		//End of Schedule Notification (Shift Acknowledgement Icon)

		//ToDo Notification
		SocketService.emit('getTodoNotifications', {user_id: SessionService.currentUser._id});
		SocketService.on('todoNotification',function(data){
			if(data == SessionService.currentUser._id){
				SocketService.emit('getTodoNotifications', {user_id: SessionService.currentUser._id});
			}
		});
		SocketService.on('todoNotificationIconCount', function(data){
				$rootScope.todonotifications = data;
		});
	}	
	$scope.fetchcurrentweekschedule = function(){
    	var currWeekDates = getweekService.getCurrWeek();
    	$http.post('/api/getemployeeCurrentWeekSchedule',{from:currWeekDates[0], userId: SessionService.currentUser._id}).then(function(response){
    		if( "200" === response.data.status_code ) {
    			if(response.data.data.length){
    				var classVal = "";
    				var sortOrder = "";
    				response.data.data.forEach(function(elem,key){
    					if(elem.timeslot == "Morning"){
    						classVal = "bg-orange";
    						var sortOrder = 1;
    					}else if(elem.timeslot == "Afternoon"){
    						classVal = "bg-yellow";
    						var sortOrder = 2;
    					}else if(elem.timeslot == "Night"){
    						classVal = "bg-blue";
    						var sortOrder = 3;
    					}else if(elem.timeslot == "Late Night"){
    						classVal = "bg-dark";
    						var sortOrder = 4;
    					}
    					if((elem.is_approve !== undefined) && (elem.is_approve)){
							var newborderclass = " fa fa-check";
							var classVal = classVal + newborderclass;	
						}else{
							var newborderclass = "";
							var classVal = classVal + newborderclass;	
						}
    					elem.classVal = classVal;
    					elem.sortOrder = sortOrder;
    				})
    				$scope.empDataHaveResult = true;
    				$scope.schedules = response.data.data;
    			}else{
    				$scope.schedules = [];
    			}
    		}
    	});
    }
    if(SessionService.currentUser.roles[0] == 'employee'){
    	$scope.fetchcurrentweekschedule();
      	$http.post("/api/getmemos", {userId: SessionService.currentUser._id, action: "empInboxMsgs"}).then(function(response){
            if("200" === response.data.status_code) {
              	$scope.showloader = false;
              	if(response.data.data.length > 0){
                  response.data.data.forEach(function(elem, key){
                  	if(elem.description){
                      if (elem.description.length > 50) {
                          elem.description = elem.description.substring(0,50)+' .....';
                      }
                  	}
                  });
                }
                $scope.allMemos = response.data.data;
	              if ($scope.allMemos.length > 0)
	                  $scope.haveResult = true;
	              else
	                  $scope.haveResult = false;
	                  $scope.userRole = "other";
	                  $scope.msgsCount = $scope.allMemos.length;
            };    
      	});

	  	$http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(empres){
	  		$scope.showloader = false;
			empres.data.data.forEach(function(elem, key){
				if (elem.prof_image) {
					var prof_image = $scope.backendsiteurl+"/public/thumbs/"+elem.prof_image;
				}else{
					var prof_image = "assets/img/avatars/avatar2.png";
				}
				elem.prof_image = prof_image;
			});
			$scope.loggedemployeelist = empres.data.data;
		});
    }

	$scope.checkRead = function(userId, sent_to) {
		var testStr = true;
		for(var i=0; i<sent_to.length; i++) {
		  if( sent_to[i].user_id._id === userId ) {
		    testStr = sent_to[i].mark_read;
		    break;
		  }
		}
		return testStr;
	};

	$scope.markRead = function(memoId) {
		if ("admin" === SessionService.currentUser.roles[0]) {
		    $http.post("/api/markread", {memoId: memoId, user_role: "admin"}).then(function(response){
		    });
		}
		else {
		    $http.post("/api/markread", {memoId: memoId, user_role: "other", userId: SessionService.currentUser._id}).then(function(response){
		
		    });
		}
	};
        
	//$scope.fetchdatatype = 'today';
	$scope.worktype = function(ty) {
		$scope.showloader = true;
		$scope.fetchdatatype = ty;    
	}
	if (SessionService.currentUser.roles[0] !== 'employee') 
	{
		SocketService.emit('getTimeOffNotifications', {location : SessionService.currentUser.location, user_id: SessionService.currentUser._id, role : SessionService.currentUser.roles[0]});
		SocketService.on('timeOffNotification',function(data){
			$rootScope.timeOffnotifications = data;
		});

		//To Get new time Off on run
		SocketService.on('updatetimeOffNotification',function(){
			SocketService.emit('getTimeOffNotifications', {location : SessionService.currentUser.location, user_id: SessionService.currentUser._id, role : SessionService.currentUser.roles[0]});
		});

		SocketService.emit('getAdminTradeNotifications', {location : SessionService.currentUser.location, user_id: SessionService.currentUser._id, role : SessionService.currentUser.roles[0]});
		SocketService.on('AdminTradeNotifications',function(data){
			$rootScope.admintradenotifications = data;
		});

		//To Get new trade on run
		SocketService.on('adminTradeNotification',function(){
			SocketService.emit('getAdminTradeNotifications', {location : SessionService.currentUser.location, user_id: SessionService.currentUser._id, role : SessionService.currentUser.roles[0]});
		});
		$scope.start_end = '';
		var currWeekDates = getweekService.getCurrWeek();
		$scope.dashboardweekdate = $filter('date')(currWeekDates[0], 'MM/dd/yyyy') + ' To '+ $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
		$scope.dateRange = {};
		$scope.dateRange.start_date = $filter('date')(currWeekDates[0], 'MM/dd/yyyy');
		$scope.dateRange.end_date = $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
		$scope.openWeekPicker = function(){
			$scope.dashboardweekdate = $filter('date')(currWeekDates[0], 'MM/dd/yyyy') + ' To '+ $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
		}
		//$scope.openWeekPicker();
		$scope.$watch(
	        "dashboardweekdate",
	        function handleWeekChange() {
	        	$scope.$on('startendString', function (event, args) {
                	$scope.start_end = args.startendstringJson;
	            });
	            if ($scope.start_end) {
	                 var startEndArr = $scope.start_end.split(' / ');
	                 $scope.startDate = new Date(startEndArr[0]);
	                 $scope.endDate = new Date(startEndArr[1]);
	                 $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
	            }
	            else{
	                $scope.startDate = currWeekDates[0];
	                $scope.endDate = currWeekDates[1];
	                $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
	            }
	            $scope.empids = [];
	            $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(empres){
					empres.data.data.forEach(function(elem, key){
						$scope.empids.push(elem._id);
					});
					$scope.checkmarkavailability($scope.empids, $scope.dateRange);
					$http.post('/api/checkloggedin', {empids:$scope.empids}).then(function(checkloggedinresponse){
					      if( "200" === checkloggedinresponse.data.status_code ) {
						    checkloggedinresponse.data.data.forEach(function(elem, key){
							if (elem.prof_image) {
								var prof_image = $scope.backendsiteurl+"/public/thumbs/"+elem.prof_image;
							}else{
								var prof_image = "assets/img/avatars/avatar2.png";
							}
							elem.prof_image = prof_image;
							var loggincolor = (elem.logged_in === true) ? 'limegreen':'lightgray';
							elem.logged_in  = loggincolor;
						    });
					      }
					      $scope.showloader = false;
					      $scope.loggedemployeelist =  checkloggedinresponse.data.data;
					});
				});
        	}
        );
		$scope.$watch('fetchdatatype', function() {
			//var currentdateformat = new Date(new Date().setHours(0, 0, 0, 0));
			var currentdateformat = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
			var ty = $scope.fetchdatatype;
			var buttons = angular.element('.ui-controlgroup-controls .ui-checkbox label');
			for(var i=0;i<buttons.length;i++){
			     if(buttons.get(i).id == ty){
				 angular.element(".ui-controlgroup-controls .ui-checkbox label[id!='"+ty+"']").removeClass("ui-btn-active");
				 angular.element(".ui-controlgroup-controls .ui-checkbox label[id='"+ty+"']").addClass("ui-btn-active");
			     }
			}
			if (ty == 'today') {
				$http.post('/api/getschedulecount', {location : SessionService.currentUser.location, _id: SessionService.currentUser._id,currDate:currentdateformat,fetchtype:'today'}).then(function(response){
					$scope.showloader = false;
					$scope.todayevents = response.data.data;
				});
			}
			if (ty == 'week') {
				$http.post('/api/getschedulecount', {location : SessionService.currentUser.location, _id: SessionService.currentUser._id,currDate:currentdateformat,fetchtype:'week', startWeek:currWeekDates[0], endWeek:currWeekDates[1]}).then(function(response){
					$scope.showloader = false;
					$scope.todayevents = response.data.data;
				});
			}
			if (ty == 'month') {
				var date = new Date();
				var fDay = new Date(date.getFullYear(), date.getMonth(), 1);
				var lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
				var firstDay = moment(fDay).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				var lastDay = moment(lDay).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$http.post('/api/getschedulecount', {location : SessionService.currentUser.location, _id: SessionService.currentUser._id,currDate:currentdateformat,fetchtype:'month',currMonthStartDate:firstDay, currMonthEndDate:lastDay}).then(function(response){
					$scope.showloader = false;
					$scope.todayevents = response.data.data;
				});
			}
		});
		var sevenWeekDayArr = new Array();
		for(var i=0; i<7; i++) {
		    sevenWeekDayArr[i] = new Date(currWeekDates[0].getTime() + i*86400000);
		}
		$scope.empids = [];
		$scope.checkmarkavailability = function(empIds, daterange){
			$scope.dateRange = daterange;
			$scope.empids = empIds;
			$http.post('/api/checkmarkavailability', {empids:$scope.empids,startWeek:$scope.dateRange.start_date,endWeek:$scope.dateRange.end_date}).then(function(chkmarkresponse){
			    if( "200" === chkmarkresponse.data.status_code ) {
				     chkmarkresponse.data.data.forEach(function(elem, key){
					if (elem.prof_image) {
						var prof_image = $scope.backendsiteurl+"/public/thumbs/"+elem.prof_image;
					}else{
						var prof_image = "assets/img/avatars/avatar2.png";
					}
					elem.prof_image = '<img src="'+prof_image+'"/ width="35" class="pull-left" alt="avatar1">';
				    });
			    }
			    $scope.showloader = false;
			    $scope.employeelist =  chkmarkresponse.data.data;
			});
		}		
	}

	$scope.findCurrentlatlong = function(){
		var options = {
		  enableHighAccuracy: true,
		  timeout: 20000,
		  maximumAge: 0
		};

		function success(pos) {
		  var crd = pos.coords;
		  $scope.latitude = crd.latitude;
		  $scope.longitude = crd.longitude;
		};

		function error(err) {
		  console.warn('ERROR(${'+err.code+'}): ${'+err.message+'}');
		};

		if(navigator.geolocation){
		 	navigator.geolocation.getCurrentPosition(success, error, options);
		}else{
			toastr.error("Geolocation is not supported by this browser.");
		}
	}
	$scope.findCurrentlatlong();
	$scope.clockin = function(){
		$scope.findCurrentlatlong();
		if($scope.latitude && $scope.longitude){
			angular.element("#clockIn").css('display','none');
			angular.element("#clockingIn").css('display','inline-block');
			var geoJsonString = {};
			geoJsonString.latitude = $scope.latitude;
			geoJsonString.longitude = $scope.longitude;
			geoJsonString.location = SessionService.currentUser.location;
			//geoJsonString.currDate = new Date(new Date().setHours(0, 0, 0, 0));
			geoJsonString.currDate = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();

			$http.post('/api/checkGeoFencingArea',geoJsonString).then(function(response){
				if("200" === response.data.status_code){
					$scope.eventAccountArr = response.data.data;
					$scope.selectAccount = true;
					angular.element("#clockingIn").css('display','none');
				}
			});
		}else{
			$scope.findCurrentlatlong();
			toastr.error("Please share your location first.");
		}
	}	

	$scope.openQuestion = function(){
		var question = {};
		question.questionIds = $scope.eventInfo.questions;
		$http.post('/api/IdBasedQuestion',question).success(function(response) {
            if (response.status_code == "200") {
                $scope.questions = response.data;
            }
        });
	}

	$scope.addTrackInfo = function(trackInfo, clockId){
		if(trackInfo.make && trackInfo.license && clockId){
			trackInfo.clockTimesheetId = clockId;
			trackInfo.ShiftTrackListsEntry = {make:trackInfo.make, license:trackInfo.license};
			$http.post("/api/addShiftTrackInList", trackInfo).then(function(response){
	            if(response.data.status_code === "200"){
	                toastr.success("Successfully Added!");
	                $http.post('/api/fetchTrackList',{clockTimesheetId : clockId}).success(function(response) {
	                    if(response.status_code == "200"){
	                    	$scope.trackItemList = response.data.trackList;
	                		$scope.trackMainId = response.data._id;                        
	                    }
	                });
	                $scope.tracklist = {};
	            }else{
	                toastr.error("Something Wrong!");
	            }
	        });
		}else{
			 toastr.error("Please fill the required fields.");
		}
	}

	$scope.openTrackList = function(clockInId){
		$scope.tracklist = {};
		$http.post('/api/fetchTrackList',{clockTimesheetId : clockInId}).success(function(response) {
            if(response.status_code == "200"){
                $scope.trackItemList = response.data.trackList;
                $scope.trackMainId = response.data._id;
            }
   		});
	}

	$scope.removeTrackConfirmation = function(clockId, trackMainId, removeTrackId){
        $scope.trackInfo = {};
        $scope.trackInfo.clockTimesheetId = clockId;
        $scope.trackInfo.trackMainId = trackMainId;
        $scope.trackInfo.tracksubMainId = removeTrackId;
        $('#confirm_trackdel').show();
    }

    $scope.removeTrackListConfirmation = function(trackMainId){
        $scope.trackInfo = {};
        $scope.trackInfo.trackMainId = trackMainId;
        $('#confirm_tracklistdel').show();
    }

    $scope.removeTrack = function(){
        $http.post("/api/removeTrackInList", $scope.trackInfo).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Track info is removed successfully.");
                $scope.trackItemList = response.data.data.trackList;
                $scope.trackMainId = response.data.data._id;
                $('#confirm_trackdel').hide();
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }

    $scope.removeTrackList = function(){
        $http.post("/api/removeWholeTrackList", $scope.trackInfo).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Track list is cleared successfully.");
                $('#confirm_tracklistdel').hide();
                angular.element('#modal-tracklist').modal('hide');
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }

    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }

	$scope.openTicketcontrol = function(){
		var shift = 'Morning';
		//var today  =  new Date(new Date().setHours(0, 0, 0, 0));
		var today = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
		var day = today.getDay();
		$scope.todayDay = $scope.weekdays[day];
		$scope.amountPerCar = 0;
        if($scope.eventInfo.revenue_type === 'per_car'){
            $scope.revenue_type = "Per Car";
            $scope.eventInfo.parking_prices.forEach(function(elem, key){
                if(elem.timeslot == shift && elem.day == $scope.todayDay){
                    $scope.amountPerCar = elem.price || 0;
                }
            });
        }
        else if($scope.eventInfo.revenue_type === 'flat_rate'){
            $scope.revenue_type = "Flat Rate";
        }
        else if($scope.eventInfo.revenue_type === 'rate_per_hour'){
            $scope.revenue_type = "Rate Per Hour";
            var profitLossJson = {};
            profitLossJson.event_account_id = $scope.eventInfo._id;
            profitLossJson.selectDay = today;
            $http.post("/api/getTotalHoursOfDay",profitLossJson).then(function(response){
                if ( 200 == response.status ) {
                    if(response.data.data.length){
                        $scope.totalhoursofDay = response.data.data[0].totalTimeDiff;
                    }else{
                        $scope.totalhoursofDay = 0;
                    }
                } 
            });
        }
	}

	$scope.openTicketcontrolOnshift = function(shift){
		$scope.eventInfo.parking_prices.forEach(function(elem, key){
			if(elem.timeslot == shift && elem.day == $scope.todayDay){
				$scope.amountPerCar = elem.price || 0;
			}
		});
	}

	$scope.clockInEvent = function(eventId, shift){
		var eventId = JSON.parse(eventId);
		var shift = JSON.parse(shift);
		var clockInJson = {};
		clockInJson.eventId = eventId._id;
		clockInJson.revenueType = eventId.revenue_type;
		if(eventId.revenue_type === 'flat_rate'){
            clockInJson.revenueFixed = eventId.flat_rate;
        }
        if(eventId.revenue_type === 'rate_per_hour'){
            clockInJson.revenueRate = eventId.rate_per_hour;
        }
		clockInJson.shift = shift.value;
		clockInJson.location_coordinates = eventId.location_coordinates;
		clockInJson.userId = SessionService.currentUser._id;
		clockInJson.created_by = SessionService.currentUser._id;
		clockInJson.clockInDateTime = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		//clockInJson.created_date = new Date(new Date().setHours(0, 0, 0, 0));
		clockInJson.created_date = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
		clockInJson.location = SessionService.currentUser.location;
		$http.post('/api/clockIn',clockInJson).then(function(response){
			if("200" === response.data.status_code){
				$scope.clockinId = response.data.data._id;
				$scope.clockInTime = response.data.data.clockInDateTime;
				$scope.clkInDte    = response.data.data.clockInDateTime;
				var currentclockinformat = new Date(new Date($scope.clkInDte).setHours(0, 0, 0, 0));
				$scope.fetchExceptDate = moment(currentclockinformat).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$scope.eventInfo   = response.data.data.eventId;
				$scope.selectedtimeslot   = response.data.data.shift;
				$http.post('/api/fetchExceptionRate',{eventId : response.data.data.eventId._id, employee:response.data.data.userId, fetchExceptionDate : $scope.fetchExceptDate}).then(function(exceptionRes){
					$scope.clockinId = response.data.data._id;
					if(exceptionRes.data.status_code == "200"){
						if(exceptionRes.data.data[0] && exceptionRes.data.data[0].rates){
							$scope.eventInfo.basic_pay_rate = exceptionRes.data.data[0].rates.price;						
						}
					}
				});
				$scope.selectAccount = false;
				angular.element("#clockedOut").css('display','inline-block');
				angular.element("#Questions").css('display','inline-block');
				angular.element("#tracklist").css('display','inline-block');
				if(response.data.data.eventId.ticket_control){
					angular.element("#Ticketcontrol").css('display','inline-block');
				}
			}
		});
	}

	$scope.clockOutConfirmbox = function(clockInId){
		$('#confirm_shiftclose').show();
		$scope.alreadyclockId = clockInId;
	}

	$scope.clockOutconfirmation = function(clockoutConfirmation, clockInId){
		$('#confirm_shiftclose').hide();
		if(clockoutConfirmation == "no"){
			$scope.clockout(clockInId, "NoTicketPanel");
		}
		if((clockoutConfirmation == "yes") && ($scope.eventInfo.ticket_control)){
			$scope.clockout(clockInId,"withTicketPanel");
		}
		if((clockoutConfirmation == "yes") && (!$scope.eventInfo.ticket_control)){
			$scope.clockout(clockInId,"NoTicketPanel");
		}
	}
	
	$scope.clockout = function(clockInId, confirmationdialog){
		$scope.findCurrentlatlong();
		$http.post('/api/totalBreak',{clockInId : clockInId}).then(function(response){
			response.data.data.forEach(function(elem,key){
				if(confirmationdialog == "withTicketPanel" && !elem.ticketControl){
					toastr.error("Please fill ticket system before closing your shift.");
				}else{
					$scope.totalBreakTime = 0;
					if(elem.breaks.length){
						var i = 1;
						$scope.timediff = 0;
						elem.breaks.forEach(function(el,ke){
							if(el.timediff){
								$scope.timediff = $scope.timediff + el.timediff;
							}
							if(i === elem.breaks.length){
								$scope.totalBreakTime = $scope.timediff;
							}
							i++;
						});
					}
					var then = $scope.clockInTime;
					var nowtime = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
					var ms = moment(nowtime).diff(moment(then)); // Millisecond
					var timeWithoutBreak = ms - $scope.totalBreakTime;
					var d = moment.duration(timeWithoutBreak);
					var s = d.asHours().toFixed(2);
					var clockOutJson = {};
					clockOutJson.userId = SessionService.currentUser._id;
					clockOutJson.clockInId = clockInId;
					clockOutJson.clockOutDateTime = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
					clockOutJson.totalBreakTime = $scope.totalBreakTime;
					clockOutJson.totalTimeDiff  = timeWithoutBreak; // Total Time - Break time 
					clockOutJson.payrate = $scope.eventInfo.basic_pay_rate;
					clockOutJson.locationtimezone = SessionService.currentUser.locationtimezone;
					clockOutJson.clockInTime = $scope.clockInTime;
					clockOutJson.payroll = parseFloat(s*$scope.eventInfo.basic_pay_rate).toFixed(2);
					if($scope.latitude && $scope.longitude){
						clockOutJson.latitude = $scope.latitude;
						clockOutJson.longitude = $scope.longitude;
						$http.post('/api/clockOut',clockOutJson).then(function(response){
							if("200" === response.data.status_code){
								angular.element("#clockIn").css('display','block');
								angular.element("#clockedOut").css('display','none');
								angular.element("#Questions").css('display','none');
								angular.element("#tracklist").css('display','none');
								angular.element("#Ticketcontrol").css('display','none');
								toastr.success("Successfully Done!");
							}else{
								toastr.error("You are not in the geo fenceing area of this event.");
							}
						});
					}else{
						toastr.error("Please share your location first.");
					}
				}
			});
		});
	}

	$scope.chckclockedIn = function(){
		var clockJson = {};
		clockJson.userId = SessionService.currentUser._id;
		$http.post('/api/clockIncheck',clockJson).then(function(response){
			if("200" === response.data.status_code && response.data.data){
				$scope.clkInDte    = response.data.data.clockInDateTime;
				var currentdteformat  =  new Date(new Date($scope.clkInDte).setHours(0, 0, 0, 0));
				$scope.fetchExceptDate = moment(currentdteformat).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$http.post('/api/fetchExceptionRate',{eventId : response.data.data.eventId._id, employee:response.data.data.userId, fetchExceptionDate : $scope.fetchExceptDate}).then(function(exceptionRes){
					$scope.showloader = false;
					$scope.clockinId = response.data.data._id;
					$scope.shift       = response.data.data.shift;
					$scope.eventInfo   = response.data.data.eventId;
					var today = moment(currentdteformat).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
					var day   = today.getDay();
					$scope.todayDay = $scope.weekdays[day];
					$scope.eventInfo.pay_rates.forEach(function(elem, key){
						if(elem.timeslot == $scope.shift && elem.day == $scope.todayDay){
							$scope.eventInfo.basic_pay_rate = elem.price || 0;
						}
					});
					if(exceptionRes.data.status_code == "200"){
						$scope.selectedtimeslot = response.data.data.shift;
						if(exceptionRes.data.data[0] && exceptionRes.data.data[0].rates){
							$scope.eventInfo.basic_pay_rate = exceptionRes.data.data[0].rates.price;						
						}
					}
					$scope.clockInTime = response.data.data.clockInDateTime;
					response.data.data.breaks.forEach(function(elem, key){
						if(!elem.endTime){
							$scope.breakId = elem._id;
							$scope.startbreakTime = elem.startTime;
						}
					});
					if($scope.breakId){
						angular.element("#clockIn").css('display','none');
						angular.element("#clockedOut").css('display','inline-block');
						angular.element("#Questions").css('display','inline-block');
						angular.element("#tracklist").css('display','inline-block');
						if(response.data.data.eventId.ticket_control){
							angular.element("#Ticketcontrol").css('display','inline-block');
						}
						$scope.beakcomes = true;
						angular.element("#clockOutButtons").css('display','none');
					}else{
						angular.element("#clockIn").css('display','none');
						angular.element("#clockedOut").css('display','inline-block');
						angular.element("#Questions").css('display','inline-block');
						angular.element("#tracklist").css('display','inline-block');
						if(response.data.data.eventId.ticket_control){
							angular.element("#Ticketcontrol").css('display','inline-block');
						}
					}
				});
			}else{
				$scope.showloader = false;
				angular.element("#clockIn").css('display','inline-block');
			}
		});
	}	

	$scope.viewEvent = function(eventInfo){
		$scope.evtDetail = eventInfo;
	}

	$scope.breakTime = function(clockId){
		var breakTimeJson = {};
		breakTimeJson.clockId = clockId;
		breakTimeJson.startTime = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		$scope.startbreakTime = breakTimeJson.startTime;
		$http.post('/api/startBreak',breakTimeJson).then(function(response){
			if("200" === response.data.status_code){
				$scope.beakcomes = true;
				response.data.data.breaks.forEach(function(elem, key){
					if(!elem.endTime){
						$scope.breakId = elem._id;
					}
				});
				angular.element("#clockOutButtons").css('display','none');
			}
		});
	}

	$scope.continueshift = function(clockId){
		var breakTimeJson = {};
		breakTimeJson.clockId  = clockId;
		breakTimeJson.endTime  = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		breakTimeJson.breakId  = $scope.breakId;
		breakTimeJson.timediff = moment(breakTimeJson.endTime).diff(moment($scope.startbreakTime));
		$http.post('/api/stopBreak',breakTimeJson).then(function(response){
			if("200" === response.data.status_code){
				$scope.beakcomes = false;
				angular.element("#clockOutButtons").css('display','inline-block');
			}
		});
	}

	$scope.chckclockedIn();

	$scope.saveQuest = function(clockId){
		var questionsJson = {};
		var questionArr = [];
		questionsJson.clockId = clockId;		
		$scope.questions.forEach(function(elem, key){
			var elemid = elem._id;
			if($scope.questionProcedure[elemid]){
				questionArr.push({questId : elem._id, question : elem.title, answer : $scope.questionProcedure[elemid]});
			}
		});
		questionsJson.questionArr = questionArr;
		$http.post('/api/saveOpeningClosure',questionsJson).then(function(response){
			if("200" === response.data.status_code){
				toastr.success("Successfully Updated!");
				angular.element('#modal-questions').modal('hide');
			}
		});
	}

	$scope.saveTicketControl = function(clockId){
		var ticketJson = {};
		ticketJson = $scope.ticketForm;
		ticketJson.eventId = $scope.eventInfo._id;
        ticketJson.cashDeposit = angular.element('#cashDeposit').val();
        ticketJson.revenue = parseFloat(angular.element('#revenue').val()).toFixed(2);
        ticketJson.revenueType = $scope.eventInfo.revenue_type;
        ticketJson.revenueRate = 0;
        if($scope.eventInfo.revenue_type === 'flat_rate'){
            ticketJson.revenueRate = $scope.eventInfo.flat_rate;
        }
        if($scope.eventInfo.revenue_type === 'rate_per_hour'){
            ticketJson.revenueRate = $scope.eventInfo.rate_per_hour;
        }
        ticketJson.totalChargedCars = angular.element('#totalChargedCars').val();
        var currentTctdateformat  =  new Date(new Date($scope.clockInTime).setHours(0, 0, 0, 0));
        ticketJson.dateOfReport = moment(currentTctdateformat).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        ticketJson.created_by = SessionService.currentUser._id;
        $http.post('/api/saveTicketControlReport',ticketJson).then(function(response){
            if("200" === response.data.status_code){
            	$http.post('/api/updateTicketControlIntimesheet',{'clockInId' : clockId}).then(function(response){
            		if("200" === response.data.status_code){
            			 toastr.success("Successfully Added!");
                		 angular.element('#modal-tickets').modal('hide');
            		}
            	});
            }
            if("202" === response.data.status_code){
                $('#confirm_ticketcontrolOverride').show();
            }
            if("500" === response.data.status_code){
                toastr.error("Something wrong!");
            }
        }); 
	}

	$scope.updateTicketControlConfirm = function(){
        var ticketJson = {};
		ticketJson = $scope.ticketForm;
		ticketJson.eventId = $scope.eventInfo._id;
        ticketJson.cashDeposit = angular.element('#cashDeposit').val();
        ticketJson.revenue = parseFloat(angular.element('#revenue').val()).toFixed(2);
        ticketJson.totalChargedCars = angular.element('#totalChargedCars').val();
        var currentTctDateFormat  =  new Date(new Date($scope.clockInTime).setHours(0, 0, 0, 0));
        ticketJson.dateOfReport = moment(currentTctDateFormat).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        ticketJson.created_by = SessionService.currentUser._id;
        $http.post('/api/updateTicketControlReport',ticketJson).then(function(response){
            if("200" === response.data.status_code){
            	$http.post('/api/updateTicketControlIntimesheet',{'clockInId' : $scope.clockinId}).then(function(response){
            		if("200" === response.data.status_code){
            			toastr.success("Successfully Updated!");
                		angular.element('#modal-tickets').modal('hide');
                		$('#confirm_ticketcontrolOverride').hide();
            		}
            	});
            }
        }); 
    }

	$scope.toggleSubMenu = function($event){
		angular.element($event.currentTarget).parent('li').toggleClass('active').children('ul').collapse('toggle');
    	angular.element($event.currentTarget).parent('li').siblings().removeClass('active').children('ul.in').collapse('hide');
	}

	$scope.viewEmployeesList = function(){
		$location.path("/viewEmployeesToUser");
	}

	$scope.viewShiftAcknowledgement = function(){
		$location.path("/ShiftAcknowledgement");
	}

	$scope.viewManagerEmployees = function(){
		$location.path("/viewmanageremployee");
	}

	$scope.viewAdminEmployees = function(){
		$location.path("/viewadminemployee");
	}

	$scope.viewhremployees = function(){
		$location.path("/viewhremployee");
	}

	$scope.gotochangePassword = function(){
		$location.path("/editpassword");
	}

	$scope.mytodolist = function(){
		$location.path("/mytodolist");
	}

	$scope.twentyfour_to_twelve = function(ti){
		if(ti){
			ti = ti.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [ti];
			if (ti.length > 1) { // If time format correct
				ti = ti.slice (1);  // Remove full string match value
				ti[5] = +ti[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
				ti[0] = +ti[0] % 12 || 12; // Adjust hours
			}
			return ti.join (''); // return adjusted time or original string
		}
	}
	$scope.showscheduleDetail = function(schInfo){
		$scope.schInfo = schInfo;
		$scope.evtDetail = schInfo.event_account_id;
	}
	$scope.approveScheduleView = function(sch){
		$http.post("/api/checkSchedule",{schId:sch, acknowledgeDate : moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString()}).success(function(response){
			if(response.status_code == "200"){
				angular.element('#event-modal').modal('hide');
				SocketService.emit('get availability notification', {user_id: SessionService.currentUser._id, location : SessionService.currentUser.location});
				SocketService.on('notification icon data', function(data){
					$rootScope.availnotifications = data;
				});	
				$scope.fetchcurrentweekschedule();
			}
		})
	}

	$scope.callOnNumber = function(phonenumber){
		window.location.href="tel://"+phonenumber;
	}

	$scope.getshiftsacknowledgement=function(){
    	$http.post("/api/getshiftsacknowledgementForDashboard", {
            count : 10,
            page : 1,
            alignedBy_id : SessionService.currentUser._id,
            role : SessionService.currentUser.roles[0],
            location : SessionService.currentUser.location
        }).then(function(response) {
        	if(response.data.status_code == "200"){
        		$scope.showloader = false;
                $scope.visibility = true
                var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                response.data.data.forEach(function(elem, key) {
					var weekDate = moment(elem.week_of).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
            		elem.shiftDate = weekDate.setDate(weekDate.getDate() + weekdays.indexOf(elem.day_of_week));
            		var weekDate      =      moment(elem.week_of).toDate();
            		elem.shiftDate = weekDate.setDate(weekDate.getDate() + $scope.weekdays.indexOf(elem.day_of_week));
            		elem.shiftDate = moment(elem.shiftDate).format("dddd, MMMM Do YYYY");
					if(elem.is_approve){
                    	elem.scheduleInfoclass = 'alert-success';
                	}else{
                		elem.scheduleInfoclass = 'bg-red';
                	}
                });
                $scope.allSchedules = response.data.data;
                
                $scope.haveResult = true;
                if (!response.data.total) {
                    $scope.haveResult = false;
                }
        	}
        })    
	}
	
	$scope.ScheduleView = function(sch){
		var schid = sch._id;
		$http.post("/api/checkScheduleDetails",{schId:schid}).success(function(response){
			if(response.status_code == "200"){
				$scope.eventDetails = response.data.event_account_id;
				$scope.eventStartDate = moment($scope.eventDetails.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$scope.eventEndDate = moment($scope.eventDetails.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$scope.shiftData = $scope.eventDetails.shift_template_id;
				$scope.evtDetail = $scope.eventDetails;
				response.data.scheduleDate = moment(response.data.scheduleDate).clone().tz(SessionService.currentUser.locationtimezone, true).format("dddd, MMMM Do YYYY");
				$scope.wholeData = response.data;
			}
		})
	}

	$scope.approveScheduleViewforAdmin = function(sch){
		var todaydate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		$http.post("/api/checkSchedule",{schId:sch, acknowledgeDate : todaydate}).success(function(response){
			if(response.status_code == "200"){
				angular.element('#shiftack-modal').modal('hide');
				$scope.showloader= true;
				$scope.getshiftsacknowledgement();
			}
		})
	} 

    $scope.dashboardtodolist = function(){
    	$http.post("/api/dashboardtodolist", {
            owner_id: SessionService.currentUser._id,
            location: SessionService.currentUser.location
        }).then(function(response) {
            $scope.showloader = false;
            $scope.visibility = true;
            $scope.allTodos = response.data.data;
            response.data.data.forEach(function(elem, key) {
                 var todoIncompletelen = 0;
                 var i = 0;
                 elem.todo_items.forEach(function(el,ke){
                 	if(el.done == false){
                 		todoIncompletelen++;
                 	}
                 	i++;
                 })
                 if(i == elem.todo_items.length){
                 	elem.todoIncompleteCount = todoIncompletelen;
                 }
            });
            $scope.haveResult = true;
            $scope.todolength = true;
            if (!response.data.total) {
                $scope.haveResult = false;
                $scope.todolength = false;
            }
        })
    }

    if (SessionService.currentUser.roles[0] !== 'employee') {
    	$scope.dashboardtodolist();
    	$scope.getshiftsacknowledgement();	
	}


});

