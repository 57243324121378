angular.module("app").factory("UserService", function($http, $q, $location,SessionService,User) {
  return {
    updateUser: function (userdetails) {
      var defer = $q.defer();
      $http.put("/api/users", userdetails).then(function( response ) {
        if (response.data.status_code == "200") {
          var user = new User();
          angular.extend(user, response.data.data);
          SessionService.storeUser(user);
          defer.resolve(true);
        } else {
          defer.resolve(false);
        }
      });
      return defer.promise;
    }
  };
  
});