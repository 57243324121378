angular.module("app").controller("LocationsController", function($scope, $http, $filter, $location, SessionService, NgTableParams, moment){
    $scope.visibility = true;
    $scope.haveResult = true;
    $scope.sortType='';
    $scope.sortOrder=undefined;
    $scope.searchText={};
    $scope.searchText.val='';
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.selectenable = false;
    $scope.checkAll = {};
    $scope.showloader = true;
    $scope.selectedLocationslist = {
        LocationslistArr: []
    };
    $scope.selectedAction = 0;
    $scope.pageNum = 1;
    $scope.clearList = function(){
        $scope.title = '';
    }
    $scope.locationList = function() {
      $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType='created_date';
            $scope.sortOrder=-1;
        }    
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/getLocationlist", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0]
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.allLocations = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allLocations;
                })
            }
        });
    }

    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.locationList()
              }
          }
    );
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.locationList()
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    $scope.locationList();
    $scope.createLocation = function(){
        var locationInfo = {};
        locationInfo.title = $scope.title;
        locationInfo.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        locationInfo.created_by = SessionService.currentUser._id;
        $http.post("/api/addlocation", locationInfo).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Location is added successfully in list.");
                angular.element('#modal-basic').modal('hide');
                $scope.tableParams.reload();
                $scope.locationList();
            }else{
                toastr.error("Somthing Wrong!");
            }
        });
    }
    /*change active/inactive on click*/
    $scope.update_status=function($index,data) {
        $http.post("/api/update_location_status", {"data":data}).then(function(response) {
            if(response.data.status_code == "200"){
                $scope.locationList();
                toastr.success("Successfully updated.");
            }
        })
    }
    /*end*/

    $scope.checkAll.val= false;
    $scope.selectAllGroup = function(check) {
            $scope.flag = {};
            $scope.flag.enable = false;
            if (check) {
                $scope.selectedLocationslist.LocationslistArr = $scope.allLocations.map(function(item) {
                    return item._id;
                });
                $scope.selectenable = true;
            } else {
                $scope.selectedLocationslist.LocationslistArr = [];
                $scope.checkAll.val= false;
                $scope.selectenable = false;
            }
    }
   
    $scope.delete_locationlists=function(){
        $http.post("/api/deleteLocationList", {
            "locationid": $scope.selectedLocationslist.LocationslistArr
        }).then(function(response) {
            $scope.allLocations = response.data.data;
            angular.element('#selectedAction').val(0);
            $scope.locationList();
            toastr.success("Successfully Deleted.");
            $scope.selectedLocationslist.LocationslistArr = [];
            $scope.checkAll.val = false;
            $('#confirm_del').hide();
        })
    }

    $scope.cnfrm_pwd = function(questlist){
        $('#confirm_del').show();
    }
    
    $scope.performAction = function() {
        $scope.selectedAction = selectedAction.value;
        if ($scope.selectedAction == 0) {
            toastr.error("Please select some selection.");
        }else if ($scope.selectedLocationslist.LocationslistArr.length == 0) {
            toastr.error("Please select atleast one checkbox.");
        }
        if ($scope.selectedAction != 0 && $scope.selectedAction == 1 && $scope.selectedLocationslist.LocationslistArr.length > 0) {
            $scope.enbl = true;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 2 && $scope.selectedLocationslist.LocationslistArr.length > 0) {
            $scope.enbl = false;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 3 && $scope.selectedLocationslist.LocationslistArr.length > 0) {
            $scope.cnfrm_pwd($scope.selectedLocationslist.LocationslistArr);
        }

        if( $scope.selectedLocationslist.LocationslistArr.length > 0 && $scope.selectedAction > 0 && $scope.selectedAction < 3){
            $http.post("/api/enable_disable_Locations", {
                'enabled': $scope.enbl,
                'allChecked': $scope.selectenable,
                "locations": $scope.selectedLocationslist.LocationslistArr
            }).then(function(response) {
                $scope.allZips = response.data.data;
                angular.element('#selectedAction').val(0);
                $scope.locationList();
                toastr.success("Successfully updated.");
                $scope.selectedLocationslist.LocationslistArr = [];
                $scope.checkAll.val = false;
            })
        }
    }

});