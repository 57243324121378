angular.module("app").directive("timepicker", function() {
    return {
        restrict: 'C',
        require: 'ngModel',
        //controller:'TimeslotrangeController',
        link: function (scope, el, attr, ngModel) {
            scope.$watch(attr.ngTimeArr,function(newValue,oldValue){
                $(el).datetimepicker({
                    format: 'H:i',
                    timepicker:true,
                    datepicker:false,
                    step:5,
                    //allowTimes:scope[attr.ngTimeArr],
                    onChangeDateTime:function(dp,$input){
                        scope.$apply(function () {
                            ngModel.$setViewValue($input.val());
                        });
                    }
                });
            });
        }
    };
});