angular.module("app").controller("ViewEmployeesForEventController", function($scope, $location, SessionService, $http, UserService) {

$scope.event=[];
$scope.noempaval=false;

    //get events
    $scope.eventlst=function(){
      // $scope.emplist=[];
      $http.post("/api/viewempforevents",{date:$scope.selected_date}).then(function(data){
          $scope.event=data.data.data;
      });
    }
    $scope.$watch("selected_date",function(new_val,old_val){
      // alert(new_val);
      if(new_val != undefined){
        $scope.eventlst();
      }
    }) 

    //viewempnameforevents
    $scope.empname=function(){
        $http.post("/api/viewempnameforevents",{date:$scope.selected_date,event:$scope.selected_event}).then(function(data){

          $scope.data=data.data.data;
          if(data.data.data.length==0){
            $scope.noempaval=true;
          }
          else{
            $scope.noempaval=false;
          }
      });
    }

});