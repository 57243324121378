angular.module("app").directive("mCustomScrollbar", function($window) {
	return {
		restrict: 'C',
		link: function(scope, el, attr, ngModel) {
			var width = $window.innerWidth;
			if(width < 1200 && attr.id == 'sidebar'){
				$(el).mCustomScrollbar("destroy");
			}else{
				$(el).mCustomScrollbar("destroy");
				$(el).mCustomScrollbar({
					scrollButtons: {
	                enable: false
		            },
		            autoHideScrollbar: true,
		            scrollInertia: 150,
		            theme: "dark",
		            advanced: {
		                updateOnContentResize: true
		            }
				});
			}
			angular.element($window).bind('resize', function () {
				var width = $window.innerWidth;
				if(width < 1200 && attr.id == 'sidebar'){
					$(el).mCustomScrollbar("destroy");
				}else{
					$(el).mCustomScrollbar("destroy");
					$(el).mCustomScrollbar({
						scrollButtons: {
		                enable: false
			            },
			            autoHideScrollbar: true,
			            scrollInertia: 150,
			            theme: "dark",
			            advanced: {
			                updateOnContentResize: true
			            }
					});
				}
			});
		}
	};
});