angular.module('app').directive('fallbackSrc', [function () {
  var fallbackSrc = {
    link: function postLink(scope, iElement, iAttrs) {
      iElement.bind('error', function() {
        angular.element('#bigprofilepic').attr("src", "assets/img/avatars/avatar2_big.png");
        angular.element('#smallprofilepic').attr("src", "assets/img/avatars/avatar2.png");
        angular.element('#smallprofilepicmob').attr("src", "assets/img/avatars/avatar2.png");
      });
    }
   }
   return fallbackSrc;
}]);