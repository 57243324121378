angular.module("app").directive("dateselection", function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, el, attr, ngModel) {
            if (attr.dateselection == "dob") {
                //Stop to select current day on enter
                el.bind('keydown',function (e) {
                        if(e.keyCode === 13) {
                            e.stopImmediatePropagation();
                            e.preventDefault();
                        }
                });

            	var newday = moment().subtract(18, 'years').calendar();
	            var maxDate = moment(newday).format('YYYY/MM/DD');

                $(el).datetimepicker({
                    format: 'm/d/Y',
                    maxDate : maxDate,
                    defaultDate : null,
                    timepicker:false,
                    scrollMonth:false,
                    scrollTime:false,
                    scrollInput:false,
                    onChangeDateTime:function(dp,$input){
                        scope.$apply(function () {
                            ngModel.$setViewValue($input.val());
                        });
                    }
                });
            }
            
            if (attr.dateselection == "event") {
                $(el).datetimepicker({
                    format: 'm/d/Y',
                    minDate : 'today',
                    timepicker:false,
                    scrollMonth:false,
                    scrollTime:false,
                    scrollInput:false,
                    onChangeDateTime:function(dp,$input){
                        scope.$apply(function () {
                            ngModel.$setViewValue($input.val());
                        });
                    }
                });
            }
        }
    };
});