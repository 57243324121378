angular.module("app").controller("ViewShiftsController", function($rootScope,$scope, SessionService, $http, $routeParams,$q, SocketService, sidepanelactiveService, getweekService, moment) {
	$("#get-modal").hide();
	$scope.showloader= true;
	var $modal =$("#get-modal");
	$scope.schedulepage = 1;
	$scope.totalschedulepage = 1;
	$scope.allSchedules = [];
	$scope.busy = false;
	$scope.is_approve_sortvalue = 'false';
	$scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	var currWeekDates = getweekService.getCurrWeek();
	$scope.viewschedules=function(){
		$http.get('/api/getemployeeSideSchedule/'+SessionService.currentUser._id+'/'+SessionService.currentUser.location).then(function(response){
	 		$scope.count= 0;
	 		if ( 200 === response.status && response.data.length) {
				$scope.showloader = false;
				var countLess = 0;
				response.data.forEach(function(elem, key) {
					var nextWeekDates = getweekService.getNextWeek(currWeekDates[1].getDay() + 2);
					lastday_date = new Date(nextWeekDates[1].setHours(23, 59, 59, 999)).toISOString()
					
					if (elem.scheduleDate > lastday_date) {
						countLess++;
						response.data.splice(key);//remove shifts after next week i.e shifts after next saturday
					}else{


						if(elem.is_approve){
							elem.scheduleInfoclass = 'alert-success';
						}else{
							elem.scheduleInfoclass = 'bg-red';
						}
						var weekDate      =      moment(elem.week_of).toDate();
						elem.scheduleDate = weekDate.setDate(weekDate.getDate() + $scope.weekdays.indexOf(elem.day_of_week));
						elem.scheduleDate = moment(elem.scheduleDate).format("MM/DD/YYYY");
						elem.is_approve_date = moment(elem.is_approve_date).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY HH:mm:ss");
					}
                });
                $scope.allSchedules = response.data;
			}else{
				$scope.showloader = false;
				$scope.allSchedules = [];
			}
	 	});
	}
	$scope.viewschedules();	

	$scope.approveScheduleView = function(sch){
		var schid = sch._id;
		$scope.eventDetails = [];
		$scope.eventStartDate = "";
		$scope.eventEndDate = "";
		$scope.scheduleDateTime = sch;
		$scope.shiftData = $scope.eventDetails.shift_template_id;
		$scope.evtDetail = $scope.eventDetails;
		var todaydate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		$http.post("/api/checkSchedule",{schId:schid, acknowledgeDate : todaydate}).success(function(response){
			if(response.status_code == "200"){
				$scope.eventDetails = response.data.event_account_id;
				$scope.eventStartDate = moment($scope.eventDetails.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$scope.eventEndDate = moment($scope.eventDetails.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$scope.shiftData = $scope.eventDetails.shift_template_id;
				$scope.evtDetail = $scope.eventDetails;
				$('#'+schid).removeClass("alert-info");
				$('#'+schid).addClass("alert-success");
				SocketService.emit('get availability notification', {user_id: SessionService.currentUser._id});
				SocketService.on('notification icon data', function(data){
					$rootScope.availnotifications = data;
				});	
				$scope.viewschedules();	
			}
		})
	}

	$scope.closemodal = function(){
		$("#get-modal").hide();	
	}

	$scope.sortbyFunction = function(val){
		if(val){
			$scope.is_approve_sortvalue = 'true';
		}else{
			$scope.is_approve_sortvalue = 'false';
		}
	}

});
