angular.module("app").controller("ViewAdminEmployeeController", function($scope, ports,$location, SessionService, $http, $filter, $timeout, $rootScope, sidepanelactiveService, NgTableParams, $window, moment) {
    $scope.searchText={};
    $scope.active='true';
    $scope.sortType='';
    $scope.sortOrder = undefined;
    $scope.status = "active";
    $scope.roleselect = "all";
    $scope.showloader = true;
    sidepanelactiveService.test();
    $scope.user = SessionService.currentUser;
    $scope.searchText.val = '';
    $scope.selectenable = false;
    $scope.checkAll = {};
    $scope.exception = {};
    $scope.noteItemListId = "";
    $scope.searchPattern = /^[a-zA-Z0-9\'\#\@\_\-\`\.\$\,]+( [a-zA-Z0-9\'\#\@\_\-\`\.\$\,]+)*$/;
    $scope.selectedEmployee = {
        employee: []
    };
    $scope.backendsiteurl = $location.protocol()+'://'+$location.host();
    $scope.selectedAction = 0;
    $(document).ready(function(){
        $("body").removeClass("events");
    });
    $scope.visibility = false;
    $scope.haveResult = true;
    $scope.showEmp = function(empid) {
        $scope.showloader = true;
        $timeout(function() {
            $scope.$apply(function() {
                $location.path('/employee/edit/:' + empid);
            });
        },100);
        $window.scrollTo(0, 0);
    };
    $scope.showEmpSch = function(empid, locationId) {
        $scope.showloader = true;
        $timeout(function() {
            $scope.$apply(function() {
                $location.path('/viewschedule/:' + empid + '/:' + locationId);
            });
        },100);
        $window.scrollTo(0, 0);
    };

    //listing of active,inactive
    $scope.liststs = [{
        name: "Active",
        value: 'active'
    }, {
        name: "Inactive",
        value: 'inactive'
    }, {
        name: "All",
        value: "all"
    }];

    $scope.rolelist = [{
        name: "Manager",
        value: 'manager'
    }, {
        name: "Employee",
        value: 'employee'
    }, {
        name: "Hr",
        value: "hr"
    }, {
        name: "All",
        value: "all"
    }];

    /*listing of employees*/
    $scope.pageNum = 1;
    $scope.listemployees = function() {
        if ($scope.sortType == '') {
            $scope.sortType = 'created_date';
            $scope.sortOrder = -1;
        }
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.showloader = true;
                $scope.pageNum = params.page(); 
                return $http.post("/api/getemployees", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    status:$scope.status,
                    searchByRole:$scope.roleselect,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    location:SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true
                    params.total(response.data.total); // recal. page nav controls
                    $scope.empData = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.empData;
                })
            }
        });
    }
    /*end*/
    $scope.listemployees();
    $scope.showfullImage = function(fullImage){
        $scope.fullimage = fullImage;
    }
    $scope.showSparkyWriteup = function(empid){
        $scope.showloader = true;
        $timeout(function() {
            $scope.$apply(function() {
                $location.path('/rankRecog/:' + empid);
            });
        },100);
        $window.scrollTo(0, 0);
    }
    /*watch for search*/
    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.listemployees()
              }
          }
    );
    /*change active/inactive on click*/
    $scope.update_status=function($index,data) {
        $http.post("/api/update_status", {"data":data}).then(function(response) {
            if(response.data.status_code == "200"){
                $scope.listemployees();
                toastr.success("Successfully updated.");
            }
        })
    }
    /*end*/
    $scope.reset_page=function() {
        $scope.pageNum = 1;
        $scope.listemployees();
    }
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.listemployees()
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    
    $scope.checkAll.val= false;
    $scope.selectAllGroup = function(check) {
            $scope.flag = {};
            $scope.flag.enable = false;
            if (check) {
                $scope.selectedEmployee.employee = $scope.empData.map(function(item) {
                    return item._id;
                });
                $scope.selectenable = true;
            } else {
                $scope.selectedEmployee.employee = [];
                $scope.checkAll.val = false;
                $scope.selectenable = false;
            }
    }

    //save all enable disable
    $scope.performAction = function() {
        $scope.selectedAction = selectedAction.value;
        if ($scope.selectedAction == 0) {
            toastr.error("Please select some selection.");
        }else if ($scope.selectedEmployee.employee.length == 0) {
            toastr.error("Please select atleast one checkbox.");
        }
        
        if ($scope.selectedAction != 0 && $scope.selectedAction == 1 && $scope.selectedEmployee.employee.length > 0) {
            $scope.enbl = true;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 2 && $scope.selectedEmployee.employee.length > 0) {
            $scope.enbl = false;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 3 && $scope.selectedEmployee.employee.length > 0) {
            $scope.cnfrm_pwd($scope.selectedEmployee.employee);
        }
        
        if( $scope.selectedEmployee.employee.length > 0 && $scope.selectedAction > 0 && $scope.selectedAction < 3){
            $http.post("/api/enableemployees", {
                'enabled': $scope.enbl,
                'allChecked': $scope.selectenable,
                "employee": $scope.selectedEmployee.employee
            }).then(function(response) {
                $scope.empData = response.data.data;
                angular.element('#selectedAction').val(0);
                $scope.listemployees();
                toastr.success("Successfully updated.");
                $scope.selectedEmployee.employee = [];
                $scope.checkAll.val = false;
            })
        }

        if( $scope.selectedEmployee.employee.length > 0 && $scope.selectedAction > 3 && $scope.selectedAction < 6){
            if($scope.selectedAction == 4){
                $scope.notificationalert = true;
            }
            if($scope.selectedAction == 5){
                $scope.notificationalert = false;
            }
            $http.post("/api/enableNotifications", {
                'notificationalert': $scope.notificationalert,
                'allChecked': $scope.selectenable,
                "employee": $scope.selectedEmployee.employee
            }).then(function(response) {
                $scope.empData = response.data.data;
                angular.element('#selectedAction').val(0);
                $scope.listemployees();
                toastr.success("Successfully updated.");
                $scope.selectedEmployee.employee = [];
                $scope.checkAll.val = false;
            })
        }
    }

    $scope.update_notification=function($index,data) {
        $http.post("/api/update_notification", {"data":data}).then(function(response) {
            if(response.data.status_code == "200"){
                $scope.listemployees();
                toastr.success("Successfully updated.");
            }
        })
    }

    $scope.chk_status=function(newValue){
        if(newValue=='true'){
            $scope.empData=$scope.activedata;
        }
        else if(newValue=='false'){
              $scope.empData=$scope.inactivedata;
        }
        else{
            $scope.empData=$scope.all_Data;
        }
        $scope.listemployees();
        $scope.$apply();
    }
 
    //to delete user
    $scope.delete_empl=function(id){
        $http.post("/api/delempl",{empid:id} ).then(function(response){
            if(response.data.status_code== "200"){
                $scope.listemployees();
                toastr.success("Successfully Deleted");
            }
        });
    }

    $scope.check_usrpwd=function(){
        if($scope.pwd){
            $http.post("/api/checkPwd",{empid:$scope.user._id,password:$scope.pwd}).then(function(response){
                   $('#confirm_del').hide();
                    if(response.data.status_code== "200"){
                    $scope.delete_empl($scope.del_empid);
                    }
                    else{
                        toastr.error("You are not authorized");
                    }
            });
        }else{
            toastr.error("Please enter password.");
        }
    }

    //password to delete
    $scope.cnfrm_pwd=function(emp){
        //e.stopPropagation();
        $scope.del_empid=emp;
        $('#confirm_del').show();
    }

    /*function to addemp*/
    $scope.addemp=function() {
        $scope.showloader= true;
        $timeout(function() {
            $scope.$apply(function() {
                $location.path("/addemployee");
            });
        },100);
    }

    $scope.showEmpdetails = function(empid, e) {
        $http.get('/api/users/:' + empid).
        success(function(response) {
          if (response.date_of_birth !== undefined ) {
            response.date_of_birth = $filter('date')(response.date_of_birth, 'MM/dd/yyyy');
          }
          if (response.phone !== undefined ) {
            response.phone = $filter('tel')(response.phone, false);
          }
          $scope.employee = response;
          if(response.roles[0] == 'employee'){
            var employeerole = 'Employee';
          }
          if(response.roles[0] == 'hr'){
            var employeerole = 'Hr';
          }
          if(response.roles[0] == 'manager'){
            var employeerole = 'Manager';
          }
          $scope.employee.emp_role = employeerole;
        });
    };

    //Start Notes Library
    $scope.showEmpNotes = function(empid){
        $http.get('/api/users/:' + empid).success(function(response) {
          $scope.employee = response;
        });
        $scope.noteitemjson = {};
        $scope.noteitemjson.employee = empid;
        $scope.noteitemjson.createdby = $scope.user._id;
        $scope.noteItemList = [];
        $http.post('/api/empNotesList',$scope.noteitemjson).success(function(response) {
            if(response.status_code == "200"){
                $scope.noteItemList = response.data.notes;
                $scope.employeeid = response.data.employee;
                $scope.noteItemListId = response.data._id;
            }else{
                $scope.noteItemList = [];
                $scope.noteItemListId = '';
            }
        });
    };

    $scope.noteAdd = function(empid){
        if(!$scope.noteItemList){
            $scope.noteItemList = [];
            $scope.noteItemListLength = 0;
        }else{
            $scope.noteItemListLength = $scope.noteItemList.length; 
        }
        var noteadddate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        $scope.noteItemList.push({noteText:$scope.noteitem.noteInput, added_date : noteadddate});
        $scope.noteitemjson = {};
        $scope.noteitemjson.notesEntry = {noteText:$scope.noteitem.noteInput, added_date : noteadddate, added_by : $scope.user._id};
        $scope.noteitemjson.employee = empid;
        $scope.noteitemjson.created_by = $scope.user._id;
        $scope.noteitemjson.noteslistCount = $scope.noteItemListLength;
        if($scope.noteItemListId){
          $scope.noteitemjson.noteItemListId = $scope.noteItemListId;
        }
        $http.post("/api/addNoteInList", $scope.noteitemjson).then(function(response){
            if(response.data.status_code === "200"){
                $scope.noteItemListId = response.data.data._id;
                $scope.noteItemList = response.data.data.notes;
                toastr.success("Note is added successfully.");
                $scope.showEmpNotes(empid);
            }else{
                toastr.error("Something Wrong!");
            }
        });
        $scope.noteitem = {};
    }

    $scope.removeNoteConfirmation = function(empid, noteMainId, removeNoteId){
        $scope.noteitemjson = {};
        $scope.noteitemjson.employee = empid;
        $scope.noteitemjson.created_by = $scope.user._id;
        $scope.noteitemjson.noteMainId = noteMainId;
        $scope.noteitemjson.removeNoteId = removeNoteId;
        $('#confirm_notedel').show();
    }

    $scope.removeNoteListConfirmation = function(noteMainId){
        $scope.noteitemjson = {};
        $scope.noteitemjson.noteMainId = noteMainId;
        $('#confirm_notelistdel').show();
    }

    $scope.removeNote = function(){
        $http.post("/api/removeNoteInList", $scope.noteitemjson).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Note is removed successfully.");
                $scope.noteItemList = response.data.data.notes;
                $scope.employeeid = response.data.data.employee;
                $scope.noteItemListId = response.data.data._id;
                $('#confirm_notedel').hide();
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }

    $scope.removeNoteList = function(){
        $http.post("/api/removeWholeNoteList", $scope.noteitemjson).then(function(response){
            if(response.data.status_code === "200"){
                $scope.noteItemListId = "";
                toastr.success("List is cleared successfully.");
                $('#confirm_notelistdel').hide();
                angular.element('#modal-employeeNotesSection').modal('hide');
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }
    //End of Notes Library

    //Exception Rates

    $scope.showEmpExceptions = function(empid){
        $scope.exceptionItemList = [];
        $http.get('/api/users/:' + empid).success(function(response) {
          $scope.employee = response;
        });

        $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
             if ( "200" === response.data.status_code) {
                 $scope.allEvents = response.data.events;
                 angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.basic_pay_rate) {
                       value.buttonlabel = value.name+', $'+value.basic_pay_rate;
                   }else{
                       value.buttonlabel = value.name;
                   }
                 });
             } 
        });
        
    };

    $scope.showEmpEvtExceptions = function(){
        angular.forEach( $scope.allEvents, function( value, key ) {
           if ( value.ticked === true ) {
               storeSelEventAccountId = value;
           }
        });
        $scope.exceptionitemjson = {};
        $scope.exceptionitemjson.employee = $scope.employee._id;
        $scope.exceptionitemjson.event_account = storeSelEventAccountId._id;
        $scope.exceptionitemjson.created_by = $scope.user._id;
        $scope.exceptionItemList = [];
        $http.post('/api/empExceptionsList',$scope.exceptionitemjson).success(function(response) {
            if(response.status_code == "200"){
                $scope.exceptionItemList = response.data.rates;
                $scope.employeeid = response.data.employee;
                $scope.event_account = response.data.event_account;
                $scope.exceptionItemListId = response.data._id;
            }
        });
    }

    $scope.addExceptionRate = function(exception){
        $scope.exception = exception;
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       storeSelEventAccountId = value;
                   }
            });
        }

        if($scope.exception.price && storeSelEventAccountId && $scope.exception.effective_date){
            var x = _.some($scope.exceptionItemList, function(c) {
                var databaseEffective = moment(c.effective_date).valueOf();
                var nowEffective = moment($scope.exception.effective_date).valueOf();
                return databaseEffective === nowEffective; 
            });
            if(x){
                $('#confirm_exceptionOverride').show();
                $scope.exception.override = true;
            }else{
                $scope.exception.override = false;
                $scope.addExceptionRateConfirm();
            }
        }else{
            toastr.error("Please fill the required fields.");
        }
    }

    $scope.addExceptionRateConfirm = function(){
        $('#confirm_exceptionOverride').hide();
        var exception = $scope.exception;

        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       storeSelEventAccountId = value;
                   }
            });
        }
        angular.forEach( $scope.exceptionItemList, function( value, key ) {
           if ( value.event_account === storeSelEventAccountId._id ) {
               $scope.exceptionItemList.splice(key,1);
           }
        });
        if(storeSelEventAccountId && exception.price){
            var empId = $scope.exceptionitemjson.employee;
            if(!$scope.exceptionItemList.length){
                $scope.exceptionItemList = [];
                $scope.exceptionItemListLength = 0;
            }else{
                $scope.exceptionItemListLength = $scope.exceptionItemList.length; 
            }
            $scope.exceptionitemjson = {};
            $scope.exceptionitemjson.override = $scope.exception.override;
            $scope.exceptionitemjson.exceptionratesEntry = {price : exception.price, effective_date : moment(exception.effective_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
            $scope.exceptionitemjson.employee = empId;
            $scope.exceptionitemjson.event_account = storeSelEventAccountId._id;
            $scope.exceptionitemjson.created_by = $scope.user._id;
            $scope.exceptionitemjson.exceptionrateslistCount = $scope.exceptionItemListLength;
            $http.post("/api/addExceptionInList", $scope.exceptionitemjson).then(function(response){
                if(response.data.status_code === "200"){
                    toastr.success("Exception Rate is added successfully.");
                    $http.post('/api/empExceptionsList',$scope.exceptionitemjson).success(function(response) {
                        if(response.status_code == "200"){
                            $scope.exceptionItemList = response.data.rates;
                            $scope.employeeid = response.data.employee;
                            $scope.exceptionItemListId = response.data._id;
                        }
                    });
                    $scope.exception = {};
                    storeSelEventAccountId = '';
                    angular.forEach( $scope.allEvents, function( value, key ) {
                        if ( value.ticked === true ) {
                           value.ticked = false;
                        }
                    });
                }else{
                    toastr.error("Something Wrong!");
                }
            });
            $scope.noteitem = {};
        }else if(!storeSelEventAccountId && !exception.price ){
            toastr.error('Please enter price based on Event / Account');   
        }
    }

    $scope.removeExceptionConfirmation = function(empid, eventAccId, exceptionMainId, removeExceptionId){
        $scope.exceptionitemjson = {};
        $scope.exceptionitemjson.employee = empid;
        $scope.exceptionitemjson.eventAccId = eventAccId;
        $scope.exceptionitemjson.created_by = $scope.user._id;
        $scope.exceptionitemjson.exceptionMainId = exceptionMainId;
        $scope.exceptionitemjson.removeExceptionId = removeExceptionId;
        $('#confirm_exceptiondel').show();
    }

    $scope.removeExceptionListConfirmation = function(exceptionMainId){
        $scope.exceptionitemjson = {};
        $scope.exceptionitemjson.exceptionMainId = exceptionMainId;
        $('#confirm_exceptionlistdel').show();
    }

    $scope.removeException = function(){
        $http.post("/api/removeExceptionInList", $scope.exceptionitemjson).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Exception is removed successfully.");
                $scope.exceptionItemList = response.data.data.rates;
                $scope.employeeid = response.data.data.employee;
                $scope.exceptionItemListId = response.data.data._id;
                $('#confirm_exceptiondel').hide();
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }

    $scope.removeExceptionList = function(){
        $http.post("/api/removeWholeExceptionList", $scope.exceptionitemjson).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("List is cleared successfully.");
                $('#confirm_exceptionlistdel').hide();
                angular.element('#modal-employeeExceptionSection').modal('hide');
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }

    $scope.calculateTimeWithCompany = function(employeeId){
        $http.post("/api/fetchUserTime", {employee_id : employeeId}).then(function(response){1010
            if(response.data.status_code === "200"){
                $scope.empTimeData = response.data.data;
                $scope.hiringdate = $scope.empTimeData[0].employee_id.date_of_hiring;
                $scope.activeDays = moment().diff(moment($scope.hiringdate), 'days');
                $scope.Inactivedays = 0;                
                angular.forEach($scope.empTimeData, function( value, key ) {
                        var a = moment(value.ActiveFrom);
                        var b = moment(value.InActiveFrom);
                        var daydiff = a.diff(b, 'days');
                        $scope.Inactivedays = $scope.Inactivedays + daydiff;
                });
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }

    //End of Exception Rates
});

angular.module("app").filter('numberFixedLen', function () {
       return function (n, len) {
           var num = parseInt(n, 10);
           len = parseInt(len, 10);
           if (isNaN(num) || isNaN(len)) {
               return n;
           }
           num = '' + num;
           while (num.length < len) {
               num = '0' + num;
           }
           return num;
       };
});

