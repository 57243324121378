angular.module("app").controller("AddEmployeeController", function($scope, states, patterns, NotificationService, SessionService, $http, fileUpload, $location, $window, moment) {
    $window.scrollTo(0, 0);
    $scope.employee = {};
    $scope.rolesArr = [
        {name: "Employee", value: 'Employee'},
        {name: "Manager", value: 'Manager'},
        {name: "Hr", value: 'Hr'}
    ];
    $scope.field1 = 'filename'; 
    $scope.emplnamePattern = /^[a-zA-Z0-9\'\-]+( [a-zA-Z0-9\'\-]+)*$/;
    $scope.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    $scope.addressPattern = patterns.address;
    $scope.addressPatternError = patterns.addressPatternError;
    $scope.states = states.statelist;
    $scope.user = SessionService.currentUser;
    $scope.employee.date_of_birth = '01/01/1998';
    $scope.empData = {};
        if(SessionService.currentUser.roles.indexOf('admin') > -1)
            $scope.role = 'admin';
        else if(SessionService.currentUser.roles.indexOf('manager') > -1)
            $scope.role = 'manager';
        else if(SessionService.currentUser.roles.indexOf('employee') > -1)
            $scope.role = 'employee';
        else if(SessionService.currentUser.roles.indexOf('hr') > -1)
            $scope.role = 'hr';
    $scope.saveEmployeeInfo = function(employee){
        $http.post("/api/users", employee).then(function(response){
            if (response.data.status_code === "200") {
                console.log(response.data)
                toastr.success("Employee added successfully.");
                $scope.employeeForm.$setPristine();
                $scope.employee = {};
                if ("admin" === SessionService.currentUser.roles[0]) {
                    $location.path("/viewadminemployee");
                }
                else if ("manager" === SessionService.currentUser.roles[0]) {
                    $location.path("/viewmanageremployee");
                }
                else if ("hr" === SessionService.currentUser.roles[0]) {
                    $location.path("/viewhremployee");
                }
                else {
                    $location.path("/login");
                }
            }
            else if (response.data.status_code === "500"){
                toastr.error("Email is Already Exists. Please choose a different one.");
                $scope.buttonDisabled = false;
            }
            else {                
                if (employee.date_of_birth != "") {
                    $scope.employee.date_of_birth = jsDate;
                }
                toastr.error("Something went wrong, check your credentials");
                $scope.buttonDisabled = false;
            }
        });
    }

    $scope.addEmployee = function(employee){
        $scope.buttonDisabled = true;
        if($scope.employee.employeeid){
            $http.post("/api/chk_random", {num:$scope.employee.employeeid}).then(function(response){
                if(response.data.status_code=="200"){
                    if($scope.employeeForm.$invalid){
                        toastr.error("Please fill all the required fields.");
                        $scope.buttonDisabled = false;
                        angular.element("input.ng-invalid").css("border-color","red");
                        angular.element("input.ng-valid").css("border-color","#d3d3d3");
                        $window.scrollTo(0, 0);
                    }else{
                        if (employee.date_of_birth != "") {
                                employee.date_of_birth = moment(employee.date_of_birth).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                        }
                        if ($scope.role == "admin") {
                            if(employee.emp_role == 'Employee')
                                employee.roles = ["employee"];
                            if(employee.emp_role == 'Manager')
                                employee.roles = ["manager"];
                            if(employee.emp_role == 'Hr')
                                employee.roles = ["hr"];
                        }
                        if ($scope.role == "manager") {
                            employee.roles = ["employee"];
                        }
                        if ($scope.role == "hr") {
                            employee.roles = ["employee"];
                        }
                        if(employee.date_of_hiring != ""){
                            employee.date_of_hiring = moment(employee.date_of_hiring).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                        }
                        employee.created_by = SessionService.currentUser._id;
                        if(employee.active == "Yes"){
                            employee.active = true;
                        }
                        if(employee.active == "No"){
                            employee.active = false;
                        }
                        employee.password = "password";
                        employee.password_change = false;
                        employee.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
                        employee.location = SessionService.currentUser.location;
                        employee.locationtimezone = SessionService.currentUser.locationtimezone;
                        employee.address.line1 = employee.address.line1.formatted_address;
                        if ($scope.myFile) {
                            var file = $scope.myFile;
                            var chkData = "";
                            var uploadUrl = "/api/fileUpload";
                            $scope.showloader = true;
                            fileUpload.uploadFileToUrl(file, uploadUrl, function(retData){
                                if ( true === retData.success && retData.code != 400 ) {
                                     employee.prof_image = retData.save_image_name;
                                     $scope.saveEmployeeInfo(employee);
                                }
                                else if(retData.code==400){
                                    $scope.showloader = false;
                                    toastr.error(retData.msg)
                                    $scope.buttonDisabled = false;
                                }
                            });
                        }else{
                            $scope.saveEmployeeInfo(employee);
                        }
                    }
                }
                else{
                    toastr.error("This employee no is already exist");
                    return false;
                }
            });
        }else{
                if($scope.employeeForm.$invalid){
                    toastr.error("Please fill all the required fields.");
                    angular.element("input.ng-invalid").css("border-color","red");
                    $window.scrollTo(0, 0);
                    angular.element("input.ng-valid").css("border-color","#d3d3d3");
                }
        }
    };

    $scope.cleardata = function(){
        toastr.info("You cleared the data.");
        angular.element("input.ng-invalid").css("border", "1px solid #d3d3d3");
        $scope.employeeForm.$setPristine();
        $scope.employee = {};
        $scope.employee.emp_role='Employee';
    };

    $scope.generate_random=function(){
        if($scope.employee.employeeid){
            $scope.showloader = true;
            $http.post("/api/chk_random", {num:$scope.employee.employeeid}).then(function(response){
                $scope.showloader = false;
                if(response.data.status_code=="200"){
                    //toastr.success("This is valid Employee id");
                    angular.element("#empNo").css("border-color","#d3d3d3");
                    $scope.empnoVerifyClass = "fa-check c-green";
                    return 1;
                }
                else{
                    $scope.empnoVerifyClass = "fa-times c-red";
                    angular.element("#empNo").css("border-color","red");
                    //toastr.error("This Employee id already exist");
                    return 0;
                }
            });
        }
        else{
            toastr.error("Please enter employee id.");
        }
    };   

    $scope.checkEmailUnique = function($event){
        $scope.showRestoreLink = false;
        if($scope.employee.email){
            $http.post('/api/uniqueEmail',{'email':$scope.employee.email})
              .success(function(data){
              if(data.message=="already existed"){
                $scope.emailVerifyClass="fa-times c-red";
                angular.element("#emailAddress").css("border-color","red");
                if(data.data.is_deleted==true){
                    $('#confirm_restore').show();
                    $scope.showRestoreLink = true;
                }else{
                    $scope.showRestoreLink = false;
                }
              }else{
                $scope.showRestoreLink = false;
                angular.element("#emailAddress").css("border-color","#d3d3d3");
                $scope.emailVerifyClass="fa-check c-green";
              }
            })
        }
    };

    $scope.restorePopup = function(){
        $('#confirm_restore').show();
    }

    $scope.restoreEmployee=function(){        
        $http.post('/api/restoreEmployee',{'email':$scope.employee.email})
        .success(function(data){
            if(data.message == "updated successfullly"){
                $('#confirm_restore').hide();
                $location.path("/viewadminemployee");
            }
        })
    }

    $scope.changeAdd = function(){
        var details = $scope.employee.address.line1;
        if(details && details.address_components){
            for (var i = 0; i < details.address_components.length; i++) {
              var addressType = details.address_components[i].types[0];
              var val = details.address_components[i].long_name;
              if (addressType == 'country') {
                $scope.employee.country = val;
              }
              if (addressType == 'administrative_area_level_1' || addressType == 'locality' || addressType == 'postal_code' || addressType == 'street_number' || addressType == 'route') {
                if (addressType == 'administrative_area_level_1') {
                  $scope.employee.address.state = val;
                }
                if (addressType == 'locality') {
                  $scope.employee.address.city = val;
                }

                if (addressType == 'postal_code') {
                  $scope.employee.address.zip = val;
                }
              }
            }
        }
    }
});