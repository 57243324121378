angular.module("app").controller("RecognitionController", function($scope, $http, $rootScope, ports, $routeParams, SessionService, $filter, NgTableParams, moment){
	$scope.visibility = false;
    $scope.haveResult = true;
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.sortType = '';
    $scope.sortOrder = undefined;
    $scope.searchText = {};
    $scope.searchText.val = '';
    $scope.searchOccur = false;
    $scope.employeeid = $routeParams.empid;
    $scope.showbackbutton = false;

    if($scope.employeeid){
        var selectedIdStr = $scope.employeeid;
        $scope.employee_id = selectedIdStr.substr(1);
        $scope.searchText.val = $scope.employee_id;
        $scope.showbackbutton = true;
    }

    $scope.selectedRecognitionList = {
        RecognitionlistArr: []
    };

    $scope.fetchAllEmployees = function(){
        $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                    if(elem._id === $scope.employee_id){
                        elem.ticked = true;
                    }
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.modernWebBrowsers = response.data.data;
            }
        });
    }
    $scope.fetchAllManagers = function(){
        $http.post("/api/getAllManagers",{location:SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.allManagers = response.data.data;
            }
        });
    }

    console.log(".............",SessionService.currentUser.roles[0]);

    if(SessionService.currentUser.roles[0] !== "employee"){
        $scope.fetchAllEmployees();
        $scope.fetchAllManagers();
        $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
             if ( "200" === response.data.status_code ) {
                 $scope.allEvents = response.data.events;
                 angular.forEach( $scope.allEvents, function( value, key ) {
                  value.buttonlabel = value.name;
                 });
             } 
        });
    }
    

	$scope.saveWriteup = function(writeUpInfo){
        $scope.buttonDisabled = true;
		if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       storeSelEventAccountId = value;
                   }
            });
            writeUpInfo.event_account = storeSelEventAccountId._id;
        }

        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
               if ( value.ticked === true ) {
                   storeSelUserId = value;
               }
            });
            writeUpInfo.employee = storeSelUserId._id;
        }

        if($scope.allManagers){
            angular.forEach( $scope.allManagers, function( value, key ) {
               if ( value.ticked === true ) {
                   storeSelManagerId = value;
               }
            });
            writeUpInfo.manager = storeSelManagerId._id;
        }
        if (writeUpInfo.writeupDate != "") {
            writeUpInfo.writeupDate = moment(writeUpInfo.writeupDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();            
        }
        writeUpInfo.currDate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        writeUpInfo.created_date = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
        if(writeUpInfo.writeup_type == 'negative'){
            writeUpInfo.value = -Math.abs(writeUpInfo.value);
        }
        writeUpInfo.location = SessionService.currentUser.location;
		$http.post("/api/saveWriteUp",writeUpInfo).then(function(response){
            if (response.data.status_code == 200) {
               toastr.success("Successfully Added!");
               angular.element('#modal-writeup').modal('hide');
               angular.element('#modal-editWriteup').modal('hide');
               $scope.fetchWriteUps();
            }
	    });
	}

	$scope.fetchWriteUps = function() {
      $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType='currDate';
            $scope.sortOrder=-1;
        }    
        var search = "";
        var accountsearch = "";
        var managersearch = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }

        if ($scope.searchText.accountval) {
            accountsearch = $scope.searchText.accountval;
        }

        if ($scope.searchText.managerval) {
            managersearch = $scope.searchText.managerval;
        }

        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/fetchWriteUps", {
                    count: params.count(),
                    page: params.page(),
                    field: $scope.sortType,
                    search: search,
                    accountsearch : accountsearch,
                    managersearch : managersearch,
                    sortOrder:$scope.sortOrder,
                    userrole:$scope.userRole,
                    loggedInId : SessionService.currentUser._id,
                    location:SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.allWriteups = response.data.data;
                    $scope.positiveTotal = 0;
                    $scope.negativeTotal = 0;
                    angular.forEach( $scope.allWriteups, function( val, key ) {
                           if ( val.writeup_type == "positive" ) {
                               $scope.positiveTotal = $scope.positiveTotal + val.value;
                           }
                           if ( val.writeup_type == "negative" ) {
                               $scope.negativeTotal = $scope.negativeTotal + val.value;
                           }
                    });
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allWriteups;
                })
            }
        });
    }
    $scope.fetchWriteUps();
    $scope.employeeBasedSearch = function(){
        $scope.searchOccur = true;
        $scope.searchText.val = '';
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                   if ( value.ticked === true ) {
                        $scope.searchText.val = value._id;
                   }
            });
        }
        if(!$scope.searchText.val){
            $scope.searchText.val = '';
        }
        $scope.showloader = false;
        $scope.pageNum = 1;
        $scope.fetchWriteUps();
    }

    $scope.accountBasedSearch = function(){
        $scope.searchOccur = true;
        $scope.searchText.accountval = '';
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                if ( value.ticked === true ) {
                    $scope.searchText.accountval = value._id;
                }
            });
        }

        if(!$scope.searchText.accountval){
            $scope.searchText.accountval = '';
        }
        $scope.showloader = false;
        $scope.pageNum = 1;
        $scope.fetchWriteUps();
    }

    $scope.managerBasedSearch = function(){
        $scope.searchOccur = true;
        $scope.searchText.managerval = '';
        if($scope.allManagers){
            angular.forEach( $scope.allManagers, function( value, key ) {
                if ( value.ticked === true ) {
                    $scope.searchText.managerval = value._id;
                }
            });
        }

        if(!$scope.searchText.managerval){
            $scope.searchText.managerval = '';
        }
        $scope.showloader = false;
        $scope.pageNum = 1;
        $scope.fetchWriteUps();
    }

    $scope.closeSearch = function(){
        $scope.searchText.val = '';
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if( value.ticked === true ){
                    $scope.searchText.val = value._id;
                }
            });
        }

        if(!$scope.searchText.val){
            $scope.searchText.val = '';
            $scope.showloader = false;
            $scope.pageNum = 1;
            $scope.fetchWriteUps();
        }   
    }

    $scope.closeAccountSearch = function(){
        $scope.searchText.accountval = '';
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                if ( value.ticked === true ) {
                    $scope.searchText.accountval = value._id;
                }
            });
        }

        if(!$scope.searchText.accountval){
            $scope.searchText.accountval = '';
            $scope.showloader = false;
            $scope.pageNum = 1;
            $scope.fetchWriteUps();
        }   
    }

    $scope.closeManagerSearch = function(){
        $scope.searchText.managerval = '';
        if($scope.allManagers){
            angular.forEach( $scope.allManagers, function( value, key ) {
                if ( value.ticked === true ) {
                    $scope.searchText.managerval = value._id;
                }
            });
        }

        if(!$scope.searchText.managerval){
            $scope.searchText.managerval = '';
            $scope.showloader = false;
            $scope.pageNum = 1;
            $scope.fetchWriteUps();
        }   
    }


    $scope.clearWriteupConfirmation = function(){
		$('#clear_all').show();
	}
    $scope.removeSelectedConfirmation = function(){
        if($scope.selectedRecognitionList.RecognitionlistArr.length){
            $('#clear_selected').show();
        }else{
            toastr.error('Please select an item from the list.');
        }
    }

    $scope.clearAllWriteups = function(){
		$('#clear_all').hide();
		$http.post("/api/clearWriteups", {searchemp : $scope.searchText.val, searchAcc : $scope.searchText.accountval}).then(function(response){
	    	if(response.status == "200"){
	    		toastr.success("All items are cleared successfully.");
	    		$scope.fetchWriteUps();
	    	}
	    });
	}
    $scope.clearSelectedWriteups = function(){
        $('#clear_selected').hide();
        $http.post("/api/clearWriteupsbyIds",{'removeIds' : $scope.selectedRecognitionList.RecognitionlistArr}).then(function(response){
            if(response.status == "200"){
                toastr.success("Successfully Removed.");
                $scope.fetchWriteUps();
            }
        });
    }
    $scope.addWriteUp = function(){
        $scope.buttonDisabled = false;
    	$scope.writeupData = {};
    	$http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
	        if ( "200" === response.data.status_code) {
	             $scope.allEvents = response.data.events;
	             angular.forEach( $scope.allEvents, function( elem, key ) {
	             	elem.ticked = false;
	                elem.buttonlabel = elem.name;
	             });
	        } 
	    });

	    $http.post("/api/getAllManagers",{location:SessionService.currentUser.location}).then(function(response){
	        if (response.status == 200) {
	            response.data.data.forEach(function(elem, key){
	                elem.ticked = false;
	                elem.fullname = elem.first_name+' '+elem.last_name;
	            });
	            $scope.allManagers = response.data.data;
	        }
	    });
        $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.modernWebBrowsers = response.data.data;
            }
        });
    }
    $scope.editWriteUp = function(writeUpData){
    	$http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
	        if ( "200" === response.data.status_code) {
	             $scope.allEvents = response.data.events;
	             angular.forEach( $scope.allEvents, function( elem, key ) {
	                if (elem._id ===  writeUpData.eventInfo[0]._id) {
                        elem.ticked = true;
                    }else{
                        elem.ticked = false;
                    }
	                elem.buttonlabel = elem.name;
	             });
	        } 
	    });

	    $http.post("/api/getAllManagers",{location:SessionService.currentUser.location}).then(function(response){
	        if (response.status == 200) {
                $scope.allManagers = response.data.data;
                angular.forEach( $scope.allManagers, function( elem, key ) {
	                if (elem._id ===  writeUpData.managerInfo[0]._id) {
                        elem.ticked = true;
                    }else{
                        elem.ticked = false;
                    }
	                elem.fullname = elem.first_name+' '+elem.last_name;
	            });	            
	        }
	    });
        $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    if (elem._id ===  writeUpData.empInfo[0]._id) {
                        elem.ticked = true;
                   	}else{
                        elem.ticked = false;
                   	}
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.modernWebBrowsers = response.data.data;
            }
        });
        writeUpData.writeupDate = $filter('date')(writeUpData.writeupDate, 'MM/dd/yyyy');
    	$scope.writeupData = writeUpData;
    }

    $scope.checkActiveInactive = function(writeupDate){
    	var fetchDate = moment(writeupDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        $scope.datebeforefour = moment().subtract(120, 'day')._d.setHours(0, 0, 0, 0);
        var dateBeforeFourMonths = moment($scope.datebeforefour).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
    	if(fetchDate < dateBeforeFourMonths){
    		var cls = 'bg-gray-light';
    		return cls;
    	}
    }

    $scope.showWriteupDetail = function(writeUpData) {
        console.log('writeUpData-----', writeUpData);
        $scope.evtDetail = writeUpData;
    }

    $scope.approveWriteupsView = function(writeupData){
        console.log('evtDetail-----', $scope.evtDetail);
        console.log('writeupData----', writeupData);
        var todaydate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        var writeUpInfo = {
            _id: $scope.evtDetail._id,
            acknowledgeAt: todaydate
        };
        if (writeupData == undefined){
            writeUpInfo.statement = '';
        } else {
            writeUpInfo.statement = writeupData.statement;
        }
        // var schid = $scope.allSchedules[key]._id;
        $http.post("/api/acknowledgeWriteUp",writeUpInfo).then(function(response){
            if (response.data.status_code == 200) {
               toastr.success("Successfully Added!");
               angular.element('#modal-writeup').modal('hide');
               angular.element('#modal-editWriteup').modal('hide');
               $scope.fetchWriteUps();
            }
        });
    }
	
});