angular.module("app").controller("AddMemoController", function($scope, $upload, Upload,$timeout,SessionService, $http, $location, SocketService, moment) {
    // Get all employee listing for drop down
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.groupnamePattern = /^[a-zA-Z0-9\'\#\@\_\-\`]+( [a-zA-Z0-9\'\#\@\_\-\`]+)*$/;
    $http.post("/api/getemplAndManagers", {location : SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    $scope.fetchGroups = function(){
        var condition = {
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0]
        }
         $http.post("/api/fetchGroups", condition).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                });
                $scope.groupArr = response.data.data;
            }
            else {
                console.log('400');
            }
        });
    }

    $scope.openCreateGroup = function(){
        $scope.groupInfo = {};
        $scope.buttonDisabled = false;
        angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
              value.ticked = false;
        });
    }

    $scope.fetchGroups();

    $scope.removeGroupconfirmation = function(groupId){
        $scope.groupId = groupId;
        $('#confirm_del_group').show();
    }

    $scope.removeGroup = function(){
        var groupId = $scope.groupId;
        $http.post("/api/removeMemoGroup", {groupId : groupId}).then(function(response){
            if(response.status == 200){
                $('#confirm_del_group').hide();
                toastr.success("Removed successfully.");
                $scope.fetchGroups();
            }
        });
    }

    $scope.editGroup = function(groupInfo){
        $scope.groupInfo = groupInfo;
        angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
               value.ticked = false;
               angular.forEach(groupInfo.employees, function(v, k){
                    if(value._id === v.user_id){
                        value.ticked = true;
                    }
               });
        });
    }

    var storeSelUserId  = [];
    var phoneNumberList = [];

    // Add Memo from admin/manager
    $scope.addMemo = function(memo) {
        $scope.buttonDisabled = true;
        var x = 0;
        angular.forEach( $scope.groupArr, function( value, key ) {
            if ( value.ticked == true ) {
               angular.forEach(value.employees, function(val, ke){
                    storeSelUserId.push({user_id: val.user_id, mark_read: false});
                    phoneNumberList.push(val.phone);
               })                   
            }
            x++;
        });

        if(x === $scope.groupArr.length){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if ( value.ticked == true ) {
                    if(!_.findWhere(storeSelUserId, {user_id: value._id, mark_read: false})){
                        storeSelUserId.push({user_id: value._id, mark_read: false});
                        phoneNumberList.push(value.phone);
                    }
                }
            });
        }

        if (storeSelUserId.length < 1) {
            toastr.error("Please select atleast one employee to send memo.");
            $scope.buttonDisabled = false;
            angular.element(".multiSelectButton").css("border-color", "red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
        }
        else if($scope.memoForm.$invalid){
            toastr.error("Please fill all the required fields.");
            $scope.buttonDisabled = false;
            angular.element("input.ng-invalid").css("border-color", "red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
            angular.element("input.ng-invalid").focus();
        }
        else{
            var creatorInfo = SessionService.currentUser;
            var first_name, last_name;
            if ( undefined !== SessionService.currentUser.first_name )
                first_name = SessionService.currentUser.first_name;
            else
                first_name = "";          
            if ( undefined !== SessionService.currentUser.last_name )
                last_name = SessionService.currentUser.last_name;
            else
                last_name = "";
            var memos = {
                    title: memo.title,
                    description: memo.description,
                    my_file :$scope.uploaded_files,
                    created_by: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    created_date: moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
                    creator_name: first_name+" "+last_name,
                    sent_to: storeSelUserId,
                    phoneNumberList : phoneNumberList
                };
            $http.post("/api/memos", memos).then(function(response){
                if( "200" === response.data.status_code ) {
                    if(response.data.data._id){
                        var memoNotificationData = [];  
                        angular.forEach(storeSelUserId, function(elem, key){
                            memoNotificationData.push({
                                memo_id:response.data.data._id,
                                receiver_id: elem.user_id,
                                message: memo.title,
                                sender_id: SessionService.currentUser._id,
                                sender_name: first_name+" "+last_name,
                                sent_date: moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
                                is_marked: false
                            });
                        });
                        $http.post("/api/createMemoNotification", memoNotificationData).then(function(response){
                            toastr.success("Memo added successfully.");
                            $location.path('/memolist');
                        });
                    }
                }
            });
        }
    };
    
    $scope.uploadFiles = function (files) {
            var allowedMimeTypes = ["text/plain", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "image/jpeg", "image/pjpeg", "image/png"];
            if (files && files.length) {
                var filecount = 0;
                $scope.fileError = false;
                console.log($scope.progress);
                angular.forEach( files, function( value, key ) {
                    if(value.size > 20971520){
                        toastr.error("Max File Size : 20MB");
                        $scope.fileError = true;
                        $scope.progress = -1;
                    }
                    if (allowedMimeTypes.indexOf(value.type) == -1) {
                        toastr.error("File type is invalid. Please upload with one of the following extension : '.txt','.doc','.docs','.xls','.xlsx','.pdf','.jpg','.jpeg','.png'");
                        $scope.fileError = true;
                        $scope.progress = -1;
                    }
                    filecount++;
                    if(filecount == files.length && !$scope.fileError){
                        $scope.files = files;
                        if (files && files.length) {
                            Upload.upload({
                                url : '/api/memoupload',
                                method: $scope.httpMethod,
                                headers: {'my-header': 'my-header-value'},
                                data : {
                                    myModel : $scope.myModel
                                },              
                                file: files,
                                fileFormDataName: 'myFile'
                            }).then(function (response) {
                                $timeout(function () {
                                    $scope.uploaded_files = response.data.data;
                                });
                            }, function (response) {
                                if (response.status > 0) {
                                    $scope.errorMsg = response.status + ': ' + response.data;
                                }
                            }, function (evt) {
                                $scope.progress = 
                                    Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                            });
                        }
                    }
                });
            }
    };

    $scope.addGroup = function(groupInfo){
        $scope.buttonDisabled = true;
        var storeSelUserId = [];
        angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
           if ( value.ticked === true ) {
               storeSelUserId.push({user_id: value._id, mark_read: false, phone:value.phone});
           }
        });
        
        if (storeSelUserId.length < 1) {
            toastr.error("Please select atleast one employee to add in group.");
            $scope.buttonDisabled = false;
            //angular.element(".multiSelectButton").css("border-color", "red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
        }

        if($scope.groupForm.$invalid){
            toastr.error("Please fill all the required fields.");
            $scope.buttonDisabled = false;
            angular.element("input.ng-invalid").css("border-color", "red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
            angular.element("input.ng-invalid").focus();
        }
        else{
            var groupId = groupInfo._id;
            var groupInfo = {
                groupname: groupInfo.groupname,
                created_by: SessionService.currentUser._id,
                created_date: moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
                employees: storeSelUserId
            };

            $http.post("/api/createMemoGroup", {groupdata:groupInfo,_id: groupId}).then(function(response){
                if( "200" === response.data.status_code ) {
                    toastr.success("Group is created successfully");
                    angular.element('#modal-group').modal('hide');
                    $scope.fetchGroups();
                    angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                        value.ticked = false;
                    });
                }
                else{
                    toastr.error("Something wrong in creating group");
                    $scope.buttonDisabled = false;
                }
            });
        }
    }
});