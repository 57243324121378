angular.module("app").controller("ScheduleController", function($scope, $filter, getweekService, NotificationService,TimeSlotResource, $http,$q,sidepanelactiveService, SessionService, SocketService, $rootScope, $routeParams, moment) {
	$scope.showloader = true;
	$scope.showshifts = false;
	$scope.currDate   = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate(); 
	var currWeekDates = getweekService.getCurrWeek();
	var nextWeekDates = getweekService.getNextWeek(currWeekDates[1].getDay() + 1);
	var nextNextWeekDates = getweekService.getNextWeek(nextWeekDates[1].getDay() + 9);
	sidepanelactiveService.test();
	$scope.modernWebBrowsers = [];	
	$scope.userRole = SessionService.currentUser.roles[0];
	if($routeParams.employeeId && $routeParams.location){
		var userIdstr = $routeParams.employeeId;
		var locationIdstr = $routeParams.location;
		var userId = userIdstr.substr(1);
		var location = locationIdstr.substr(1);
		$http.get('/api/users/:'+userId).success(function(response) {
			console.log('response', response);
			$scope.userloggedName = response.first_name+' '+response.last_name;
        });
	}else{
		var userId = SessionService.currentUser._id;
		var location = SessionService.currentUser.location;
		$scope.userloggedName = SessionService.currentUser.first_name +' '+ SessionService.currentUser.last_name;
	}
	// Get data from TimeSlotResource
	TimeSlotResource.query({locationId : SessionService.currentUser.location},function(response){
	    $scope.slot = response[0];
	});

	//Fetch current week and next week schedules employees
	var inputs = {};
	inputs.startDate = $scope.currDate;
	inputs.endDate = nextNextWeekDates[1];
	inputs.location = SessionService.currentUser.location;
	var events = [];
	$http.post("/api/getCoworkerEmplData",inputs).then(function(response){
        if (response.status == 200) {
        	response.data.data.forEach(function(elem, key){
        		if(elem._id.employee_id != userId){
                    elem.ticked = false;
                    elem.fullname = elem._id.empId+'-'+elem._id.empLname[0]+', '+elem._id.empFname[0];
                    $scope.modernWebBrowsers.push(elem);
            	}
            });
        }
        else {
            console.log('400');
        }
    });
	var $modal = $('#event-modal');
	$('#external-events div.external-event').each(function () {
			// create an Event Object (http://arshaw.com/fullcalendar/docs/event_data/Event_Object/)
			// it doesn't need to have a start or end
			var eventObject = {
				title: $.trim($(this).text()) // use the element's text as the event title
			};
			// store the Event Object in the DOM element so we can get to it later
			$(this).data('eventObject', eventObject);
			// make the event draggable using jQuery UI
			$(this).draggable({
				zIndex: 999,
				revert: true, // will cause the event to go back to its
				revertDuration: 20 //  original position after the drag
			});
	});	

	$scope.twentyfour_to_twelve = function(ti){
		if(ti){
			ti = ti.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [ti];
			if (ti.length > 1) { // If time format correct
				ti = ti.slice (1);  // Remove full string match value
				ti[5] = +ti[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
				ti[0] = +ti[0] % 12 || 12; // Adjust hours
			}
			return ti.join (''); // return adjusted time or original string
		}
	}

	$scope.TradeBoardPost = function(scheduleNewData, shiftTime, title, seltimeslot, dayName){
		$scope.scheduleNewData = scheduleNewData;
		$scope.newTitle = title;
		$scope.dayName = dayName;
		$scope.seltimeslot = seltimeslot;
		$scope.tradedata = {};
		$scope.tradedata.tradePosttype = "onBoard";
	}

	$scope.BackFromTrade = function(tradeId){
		$http.post("/api/takeFromTrade",{tradeId:tradeId}).then(function(innerresponse){
			if ( "200" === innerresponse.data.status_code) {
				toastr.success("Successfully Done !");
				angular.element('#event-modal').modal('hide');	
			}
		});
	}

	$scope.fetchOtherEmployees = function(eventId, scheduleDate, tsl){
		$http.post("/api/otherEmpOnThisEventAndDate",{event:eventId,scheduleDate:scheduleDate, curremployee:userId,timeslot:tsl, locationval : SessionService.currentUser.location}).then(function(innerresponse){
				if ( "200" === innerresponse.data.status_code) {
					if (typeof innerresponse.data!="undefined") {
						$scope.sameemployees = innerresponse.data.data;
					}else{
						$scope.sameemployees = {};
					}
				}
		}); 
	}

	$scope.showOrBackFromTradeboard = function(schId){
		angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
            if ( value.ticked === true ) {
               value.ticked = false;
            }
	    });
		$http.post("/api/checkonTradestatus",{scheduleId:schId}).then(function(innerresponse){
			if(innerresponse.status == 200 && innerresponse.data.data) {
				$scope.showOnBoard = 1;
				$scope.tradeId = innerresponse.data.data._id;
			}else{
				$scope.showOnBoard = 0;
			}
		});
	}

	$scope.empData = function(){
		$http.get('/api/getemployeeSchedule/'+userId+'/'+location).then(function(response){
				var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
				var eventCollection = [];
				var eventShifts = [];
				var sameemployees = {};
				if ( 200 === response.status && response.data.length) {
					$scope.showloader= false;
					var allSchedules = response.data;
					if(SessionService.currentUser.roles[0] == "employee"){
						$scope.userNameCalendaer = "";						
					}else{
						$scope.userNameCalendaer = "("+allSchedules[0].employee_id.first_name + ' '+ allSchedules[0].employee_id.last_name + " schedules)";	
					}
					
					for(var i=0;i<allSchedules.length;i++){
						
						//var starttime = new Date(allSchedules[i].week_of);
						var starttime = moment(allSchedules[i].week_of).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
						if(new Date(allSchedules[i].scheduleDate).getTime() < $scope.currDate.getTime()){
							var classVal = "redstriketext ";
						}else{
							
							var nextWeekDates = getweekService.getNextWeek(currWeekDates[1].getDay() + 2);
							lastday_date = new Date(nextWeekDates[1].setHours(23, 59, 59, 999)).toISOString()
							if (allSchedules[i].scheduleDate > lastday_date) {
								var classVal = " hide-event ";
							}else{
								var classVal = "";
							}
						}
						if(allSchedules[i].timeslot == "Morning"){
							var classVal = classVal + "bg-orange w-700";
							var sortOrder  = 1;
						}
						else if(allSchedules[i].timeslot == "Afternoon"){
							var classVal = classVal + "bg-yellow w-700";
							var sortOrder  = 2;
						}
						else if(allSchedules[i].timeslot == "Night"){
							var classVal = classVal + "bg-blue w-700";
							var sortOrder  = 3;
						}
						else if(allSchedules[i].timeslot == "Late Night"){
							var classVal = classVal + "bg-dark w-700";	
							var sortOrder  = 4;
						}

						if(allSchedules[i].event_account_id.extrashifts && allSchedules[i].event_account_id.extrashifts.length){
                            allSchedules[i].event_account_id.extrashifts.forEach(function(e,k){                                
                                if(allSchedules[i].event_account_id.shift_template_id.length){
                                    var x = 0;
                                    allSchedules[i].event_account_id.shift_template_id.forEach(function(el, ke){
                                        if((el.day === e.day) && (el.timeslot === e.timeslot)){
                                            el.shifts = el.shifts.concat(e.shifts);
                                            return;
                                        }
                                        if(x === allSchedules[i].event_account_id.shift_template_id.length-1){
                                            allSchedules[i].event_account_id.shift_template_id.push(e);
                                        }
                                        x++;
                                    })
                                }else{
                                    allSchedules[i].event_account_id.shift_template_id.push(e);  
                                }                                                 
                            })
                        }
						angular.forEach(allSchedules[i].event_account_id.shift_template_id, function(sft){
							angular.forEach(sft.shifts, function(innerSft){
	    						if(innerSft._id === allSchedules[i].shift_id){
	    							if(allSchedules[i].event_account_id.showendtime){
	    								allSchedules[i].endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
	    							}else{
	    								allSchedules[i].endStr = '';
	    							}
	    							allSchedules[i].event_account_id.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + allSchedules[i].endStr;
	    						}
							});
						});
						if((allSchedules[i].is_approve !== undefined) && (allSchedules[i].is_approve)){
							var newborderclass = " fa fa-check";
							var classVal = classVal + newborderclass;	
						}else{
							var newborderclass = "";
							var classVal = classVal + newborderclass;	
						}
						var twelve=allSchedules[i].event_account_id.shifttime;
						var starttime_m = starttime.setDate(starttime.getDate() + weekdays.indexOf(allSchedules[i].day_of_week));
						eventCollection.push({start:starttime_m,end: starttime_m,title:allSchedules[i].event_account_id.name+' : '+twelve,className: classVal,"allData":allSchedules,"Dayname":allSchedules[i].day_of_week, "weekOff" : allSchedules[i].week_of,"scheduleDate" : allSchedules[i].scheduleDate, "eventId":allSchedules[i].event_account_id._id,"eventDetails":allSchedules[i].event_account_id, "sortOrder" : sortOrder,"timeslot" : allSchedules[i].timeslot, "shifttimetoDisplay" : twelve, "shiftId": allSchedules[i].shift_id, "scheduleDate" : allSchedules[i].scheduleDate, "scheduleId" : allSchedules[i]._id, "scheduleApproveStatus" : allSchedules[i].is_approve});
					}
				}

				if(!response.data.length){
					var calendar = $('#calendar').fullCalendar();
					$scope.showloader= false;
				}else{
					var arr = []; //to collect id values 
					var events = []; //collect unique object
					_.each(eventCollection, function (data, idx) {
					    if (arr.indexOf(data.start+'__'+data.title) == -1) { //check if id value not exits than add it
					        arr.push(data.start+'__'+data.title);//push id value in arr
					        events.push(data); //put object in collection to access it's all values
					    }
					});
					
					/*  Initialize the calendar  */
					var date = new Date();
					var d = date.getDate();
					var m = date.getMonth();
					var y = date.getFullYear();
					var form = '';
					$('#calendar').fullCalendar('destroy');
					var calendar = $('#calendar').fullCalendar({
						minTime: '00:00:00',
						maxTime: '24:00:00',          
						header: {
								left: 'prev,next today',
								right: 'month,agendaWeek,agendaDay',
								center: 'title'
						},
						eventOrder: "sortOrder",
						// LOAD DATA IN THIS GRID - SWAPNESH
						events: events,
						eventClick: function (calEvent, jsEvent, view) {

							var nextWeekDates = getweekService.getNextWeek(currWeekDates[1].getDay() + 2);
							lastday_date = new Date(nextWeekDates[1].setHours(23, 59, 59, 999)).toISOString()
							if (calEvent.scheduleDate > lastday_date) {
								return false;
							}

							$scope.$apply(function () {
								$scope.scheduledData = calEvent.allData;
								$scope.title = calEvent.title;
								$scope.eventId = calEvent.eventId;
								$scope.weekOff = calEvent.weekOff;
								$scope.dayName = calEvent.Dayname;
								$scope.selectedDate = calEvent._start._d;
								$scope.shiftTime = calEvent.shifttimetoDisplay;
								$scope.shiftID = calEvent.shiftId;
								$scope.timeslot = calEvent.timeslot;
								$scope.eventScheduleDate = calEvent.scheduleDate;
								$scope.eventStartDate = new Date(calEvent.eventDetails.start_date);
								$scope.eventEndDate = new Date(calEvent.eventDetails.end_date);
								$scope.shiftData = calEvent.eventDetails.shift_template_id;
								$scope.evtDetail = calEvent.eventDetails;
								$scope.scheduleDate = calEvent.scheduleDate;
								$scope.scheduleId = calEvent.scheduleId;
								$scope.scheduleApproveStatus = calEvent.scheduleApproveStatus;
								$scope.showOrBackFromTradeboard($scope.scheduleId);
								$scope.OtherEmployeesList = $scope.fetchOtherEmployees($scope.eventId, calEvent.scheduleDate, calEvent.timeslot);
							});
							$modal.modal();
						}
					});
				}
		});
	}
	$scope.empData();
	$scope.approveScheduleView = function(sch){
		var todaydate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		$http.post("/api/checkSchedule",{schId:sch, acknowledgeDate : todaydate}).success(function(response){
			if(response.status_code == "200"){
				angular.element('#event-modal').modal('hide');
				SocketService.emit('get availability notification', {user_id: SessionService.currentUser._id});
				SocketService.on('notification icon data', function(data){
					$rootScope.availnotifications = data;
				});	
				$scope.empData();
			}
		})
	}

	$scope.postOnTrade = function(tradedata){
		$scope.tradedata = tradedata;
		var storeSelUserId = {};
		if($scope.tradedata.tradePosttype == "coworker"){
	        angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
	           if ( value.ticked === true ) {
	               storeSelUserId = {user_id: value._id.employee_id};
	               $scope.tradedata.coWorkerUserId = storeSelUserId.user_id;
	           }
	        });
	        if($scope.tradedata.coWorkerUserId){
	        	if($scope.tradedata.exchangeshiftObj){
	        		$scope.tradedata.exchangeshiftStr = $scope.tradedata.exchangeshiftObj.name.split(' | ');
	        		$scope.tradedata.exchangeshift = $scope.tradedata.exchangeshiftStr[2];
	        		$scope.tradedata.exchangeScheduleDate = $scope.tradedata.exchangeshiftObj.schduleDate;
	        		$scope.tradedata.exchangeSchId = $scope.tradedata.exchangeshiftObj.schid;
	        		$scope.tradedata.exchangeEventAccId = $scope.tradedata.exchangeshiftObj.evAcId;
	        	}
	        }
    	}
    	console.log(userId, $scope.userloggedName);
        $scope.tradedata.userId  = userId;
        $scope.tradedata.postedBy = $scope.userloggedName;
        $scope.tradedata.eventId = $scope.eventId;
        $scope.tradedata.eventName = $scope.title;
        $scope.tradedata.shiftId = $scope.shiftID;
        $scope.tradedata.scheduleId = $scope.scheduleId;
        $scope.tradedata.eventScheduleDate = $scope.eventScheduleDate;
        $scope.tradedata.timeslot = $scope.timeslot;
        $scope.tradedata.shiftTime = $scope.shiftTime;
        $scope.tradedata.location = SessionService.currentUser.location;
		$http.post("/api/postOnBoard",$scope.tradedata).then(function(innerresponse){
			if(innerresponse.status == 200){
				toastr.success("Successfully Done !");
				angular.element('#modal-trade').modal('hide');
				angular.element('#event-modal').modal('hide');
			}
			if(innerresponse.status == 208){
				toastr.error("Already posted on trade board");
				angular.element('#modal-trade').modal('hide');
				angular.element('#event-modal').modal('hide');

			}
		});
	}	

	$scope.showShifts = function(){
		angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
            if ( value.ticked === true ) {
           	   $scope.showshifts = true;
           	   $scope.ShiftIds = [];
			   $scope.coworkerShifts = [];
           	   angular.forEach(value.shiftReminderTime, function(v, k){
					$scope.ShiftIds.push(v.shiftId);
           	   });
           	   var varschIds = {schIds : $scope.ShiftIds};
           	   $http.post("/api/fetchSchedulesbyIds",varschIds).then(function(innerresponse){
					if(innerresponse.status == 200){
						angular.forEach(innerresponse.data.data, function(val, ke){
							val.schDateNew = new Date(val.scheduleDate);
							val.schDate = val.schDateNew.getFullYear()+'-' + (val.schDateNew.getMonth()+1) + '-'+val.schDateNew.getDate();
							$scope.coworkerShifts.push({name:val.event_account_id.name+' | '+val.schDate+' | '+$scope.twentyfour_to_twelve(val.shiftReminderTime)+' - '+$scope.twentyfour_to_twelve(val.shiftEndTime), evAcId: val.event_account_id, schid: val._id, schduleDate : val.scheduleDate});
						});
					}
           	    });
            }
        });
	}
});