angular.module("app").controller("ForgetController", function($scope, $http, $timeout) {
  $scope.credentials = { email: "" };
  var sendingData = {};
  $scope.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  $scope.forgetpassword = function() {
    $http.post("/api/forgetpassword", { email: $scope.credentials.email }).then(function( response ) {
      if (response.data.status_code === "200") {
        $scope.showforgetmessage = true;
          $scope.alertType = "alert alert-success";
          $scope.message = "Password changed successfully, check your inbox!!";
            $timeout(function() {
            $scope.showforgetmessage = false;
          }, 3000);
      }
      else if(response.data.status_code === "202"){
        $scope.showforgetmessage = true;
          //$scope.alertType = "alert alert-danger";
          //$scope.message = "Your Email doesn't exists";
          $scope.alertType = "alert alert-success";
          $scope.message = "Password changed successfully, check your inbox!!";
            $timeout(function() {
            $scope.showforgetmessage = false;
          }, 3000);
      }
      else{
        $scope.showforgetmessage = true;
          $scope.alertType = "alert alert-danger";
          $scope.message = "Something went wrong!!";
            $timeout(function() {
            $scope.showforgetmessage = false;
          }, 3000);
      }
    });

  };

});