angular.module("app").controller("EmpSchController", function($rootScope, $scope, $filter, ports, $location, SessionService, SocketService, $http, UserService, $timeout, getweekService, NgTableParams, $window, $route, moment) {
    $scope.weekdays  = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $scope.timeslots = ["Morning", "Afternoon", "Night", "Late Night"];
    $scope.user = SessionService.currentUser;
    $scope.currpage = $location.absUrl().split('/')[3];
//    $scope.schedules = [];
    $scope.scheduleIds = [];
    $scope.disenable = '';
    $scope.currDate   = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate(); 
    $scope.start_end = '';
    $scope.shift = 'Morning';
    $scope.searchText = {};
    $scope.haveResult = false;
    $scope.searchOccur = false;
    $scope.searchText.val = '';
    $scope.removeshiftsArray = [];
    $scope.removeTradeShiftsArray = [];
    var currWeekDates = getweekService.getCurrWeek();
    $scope.weekdate = $filter('date')(currWeekDates[0], 'MM/dd/yyyy') + ' To '+ $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
    $scope.busy = false;
    $scope.selectedUserinfo = {}; 
    $scope.dateRange = {};
    $scope.dateRange.start_date = $filter('date')(currWeekDates[0], 'MM/dd/yyyy');
    $scope.dateRange.end_date = $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
    $scope.screenlockcheck = function(){
        $http.post("/api/checkScreenLock", {location:SessionService.currentUser.location}).then(function(response){
            if (200 == response.status) {
                if(response.data.data){
                    $scope.screenlockedDetails = response.data.data;
                    if($scope.screenlockedDetails._id && ($scope.screenlockedDetails._id !== $scope.user._id)){
                        $scope.disenable = 'disableTable';
                    }else{
                        $scope.disenable = 'removedisableTable';
                    }
                    
                }
            } 
        });
    }
    $scope.$watch(
        "weekdate",
        function handleWeekChange() {
            $scope.employeeMarkAvail = [];
            $scope.eventAccountList = [];
            $scope.emplist = [];
            $scope.days = [];
            $scope.$on('startendString', function (event, args) {
                $scope.start_end = args.startendstringJson;
            });
            if ($scope.start_end) {
                 var startEndArr = $scope.start_end.split(' / ');
                 $scope.startDate = new Date(startEndArr[0]);
                 $scope.endDate = new Date(startEndArr[1]);
                 $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
            }
            else{
                $scope.startDate = currWeekDates[0];
                $scope.endDate = currWeekDates[1];
                $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
            }

            if($scope.dateRange == null){
                var StartDate = currWeekDates[0];
                var EndDate = currWeekDates[1];
            }else{
                var StartDate = $scope.dateRange.start_date;
                var EndDate = $scope.dateRange.end_date;
            }
            if (StartDate && EndDate) {
                $scope.selectedDate = EndDate;
                var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;
                var diff = 6; //To Resolve day night saving time issue because in this change it return somewhere less than 6 and can be more than 6.
            }
            var sevenWeekDayArr = [];
            for(var i=0; i<= diff; i++) {
                sevenWeekDayArr[i] = new Date(StartDate.getTime() + i*86400000);
                sevenWeekDayArr[i].dayName = $scope.weekdays[sevenWeekDayArr[i].getDay()];
                sevenWeekDayArr[i].differ = Math.floor((new Date(StartDate.getTime() + i*86400000) - new Date().getTime())/86400000);
            }
            $scope.sevenWeekDayArr = sevenWeekDayArr;
            
            if ($scope.currpage == 'empschedule') {
                $scope.showloader = true;
                $scope.empAvailbilityPage = 1;
                $scope.totalEmppages = 1;
                $scope.busy = false;
                $scope.days = [];
                $scope.employeeMarkAvail = [];
                $scope.fetchEmpList = [];
                $scope.fetchEmpl();
                $http.get("/api/getAllInitalzips").then(function(response) {
                    if(response.status == 200){
                        $scope.AllInitials = response.data.data;
                        $scope.fetchemployee();
                    }
                });
            }
            if ($scope.currpage == 'schedule') {
                $scope.showloader = true;
                if($scope.searchText.val){
                    if($scope.allEvents){
                        angular.forEach( $scope.allEvents, function( value, key ) {
                               if ( $scope.searchText.val == value.name ) {
                                    value.ticked = true;
                               }
                        });
                    }
                }else{
                    $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
                         if ( "200" === response.data.status_code ) {
                             $scope.allEvents = response.data.events;
                             angular.forEach( $scope.allEvents, function( value, key ) {
                              value.buttonlabel = value.name;
                             });
                         } 
                    });
                }
                $scope.callScheduleFunction();
                $scope.screenlockcheck();
            }
            if ($scope.currpage == 'reportSchedule') {
                $scope.showloader = true;
                $scope.scheduleIdsUnique = [];
                $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
                    $scope.showloader = true;
                    if ("200" === response.data.status_code) {
                        $scope.showloader = false;
                        $scope.allEvents = response.data.events;
                    } 
                });
                $http.get("/api/getAllInitalzips").then(function(response) {
                    if(response.status == 200){
                        $scope.AllInitials = response.data.data;
                        $scope.fetchscheduleReport();
                    }
                });  
                $scope.fetchEmpl();
            }
        }
    );



    $scope.twentyfour_to_twelve = function(ti){
        ti = ti.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [ti];
        if (ti.length > 1) { // If time format correct
            ti = ti.slice (1);  // Remove full string match value
            ti[5] = +ti[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            ti[0] = +ti[0] % 12 || 12; // Adjust hours
        }
        return ti.join (''); // return adjusted time or original string
    }

    $scope.schDetail = function(schId, notes){
        $scope.noteitem = {};
        $scope.scheduleshiftId = schId;
        $http.post("/api/checkScheduleDetails",{schId:schId}).success(function(response){
            if(response.status_code == "200"){
                $scope.noteitem.noteInput = response.data.schnotes;
            }
        });
    }

    $scope.saveScheduleNote = function(scheduleId, note){
        var schJson = {};
        schJson.scheduleId = scheduleId;
        schJson.schNote = note;
        $http.post("/api/saveschNote", schJson).then(function(response) {
            toastr.success("Note is added updated successfully.");
            angular.element('#modal-schNote').modal('hide');
        });
    }

    $scope.callScheduleFunction = function(){
        $scope.scheduleArr = [];
        $http.get("/api/getAllInitalzips").then(function(response) {
            if(response.status == 200){
                $scope.AllInitials = response.data.data;
                $scope.fetchTotalShiftsInWeek();
                $scope.fetchTotalExtraShiftsInWeek();
                $scope.fetchWeekSchedule();
                $scope.fetchScheduledShifts();
            }
        });
    }
    
    $scope.fetchTotalShiftsInWeek = function(){
        $http.post("/api/fetchTotalShiftsInWeek", {
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            "to": $scope.endDate,
            "from": $scope.startDate,
            "location":SessionService.currentUser.location
        }).then(function(response) {
            $scope.totalShifts = response.data.totalShifts ? response.data.totalShifts : 0;
        });
    };

    $scope.fetchTotalExtraShiftsInWeek = function(){
        $http.post("/api/fetchTotalExtraShiftsInWeek", {
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            "to": $scope.endDate,
            "from": $scope.startDate,
            "location":SessionService.currentUser.location
        }).then(function(response) {
            $scope.totalExtraShifts = response.data.extrashifts ? response.data.extrashifts : 0;
        });
    };

    $scope.fetchScheduledShifts = function(){
        $http.post("/api/fetchScheduledShifts", {
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            "to": $scope.endDate,
            "from": $scope.startDate,
            "location":SessionService.currentUser.location
        }).then(function(response) {
            $scope.totalScheduledShifts = response.data.data;
        });
    };


    $scope.fetchWeeklyEvents = function(){
        var search = "";
    	if ($scope.searchText.val) {
            var search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.showloader = true;
                $scope.haveResult = false;
                $scope.pageNum = params.page(); 
                return $http.post("/api/getweeklyevents", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    "to": $scope.endDate,
                    "from": $scope.startDate,
                    "location":SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    response.data.data.forEach(function(elem, key){
                        if(elem.extrashifts.length){
                            elem.extrashifts.forEach(function(e,k){
                                e.shifts.forEach(function(en,kn){
                                    en.extrashift = true;
                                });
                                if((new Date($scope.startDate).getTime() <= new Date(e.shiftdate).getTime()) && ( new Date(e.shiftdate).getTime() <= new Date($scope.endDate).getTime()) && e.shifts.length){
                                    if(elem.shift_template_id.length){
                                        var x = 0;
                                        elem.shift_template_id.forEach(function(el, ke){
                                            if((el.day === e.day) && (el.timeslot === e.timeslot)){
                                                el.shifts = el.shifts.concat(e.shifts);
                                                return;
                                            }
                                            if(x === elem.shift_template_id.length-1){
                                                elem.shift_template_id.push(e);
                                            }
                                            x++;
                                        })
                                    }else{
                                      elem.shift_template_id.push(e);  
                                    }                                                 
                                }
                            })
                        }
                        elem.shifttobescheduled = $scope.fetchshift(elem.shift_template_id, elem._id);
                    });
                    $scope.eventAccountList = response.data.data;
                    $scope.eventAccountList_status = response.data.status_code;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    $scope.fetchschedule();
                    return $scope.eventAccountList;
                })
            }
        });
    };

    $scope.fetchEmpl = function(){
        $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.modernWebBrowsers = response.data.data;
            }
            else {
                console.log('400');
            }
        });
    }

    $scope.accountBasedSearch = function(){
        $scope.searchOccur = true;
        $scope.searchText.val = '';
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                        $scope.searchText.val = value.name;
                   }
            });
        }
        if(!$scope.searchText.val){
            $scope.searchText.val = '';
        }
        $scope.showloader = false;
        $scope.eventpage = 1;
        $scope.totalpages = 1;
        $scope.pageNum = 1;
        $scope.busy = false;
        $scope.eventAccountList = [];
        $scope.fetchWeeklyEvents();
    }

    $scope.closeSearch = function(){
        $scope.searchText.val = '';
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                if( value.ticked === true ){
                    $scope.searchText.val = value.name;
                }
            });
        }
        if(!$scope.searchText.val){
            $scope.searchText.val = '';
            $scope.showloader = false;
            $scope.eventpage = 1;
            $scope.totalpages = 1;
            $scope.pageNum = 1;
            $scope.busy = false;
            $scope.eventAccountList = [];
            $scope.fetchWeeklyEvents();
        }   
    }

    $scope.closeEmpAvailSearch = function(){
        $scope.searchEmployeeId = '';
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if ( value.ticked === true ) {
                   $scope.empInfo = value;
                   $scope.searchEmployeeId = $scope.empInfo._id;
                   $scope.showloader = true;
                   $scope.empAvailbilityPage = 1;
                   $scope.totalEmppages = 1;
                   $scope.busy = false;
                   $scope.days = [];
                   $scope.employeeMarkAvail = [];
                   $scope.fetchEmpList = [];
                }
            });
        }
        if(!$scope.searchEmployeeId){
               $scope.searchEmployeeId = '';
               $scope.showloader = true;
               $scope.empAvailbilityPage = 1;
               $scope.totalEmppages = 1;
               $scope.busy = false;
               $scope.days = [];
               $scope.employeeMarkAvail = [];
               $scope.fetchEmpList = [];
               $scope.fetchemployee();
        }
    }

    $scope.fetchWeekSchedule = function(){
        $http.post("/api/fetchScheduledShiftsData", {
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            "to": $scope.endDate,
            "from": $scope.startDate,
            "location":SessionService.currentUser.location
        }).then(function(response) {
            $scope.weeklyscheduleData = response.data.data;
            $scope.fetchWeeklyEvents();
        });
    }
    
    $scope.fetchschedule = function(){
        $scope.employeeMarkAvail = [];
        if($scope.eventAccountList){
            $http.post('/api/getempschedule', {
                "to": $scope.endDate,
                "from": $scope.startDate,
                "owner_id": SessionService.currentUser._id,
                "role": SessionService.currentUser.roles[0],
                "location":SessionService.currentUser.location
            }).then(function(response) {
                $scope.showloader = false;
                response.data.data.forEach(function(elem, key){
                    if (elem.result.length) {
                        elem.result.forEach(function(el, k){
                            if ((el.employee_id.is_deleted) == true) {
                                elem.result.splice(k,1);
                            }
                        });
                        if (elem.result.length) {
                            $scope.employeeMarkAvail.push(elem);
                        }
                    }
                });
            })
    	}

    	if($scope.employeeMarkAvail){
	    	var schedule = {};
	        schedule.from = $scope.startDate;
	        schedule.to = $scope.endDate;
            schedule.owner_id = SessionService.currentUser._id;
            schedule.role = SessionService.currentUser.roles[0];
            schedule.location = SessionService.currentUser.location;
	        $scope.showloader = true;
            $scope.selectedEmpArr = [];
            $scope.scheduleArr = [];
	        $http.post('/api/schedulereport', schedule).then(function(response) {
	            $scope.showloader = false;	           
                if(response.data.data.length){
    	            response.data.data.forEach(function(elem, key){
    	            	var id = elem.shift_id+'_'+elem.event_account_id+'_'+elem.day_of_week+'_'+elem.timeslot+'_'+elem.shiftReminderTime+'_'+elem.endshiftTime+'_value';
    	            	var shiftId = elem.shift_id;
    	            	var empjson = {};
    	            	var empjson = {
                                fname: elem.employee_id.first_name,
                                lname: elem.employee_id.last_name,
                                emp_id: elem.employee_id._id,
                                empRandomGeneratedID_id: elem.employee_id.employeeid,
                                fullname : elem.employee_id.employeeid+' - '+elem.employee_id.last_name+', '+elem.employee_id.first_name
                        };
                        $scope.selectedEmpArr[shiftId] = empjson;
                        var weekDate = new Date($scope.startDate.getTime());
                        var sheduleDate = weekDate.setDate(weekDate.getDate() + $scope.weekdays.indexOf(elem.day_of_week));
                        //var sheduleDate = weekDate;
                        var nestedObj = {};
                        var nestedObj = {
                            shift_id: shiftId,
                            event_id: elem.event_account_id,
                            employeeId: elem.employee_id._id,
                            day: elem.day_of_week,
                            timeslot: elem.timeslot,
                            aligned_by: $scope.user._id,
                            week_of: $scope.startDate,
                            shiftReminderTime : elem.shiftReminderTime,
                            endshiftTime : elem.shiftEndTime,
                            scheduleDate: moment(sheduleDate).toISOString()
                        };
                        $scope.scheduleArr.push(nestedObj);
    	            });
                }
	        }); 
    	}
    }

    $scope.fetchEmployeeAvail = function(){
        angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
            if ( value.ticked === true ) {
               $scope.empInfo = value;
               $scope.searchEmployeeId = $scope.empInfo._id;
               $scope.showloader = true;
               $scope.empAvailbilityPage = 1;
               $scope.totalEmppages = 1;
               $scope.busy = false;
               $scope.days = [];
               $scope.employeeMarkAvail = [];
               $scope.fetchEmpList = [];
               $scope.fetchemployee();
            }
        });
    }

    $scope.fetchemployeeOnChangeShift = function(){
        $scope.showloader = true;
        $scope.empAvailbilityPage = 1;
        $scope.totalEmppages = 1;
        $scope.busy = false;
        $scope.days = [];
        $scope.employeeMarkAvail = [];
        $scope.fetchEmpList = [];
        $scope.fetchemployee();
    }

    $scope.fetchemployee = function() {
        $scope.showloader = true;
        if($scope.searchEmployeeId){
            $scope.employeeId = $scope.searchEmployeeId;
        }else{
            $scope.employeeId = '';
        }           
        $http.post('/api/getempschedulelist', {
            "to": $scope.endDate,
            "from": $scope.startDate,
            "owner_id": SessionService.currentUser._id,
            "role": SessionService.currentUser.roles[0],
            "searchEmpId" : $scope.employeeId,
            "location":SessionService.currentUser.location
        }).then(function(response) {
            $scope.showloader = false;
            $scope.employeeMarkAvail = [];
            $scope.days = [];
            $scope.fetchEmpList = response.data.data;
            $scope.fetchEmpList.forEach(function(elem, key){
                if (elem.result.length) {
                    elem.result.forEach(function(el, k){
                        if ((el.employee_id.is_deleted) == true) {
                            elem.result.splice(k,1);
                        }
                    });
                    if (elem.result.length) {
                        $scope.employeeMarkAvail.push(elem);
                    }
                }
            });
            $scope.employeeMarkAvail_statuscode = response.data.status_code;
            if ($scope.employeeMarkAvail.length) {
                angular.forEach($scope.employeeMarkAvail, function(item) {
                    $scope.emplist = [];
                    angular.forEach(item.result, function(emp) {
                        if ($scope.shift == 'Morning') {
                            var textclass = (emp.is_morning_scheduled_force) ? emp.is_morning_scheduled_type+'forced' : ((emp.is_morning_scheduled_type) ? emp.is_morning_scheduled_type :'')
                        }
                        if ($scope.shift == 'Afternoon') {
                            var textclass = (emp.is_afternoon_scheduled_force) ? emp.is_afternoon_scheduled_type+'forced' : ((emp.is_afternoon_scheduled_type) ? emp.is_afternoon_scheduled_type :'');
                        }
                        if ($scope.shift == 'Night') {
                            var textclass = (emp.is_night_scheduled_force) ? emp.is_night_scheduled_type+'forced' : ((emp.is_night_scheduled_type) ? emp.is_night_scheduled_type :'');
                        }
                        if ($scope.shift == 'LateNight') {
                            var textclass = (emp.is_late_night_scheduled_force) ? emp.is_late_night_scheduled_type+'forced' : ((emp.is_late_night_scheduled_type) ? emp.is_late_night_scheduled_type :'');
                        }

                        if(textclass == 'yes'){
                            var textOrder = 1;
                        }else if(textclass == 'maybe'){
                            var textOrder = 2;
                        }else if(textclass == 'no'){
                            var textOrder = 3;
                        }else if(textclass == 'yesforced'){
                            /*var textclass = 'yes';
                            var textOrder = 1;*/
                            var textOrder = 5;
                        }else if(textclass == 'maybeforced'){
                            var textOrder = 6;
                        }
                        
                        if(!emp.employee_id.address){
                            emp.employee_id.address = {};
                        }
                        var found = $filter('filter')($scope.emplist, {
                            fname: emp.employee_id.first_name,
                            lname: emp.employee_id.last_name,
                            emp_id: emp.employee_id._id,
                            empRandomGeneratedID_id: emp.employee_id.employeeid,
                            fullname : emp.employee_id.first_name+' '+emp.employee_id.last_name,
                            textclass: textclass,
                            textOrder : textOrder,
                            zipcode : emp.employee_id.address.zip
                        }, true);
                        if (!found.length) {
                            if(textclass){
                                $scope.emplist.push({
                                    fname: emp.employee_id.first_name,
                                    lname: emp.employee_id.last_name,
                                    emp_id: emp.employee_id._id,
                                    empRandomGeneratedID_id: emp.employee_id.employeeid,
                                    fullname : emp.employee_id.first_name+' '+emp.employee_id.last_name,
                                    textclass : textclass,
                                    textOrder : textOrder,
                                    zipcode : emp.employee_id.address.zip
                                });
                            }
                        }
                    });
                    if($scope.emplist.length){
                        var insertedjson = {
                            day: moment(item.date).format("dddd"),
                            employees: $scope.emplist
                        };
                    }
                    $scope.days.push(insertedjson);
                });
            }
        });
    }

 
    $scope.fetchscheduleReportOnChangeShift = function(){
        $scope.showloader = true;
        $scope.scheduleIdsUnique = [];
        $scope.fetchscheduleReport();
    }

    $scope.fetchscheduleReport = function() {
        if($scope.searchEventId){
            $scope.eventId = $scope.searchEventId;
        }else{
            $scope.eventId = '';
        }
        var schedule = {};
        schedule.eventId = $scope.eventId;
        schedule.shift = $scope.shift;
        schedule.from = $scope.startDate;
        schedule.to = $scope.endDate;
        schedule.location = SessionService.currentUser.location;
        $http.post('/api/schedulereportList', schedule).then(function(response) {
            if ( 200 === response.status ) {
                $scope.showloader = false;
                $scope.scheduleIdsUnique = response.data.data;
                $scope.schedules_status = response.status;
            }
        });  
    }

    $scope.saveschedule = function() {
        if($scope.scheduleArr.length){
            $scope.newscheduleArr = [];
            var x = 1;
            $scope.scheduleArr.forEach(function(el,ke){
                if(el.usedEntry){
                    $scope.newscheduleArr.push(el);
                }
                if(el.action && el.usedEntry){
                    var removeObj = {"tmsl" : el.timeslot, "shift" : el.shift_id, "eventId" :el.event_id};
                    $scope.removeshiftsArray.push(removeObj);
                }
                if(!el.action && el.usedEntry){
                    var removeFromTradeObjifExistsObj = {"tmsl" : el.timeslot, "shift" : el.shift_id, "eventId" :el.event_id};
                    $scope.removeTradeShiftsArray.push(removeFromTradeObjifExistsObj);
                }
                if(x == $scope.scheduleArr.length){
                    $http.post('/api/saveschedule', {
                        "data": $scope.newscheduleArr
                    }).then(function(response) {
                        if($scope.removeshiftsArray.length){
                            $http.post("/api/removeshiftfromTradeBoardAfterSchedule", {"removeShifts" : $scope.removeshiftsArray});
                        }
                        if($scope.removeTradeShiftsArray.length){
                            $http.post("/api/removeshiftfromTradeBoardAfterSchedule", {"removeShifts" : $scope.removeTradeShiftsArray});
                        }
                        toastr.success("Schedule updated successfully.");
                        $rootScope.scheduleForm = 'end';
                        $scope.callScheduleFunction();
                    })
                }
                x++;
            })
        }else{
            toastr.error("Please do some updates in scheduling.");
        }
    }

    $scope.clearschedule = function() {
        $('#confirm_change').show();
    }
    $scope.cleardata = function() {
        if($scope.scheduleArr.length){
            $scope.scheduleArr = [];
            $scope.empid = "";
            $('#confirm_change').hide();
            toastr.success("Data is successfully cleared.");
        }else{
            toastr.error("No updates to clear.");
        }
    }
    $scope.cancelbtn = function() {
        $('#confirm_change').hide();
    }    
    
    $scope.selectedUser = function(selected){
        if (selected) {
            if(selected.title){
                $rootScope.scheduleForm = 'start';
            }
            var empid = selected.originalObject.emp_id;
            $scope.selectedval = selected.originalObject;
            var selectedUserInfo = this.id.split('_');
            var focusInputElem = document.getElementById(this.id+'_value');
            angular.element('#focusedId').val(this.id+'_value');
	        $scope.focusState = 'Out';
	        focusInputElem.classList.add('small-input');
            var shift = selectedUserInfo[0];
            angular.element('#'+shift+'_selectedval').val(empid);
            var eventAccount = selectedUserInfo[1];
            var week = selectedUserInfo[2];
            var tmsl = selectedUserInfo[3];
            var shiftStartTime = selectedUserInfo[4];
            var endshiftTime = selectedUserInfo[5];
            
            var found = $filter('filter')($scope.scheduleArr, {
            shift_id: shift,
            event_id: eventAccount,
            employeeId: empid,
            day: week,
            timeslot: tmsl,
            aligned_by: $scope.user._id,
            week_of: $scope.startDate,
            shiftReminderTime : shiftStartTime,
            endshiftTime : endshiftTime
            }, true);


            var foundbutRemoved = $filter('filter')($scope.scheduleArr, {
            shift_id: shift,
            event_id: eventAccount,
            employeeId: empid,
            day: week,
            timeslot: tmsl,
            aligned_by: $scope.user._id,
            week_of: $scope.startDate,
            shiftReminderTime : shiftStartTime,
            endshiftTime : endshiftTime,
            action : "remove"
            }, true);

            var weekDate = new Date($scope.startDate.getTime());
            var sheduleDate = weekDate.setDate(weekDate.getDate() + $scope.weekdays.indexOf(week));
            //var sheduleDate = weekDate;
            if (!found.length ||foundbutRemoved.length) {
                var foundAlreadyAlignShift = {
                shift_id: shift,
                event_id: eventAccount,
                day: week,
                timeslot: tmsl,
                aligned_by: $scope.user._id,
                week_of: $scope.startDate,
                shiftReminderTime : shiftStartTime,
                endshiftTime : endshiftTime
                };
                if($scope.scheduleArr.length){
                    _.each($scope.scheduleArr, function(data, idx) {
                        if(_.isMatch(data, foundAlreadyAlignShift)) {
                            mainArrIndex = idx;
                            var removeObj = {"tmsl" : foundAlreadyAlignShift.timeslot, "shift" : foundAlreadyAlignShift.shift_id, "eventId" :foundAlreadyAlignShift.event_id};
                            $scope.removeshiftsArray.push(removeObj);
                            $scope.scheduleArr.splice(mainArrIndex, 1);
                            //return;
                        }
                    });
                }
                nestedObj = {
                    shift_id: shift,
                    event_id: eventAccount,
                    employeeId: empid,
                    day: week,
                    timeslot: tmsl,
                    aligned_by: $scope.user._id,
                    week_of: $scope.startDate,
                    shiftReminderTime : shiftStartTime,
                    endshiftTime : endshiftTime,
                    scheduleDate: moment(sheduleDate).toISOString(),
                    usedEntry:true
                };
                $scope.scheduleArr.push(nestedObj);
            }
        }
        else{
          var selectedUserInfo = this.id.split('_');
          var shift = selectedUserInfo[0];
          var eventAccount = selectedUserInfo[1];
          var week = selectedUserInfo[2];
          var tmsl = selectedUserInfo[3];
          var shiftStartTime = selectedUserInfo[4];
          var endshiftTime = selectedUserInfo[5];
          var removeObj = {
            shift_id: shift,
            event_id: eventAccount,
            day: week,
            timeslot: tmsl,
            aligned_by: $scope.user._id,
            week_of: $scope.startDate,
            shiftReminderTime : shiftStartTime,
            endshiftTime : endshiftTime
            };
            _.each($scope.scheduleArr, function(data, idx) {
                if (_.isMatch(data, removeObj)) {
                    _.extend(data, {action: 'remove', usedEntry:true});
                }
            });
          angular.element('#'+shift+'_selectedval').val('');
        }
    };

    $scope.yesMaybeEmployee = function (str, timeoutPromise) {
        $scope.yesMaybeEmployeelist = [];
        var selectedUserInfo = document.activeElement.id.split('_');
        var week = selectedUserInfo[2];
        var tmsl = selectedUserInfo[3];
        if ($scope.employeeMarkAvail.length) {            
            angular.forEach($scope.employeeMarkAvail, function(item) {
                 $scope.mainSchIndex = -1;
                if(moment(item.date).format("dddd") == week){
                    angular.forEach(item.result, function(emp) {
                        emp.employee_id.employeeid = emp.employee_id.employeeid.toString();
                        if (tmsl == 'Morning') {
                            var schedule_type = emp.is_morning_scheduled_type;
                        }
                        else if (tmsl == 'Afternoon') {
                            var schedule_type = emp.is_afternoon_scheduled_type;
                        }
                        else if (tmsl == 'Night') {
                            var schedule_type = emp.is_night_scheduled_type;
                        }
                        else if (tmsl == 'Late Night') {
                            var schedule_type = emp.is_late_night_scheduled_type;
                        }
                        var scheduleArrEachLen = 0;
                        if($scope.scheduleArr.length){
                            $scope.mainSchIndex = -1;
                            _.each($scope.scheduleArr, function(data, idx) {
                                var matchObject = {day:week,timeslot:tmsl, employeeId: emp.employee_id._id};
                                if (_.isMatch(data, matchObject)) {
                                    if(data.action !== 'remove'){
                                        $scope.mainSchIndex = idx;
                                        return;
                                    }
                                }
                                scheduleArrEachLen++;
                            });
                        }
                        if(!emp.employee_id.address){
                            emp.employee_id.address = {};
                        }
                        if(scheduleArrEachLen === $scope.scheduleArr.length){
                            if(schedule_type != 'no' && $scope.mainSchIndex < 0){
                                $scope.yesMaybeEmployeelist.push({
                                    fname: emp.employee_id.first_name,
                                    lname: emp.employee_id.last_name,
                                    emp_id: emp.employee_id._id,
                                    empRandomGeneratedID_id: emp.employee_id.employeeid,
                                    fullname : emp.employee_id.employeeid+'-'+emp.employee_id.last_name+','+emp.employee_id.first_name+'-'+$scope.matchZipInInitial(emp.employee_id.address.zip)
                                });
                            }
                        }
                       
                    });
                }
            });
            var matches = [];
            $scope.yesMaybeEmployeelist.forEach(function(person) {
			    var fullName = person.fname + ' ' + person.lname;
			    if ((person.fname.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
			        (person.lname.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
			        (person.empRandomGeneratedID_id.indexOf(str) >= 0) ||
			        (fullName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
                        matches.push(person);
                        matches = _.uniq(matches, 'emp_id');
			    }
			});
            return $timeout(function () {
                return {"data": matches};
            }, 1000);
        } else{
            var matches = [];
            return $timeout(function () {
                return {"data": matches};
            }, 1000);
        } 
    };

    $scope.focusIn = function() {
        if(document.getElementById(document.activeElement.id).value){
          angular.element('#focusedId').val(document.activeElement.id);
          var focusInputElem = document.getElementById(document.activeElement.id);
          $scope.focusState = 'In';
          focusInputElem.classList.remove('small-input');
        }
    }

    $scope.focusOut = function() {
        var blurId = angular.element('#focusedId').val();
        var lastUnderscore = blurId.lastIndexOf("_");
        var withoutvalue = blurId.substring(0,lastUnderscore);
        var sftid = blurId.split('_');
        var checkval = angular.element('#'+sftid[0]+'_selectedval').val();
        if(!checkval){
            $scope.$broadcast('angucomplete-alt:clearInput', withoutvalue);
        }else{
            if(blurId){
              var focusInputElem = document.getElementById(blurId);
              $scope.focusState = 'Out';
              focusInputElem.classList.add('small-input');
            }
        }
    }

    $.fn.ignore = function(sel){
        return this.clone().find(sel||">*").remove().end();
    };

    $scope.showhide = function(index){
        $('.table'+index+' tr:first i#plusminus').toggleClass("fa-minus-circle");
        $('.table'+index+' tr').not(':first').toggle();
        $('tr.weekdaysrows').each(function() {
            if (!$.trim($(this).ignore("th").text())) $(this).remove();
        });
    }

    $scope.haveshifts = function(wholeShiftsArr){
        if(wholeShiftsArr.length){
            for(var w = 0;w < wholeShiftsArr.length; w++){
                if(wholeShiftsArr[w].shifts.length){
                    return true;
                    break;
                }
            }            
        }else{
            return false;
        }
    }

    $scope.fetchshift = function(wholeShiftsArr, eventAccount){
        if(wholeShiftsArr.length){
            var totalshifts = 0;
            for(var w = 0;w < wholeShiftsArr.length; w++){
                if(wholeShiftsArr[w].shifts.length){
                    totalshifts = totalshifts + wholeShiftsArr[w].shifts.length;
                }
            }            
        }else{
            $scope.shiftDataJson = {};
            $scope.shiftDataJson.shiftTextClass = 'c-orange';
            $scope.shiftDataJson.shifttobescheduleText = 'No Shifts Added';
            return $scope.shiftDataJson;   
        }
        var schcount = 0; 
        $scope.weeklyscheduleData.forEach(function(el, k){
            if (el.event_account_id == eventAccount) {
                schcount++;
            }
        });
        $scope.shifttobeschedule = totalshifts - schcount;
        $scope.shiftDataJson = {};

        if($scope.shifttobeschedule){
            $scope.shiftDataJson.shiftTextClass = 'c-red';
            $scope.shiftDataJson.shifttobescheduleText = 'Shifts to be Filled : '+$scope.shifttobeschedule;
        }else{
            $scope.shiftDataJson.shiftTextClass = 'c-green';
            $scope.shiftDataJson.shifttobescheduleText = "All Shifts Scheduled";
        }
        return $scope.shiftDataJson;     
    }

    $scope.matchZipInInitial = function(zipvalue){
        var zipVal = '';
        if(zipvalue){
            for(i = 0; i < $scope.AllInitials.length; i++){
                if($scope.AllInitials[i].zipcodes.indexOf(zipvalue) >= 0){
                    zipVal = $scope.AllInitials[i].initial;
                    break;
                }
            }
        }
        return zipVal;
    }

    $scope.checkOnBoard = function(tradedata, timeslot, eventId, day_of_week, eventtitle){
        $http.post("/api/checkonTradestatus",{shiftId:tradedata._id, eventId:eventId, timeslot:timeslot}).then(function(innerresponse){
            if(innerresponse.status == 200 && !innerresponse.data.data){
                $scope.postOnTrade(tradedata, timeslot, eventId, day_of_week, eventtitle);
            }else if(innerresponse.status == 200 && innerresponse.data.data) {
                $scope.tradeId = innerresponse.data.data._id;
                $('#confirm_trade').show();
            }
        });
    }

    $scope.BackFromTradeDocument = function(tradeId){
        $http.post("/api/takeFromTrade",{tradeId:tradeId}).then(function(innerresponse){
            if ( "200" === innerresponse.data.status_code) {
                toastr.success("Successfully Taken Back !");
                $('#confirm_trade').hide();
            }
        });
    }

    $scope.postOnTrade = function(tradedata, timeslot, eventId, day_of_week, eventtitle){
        var weekDate = new Date($scope.startDate.getTime());
        var sheduleDate = weekDate.setDate(weekDate.getDate() + $scope.weekdays.indexOf(day_of_week));
        $scope.tradedata = {};  
        $scope.tradedata.userId  = SessionService.currentUser._id;
        $scope.tradedata.eventId = eventId;
        $scope.tradedata.eventName = eventtitle+' : '+$scope.twentyfour_to_twelve(tradedata.startShiftTime);
        $scope.tradedata.shiftId = tradedata._id;
       // $scope.tradedata.scheduleId = $scope.scheduleId;
        $scope.tradedata.eventScheduleDate = moment(sheduleDate).toISOString();
        $scope.tradedata.timeslot = timeslot;
        $scope.tradedata.shiftTime = tradedata.startShiftTime;
        $scope.tradedata.endshiftTime = tradedata.endshiftTime;
        $scope.tradedata.day_of_week = day_of_week;
        $scope.tradedata.week_of = $scope.startDate;
        $scope.tradedata.location = SessionService.currentUser.location;
        $scope.tradedata.tradePosttype = "onBoard";
        $scope.userloggedName = SessionService.currentUser.first_name +' '+ SessionService.currentUser.last_name;
        $scope.tradedata.postedBy = $scope.userloggedName;
        $http.post("/api/postOnBoard",$scope.tradedata).then(function(innerresponse){
            if(innerresponse.status == 200){
                toastr.success("Successfully Done !");
                angular.element('#modal-trade').modal('hide');
                angular.element('#event-modal').modal('hide');
            }
            else if(innerresponse.status == 208){
                toastr.error("Already posted on trade board");
                angular.element('#modal-trade').modal('hide');
                angular.element('#event-modal').modal('hide');
            }
        });
    }   

    $scope.goToEvent = function(id){
        $scope.showloader = true;
        $location.path("/event/edit/"+id);
    }

    $scope.getpdffile = function(){
        $scope.showloader = true;
        var schedule = {};
        schedule.shift = $scope.shift;
        schedule.from = $scope.startDate;
        schedule.to = $scope.endDate;
        schedule.location = SessionService.currentUser.location;
        $http.post("/api/schedulePdfReport", schedule).then(function(response){
             $scope.showloader = false;
             if(response.data.status_code == "404"){
                 toastr.error("No Schedules are found for this selection");
             }
             if ( 200 === response.status && response.data.status_code !== "404") {
                window.open($scope.backendsiteurl+ '/public/schReport.pdf');
             } 
        });
    }

    $scope.fetchScheduleReportOnSearch = function(){
        angular.forEach( $scope.allEvents, function( value, key ) {
            if ( value.ticked === true ) {
               $scope.eventInfo = value;
               $scope.searchEventId = $scope.eventInfo._id;
               $scope.showloader = true;
               $scope.scheduleIdsUnique = [];
               $scope.fetchscheduleReport();
            }
        });
    }

    $scope.closeScheduleReportSearch = function(){
        $scope.searchEventId = '';
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                if ( value.ticked === true ) {
                   $scope.eventInfo = value;
                   $scope.searchEventId = $scope.eventInfo._id;
                   $scope.showloader = true;
                   $scope.scheduleIdsUnique = [];
                   $scope.fetchscheduleReport();
                }
            });
        }
        if(!$scope.searchEventId){
            $scope.searchEventId = '';
            $scope.showloader = true;
            $scope.scheduleIdsUnique = [];
            $scope.fetchscheduleReport();
        }
    }

    $scope.filterByClass = function(empdata){
        if(empdata.textclass !== 'false'){
            return empdata;
        }
    }

   /* SocketService.on('scheduleNotification',function(data){
        SocketService.removeAllListeners();
        $scope.fullName = SessionService.currentUser.first_name + ' ' + SessionService.currentUser.last_name;
        if(SessionService.currentUser._id !== data.user_id){
            $http.get('/api/users/:' + data.user_id).
            success(function(response) {
                $scope.fullName = response.first_name + ' ' + response.last_name;
                $('#confirm_scheduleUpdate').show();
            });  
        }
    });*/

    $scope.reloadPage = function(){
        $route.reload();
    }

    $scope.lock_Screen = function(){
        $http.post("/api/screenLock", {userId:SessionService.currentUser._id}).then(function(response){
            $scope.showloader = true;
            if (200 == response.status) {
                $scope.showloader = false;
                $scope.screenlockedDetails = response.data.data;
                if($scope.screenlockedDetails._id && ($scope.screenlockedDetails._id !== $scope.user._id)){
                    $scope.disenable = 'disableTable';
                }else{
                    $scope.disenable = 'removedisableTable';
                }
            } 
        });
    }

    $scope.unlock_Screen = function(){
        $http.post("/api/screenUnLock", {userId:SessionService.currentUser._id}).then(function(response){
            $scope.showloader = true;
            if (200 == response.status) {
                $scope.showloader = false;
                $scope.screenlockedDetails = {};
                if($scope.screenlockedDetails._id && ($scope.screenlockedDetails._id !== $scope.user._id)){
                    $scope.disenable = 'disableTable';
                }else{
                    $scope.disenable = 'removedisableTable';
                }
            } 
        });
    }

});

angular.module("app").filter('numberFixedLen', function () {
    return function (n, len) {
       var num = parseInt(n, 10);
       len = parseInt(len, 10);
       if (isNaN(num) || isNaN(len)) {
           return n;
       }
       num = '' + num;
       while (num.length < len) {
           num = '0' + num;
       }
       return num;
    };
});
