angular.module("app").directive('ngConfirmClick', [
        function(){
            return {
                link: function (scope, element, attr) {
                    var msg = attr.ngConfirmClick || "All unsaved changes will be lost. Are you sure you want to continue?";
                    element.bind('click',function (event) {
                        if(!confirm(msg)){
                            event.stopImmediatePropagation();
                            event.preventDefault();
                        }
                    });
                }
            };
    }])
