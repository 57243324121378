angular.module("app").controller("ShiftAcknowledgementController", function($scope, SocketService, SessionService, $http, $routeParams, $q, moment, $rootScope, NgTableParams) {
	$scope.showloader= true;
	$scope.schedulepage = 1;
	$scope.allSchedules = [];
	$scope.storeSelEventAccountId = '';
	$scope.storeSelUserId = '';
	$http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
        if ( "200" === response.data.status_code ) {
            $scope.allEvents = response.data.events;
            angular.forEach( $scope.allEvents, function( value, key ) {
              value.buttonlabel = value.name;
            });
        } 
    });
    $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
	$scope.getshiftsacknowledgement=function(){
		var shiftack = {};
        if($scope.storeSelEventAccountId._id){
        	shiftack.eventId = $scope.storeSelEventAccountId._id;	
        }
        if($scope.storeSelUserId._id){
            shiftack.empId = $scope.storeSelUserId._id;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
            	$scope.showloader = true;
                $scope.pageNum = params.page(); 
                return $http.post("/api/getshiftsacknowledgement", {
                    count: params.count(),
                    page: params.page(),
                    alignedBy_id: SessionService.currentUser._id,
                    search: shiftack,
                    role: SessionService.currentUser.roles[0],
                    location:SessionService.currentUser.location
                }).then(function(response) {
                	if(response.data.status_code == "200"){
                		$scope.showloader = false;
	                    $scope.visibility = true
	                    params.total(response.data.total); // recal. page nav controls
	                    var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
						
						response.data.data.forEach(function(elem, key) {
						
							var weekDate = moment(elem.week_of).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
							elem.shiftDate = weekDate.setDate(weekDate.getDate() + weekdays.indexOf(elem.day_of_week));
							var weekDate      =      moment(elem.week_of).toDate();
							elem.shiftDate = weekDate.setDate(weekDate.getDate() + $scope.weekdays.indexOf(elem.day_of_week));
							elem.shiftDate = moment(elem.shiftDate).format("dddd, MMMM Do YYYY");
							if(elem.is_approve){
								elem.scheduleInfoclass = 'alert-success';
							}else{
								elem.scheduleInfoclass = 'bg-red';
							}
						});
	                    $scope.allSchedules = response.data.data;
	                    $scope.totalRecords = response.data.total;
	                    $scope.count = params.count();
	                    $scope.haveResult = true;
	                    if (!response.data.total) {
	                        $scope.haveResult = false;
	                    }
	                    return $scope.allSchedules;
                	}
                })
            }
        });
	}
	$scope.getshiftsacknowledgement();	
	$scope.ScheduleView = function(sch){
		var schid = sch._id;
		$http.post("/api/checkScheduleDetails",{schId:schid}).success(function(response){
			if(response.status_code == "200"){
				$scope.eventDetails = response.data.event_account_id;
				$scope.eventStartDate = moment($scope.eventDetails.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$scope.eventEndDate = moment($scope.eventDetails.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
				$scope.shiftData = $scope.eventDetails.shift_template_id;
				$scope.evtDetail = $scope.eventDetails;
				response.data.scheduleDate = moment(response.data.scheduleDate).clone().tz(SessionService.currentUser.locationtimezone, true).format("dddd, MMMM Do YYYY");
				$scope.wholeData = response.data;
			}
		})
	}

	$scope.approveScheduleView = function(sch){
		var todaydate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		$http.post("/api/checkSchedule",{schId:sch, acknowledgeDate : todaydate}).success(function(response){
			if(response.status_code == "200"){
				angular.element('#event-modal').modal('hide');
				SocketService.emit('get availability notification', {user_id: SessionService.currentUser._id});
				SocketService.on('notification icon data', function(data){
					$rootScope.availnotifications = data;
				});	
				$scope.schedulepage = 1;
				$scope.allSchedules = [];
				$scope.showloader= true;
				$scope.getshiftsacknowledgement();
			}
		})
	}

	$scope.getReport = function() {
		$scope.storeSelEventAccountId = '';
    	$scope.storeSelUserId = '';
		if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                if ( value.ticked === true ) {
                   $scope.storeSelEventAccountId = value;
                }
            });
        }

        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if (value.ticked == true) {
                    $scope.storeSelUserId = value;
                }
            });
        }

        $scope.getshiftsacknowledgement();
	}
});