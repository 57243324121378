angular.module("app").controller("MyavailabilityController", function($scope, SessionService, TimeSlotResource, sidepanelactiveService, getweekService, $http, $q, $location, moment) {
    $scope.user = SessionService.currentUser;
    $scope.showloader= true;
    // Get data from TimeSlotResource
    TimeSlotResource.query({locationId : SessionService.currentUser.location},function(response){
        $scope.slot = response[0];
    });
    $scope.all_box_scheduled = '';
    $scope.changeview = function(view) {
        $location.path(view);
    };
    $scope.disenable = '';
    $scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $scope.timeslots = ["Morning", "Afternoon", "Night", "Late Night"];

    // Show side panel active
    sidepanelactiveService.test();
    // Create weeklist
    var currWeekDates = getweekService.getCurrWeek(),
    nextWeekDates = getweekService.getNextWeek(currWeekDates[1].getDay() + 1),
    nextNextWeekDates = getweekService.getNextWeek(nextWeekDates[1].getDay() + 8);
    nextNextNextWeekDates = getweekService.getNextWeek(nextWeekDates[1].getDay() + 15);
    nextNextNextNextWeekDates = getweekService.getNextWeek(nextWeekDates[1].getDay() + 22);
    nextNextNextNextNextWeekDates = getweekService.getNextWeek(nextWeekDates[1].getDay() + 29);
    nextNextNextNextNextNextWeekDates = getweekService.getNextWeek(nextWeekDates[1].getDay() + 36);
    // Create object to hold availability and is_scheduled
    $scope.availability = [];
    var currentDate = moment(new Date());
    var nextnextweekdate = moment(nextNextWeekDates[0]);
    $scope.duration = nextnextweekdate.diff(currentDate, 'days')+1;
    $scope.nextNextWeek = nextNextWeekDates[0];
    $scope.tableStartDate = nextNextWeekDates[0];
    if($scope.duration < 11 && $scope.tableStartDate === $scope.nextNextWeek){
        $scope.disenable = 'disableTable';
    }else{
        $scope.disenable = 'removedisableTable';
    }
    $scope.checkAvaiabilityMarkTime = function(){
        var currentDate = moment(new Date());
        var nextnextweekdate = moment($scope.tableStartDate);
        $scope.durationValue = nextnextweekdate.diff(currentDate, 'days')+1;
        if ($scope.durationValue < 11) {
            toastr.error("You can't fill availability for this week now.");
            return false;
        }
    }
    $scope.checkNoMark = function(employees, shifts, extrashifts, timeslotbuffer, noCount, index, shift){
        var employees = employees || 0;
        var shifts = shifts || 0;
        var extrashifts = extrashifts || 0;
        var timeslotbuffer = timeslotbuffer || 0;
        var empCanMarkNo = employees - shifts - extrashifts - timeslotbuffer;
        //console.log(empCanMarkNo, noCount, employees , shifts , extrashifts , timeslotbuffer);
        if(empCanMarkNo <= noCount){
            alert("The max # of No have been met for this shift, select M or Y");
            if(shift === 'morning'){
                $scope.availability[index].is_morning_scheduled_force = true;
                $scope.availability[index].is_morning_scheduled = '';
            }
            if(shift === 'afternoon'){
                $scope.availability[index].is_afternoon_scheduled_force = true;
                $scope.availability[index].is_afternoon_scheduled = '';
            }
            if(shift === 'night'){
                $scope.availability[index].is_night_scheduled_force = true;
                $scope.availability[index].is_night_scheduled = '';
            }
            if(shift === 'latenight'){
                $scope.availability[index].is_late_night_scheduled_force = true;
                $scope.availability[index].is_late_night_scheduled = '';
            }  
        }else{
            if(shift === 'morning'){
                $scope.availability[index].is_morning_scheduled_force = false;
            }
            if(shift === 'afternoon'){
                $scope.availability[index].is_afternoon_scheduled_force = false;
            }
            if(shift === 'night'){
                $scope.availability[index].is_night_scheduled_force = false;
            }
            if(shift === 'latenight'){
                $scope.availability[index].is_late_night_scheduled_force = false;
            }
            return true;
        }
    }
    
    // Week day list based on currWeekDates
    var sevenWeekDayArr = new Array();
    for (var i = 0; i < 7; i++) {
        sevenWeekDayArr[i] = new Date(nextNextWeekDates[0].getTime() + i * 86400000);
        sevenWeekDayArr[i]['day'] = $scope.weekdays[sevenWeekDayArr[i].getDay()];
        $scope.availability.push({
            currDate: "",
            is_morning_scheduled: false,
            is_afternoon_scheduled: false,
            is_night_scheduled: false,
            is_late_night_scheduled: false
        });
    }
    $scope.sevenWeekDayArr = sevenWeekDayArr;

    // Show 5 week in buttons
    $scope.weeklist = [nextNextWeekDates, nextNextNextWeekDates, nextNextNextNextWeekDates, nextNextNextNextNextWeekDates, nextNextNextNextNextNextWeekDates];
    $scope.showWeekDays = function(startDate, endDate, e) {
        $scope.all_box_scheduled = '';
        $scope.tableStartDate = startDate;
        if($scope.duration < 11 && $scope.tableStartDate === $scope.nextNextWeek){
            $scope.disenable = 'disableTable';
        }else{
            $scope.disenable = 'removedisableTable';
        }
        var startdatetochangeformat = new Date(startDate);
        var formatteddate = startdatetochangeformat.getMonth() + 1 + '/' + startdatetochangeformat.getDate() + '/' + startdatetochangeformat.getFullYear().toString(10).substring(2, 4);
        var buttons = angular.element('ul li button');
        for (var i = 0; i < buttons.length; i++) {
            if (buttons.get(i).id == formatteddate) {
                angular.element("ul li button[id!='" + formatteddate + "']").addClass("bg-blue");
                angular.element("ul li button[id='" + formatteddate + "']").removeClass("bg-blue");
                angular.element("ul li button[id='" + formatteddate + "']").addClass("label-success");
            }
        }
        var sevenWeekDayArr = [];
        $scope.availability = [];
        for (var i = 0; i < 7; i++) {
            sevenWeekDayArr[i] = new Date(startDate.getTime() + i * 86400000);
            sevenWeekDayArr[i]['day'] = $scope.weekdays[sevenWeekDayArr[i].getDay()];
            $scope.availability.push({
                currDate: "",
                is_morning_scheduled: false,
                is_afternoon_scheduled: false,
                is_night_scheduled: false,
                is_late_night_scheduled: false,
                is_morning_scheduled_confirmation: false
            });
        }

        $scope.sevenWeekDayArr = sevenWeekDayArr;
        /****************************************/

        $scope.dateWiseData = [];
        var x = 0;
        $scope.getWeekReportcomment();
        $scope.myData = function() {
            var deferred = $q.defer();
            for (var i = 0; i < 7; i++) {
                $http.post('/api/dayavailability', {
                    _id: SessionService.currentUser._id,
                    weekDay: sevenWeekDayArr[i]
                }).then(function(response) {
                    tmpArr = response.data;
                    if (x < 7) {
                        $scope.dateWiseData.push(tmpArr);
                    }
                    if (x == 6) {
                        deferred.resolve();
                    }
                    x++;
                });
            }
            return deferred.promise;
        };

        $scope.myData().then(function() {
            if ($scope.dateWiseData[0] !== "null") {
                $scope.dateWiseData.sort(function(a, b) {
                    var dateA = new Date(a.currDate);
                    var dateB = new Date(b.currDate);
                    return dateA - dateB;
                });
                var lclDateArr = [];
                lclDateArr = $scope.dateWiseData;

                $scope.availability = [];
                var tmpArr = [];
                for (var i = 0; i < 7; i++) {
                    if (!lclDateArr[i].manager_schedule) {
                        lclDateArr[i].manager_schedule = {};
                    }
                    if (!lclDateArr[i].manager_schedule.morning_schedule_details) {
                        lclDateArr[i].manager_schedule.morning_schedule_details = {};
                    }
                    if (!lclDateArr[i].manager_schedule.afternoon_schedule_details) {
                        lclDateArr[i].manager_schedule.afternoon_schedule_details = {};
                    }
                    if (!lclDateArr[i].manager_schedule.night_schedule_details) {
                        lclDateArr[i].manager_schedule.night_schedule_details = {};
                    }
                    if (!lclDateArr[i].manager_schedule.late_night_schedule_details) {
                        lclDateArr[i].manager_schedule.late_night_schedule_details = {};
                    }
                    tmpArr.push({
                        currDate: new Date(lclDateArr[i].currDate).toString(),
                        is_morning_scheduled: lclDateArr[i].is_morning_scheduled_type,
                        is_afternoon_scheduled: lclDateArr[i].is_afternoon_scheduled_type,
                        is_night_scheduled: lclDateArr[i].is_night_scheduled_type,
                        is_late_night_scheduled: lclDateArr[i].is_late_night_scheduled_type,
                        is_morning_scheduled_force: lclDateArr[i].is_morning_scheduled_force,
                        is_afternoon_scheduled_force: lclDateArr[i].is_afternoon_scheduled_force,
                        is_night_scheduled_force: lclDateArr[i].is_night_scheduled_force,
                        is_late_night_scheduled_force: lclDateArr[i].is_late_night_scheduled_force,
                        is_morning_scheduled_confirmation: lclDateArr[i].manager_schedule.morning_schedule_details.is_morning_scheduled,
                        is_afternoon_scheduled_confirmation: lclDateArr[i].manager_schedule.afternoon_schedule_details.is_afternoon_scheduled,
                        is_night_scheduled_confirmation: lclDateArr[i].manager_schedule.night_schedule_details.is_night_scheduled,
                        is_late_night_scheduled_confirmation: lclDateArr[i].manager_schedule.late_night_schedule_details.is_late_night_scheduled
                    });
                }
                tmpArr.sort(function(a, b) {
                    var dateA = new Date(a.currDate);
                    var dateB = new Date(b.currDate);
                    return dateA - dateB;
                });
                tmpArr.report = $scope.availabilitycomment;
                $scope.availability = tmpArr;
            }
        });

        
        $scope.nofunctionality();
        /****************************************/

    };

    // Get weekwise availability for an employee - 24-09-2014

    /*****/
    $scope.dateWiseData = [];
    var x = 0;
    $scope.getData = function() {
        var deferred = $q.defer();
        for (var i = 0; i < 7; i++) {
            $http.post('/api/dayavailability', {
                _id: SessionService.currentUser._id,
                weekDay: $scope.sevenWeekDayArr[i]
            }).then(function(response) {
                $scope.showloader= false;
                tmpArr = response.data;
                if (x < 7) {
                    $scope.dateWiseData.push(tmpArr);
                }
                if (x == 6) {
                    deferred.resolve();
                }
                x++;
            });
        }
        return deferred.promise;
    };

    $scope.getData().then(function() {
        if ($scope.dateWiseData[0] !== "null") {
            $scope.dateWiseData.sort(function(a, b) {
                var dateA = new Date(a.currDate);
                var dateB = new Date(b.currDate);
                return dateA - dateB;
            });
            $scope.availability = [];
            var lclDateArr = [];
            lclDateArr = $scope.dateWiseData;
            $scope.availability = [];
            var tmpArr = [];
            for (var i = 0; i < 7; i++) {
                if (!lclDateArr[i].manager_schedule) {
                    lclDateArr[i].manager_schedule = {};
                }
                if (!lclDateArr[i].manager_schedule.morning_schedule_details) {
                    lclDateArr[i].manager_schedule.morning_schedule_details = {};
                }
                if (!lclDateArr[i].manager_schedule.afternoon_schedule_details) {
                    lclDateArr[i].manager_schedule.afternoon_schedule_details = {};
                }
                if (!lclDateArr[i].manager_schedule.night_schedule_details) {
                    lclDateArr[i].manager_schedule.night_schedule_details = {};
                }
                if (!lclDateArr[i].manager_schedule.late_night_schedule_details) {
                    lclDateArr[i].manager_schedule.late_night_schedule_details = {};
                }
                tmpArr.push({
                    currDate: new Date(lclDateArr[i].currDate).toString(),
                    is_morning_scheduled: lclDateArr[i].is_morning_scheduled_type,
                    is_afternoon_scheduled: lclDateArr[i].is_afternoon_scheduled_type,
                    is_night_scheduled: lclDateArr[i].is_night_scheduled_type,
                    is_late_night_scheduled: lclDateArr[i].is_late_night_scheduled_type,
                    is_morning_scheduled_confirmation: lclDateArr[i].manager_schedule.morning_schedule_details.is_morning_scheduled,
                    is_afternoon_scheduled_confirmation: lclDateArr[i].manager_schedule.afternoon_schedule_details.is_afternoon_scheduled,
                    is_night_scheduled_confirmation: lclDateArr[i].manager_schedule.night_schedule_details.is_night_scheduled,
                    is_late_night_scheduled_confirmation: lclDateArr[i].manager_schedule.late_night_schedule_details.is_late_night_scheduled
                });
            }
            tmpArr.sort(function(a, b) {
                var dateA = new Date(a.currDate);
                var dateB = new Date(b.currDate);
                return dateA - dateB;
            });
            $scope.availability = tmpArr;
        }
    });


    $scope.submitAvailability = function(weekAvailability, startWeek, endWeek) {
        $scope.endChainCheck = false;
        var deferred = $q.defer();
        $scope.submitData = function() {
            var x=0;
            $scope.tmpAvailbilityArr = [];
            for (var i = 0; i < weekAvailability.length; i++) {
                var slot = '';
                if (undefined === weekAvailability[i].is_morning_scheduled || weekAvailability[i].is_morning_scheduled === '') {
                    var slot = 'morning';
                } else if (undefined === weekAvailability[i].is_afternoon_scheduled || weekAvailability[i].is_afternoon_scheduled === '') {
                    var slot = 'after';
                } else if (undefined === weekAvailability[i].is_night_scheduled || weekAvailability[i].is_night_scheduled === '') {
                    var slot = 'night';
                } else if (undefined === weekAvailability[i].is_late_night_scheduled || weekAvailability[i].is_late_night_scheduled === '') {
                    var slot = 'latenight';
                }
                if (slot) {
                    var dayVal = $scope.weekdays[new Date(weekAvailability[i].currDate).getDay()];
                    angular.element("table.table-striped tbody tr." + dayVal + "_" + slot + " td input:checkbox").focus();
                    angular.element("table.table-striped tbody tr." + dayVal + "_" + slot + " td").css('background-color', 'darkgray');
                    angular.element("table.table-striped tbody tr:not(." + dayVal + "_" + slot + ") td").css('background-color', '');
                    $scope.endChainCheck = true;
                    toastr.error("You must select one box per slot to submit availability");
                    break;
                    $q.reject();
                }
                //if ("admin" != SessionService.currentUser.roles[0]) {
                weekAvailability[i].employee_id = SessionService.currentUser._id;
                weekAvailability[i].currDate = new Date(weekAvailability[i].currDate).toUTCString();
                weekAvailability[i].is_morning_scheduled_type = weekAvailability[i].is_morning_scheduled;
                weekAvailability[i].is_afternoon_scheduled_type = weekAvailability[i].is_afternoon_scheduled;
                weekAvailability[i].is_night_scheduled_type = weekAvailability[i].is_night_scheduled;
                weekAvailability[i].is_late_night_scheduled_type = weekAvailability[i].is_late_night_scheduled;
                $scope.tmpAvailbilityArr.push({
                    'employee_id': SessionService.currentUser._id,
                    'currDate': new Date(weekAvailability[i].currDate).toUTCString(),
                    'is_morning_scheduled_type' : weekAvailability[i].is_morning_scheduled,
                    'is_morning_scheduled_force' : weekAvailability[i].is_morning_scheduled_force,
                    'is_morning_scheduled': ('yes' == weekAvailability[i].is_morning_scheduled || 'maybe' == weekAvailability[i].is_morning_scheduled) ? true : false,
                    'is_afternoon_scheduled_type': weekAvailability[i].is_afternoon_scheduled,
                    'is_afternoon_scheduled_force': weekAvailability[i].is_afternoon_scheduled_force,
                    'is_afternoon_scheduled': ('yes' == weekAvailability[i].is_afternoon_scheduled || 'maybe' == weekAvailability[i].is_afternoon_scheduled) ? true : false,
                    'is_night_scheduled_type': weekAvailability[i].is_night_scheduled,
                    'is_night_scheduled_force': weekAvailability[i].is_night_scheduled_force,
                    'is_night_scheduled': ('yes' == weekAvailability[i].is_night_scheduled || 'maybe' == weekAvailability[i].is_night_scheduled) ? true : false,
                    'is_late_night_scheduled_type': weekAvailability[i].is_late_night_scheduled,
                    'is_late_night_scheduled_force': weekAvailability[i].is_late_night_scheduled_force,
                    'is_late_night_scheduled' : ('yes' == weekAvailability[i].is_late_night_scheduled || 'maybe' == weekAvailability[i].is_late_night_scheduled) ? true : false,
                    'modified_date' : moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
                    'modified_by' : SessionService.currentUser._id
                })
                //}
                $scope.tmpAvailbilityArrReport = weekAvailability.report;
                weekAvailability[i].modified_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
                weekAvailability[i].modified_by = SessionService.currentUser._id;
                if (x == 6) {
                    deferred.resolve();
                }
                x++;
            }
            return deferred.promise;
        }

        $scope.submitData().then(function() {
            if(!$scope.endChainCheck){
                console.log('tmpAvailbilityArr',$scope.tmpAvailbilityArr);
            if($scope.tmpAvailbilityArr.length){
                // Save week availability
               $scope.showloader = true;
               angular.element("#focuson").focus();
               $http.post('/api/weekavailability', $scope.tmpAvailbilityArr).then(function(response) {
                    response.data.data.sort(function(a, b) {
                        var dateA = new Date(a.currDate);
                        var dateB = new Date(b.currDate);
                        return dateA - dateB;
                    });
                    if ("200" === response.data.status_code) {
                        $scope.showloader = false;
                        if (response.data.length === 7) {
                            // Get Seven Week Days From The Response Array                    
                            $scope.sevenWeekDayArr = [
                                response.data.data[0].currDate,
                                response.data.data[1].currDate,
                                response.data.data[2].currDate,
                                response.data.data[3].currDate,
                                response.data.data[4].currDate,
                                response.data.data[5].currDate,
                                response.data.data[6].currDate
                            ];
                            $scope.availability = [];
                            for (var i = 0; i < 7; i++) {
                                $scope.availability.push({
                                    currDate: response.data.data[i].currDate,
                                    is_morning_scheduled: response.data.data[i].is_morning_scheduled,
                                    is_afternoon_scheduled: response.data.data[i].is_afternoon_scheduled,
                                    is_night_scheduled: response.data.data[i].is_night_scheduled,
                                    is_late_night_scheduled: response.data.data[i].is_late_night_scheduled
                                });
                            }
                        }
                        if ($scope.tmpAvailbilityArrReport) {
                            $http.post('/api/weeklyreport', {
                                emp_id: SessionService.currentUser._id,
                                startWeek: startWeek,
                                endWeek: endWeek,
                                report: $scope.tmpAvailbilityArrReport,
                                created_date :  moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
                            }).then(function(response) {

                            });
                        }
                        toastr.success("Data successfully saved!");
                        $scope.showWeekDays(startWeek,endWeek,'');
                    } else {
                        toastr.error("Something went wrong, please re login");
                    }
                }); 
            }
        }
        });
    };

    $scope.nofunctionality = function() {
        $http.post('/api/getEmployeeCount', {
            owner_id : SessionService.currentUser.created_by,
            location : SessionService.currentUser.location
        }).then(function(response) {
            $scope.no_of_employees = response.data.data;
        });

        // Get slots buffer value   
        $http.post('/api/timeslotbuffer', {locationId : SessionService.currentUser.location}).success(function(fetchresponse){
            if(fetchresponse.data[0]){
                var response = fetchresponse.data[0].buffervalues;
            }else{
                var response = [];
            }
            $scope.showloader = false;
            griddata = [];
            for (var i = 0; i < $scope.weekdays.length; i++) {
                var nestedObj = {};
                for (var j = 0; j < $scope.timeslots.length; j++) {
                    angular.forEach(response, function(item) {
                        if (item.day == $scope.weekdays[i] && item.timeslot == $scope.timeslots[j]) {
                            nestedObj[$scope.timeslots[j]] = item.buffer;
                        }
                    });
                    if(!nestedObj[$scope.timeslots[j]]) {
                        nestedObj[$scope.timeslots[j]] = 0;
                    }
                }
                griddata[$scope.weekdays[i]] = nestedObj
            }
           $scope.gridData = griddata;
        });
        // Get No buffer values grid
        $http.post('/api/getnocount', {
            "data": $scope.sevenWeekDayArr,
            "location" : SessionService.currentUser.location
        }).then(function(response) {
            nodata = [];
            for (var i = 0; i < $scope.sevenWeekDayArr.length; i++) {
                var nestedObj = {};
                for (var j = 0; j < $scope.timeslots.length; j++) {
                    angular.forEach(response.data.data, function(item) {
                        if (new Date(item.date).getTime() == new Date($scope.sevenWeekDayArr[i]).getTime()) {
                            if ($scope.timeslots[j] == 'Morning') {
                                var noVal = item.shifts.morning;
                            }
                            if ($scope.timeslots[j] == 'Afternoon') {
                                var noVal = item.shifts.afternoon;
                            }
                            if ($scope.timeslots[j] == 'Night') {
                                var noVal = item.shifts.night;
                            }
                            if ($scope.timeslots[j] == 'Late Night') {
                                var noVal = item.shifts.late_night;
                            }
                            nestedObj[$scope.timeslots[j]] = noVal;
                        }
                    });
                    if (!nestedObj[$scope.timeslots[j]]) {
                        nestedObj[$scope.timeslots[j]] = 0;
                    }

                }
                nodata[$scope.sevenWeekDayArr[i]] = nestedObj
            }
            $scope.nodata = nodata;
        })

        // Get shift count values grid
        $http.post('/api/getshiftcount', {
            "data": $scope.sevenWeekDayArr,
            "location" : SessionService.currentUser.location
        }).then(function(response) {
            for (var i = 0; i < $scope.weekdays.length; i++) {
                if (!response.data.data[$scope.weekdays[i]]) {
                    response.data.data[$scope.weekdays[i]] = {
                        'morning': 0,
                        'afternoon': 0,
                        'night': 0,
                        'latenight': 0
                    };
                }
            }
            $scope.shiftGrid = response.data.data;
        })

        // Get extra shift count values grid
        $http.post('/api/getextrashiftcount', {
            "data": $scope.sevenWeekDayArr,
            "location" : SessionService.currentUser.location
        }).then(function(response) {
            for (var i = 0; i < $scope.weekdays.length; i++) {
                if (!response.data.data[$scope.weekdays[i]]) {
                    response.data.data[$scope.weekdays[i]] = {
                        'morning': 0,
                        'afternoon': 0,
                        'night': 0,
                        'latenight': 0
                    };
                }
            }
            $scope.extrashiftGrid = response.data.data;
        })
    }
    $scope.nofunctionality();
    $scope.getWeekReportcomment = function(){
        $http.post('/api/getweeklyreport', {
            emp_id: SessionService.currentUser._id,
            startWeek: $scope.sevenWeekDayArr[0],
            endWeek: $scope.sevenWeekDayArr[6]
        }).then(function(response) {
            if ("200" === response.data.status_code) {
                    if(response.data.data){
                        $scope.availability.report = response.data.data.comment;
                        $scope.availabilitycomment = response.data.data.comment;
                    }else{
                        $scope.availability.report = "";
                        $scope.availabilitycomment = "";
                    }
            }
        });
    }
    $scope.getWeekReportcomment();
    $scope.markAll = function(){
        if($scope.all_box_scheduled == 'yes'){
            for (var i = 0; i < $scope.availability.length; i++) {
                $scope.availability[i].is_morning_scheduled = "yes";
                $scope.availability[i].is_afternoon_scheduled = "yes";
                $scope.availability[i].is_night_scheduled = "yes";
                $scope.availability[i].is_late_night_scheduled = "yes";
            }
        }
        if($scope.all_box_scheduled == 'maybe'){
            for (var i = 0; i < $scope.availability.length; i++) {
                $scope.availability[i].is_morning_scheduled = "maybe";
                $scope.availability[i].is_afternoon_scheduled = "maybe";
                $scope.availability[i].is_night_scheduled = "maybe";
                $scope.availability[i].is_late_night_scheduled = "maybe";
            }
        }
        if($scope.all_box_scheduled == 'no'){
            for (var i = 0; i < $scope.availability.length; i++) {
                $scope.availability[i].is_morning_scheduled = "no";
                $scope.availability[i].is_afternoon_scheduled = "no";
                $scope.availability[i].is_night_scheduled = "no";
                $scope.availability[i].is_late_night_scheduled = "no";
            }
        }
    }
});



angular.module("app").filter('toSec', function($filter) {
    return function(input) {
        var result = new Date(input).getTime();
        return result || '';
    };
});