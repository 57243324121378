angular.module("app").controller("ZipinitialsController", function($scope, $http, $filter, $location, SessionService, NgTableParams, moment){
    $scope.visibility = true;
    $scope.haveResult = true;
    $scope.sortType='';
    $scope.sortOrder=undefined;
    $scope.searchText={};
    $scope.searchText.val='';
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.selectenable = false;
    $scope.checkAll = {};
    $scope.showloader = true;
    $scope.selectedInitialslist = {
        InitialslistArr: []
    };
    $scope.selectedAction = 0;
    $scope.pageNum = 1;
    $scope.clearList = function(){
        $scope.initial = '';
        $scope.zipcodes = '';
        $scope.buttondisabled = false;
    }
    $scope.initialList = function() {
      $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType='created_date';
            $scope.sortOrder=-1;
        }    
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/getInitalziplist", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0]
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.allZips = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allZips;
                })
            }
        });
    }

    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.initialList()
              }
          }
    );
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.initialList()
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    $scope.initialList();
    $scope.createInitialzip = function(){
        $scope.buttondisabled = true;
        var zipInfo = {};
        $scope.initialzipForm.$setPristine();
        zipInfo.initial = $scope.initial;
        zipInfo.zipcodes = $scope.zipcodes;
        zipInfo.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        zipInfo.created_by = SessionService.currentUser._id;
        $http.post("/api/addInitialzip", zipInfo).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Zip is added successfully in list.");
                angular.element('#modal-basic').modal('hide');
                $scope.tableParams.reload();
                $scope.initialList();
            }else{
                toastr.error("Somthing Wrong!");
            }
        });
    }
    /*change active/inactive on click*/
    $scope.update_status=function($index,data) {
        $http.post("/api/update_Initalzip_status", {"data":data}).then(function(response) {
            if(response.data.status_code == "200"){
                $scope.initialList();
                toastr.success("Successfully updated.");
            }
        })
    }
    /*end*/

    $scope.checkAll.val= false;
    $scope.selectAllGroup = function(check) {
            $scope.flag = {};
            $scope.flag.enable = false;
            if (check) {
                $scope.selectedInitialslist.InitialslistArr = $scope.allZips.map(function(item) {
                    return item._id;
                });
                $scope.selectenable = true;
                console.log("$scope.selectenabletrue", $scope.selectenable);
            } else {
                $scope.selectedInitialslist.InitialslistArr = [];
                $scope.checkAll.val= false;
                $scope.selectenable = false;
            }
    }
   
    $scope.delete_ziplists=function(){
        $http.post("/api/deleteZipList", {
            "zipid": $scope.selectedInitialslist.InitialslistArr
        }).then(function(response) {
            $scope.allZips = response.data.data;
            angular.element('#selectedAction').val(0);
            $scope.initialList();
            toastr.success("Successfully Deleted.");
            $scope.selectedInitialslist.InitialslistArr = [];
            $scope.checkAll.val = false;
            $('#confirm_del').hide();
        })
    }

    $scope.cnfrm_pwd = function(questlist){
        $('#confirm_del').show();
    }
    
    $scope.performAction = function() {
        $scope.selectedAction = selectedAction.value;
        if ($scope.selectedAction == 0) {
            toastr.error("Please select some selection.");
        }else if ($scope.selectedInitialslist.InitialslistArr.length == 0) {
            toastr.error("Please select atleast one checkbox.");
        }
        if ($scope.selectedAction != 0 && $scope.selectedAction == 1 && $scope.selectedInitialslist.InitialslistArr.length > 0) {
            $scope.enbl = true;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 2 && $scope.selectedInitialslist.InitialslistArr.length > 0) {
            $scope.enbl = false;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 3 && $scope.selectedInitialslist.InitialslistArr.length > 0) {
            $scope.cnfrm_pwd($scope.selectedInitialslist.InitialslistArr);
        }

        if( $scope.selectedInitialslist.InitialslistArr.length > 0 && $scope.selectedAction > 0 && $scope.selectedAction < 3){
            $http.post("/api/enable_disable_Initalzips", {
                'enabled': $scope.enbl,
                'allChecked': $scope.selectenable,
                "Initalzips": $scope.selectedInitialslist.InitialslistArr
            }).then(function(response) {
                $scope.allZips = response.data.data;
                angular.element('#selectedAction').val(0);
                $scope.initialList();
                toastr.success("Successfully updated.");
                $scope.selectedInitialslist.InitialslistArr = [];
                $scope.checkAll.val = false;
            })
        }
    }

    $scope.updateZip = function(zipId, title, zipcodes){
      $scope.updatelist = {};
      $scope.updatelist.initial = title;
      $scope.updatelist.zipId = zipId;
      $scope.updatelist.zipcodes = zipcodes;
    }

    $scope.initialZipUpdateSubmit = function(data){
         var updatedData = {};
         updatedData.initial = data.initial;
         updatedData.zipcodes = data.zipcodes;
         updatedData.zipId = data.zipId
         $http.post("/api/editInitalzip", updatedData).then(function(response) {
            if(response.data.status_code == "200"){
                toastr.success("Successfully updated.");
                angular.element('#modal-update').modal('hide');
                $scope.tableParams.reload();
                $scope.initialList();
            }
         })
    }
});