angular.module("app").directive('weekpicker', function($timeout, $rootScope, moment, SessionService) {
  return {
    // Enforce the angularJS default of restricting the directive to
    // attributes only
    restrict: 'A',
    // Always use along with an ng-model
    require: '?ngModel',
    // This method needs to be defined and passed in from the
    // passed in to the directive from the view controller
    scope: {
      select: '&'        // Bind the select function we refer to the right scope
    },
    link: function(scope, element, attrs, ngModel) {
      if (!ngModel) return;
 
      var optionsObj = {};
      optionsObj.showOtherMonths = true;
      optionsObj.selectOtherMonths = true;
      optionsObj.dateFormat = 'mm/dd/yy';
      
      var selectCurrentWeek = function() {
          scope.$apply(function () {
              $("#ui-datepicker-div").find(".ui-datepicker-current-day a").addClass("ui-state-active");
          });
      };
      
      var updateModel = function(dateTxt) {
        scope.$apply(function () {
          // Call the internal AngularJS helper to
          // update the two way binding
          ngModel.$setViewValue(dateTxt);
          ngModel.$render();
        });
      };
 
      optionsObj.onSelect = function(dateTxt, picker) {
        var date = $(element).datepicker('getDate');
        var startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
        var startdateWithZero = new Date(new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()).setHours(0, 0, 0, 0));
        var startDateAsPerTimezone = moment(startdateWithZero).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        var endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 6);
        var enddateWithZero = new Date(new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 6).setHours(0, 0, 0, 0));
        var endDateAsPerTimezone = moment(enddateWithZero).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        var d = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
        var curr_year = d.getFullYear();
        var curr_Month = ("0" + (d.getMonth() + 1)).slice(-2);
        var curr_date = ("0" + (d.getDate() - d.getDay())).slice(-2);
        var todayDate = (curr_Month + "/" + curr_date + "/" + curr_year);
        var d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 6);
        var curr_year1 = d1.getFullYear();
        var curr_Month1 = ("0" + (d1.getMonth() + 1)).slice(-2);
        var curr_Date1 = ("0" + (d1.getDate() - d1.getDay() + 6)).slice(-2);
        var todayDate1 = (curr_Month1 + "/" + curr_Date1 + "/" + curr_year1);
        var start_end_render = todayDate + " To "+ todayDate1;
        //scope.$emit('startendString', {'startendstringJson' : startDate+' / '+endDate});
        scope.$emit('startendString', {'startendstringJson' : startDateAsPerTimezone+' / '+endDateAsPerTimezone});
        $timeout(function() {
          selectCurrentWeek();
        }, true);
        updateModel(start_end_render);
      };
 
      optionsObj.beforeShow = function (dateTxt, inst) {
        $timeout(function() {
          selectCurrentWeek();
        }, true);
      }
 
      optionsObj.beforeShowDay =function(date) {
            var cssClass = '';
            if ($(element).datepicker('getDate')) {
              var todayDate = $(element).datepicker('getDate');
            }else{
              var todayDate = new Date();
            }
            var startDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - todayDate.getDay());
            var endDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - todayDate.getDay() + 6);
            if(date >= startDate && date <= endDate)
                cssClass = 'ui-datepicker-current-day';
            return [true, cssClass];
      };
 
      optionsObj.onChangeMonthYear = function(year, month, inst) {
        $timeout(function() {
          selectCurrentWeek();
        },true);
      };
 
      ngModel.$render = function() {
        // Use the AngularJS internal 'binding-specific' variable
        element.datepicker('setDate', ngModel.$viewValue || '');
      };
 
      element.datepicker(optionsObj);
    }
  };
});