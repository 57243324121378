angular.module("app").controller("EmpScheduledController", function($rootScope, $scope, $filter, ports, $location, SessionService, $http, $q, UserService, $timeout, getweekService, NgTableParams) {
    $scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $scope.timeslots = ["Morning", "Afternoon", "Night", "Late Night"];
    $scope.user = SessionService.currentUser;
    $scope.currpage =  $location.absUrl().split('/')[3];
    $scope.shift = 'Morning';
    $scope.start_end = '';
    $scope.searchText = {};
    $scope.haveResult = false;
    $scope.NotmarkedhaveResult = false;
    $scope.marked = 'marked';
    var currWeekDates = getweekService.getCurrWeek();
    $scope.schweekdate = $filter('date')(currWeekDates[0], 'MM/dd/yyyy') + ' To '+ $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
    $scope.$watch(
        "schweekdate",
        function handleWeekChange() {
            $scope.eventAccountList = [];
            $scope.emplist = [];
            $scope.days = [];
            $scope.$on('startendString', function (event, args) {
                $scope.start_end = args.startendstringJson;
            });
            if ($scope.start_end) {
                 var startEndArr = $scope.start_end.split(' / ');
                 $scope.startDate = new Date(startEndArr[0]);
                 $scope.endDate = new Date(startEndArr[1]);
                 $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
            }
            else{
                $scope.startDate = currWeekDates[0];
                $scope.endDate = currWeekDates[1];
                $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
            }
            if($scope.dateRange == null){
                var StartDate = currWeekDates[0];
                var EndDate = currWeekDates[1];
            }else{
                var StartDate = $scope.dateRange.start_date;
                var EndDate = $scope.dateRange.end_date;
            }
            if (StartDate && EndDate) {
                 var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;
                 var diff = 6; //To Resolve day night saving time issue because in this change it return somewhere less than 6 and can be more than 6.
            }
            var sevenWeekDayArr = [];
            for(var i=0; i<= diff; i++) {
                sevenWeekDayArr[i] = new Date(StartDate.getTime() + i*86400000);
                sevenWeekDayArr[i].dayName = $scope.weekdays[sevenWeekDayArr[i].getDay()];
                sevenWeekDayArr[i].differ = Math.floor((new Date(StartDate.getTime() + i*86400000) - new Date().getTime())/86400000);
            }
            $scope.sevenWeekDayArr = sevenWeekDayArr;
            if ($scope.currpage == 'empAvlscheduled') {
                $scope.showloader = true;
                $scope.empAvailbilityPage = 1;
                $scope.totalEmppages = 1;
                $scope.busy = false;
                $scope.days = [];
                $scope.fetchEmpList = [];
                $http.get("/api/getAllInitalzips").then(function(response) {
                    if(response.status == 200){
                        $scope.gridData = [];
                        $scope.gridSchData = [];
                        $scope.updatedAvailData = [];
                        $scope.AllInitials = response.data.data;
                        $scope.pageNum = 1;
                        if($scope.marked == "marked"){
                            $scope.fetchEmployeeAvailability();
                        }
                        if($scope.marked == "notmarked"){
                            $scope.fetchNoMarkedEmployeeAvailability();
                        }
                    }
                });
            }
        }
    );

    $scope.twentyfour_to_twelve = function(ti){
        ti = ti.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [ti];
        if (ti.length > 1) { // If time format correct
            ti = ti.slice (1);  // Remove full string match value
            ti[5] = +ti[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            ti[0] = +ti[0] % 12 || 12; // Adjust hours
        }
        return ti.join (''); // return adjusted time or original string
    }
    $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    $scope.pageNum = 1;
    $scope.fetchEmployeeAvailability = function(){
        $scope.showloader = true;
        if($scope.searchEmployeeId){
            $scope.employeeId = $scope.searchEmployeeId;
        }else{
            $scope.employeeId = '';
        }

       /* $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 49,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); */
                var promiseOne = $http.post("/api/getAvailabilitylistEmpWise", {
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    "to": $scope.endDate,
                    "from": $scope.startDate,
                    "searchEmpId" : $scope.employeeId,
                    //count: params.count(),
                    //page: params.page(),
                    location:SessionService.currentUser.location
                });
                var promiseTwo = $http.post("/api/getSchedulelistEmpWise", {
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    "to": $scope.endDate,
                    "from": $scope.startDate,
                    "searchEmpId" : $scope.employeeId,
                    location:SessionService.currentUser.location
                });
                var promiseThree = $http.post("/api/getCommentReport", {
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    "to": $scope.endDate,
                    "from": $scope.startDate,
                    "searchEmpId" : $scope.employeeId,
                    location:SessionService.currentUser.location
                });
                return $q.all([promiseOne, promiseTwo, promiseThree]).then(function(data){
                    $scope.showloader = false;
                    $scope.availabilityData = data[0].data.Availabilitydata;
                    $scope.scheduleData = data[1].data.scheduledata;
                    $scope.commentData = data[2].data.data;
                    $scope.availabilityData_status = data[0].status;
                    $scope.scheduleData_status = data[1].status;
                    //params.total(data[0].data.totalReports); // recal. page nav controls
                    $scope.totalRecords = data[0].data.totalReports;
                    if (!data[0].data.totalReports || !$scope.availabilityData.length) {
                        $scope.haveResult = false;
                    }else{
                        $scope.haveResult = true;
                    }
                    var x = 0;
                    var y = 0;
                    $scope.gridData = [];
                    $scope.gridSchData = [];
                    maingriddata = [];
                    maingridschdata = [];
                    $scope.keepgoing = false;
                    if($scope.availabilityData.length){
                        angular.forEach($scope.availabilityData, function(item) {
                            angular.forEach($scope.scheduleData, function(schitem) {
                                if(schitem.empId === item.empId){
                                    item.schedules = schitem.result;
                                }
                            }); 
                            angular.forEach($scope.commentData, function(comtitem) {
                                if(comtitem.employee_id === item.empId){
                                    item.comment = comtitem.comment;
                                }
                            }); 
                            if(x === $scope.availabilityData.length-1){
                                $scope.updatedAvailData = $scope.availabilityData;
                                $scope.keepgoing = true;
                            }
                            x++;   
                        });
                    }
                    if($scope.keepgoing){
                        for (var k = 0; k < $scope.updatedAvailData.length; k++) {
                            griddata = [];
                            gridschdata = [];
                            for (var i = 0; i < $scope.sevenWeekDayArr.length; i++) { 
                                var nestedObj = {};
                                var nestedSchObj = {};
                                for (var j = 0; j < $scope.timeslots.length; j++) {
                                    angular.forEach($scope.updatedAvailData[k].result, function(item) {
                                        var datevalnew = new Date($scope.sevenWeekDayArr[i]).toISOString();
                                        if(item.currDate === datevalnew){
                                            if($scope.timeslots[j] == 'Morning'){
                                                var textclass = (item.is_morning_scheduled_force) ? item.is_morning_scheduled_type+'forced' : ((item.is_morning_scheduled_type) ? item.is_morning_scheduled_type :'')
                                            }
                                            if($scope.timeslots[j] == 'Afternoon'){
                                                var textclass = (item.is_afternoon_scheduled_force) ? item.is_afternoon_scheduled_type+'forced' : ((item.is_afternoon_scheduled_type) ? item.is_afternoon_scheduled_type :'');
                                            }
                                            if($scope.timeslots[j] == 'Night'){
                                                var textclass = (item.is_night_scheduled_force) ? item.is_night_scheduled_type+'forced' : ((item.is_night_scheduled_type) ? item.is_night_scheduled_type :'');
                                            }
                                            if($scope.timeslots[j] == 'Late Night'){
                                                var textclass = (item.is_late_night_scheduled_force) ? item.is_late_night_scheduled_type+'forced' : ((item.is_late_night_scheduled_type) ? item.is_late_night_scheduled_type :'');
                                            }

                                            if(textclass == 'yes'){
                                                nestedObj[$scope.timeslots[j]] = 'Y';
                                            }else if(textclass == 'maybe'){
                                                nestedObj[$scope.timeslots[j]] = 'M';
                                            }else if(textclass == 'no'){
                                                nestedObj[$scope.timeslots[j]] = 'N';
                                            }else if(textclass == 'maybeforced'){
                                                nestedObj[$scope.timeslots[j]] = 'SM';
                                            }else if(textclass == 'yesforced'){
                                                nestedObj[$scope.timeslots[j]] = 'SY';
                                            }
                                        }
                                    });

                                    angular.forEach($scope.updatedAvailData[k].schedules, function(item) {
                                        var datevalnew = new Date($scope.sevenWeekDayArr[i]).toISOString();
                                        if(item.scheduleDate === datevalnew){
                                            if($scope.timeslots[j] == item.timeslot){
                                                nestedSchObj[$scope.timeslots[j]] = item;
                                            }
                                        }
                                    });
                                    if(!nestedObj[$scope.timeslots[j]]) {
                                        nestedObj[$scope.timeslots[j]] = '';
                                    }
                                    if(!nestedSchObj[$scope.timeslots[j]]) {
                                        nestedSchObj[$scope.timeslots[j]] = '';
                                    }
                                }
                                griddata[$scope.sevenWeekDayArr[i]] = nestedObj;
                                gridschdata[$scope.sevenWeekDayArr[i]] = nestedSchObj;
                            }
                            maingriddata[$scope.updatedAvailData[k].empId] = griddata;
                            maingridschdata[$scope.updatedAvailData[k].empId] = gridschdata;
                            if(y == $scope.updatedAvailData.length-1){
                                $scope.gridData = maingriddata;
                                $scope.gridSchData = maingridschdata;
                            }
                            y++;
                        }
                    }
                    //return $scope.updatedAvailData;
                });
            //}
       // });      
    }

    $scope.fetchNoMarkedEmployeeAvailability = function(){
        $scope.showloader = true;
        if($scope.searchEmployeeId){
            $scope.employeeId = $scope.searchEmployeeId;
        }else{
            $scope.employeeId = '';
        }
      
        $http.post("/api/getAvailabilityHaveNotMarked", {
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            "to": $scope.endDate,
            "from": $scope.startDate,
            "searchEmpId" : $scope.employeeId,
            location:SessionService.currentUser.location
        }).then(function(response){
            $scope.showloader = false;
            $scope.availabilityData = response.data.data;                    
            $scope.availabilityData_status = response.data.status;
            $scope.totalentries = response.data.totalReports;
            if (!response.data.totalReports || !$scope.availabilityData.length) {
                $scope.NotmarkedhaveResult = false;
            }else{
                $scope.NotmarkedhaveResult = true;
                $scope.haveResult = false;
            }
        });
              
    }
    $scope.fetchDataOnChange = function(){
        $scope.gridData = [];
        $scope.gridSchData = [];
        $scope.updatedAvailData = [];
        $scope.pageNum = 1;
        if($scope.marked == "marked"){
            $scope.fetchEmployeeAvailability();
        }
        if($scope.marked == "notmarked"){
            $scope.fetchNoMarkedEmployeeAvailability();
        }

    }

    $scope.matchZipInInitial = function(zipvalue){
        var zipVal = '';
        if(zipvalue){
            for(i = 0; i < $scope.AllInitials.length; i++){
                if($scope.AllInitials[i].zipcodes.indexOf(zipvalue) >= 0){
                    zipVal = $scope.AllInitials[i].initial;
                    break;
                }
            }
        }
        return zipVal;
    }

    $scope.fetchEmployeeAvail = function(){
        angular.forEach($scope.modernWebBrowsers, function( value, key ) {
            if ( value.ticked === true ) {
               $scope.empInfo = value;
               $scope.searchEmployeeId = $scope.empInfo._id;
               $scope.showloader = true;
               $scope.gridData = [];
               $scope.gridSchData = [];
               $scope.updatedAvailData = [];
               $scope.pageNum = 1;
                if($scope.marked == "marked"){
                    $scope.fetchEmployeeAvailability();
                }
                if($scope.marked == "notmarked"){
                    $scope.fetchNoMarkedEmployeeAvailability();
                }
               
            }
        });
    }

    $scope.closeEmpAvailSearch = function(){
        $scope.searchEmployeeId = '';
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if ( value.ticked === true ) {
                   $scope.empInfo = value;
                   $scope.searchEmployeeId = $scope.empInfo._id;
                   $scope.showloader = true;                  
                   $scope.gridData = [];
                   $scope.gridSchData = [];
                   $scope.updatedAvailData = [];
                }
            });
        }
        if(!$scope.searchEmployeeId){
           $scope.searchEmployeeId = '';
           $scope.showloader = true;
           $scope.gridData = [];
           $scope.gridSchData = [];
           $scope.updatedAvailData = [];
           if($scope.marked == "marked"){
                $scope.fetchEmployeeAvailability();
            }
            if($scope.marked == "notmarked"){
                $scope.fetchNoMarkedEmployeeAvailability();
            }
        }
    }

    $scope.matchZipInInitial = function(zipvalue){
        var zipVal = '';
        if(zipvalue){
            for(i = 0; i < $scope.AllInitials.length; i++){
                if($scope.AllInitials[i].zipcodes.indexOf(zipvalue) >= 0){
                    zipVal = $scope.AllInitials[i].initial;
                    break;
                }
            }
        }
        return zipVal;
    }
    
});

angular.module("app").filter('numberFixedLen', function(){
    return function (n, len) {
       var num = parseInt(n, 10);
       len = parseInt(len, 10);
       if (isNaN(num) || isNaN(len)) {
           return n;
       }
       num = '' + num;
       while (num.length < len) {
           num = '0' + num;
       }
       return num;
    };
});

angular.module("app").filter('roundup', function () {
    return function (value) {
        return Math.ceil(value);
    };
})