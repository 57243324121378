angular.module("app").controller("MemoListController", function($scope, SessionService, $http, $filter, sidepanelactiveService, $location, NgTableParams) {
   $("#get-modal").hide();
   var $modal =$("#get-modal");
   $scope.user = SessionService.currentUser;
   $scope.showDelete=false;
   $scope.searchText={};
   $scope.sortType='';
   $scope.sortOrder=undefined;
   $scope.edit=false;
   $scope.showloader= true;
   $scope.visibility = false;
   $scope.scheduletextvisibility = false;
   $scope.haveResult = true;
    
    if("employee" === SessionService.currentUser.roles[0])
        $scope.userPriv = false;
    else
        $scope.userPriv = true;
    sidepanelactiveService.test();
    
    $(document).ready(function(){
        $("body").removeClass("events");
        $scope.colorInb = 'btn-blue';
        $scope.colorSnt = 'btn-white';
        $scope.colorSch = 'btn-white';
    });
    
    $scope.inbox = function(){
      if ($scope.colorInb != 'btn-blue') {
           $scope.pageNum = 1;
      }
        $scope.colorInb = 'btn-blue';
        $scope.colorSnt = 'btn-white';
        $scope.colorSch = 'btn-white';
        $scope.scheduletextvisibility = false;
        $scope.fetchmessage('inbox');
    }

    $scope.schMsg = function() {
         if($scope.colorSch != 'btn-blue'){
           $scope.pageNum = 1;
         }
         $scope.colorSnt = 'btn-white';
         $scope.colorInb = 'btn-white';
         $scope.colorSch = 'btn-blue';
         $scope.showloader= true;
         if ($scope.sortType == '') {
          $scope.sortType = 'schDate';
          $scope.sortOrder = -1;
         }
         var search = "";
         if ($scope.searchText.val) {
            search = $scope.searchText.val;
         }
         $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
         }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/getschMsg", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder
                }).then(function(response) {
                    if( "200" === response.data.status_code ) {
                      $scope.showloader= false;
                      $scope.allSchMsg = response.data.data;
                      if ($scope.allSchMsg.length > 0)
                           $scope.haveResult = true;
                        else
                           $scope.haveResult = false;
                      $scope.visibility = true;
                      $scope.scheduletextvisibility = true;
                      params.total(response.data.total); // recal. page nav controls
                      $scope.totalRecords = response.data.total;
                      $scope.count = params.count();
                      return $scope.allSchMsg;
                    }
                });
            }
        });
    }
    $scope.fetchmessage = function(typeOfMessage) {
      $scope.showloader= true;
      if (typeOfMessage == 'inbox') {
         if ("admin" === SessionService.currentUser.roles[0]) {
            $scope.actionname = "adminInboxMsgs";
            $scope.showDelete=false;
         }
         else if ( "manager" === SessionService.currentUser.roles[0] ) {
           $scope.actionname = "managerInboxMsgs";
         }
         else{
           $scope.actionname = "empInboxMsgs";
         }
      }else{
         $scope.actionname = "getAllCreatedByMe";
      }
       
      if ($scope.sortType == '') {
          $scope.sortType = 'created_date';
          $scope.sortOrder = -1;
      }
      var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/getmemos", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    userId: SessionService.currentUser._id,
                    action: $scope.actionname
                }).then(function(response) {
                    if( "200" === response.data.status_code ) {
                     $scope.showloader= false;
                     $scope.allMemos = response.data.data;
                     $scope.visibility = true
                     params.total(response.data.total); // recal. page nav controls
                     $scope.totalRecords = response.data.total;
                     $scope.count = params.count();
                     var unread = 0;
                     response.data.data.forEach(function(elem, key){
                        if ($scope.actionname == "adminInboxMsgs") {
                              if ((elem.created_by !== SessionService.currentUser._id) && (!elem.admin_checked)) {
                                  unread++;
                              }
                              $scope.userRole = "admin";
                              $scope.msgsCount = unread;
                        }
                        else if ($scope.actionname == "managerInboxMsgs") {
                              elem.sent_to.forEach(function(elemsent, keysent){
                                  if ((elemsent.user_id._id == SessionService.currentUser._id) && (!elemsent.mark_read)) {
                                      unread++;
                                  }
                              });
                              $scope.userRole = "other";
                              $scope.msgsCount = unread;
                        }
                        else if ($scope.actionname == "empInboxMsgs") {
                              elem.sent_to.forEach(function(elemsent, keysent){
                                  if ((elemsent.user_id._id == SessionService.currentUser._id) && (!elemsent.mark_read)) {
                                    console.log(elemsent._id);
                                    console.log(SessionService.currentUser._id, typeof SessionService.currentUser._id, elemsent.mark_read);
                                      unread++;
                                  }
                              });
                              $scope.userRole = "other";
                              $scope.msgsCount = unread;
                        }                        
                      
                        if ( $scope.actionname == "getAllCreatedByMe" ) {
                           if ("admin" === SessionService.currentUser.roles[0]) {
                              $scope.userRole = "admin";
                           }
                           if ("manager" === SessionService.currentUser.roles[0]) {
                              $scope.userRole = "other";
                           }
                           $scope.mark_read = true;
                           $scope.showDelete=true;
                           $scope.edit=true;
                        }
                     });
                        if ($scope.allMemos.length > 0)
                           $scope.haveResult = true;
                        else
                           $scope.haveResult = false;
                     }
                     return $scope.allMemos;
                })
            }
        });        
    };
    
    $scope.inbox();
    $scope.sent = function() {
         if ($scope.colorSnt != 'btn-blue') {
           $scope.pageNum = 1;
         }
         $scope.colorSnt = 'btn-blue';
         $scope.colorInb = 'btn-white';
         $scope.colorSch = 'btn-white';
         $scope.scheduletextvisibility = false;
         $scope.fetchmessage('sent');
    };

   
   
    /*watch for search*/
      $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
      function(newValue, oldValue) 
      {
          if(newValue != oldValue){
            $scope.pageNum = 1;
            if($scope.colorSnt == 'btn-blue'){
               $scope.sent();
            }if($scope.colorSch == 'btn-blue'){
               $scope.schMsg();
            }else{
                $scope.inbox();
            }
          }
      }
     );
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           if($scope.colorSnt == 'btn-blue'){
               $scope.sent();
           }
           else if($scope.colorSch == 'btn-blue'){
            $scope.schMsg();
           }
           else{
               $scope.inbox();
           }
           
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    //delete memo
    $scope.delmemos=function(ID){
        $scope.del_memoid=ID;
        $('#del_memo').show();
    };

    $scope.memoDelete = function(){
        $http.post("/api/delmemo", {Id: $scope.del_memoid, user_role: "admin"}).then(function(response){
            $('#del_memo').hide();
            if(response.data.code=="500"){
               toastr.error("time has exceeded 15 mins");
            }
            else{
                toastr.success("Memo deleted successfully.");
                $scope.sent();
            }
        });
    }
    //Mark Read
    $scope.markRead = function(memoId) {
        if ("admin" === SessionService.currentUser.roles[0]) {
            $http.post("/api/markread", {memoId: memoId, user_role: "admin"}).then(function(response){

            });
        }
        else {
            $http.post("/api/markread", {memoId: memoId, user_role: "other", userId: SessionService.currentUser._id}).then(function(response){
              if(response.data.status_code == "200"){
                $http.post("/api/markReadNotification", {memo_id: memoId, userId: SessionService.currentUser._id}).then(function(response){

                });
              }
            });
        }
    };
    
    $scope.checkRead = function(userId, sent_to) {
        var testStr = true;
        if(sent_to && sent_to.length){
          for(var i=0; i<sent_to.length; i++) {
            if( sent_to[i].user_id._id === userId ) {
              testStr = sent_to[i].mark_read;
              break;
            }
          }
        }
        return testStr;
    };
    
    $scope.closemodal=function(){
        $("#get-modal").hide(); 
    }

    $scope.open_edit=function(data){
       $("#get-modal").show();
       var curr= data;
       $scope.currentMemo= curr;
    }

    $scope.save_memo=function(){
         $http.post("/api/updmemo", $scope.currentMemo).then(function(response){
            });
         $("#get-modal").hide();
    }
    
    $scope.download_memo = function(memo) {
        $scope.file = [];
        var a = memo.my_file;
        if (a) {
            a.forEach(function(value, key) {
                $scope.file.push(value)
            });
        }
        $http.post("/api/memodownload", {
            'file': $scope.file,
            'id': memo._id
        }).then(function(response) {
            if ("200" === response.data.status_code) {
                window.open($scope.backendsiteurl+ '/public/zipFile' + response.data.zipFilePath.substr(response.data.zipFilePath.lastIndexOf('/'), response.data.zipFilePath.length))
            }
        });
    }
    $scope.addmemo=function() {
        $scope.showloader = true;
        $location.path("/addmemo");
    }
    $scope.sendScheduleMsg = function(){
      $scope.showloader = true;
      $location.path("/scheduleMsg");
    }
    $scope.activeInactiveCron = function(dataInput){

        $http.post("/api/activeInactiveCron", {"scheduletextId" : dataInput._id, "status" : dataInput.status}).then(function(response){
            if(response.data.status_code == "200"){
                $scope.schMsg();
                toastr.success("Successfully updated.");
            }
        });
    }
});