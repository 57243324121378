angular.module("app").controller("EmprankingController", function($scope, $http, $rootScope, ports, SessionService, NgTableParams, moment){
 	$scope.haveResult = false;
 	$scope.currentPage=1;
 	$scope.perPage = 150;
    $scope.searchPattern = /^[a-zA-Z0-9\'\#\@\_\-\`\.\$\,]+( [a-zA-Z0-9\'\#\@\_\-\`\.\$\,]+)*$/;
 	function setPagingData(datam){
        $scope.allData = {};
        $scope.allData = datam;
        $scope.offset = 0;
        $scope.navButtons = [];
        $scope.isPreviousDisabled = false;
        $scope.isNextDisabled = false;
        $scope.buildNavButtons = function () {
            for (var i = 0, len = ($scope.allData.length / $scope.perPage); i < len; i = i + 1) {
                $scope.navButtons.push(i);
            }
        }

        $scope.paginate = function() {
            $scope.rankdata = $scope.allData.slice($scope.offset, $scope.offset + $scope.perPage);
        };

        $scope.previous = function() {                    
            $scope.offset = $scope.offset - $scope.perPage;
        };

        $scope.next = function() {
            $scope.offset = $scope.offset + $scope.perPage;
        };	
        $scope.getoffset = function(offsetval){
             $scope.offset = offsetval;            
        }
        $scope.$watch('offset', function() {
            if( $scope.offset < 0 ){
                $scope.isPreviousDisabled = true;
                $scope.isNextDisabled = false;
                $scope.offset = 0;
                return false;
            }
            else{
                $scope.isPreviousDisabled = false;
            }
            if( $scope.offset > ($scope.allData.length-1) ){
                $scope.isNextDisabled = true;
                $scope.isPreviousDisabled = false;
                $scope.offset = ($scope.perPage * ($scope.allData.length/$scope.perPage));
                return false;
            }
            else{
                $scope.isNextDisabled = false;
            }
            $scope.paginate();
        });
        $scope.buildNavButtons();
    }          

    $scope.fetchAllEmployees = function(){
        $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.modernWebBrowsers = response.data.data;
            }
        });
    }

    $scope.fetchAllEmployees();
    
    $scope.datebeforefour = moment().subtract(120, 'day')._d.setHours(0, 0, 0, 0);
    $scope.datebeforefourMonth = moment($scope.datebeforefour).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();

	$scope.fetchRankings = function(){
        $http.post("/api/getAllRankings", {"datebeforefourMonth" : $scope.datebeforefourMonth, location:SessionService.currentUser.location, searchbyemp : $scope.searchemployee}).then(function(response){
            if (response.status == 200 && response.data.data.length) {
               $scope.totalRecords = response.data.data.length;
              
               $scope.activedaysArr = [];
               var i = 0;
               response.data.data.forEach(function(elem, key){
                    $scope.hiringdate = elem._id.hiringDate[0];
                    elem.activeDays = moment().diff(moment($scope.hiringdate), 'days');
                    $scope.activedaysArr.push(elem.activeDays);
                    i++;
               });
               if(i == response.data.data.length){
                    response.data.data.forEach(function(elem, key){
                        elem.eer_emprank = $scope.getRankEmpNum(elem.activeDays, $scope.activedaysArr);
                    });
                    $scope.rankdata = response.data.data;
                    setPagingData($scope.rankdata);
               }
               $scope.haveResult = true;
            }else{
                $scope.totalRecords = 0;
                $scope.rankdata = [];
                $scope.haveResult = false;
            }
        });
    }
    

    $scope.fetchRankings();

    $scope.closeSearch = function(){
        $scope.searchemployee = '';
        var storeSelUserId = '';
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
               if ( value.ticked === true ) {
                   storeSelUserId = value;
               }
            });
            $scope.searchemployee = storeSelUserId._id;
        }
        if(!$scope.searchemployee){
            $scope.searchemployee = '';
            $scope.showloader = false;
            $scope.fetchRankings();
        }   
    }

    $scope.getRankEmpNum = function(value, wholeArr){
		var sorted = wholeArr.slice().sort(function(a,b){return b-a})
		var str = sorted.indexOf(value)+1;
		return str;
 	}

 	$scope.EER_ValueFunction = function(rankEntry){
 		var cal_val = rankEntry._id.rankval + rankEntry.evaluationval + $scope.getRankEmpNum(rankEntry.activeDays, $scope.activedaysArr) * 2
 		return cal_val;
 	}

});