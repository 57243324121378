angular.module("app").controller("ticketNotesController", function($scope,ports,$filter,$location, SessionService, $http, getweekService, $timeout,NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.sortType = '';
    $scope.sortOrder = undefined;
    $scope.searchText = {};
    $scope.searchText.val = '';
    $scope.checkAll = {};
    $scope.selectenable = false;
    $scope.selectedAction = 0;
    $scope.daterangeLayout = true;
    $scope.monthLayout = false;
    $scope.shift = 'Morning';
    $scope.ticketcontrolshift = 'Morning';
    $scope.tickeditInfo = {};
    $("#get-modal").hide();
    $scope.notesNamePattern = /^[a-zA-Z0-9\'\#\@\_\-\`\%\=\&\$\.]+( [a-zA-Z0-9\'\#\@\_\-\`\%\=\&\$\.]+)*$/;
    var currWeekDates = getweekService.getCurrWeek();
    $scope.currWeekDate = currWeekDates;
    var $modal = $("#get-modal");
    $scope.selectedReports = {
        report: []
    };
    $scope.errormessage = "Please enter your selection to get the report.";
    $scope.report = [];
    $scope.shifts = [
      {name: "Morning", value: "Morning"},
      {name: "Afternoon", value: "Afternoon"},
      {name: "Night", value: "Night"},
      {name: "Late Night", value: "Late Night"}
    ];
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
        if ( "200" === response.data.status_code ) {
            $scope.allEvents = response.data.events;
            angular.forEach( $scope.allEvents, function( value, key ) {
              value.buttonlabel = value.name;
            });
        } 
    });

    $http.post("/api/getemplAndManagers", {location : SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });

    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }
    
    $scope.fetchEventInfo = function(){
        if(!$scope.ticketForm.dateOfReport){
            angular.forEach( $scope.allPopupEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       value.ticked = false;
                   }
            });
            toastr.error('Please Enter date for shift first to continue');
            return false;
        }
        var shift = 'Morning';
        var selectDay = new Date($scope.ticketForm.dateOfReport);
        var day = selectDay.getDay();
        $scope.selectedDay = $scope.weekdays[day];
        if($scope.allPopupEvents){
            angular.forEach( $scope.allPopupEvents, function( value, key ) {
                if ( value.ticked === true ) {
                    $scope.eventInfo = value;
                    $scope.amountPerCar = 0;
                    if($scope.eventInfo.revenue_type === 'per_car'){
                        $scope.revenue_type = "Per Car";
                        $scope.eventInfo.parking_prices.forEach(function(elem, key){
                            if(elem.timeslot == shift && elem.day == $scope.selectedDay){
                                $scope.amountPerCar = elem.price || 0;
                            }
                        });
                    }
                    else if($scope.eventInfo.revenue_type === 'flat_rate'){
                        $scope.revenue_type = "Flat Rate";
                    }
                    else if($scope.eventInfo.revenue_type === 'rate_per_hour'){
                        $scope.revenue_type = "Rate Per Hour";
                        var profitLossJson = {};
                        profitLossJson.event_account_id = $scope.eventInfo._id;
                        profitLossJson.selectDay = selectDay;
                        $http.post("/api/getTotalHoursOfDay",profitLossJson).then(function(response){
                            if ( 200 == response.status ) {
                                if(response.data.data.length){
                                    $scope.totalhoursofDay = response.data.data[0].totalTimeDiff;
                                }else{
                                    $scope.totalhoursofDay = 0;
                                }
                            } 
                        });
                    }
                    
                }
            }); 
        }
    }

    $scope.openTicketcontrol = function(){
        $scope.ticketForm = {};
        $scope.ticketForm.shift = 'Morning';
        var shift = 'Morning';
        $scope.eventInfo = '';
        $http.post("/api/getEventAccountsWithTicketControl",{location : SessionService.currentUser.location}).then(function(response){
            if ( "200" === response.data.status_code ) {
                $scope.allPopupEvents = response.data.events;
                angular.forEach( $scope.allPopupEvents, function( value, key ) {
                  value.buttonlabel = value.name;
                });
            } 
        });
    }

    $scope.viewTicketcontrolInfo = function(ticketId){
        $http.post('/api/viewTicketControl',{ticketId:ticketId}).then(function(response){
            if(response.status == 200){
                $scope.ticketDetail = response.data.data;                
            }
        }); 
    }

    $scope.editTicketcontrol = function(tickInfo){
        $scope.tickeditInfo = tickInfo;
        $scope.ticketcontrolshift = tickInfo.shift;
        var selectDay = new Date($scope.tickeditInfo.dateOfReport);
        var day = selectDay.getDay();
        $scope.selectedDay = $scope.weekdays[day];
        $scope.eventInfo = tickInfo.eventId;
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
               value.ticked = false;
               if (value._id === tickInfo.created_by._id) {
                   value.ticked = true;
               }
            });
        }

        if($scope.tickeditInfo.revenueType === 'per_car'){
            $scope.revenue_type = "Per Car";
            $scope.tickeditInfo.eventId.parking_prices.forEach(function(elem, key){
                if(elem.timeslot == tickInfo.shift && elem.day == $scope.selectedDay){
                    $scope.amountPerCar = elem.price || 0;
                }
            });
        }
        else if($scope.tickeditInfo.revenueType === 'flat_rate'){
            $scope.revenue_type = "Flat Rate";
        }
        else if($scope.tickeditInfo.revenueType === 'rate_per_hour'){
            $scope.revenue_type = "Rate Per Hour";
            var profitLossJson = {};
            $scope.revenueRate = $scope.tickeditInfo.revenueRate;
            profitLossJson.event_account_id = $scope.eventInfo._id;
            profitLossJson.selectDay = selectDay;
            $http.post("/api/getTotalHoursOfDay",profitLossJson).then(function(response){
                if ( 200 == response.status ) {
                    if(response.data.data.length){
                        $scope.totalhoursofDay = response.data.data[0].totalTimeDiff;
                    }else{
                        $scope.totalhoursofDay = 0;
                    }
                } 
            });
        }
    }

    $scope.openTicketcontrolOnshift = function(shift, dateofreport){
        if(!dateofreport){
            toastr.error('Please Enter date for shift first to continue');
            return false;
        }
        var selectDay = new Date(dateofreport);
        var day = selectDay.getDay();
        $scope.selectedDay = $scope.weekdays[day];
        $scope.ticketcontrolshift = shift;
        $scope.amountPerCar = 0;
        $scope.eventInfo.parking_prices.forEach(function(elem, key){
            if(elem.timeslot == shift && elem.day == $scope.selectedDay){
                $scope.amountPerCar = elem.price || 0;
            }
        });
    }

    $scope.saveTicketControl = function(){
        var ticketJson = {};
        $scope.ticketControlForm.$setPristine();
        ticketJson = $scope.ticketForm;
        if($scope.allPopupEvents){
            angular.forEach( $scope.allPopupEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                   }
            });
        }
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeEmployeeId = value;
                   }
            });
        }
        ticketJson.eventId = $scope.storeSelEventAccountId._id;
        ticketJson.cashDeposit = angular.element('#cashDeposit').val();
        ticketJson.revenue = parseFloat(angular.element('#revenue').val()).toFixed(2);
        ticketJson.revenueType = $scope.storeSelEventAccountId.revenue_type;
        ticketJson.revenueRate = 0;
        if($scope.storeSelEventAccountId.revenue_type === 'flat_rate'){
            ticketJson.revenueRate = $scope.storeSelEventAccountId.flat_rate;
        }
        if($scope.storeSelEventAccountId.revenue_type === 'rate_per_hour'){
            ticketJson.revenueRate = $scope.storeSelEventAccountId.rate_per_hour;
        }
        ticketJson.totalChargedCars = angular.element('#totalChargedCars').val();
        ticketJson.dateOfReport = moment($scope.ticketForm.dateOfReport).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        ticketJson.created_by = $scope.storeEmployeeId._id;
        $http.post('/api/saveTicketControlReport',ticketJson).then(function(response){
            if("200" === response.data.status_code){
                toastr.success("Successfully Added!");
                angular.element('#modal-tickets').modal('hide');
            }
            if("202" === response.data.status_code){
                $('#confirm_ticketcontrolOverride').show();
            }
        }); 
    }

    $scope.editTicketControlData = function(eventInfo){
        $scope.eventInfo = eventInfo;
        var ticketJson = {};
        ticketJson = $scope.tickeditInfo;
        ticketJson.shift = $scope.ticketcontrolshift;
        ticketJson.eventId = $scope.eventInfo._id;
        ticketJson.cashDeposit = angular.element('#cashDepositedit').val();
        ticketJson.revenue = parseFloat(angular.element('#revenueedit').val()).toFixed(2);
        ticketJson.totalChargedCars = angular.element('#totalChargedCarsedit').val();
        //ticketJson.dateOfReport = moment($scope.tickeditInfo.dateOfReport).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeEmployeeId = value;
                   }
            });
        }
        ticketJson.created_by = $scope.storeEmployeeId._id;
        $http.post('/api/saveTicketControlReportOnEdit',ticketJson).then(function(response){
            if("200" === response.data.status_code){
                toastr.success("Successfully Added!");
                angular.element('#modal-editTicket').modal('hide');
                $scope.getReport();
            }
            if("202" === response.data.status_code){
                $('#confirm_ticketeditcontrolOverride').show();
            }
        }); 
    }

    $scope.cancelTicketeditcontrolOverride = function(){
        $('#confirm_ticketeditcontrolOverride').hide();
        $scope.getReport();
    }

    $scope.updateTicketControlConfirm = function(){
        var ticketJson = {};
        ticketJson = $scope.ticketForm;
        if($scope.updateTicketControlConfirm){
            angular.forEach( $scope.allPopupEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                   }
            });
        }
        ticketJson.eventId = $scope.storeSelEventAccountId._id;
        ticketJson.cashDeposit = angular.element('#cashDeposit').val();
        ticketJson.revenue = parseFloat(angular.element('#revenue').val()).toFixed(2);
        ticketJson.totalChargedCars = angular.element('#totalChargedCars').val();
        ticketJson.dateOfReport = moment($scope.ticketForm.dateOfReport).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();        
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeEmployeeId = value;
                   }
            });
        }
        ticketJson.created_by = $scope.storeEmployeeId._id;
        $http.post('/api/updateTicketControlReport',ticketJson).then(function(response){
            if("200" === response.data.status_code){
                toastr.success("Successfully Updated!");
                angular.element('#modal-tickets').modal('hide');
                $('#confirm_ticketcontrolOverride').hide();
                $scope.getReport();
            }
        }); 
    }

    $scope.updateEditTicketControlConfirm = function(){
        var ticketJson = {};
        ticketJson = $scope.tickeditInfo;
        ticketJson.shift = $scope.tickeditInfo.shift;
        ticketJson.ticketcontrolId = $scope.tickeditInfo._id;
        ticketJson.cashDeposit = angular.element('#cashDepositedit').val();
        ticketJson.revenue = parseFloat(angular.element('#revenueedit').val()).toFixed(2);
        ticketJson.totalChargedCars = angular.element('#totalChargedCarsedit').val();
      //  ticketJson.dateOfReport = moment($scope.tickeditInfo.dateOfReport).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();        
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeEmployeeId = value;
                   }
            });
        }
        ticketJson.created_by = $scope.storeEmployeeId._id;
        $http.post('/api/updateTicketControlReportbyId',ticketJson).then(function(response){
            if("200" === response.data.status_code){
                toastr.success("Successfully Updated!");
                angular.element('#modal-editTicket').modal('hide');
                $('#confirm_ticketeditcontrolOverride').hide();
                $scope.getReport();
            }
        }); 
    }

    $scope.getReport = function() {
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDate = new Date($scope.dateRange.end_date);
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
        $scope.storeSelEventAccountId = {};
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                   }
            });
        }
      
        $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType = 'dateOfReport';
            $scope.sortOrder = -1;
        }
        var enddDate = new Date($scope.dateRange.end_date);
        var correctEndDate = enddDate.setDate(enddDate.getDate() + 1)
        //$scope.datenewRange = {"start_date" : new Date($scope.dateRange.start_date), "end_date" : new Date(correctEndDate)};
        //$scope.datenewRange = {"start_date" : new Date($scope.dateRange.start_date), "end_date" : new Date($scope.dateRange.end_date)};
        $scope.datenewRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment($scope.dateRange.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};

        var reportjson = {
            dateRange: $scope.datenewRange,
            shift:$scope.shift,
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            field: $scope.sortType,
            sortOrder:$scope.sortOrder
        };

        if($scope.storeSelEventAccountId._id){
            reportjson.eventId = $scope.storeSelEventAccountId._id;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                reportjson.count = params.count();
                reportjson.page = params.page();
                reportjson.location = SessionService.currentUser.location;
                return $http.post("/api/getTicketSystemReport", reportjson).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true
                    params.total(response.data.total); // recal. page nav controls
                    $scope.ticketData = response.data.data;
                    $scope.totalRevenue = 0;
                    $scope.totalVips = 0;
                    $scope.totalCars = 0;
                    $scope.totalChargedCars = 0;
                    $scope.ticketData.forEach(function(el, ke){
                        if(el.revenueType == "rate_per_hour"){
                            var profitLossJson = {};
                            profitLossJson.event_account_id = el.eventId._id;
                            profitLossJson.selectDay = el.dateOfReport;
                            $http.post("/api/getTotalHoursOfDay",profitLossJson).then(function(response){
                                if ( 200 == response.status ) {
                                    if(response.data.data.length){
                                        $scope.totalhoursofDay = response.data.data[0].totalTimeDiff;
                                        el.revenue = (el.revenueRate * $scope.timeToDecimal($scope.totalhoursofDay)).toFixed(2);
                                    }else{
                                        $scope.totalhoursofDay = 0;
                                        el.revenue = (el.revenueRate * $scope.timeToDecimal($scope.totalhoursofDay)).toFixed(2);
                                    }
                                } 
                            });
                        }
                        $scope.totalRevenue = $scope.totalRevenue + el.revenue;
                        $scope.totalVips = $scope.totalVips + el.vips;
                        $scope.totalChargedCars = $scope.totalChargedCars + el.totalChargedCars;
                        $scope.totalCars = $scope.totalCars + el.totalChargedCars + el.vips;
                        if(el.revenueType == "flat_rate"){
                            el.revenue = 0;
                        }
                    })
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.ticketData;
                })
            }
        });
    }
    $scope.getReport();
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.getReport();
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }

    $scope.checkAll.val= false;
    $scope.selectAllGroup = function(check) {
            $scope.flag = {};
            $scope.flag.enable = false;
            if (check) {
                $scope.selectedReports.report = $scope.ticketData.map(function(item) {
                    return item._id;
                });
                $scope.selectenable = true;
            } else {
                $scope.selectedReports.report = [];
                $scope.checkAll.val = false;
                $scope.selectenable = false;
            }
    }

    $scope.performAction = function() {
        $scope.selectedAction = selectedAction.value;
        if ($scope.selectedAction == 0) {
            toastr.error("Please select some selection.");
        }else if ($scope.selectedReports.report.length == 0) {
            toastr.error("Please select atleast one checkbox.");
        }

        if ($scope.selectedAction != 0 && $scope.selectedAction == 1 && $scope.selectedReports.report.length > 0) {
            $scope.enbl = true;
        }

        if( $scope.selectedReports.report.length > 0 && $scope.selectedAction == 1){
            $http.post("/api/removereportControls", {
                'enabled': $scope.enbl,
                'allChecked': $scope.selectenable,
                "controlId": $scope.selectedReports.report
            }).then(function(response) {
                $scope.ticketData = response.data.data;
                angular.element('#selectedAction').val(0);
                $scope.getReport();
                toastr.success("Successfully done.");
                $scope.selectedReports.report = [];
                $scope.checkAll.val = false;
            })
        }
    }
    
    /*view report in modal*/
    $scope.closemodal = function() {
        $("#get-modal").hide();
    }
    $scope.viewReport = function(data) {
            $scope.modal_data = data;
            $("#get-modal").show();
    }

    $scope.getMonthlyReport = function(startdate, enddate){
        if (startdate == 'Invalid Date' || !startdate) {
            toastr.error('Please Select Start Date');
            return false;
        }

         if (enddate == 'Invalid Date' || !enddate) {
            toastr.error('Please Select End Date');
            return false;
        }
        var startDate = new Date(startdate);
        var end_Date = new Date(enddate);
        var endDate = end_Date.setDate(end_Date.getDate() + 1)
        
        if ($scope.sortType == '') {
            $scope.sortType = 'dateOfReport';
            $scope.sortOrder = -1;
        }
        $scope.dateRange.start_date = startDate;
        $scope.dateRange.end_date = endDate;
        $scope.dateRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment($scope.dateRange.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        $scope.storeSelEventAccountId = {};
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                   }
            });
        }
        if ($scope.sortType == '') {
            $scope.sortType = 'dateOfReport';
            $scope.sortOrder = -1;
        }
        var reportjson = {
            dateRange: $scope.dateRange,
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            field: $scope.sortType,
            sortOrder:$scope.sortOrder
        };
        if($scope.storeSelEventAccountId._id){
            reportjson.eventId = $scope.storeSelEventAccountId._id;
        }else{
            toastr.error('Please Choose Event/Account.');
            return false;
        }
        $scope.showloader = true;
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                reportjson.count = params.count();
                reportjson.page = params.page();
                reportjson.location = SessionService.currentUser.location;
                return $http.post("/api/getTicketSystemReport", reportjson).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true
                    params.total(response.data.total); // recal. page nav controls
                    $scope.ticketData = response.data.data;
                    $scope.totalRevenue = 0;
                    $scope.totalVips = 0;
                    $scope.totalCars = 0;
                    $scope.totalChargedCars = 0;
                    $scope.ticketData.forEach(function(el, ke){
                        if(el.revenueType == "rate_per_hour"){
                            var profitLossJson = {};
                            profitLossJson.event_account_id = el.eventId._id;
                            profitLossJson.selectDay = el.dateOfReport;
                            $http.post("/api/getTotalHoursOfDay",profitLossJson).then(function(response){
                                if ( 200 == response.status ) {
                                    if(response.data.data.length){
                                        $scope.totalhoursofDay = response.data.data[0].totalTimeDiff;
                                        el.revenue = (el.revenueRate * $scope.timeToDecimal($scope.totalhoursofDay)).toFixed(2);
                                    }else{
                                        $scope.totalhoursofDay = 0;
                                        el.revenue = (el.revenueRate * $scope.timeToDecimal($scope.totalhoursofDay)).toFixed(2);
                                    }
                                } 
                            });
                        }
                        $scope.totalRevenue = $scope.totalRevenue + el.revenue;
                        $scope.totalVips = $scope.totalVips + el.vips;
                        $scope.totalChargedCars = $scope.totalChargedCars + el.totalChargedCars;
                        $scope.totalCars = $scope.totalCars + el.totalChargedCars + el.vips;
                        if(el.revenueType == "flat_rate"){
                            el.revenue = 0;
                        }
                    })
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.ticketData;
                })
            }
        });
    }
});