angular.module("app").controller("LogoutController", function($scope, NotificationService, AuthenticationService, SessionService, $location, $window, $http) {
  $scope.logout = function() {
    $http.post('/api/logout', {loggedid:SessionService.currentUser._id}).then(function(checkloggedoutresponse){
    	AuthenticationService.logoutUser().then(function() {
	        SessionService.unset("user");
	        SessionService.currentUser = undefined;
	        NotificationService.notify("Log out successfully", "success");
	        window.location = "/login";
    	});
    });
  };

});