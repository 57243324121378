angular.module("app").controller("TrackOcProController", function($scope,ports,$filter,$location, SessionService, $http, UserService, $timeout, getweekService,NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.searchText = {};
    $scope.searchText.val = '';
    var currWeekDates = getweekService.getCurrWeek();
    $scope.currWeekDate = currWeekDates;
    var $modal = $("#get-modal");
    $scope.errormessage = "Please enter your selection to get the report.";
    $scope.report = [];
    $scope.tracklistData = [];
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
         if ( "200" === response.data.status_code ) {
             $scope.allEvents = response.data.events;
             angular.forEach( $scope.allEvents, function( value, key ) {
              value.buttonlabel = value.name;
             });
         } 
    });
    $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    
    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }
    
    $scope.getReport = function() {
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDate = new Date($scope.dateRange.end_date);
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
        $scope.storeSelEventAccountId = '';
        $scope.storeSelUserId = '';
        var trackOpenCloseJson = {};
        var enddDate = new Date($scope.dateRange.end_date);
        var correctEndDate = enddDate.setDate(enddDate.getDate() + 1);
        //$scope.datenewRange = {"start_date" : new Date($scope.dateRange.start_date), "end_date" : new Date(correctEndDate)};
        $scope.datenewRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment(correctEndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        trackOpenCloseJson.dateRange = $scope.datenewRange;
        trackOpenCloseJson.owner_id = SessionService.currentUser._id;
        trackOpenCloseJson.role = SessionService.currentUser.roles[0];
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                   }
            });
        }
        if(!$scope.storeSelEventAccountId._id){
            toastr.error('Please choose Event/Account from list.');
            return false;
        }
        trackOpenCloseJson.eventId = $scope.storeSelEventAccountId._id;
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if (value.ticked == true) {
                    $scope.storeSelUserId = value;
                }
            });
        }
        if($scope.storeSelUserId._id){
            trackOpenCloseJson.empId = $scope.storeSelUserId._id;
        }


        if($scope.storeSelEventAccountId._id){
            $http.post("/api/getClockingReport",trackOpenCloseJson).then(function(response){
                if (!response.data.data.length) {
                    $scope.haveResult = false;
                }else{
                    $scope.haveResult = true;
                    response.data.data.forEach(function(elem, key){
                        elem.clockInDateTime = moment(elem.clockInDateTime).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY HH:mm:ss");
                        elem.clockOutDateTime = moment(elem.clockOutDateTime).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY HH:mm:ss");
                        var clockIntime = elem.clockInDateTime.split(" ");
                        var startTime = clockIntime[1];
                        if(elem.alignScheduleTime){
                            var endTime = elem.alignScheduleTime + ":00";
                            var start = moment(startTime, "HH:mm");
                            var end = moment(endTime, "HH:mm");
                            var minutes = end.diff(start, 'minutes');
                        }else{
                            var minutes = 0;
                        }
                        elem.howLate_howEarly = minutes;
                    });
                    $scope.reportData = response.data.data;
                }
            });
        }else{
            $scope.haveResult = false;
        }
    }

    $scope.showTrackingList = function(timesheetId){
        $scope.tracklistData = [];
        $http.post("/api/fetchTrackList",{clockTimesheetId:timesheetId}).then(function(response){
            if ( "200" === response.data.status_code ) {
                $scope.haveResult = true;
                response.data.data.trackList.forEach(function(elem, key){
                    elem.created_date = moment(elem.created_date).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY HH:mm:ss");
                });
                $scope.tracklistData = response.data.data.trackList;
            }
        });
    }

    $scope.openCloseProc = function(questions){
        $scope.openProcques = questions;
    }
});