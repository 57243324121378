angular.module("app").controller("LoginController", function($scope, AuthenticationService, SessionService, $location, $window, $timeout) {
  $scope.credentials = { email: "", password: "" };
  $scope.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  $scope.login = function() {
    if($scope.credentials){
      AuthenticationService.authenticateUser($scope.credentials).then(function(success) {
        if (success) {
          if(SessionService.currentUser.active === false){
            $scope.showmessage = true;
            $scope.alertType = "alert alert-danger";
            $scope.message = "Your account is deactivated, Please contact your administrator";
              $timeout(function() {
              $scope.showmessage = false;
            }, 3000);
          }
          else{
            $window.location.href = "/dashboard?loggedin=true";
          }
        } else {
            $scope.showmessage = true;
            $scope.alertType = "alert alert-danger";
            $scope.message = "Wrong Username or Password";
              $timeout(function() {
              $scope.showmessage = false;
            }, 3000);
        }
      });
    }
  };

});