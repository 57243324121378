angular.module("app").controller("tbpCalendarController", function($scope, $rootScope, $http, $q, SocketService, $route, $filter, ports, $routeParams, $location, sidepanelactiveService, SessionService, NgTableParams, moment) {
	var $modal = $('#event-modal');
	$scope.user = SessionService.currentUser;
	$scope.currpage = $location.absUrl().split('/')[3];
	$scope.currDate   = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
	$scope.showloader = true;
	$scope.timeslots = ["Morning","Afternoon","Night","Late Night"];
	sidepanelactiveService.test();
	$scope.empschedule = {};
	$scope.haveResult = false;
	$scope.typeselect = "All";
	$scope.typelist = [{
        name: "Approved",
        value: 'Approve'
    }, {
        name: "Waiting",
        value: "Waiting"
    }, {
        name: "All",
        value: "All"
    }];
	$scope.twentyfour_to_twelve = function(ti){
		if(ti){
			ti = ti.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [ti];
			if (ti.length > 1) { // If time format correct
				ti = ti.slice (1);  // Remove full string match value
				ti[5] = +ti[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
				ti[0] = +ti[0] % 12 || 12; // Adjust hours
			}
			return ti.join (''); // return adjusted time or original string
		}
	}
	var calendar = $('#calendar').fullCalendar({                                           
	  	events:function(start, end, timezone, callback) {
	  		var events = [];
	  		var monthStartDate  = moment(start._d).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
	  		var monthcorrectStartDate = moment(monthStartDate).add(1,'days').toISOString();
	  		var monthEndDate  = moment(end._d).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
	  		var inputs = {};
	  		inputs.currMonthStartDate = monthcorrectStartDate;
	  		inputs.currMonthEndDate = monthEndDate;
	  		inputs.location = SessionService.currentUser.location;
	  		var events = [];
	  		inputs.currentDate = moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate(),
    		$http.post("/api/fetchCalendarShifts",inputs).then(function(response){
    			if(response.status == 200){
    				angular.forEach(response.data.data, function(elem){
    					var shiftStr = '';
    					shiftStr = (elem.count == 1 ) ? ' Shift' : ' Shifts'
    					elem.titleString = elem.count + shiftStr;
    					events.push({start:new Date(elem._id),end: new Date(elem._id),title:elem.titleString});
    				});
					callback(events);
    			}
			});
	  	},
	  	eventRender: function(event, eventElement) {
		    if (event.title) {
		        eventElement.find("div.fc-content").addClass("text-center w-700");
		    }
		},
		eventClick: function (calEvent, jsEvent, view) {
			$scope.$apply(function () {
				var selectedDate  = moment(calEvent._start._d).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
				$scope.selectedIsoDate = selectedDate.toISOString();
				$scope.selectedDate = calEvent._start._d;
				/*var currDate = moment(calEvent._d).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
				var currIsoDate = currDate.toISOString();
				$scope.clickedDateOld = new Date(currIsoDate);
				$scope.clickedDate = $scope.clickedDateOld.setDate($scope.clickedDateOld.getDate()+1);
				$scope.sendweekday = new Date($scope.clickedDate).toISOString();*/
				$http.post("/api/fetchTradeShiftsInfo",{selectedDate:$scope.selectedIsoDate, location : SessionService.currentUser.location}).then(function(response){
	    			if(response.status == 200){
	    				angular.forEach(response.data.data, function(el){
							var checkextrashift = true;
	    					angular.forEach(el.eventId.shift_template_id, function(sft){
	    						angular.forEach(sft.shifts, function(innerSft){
		    						if(innerSft._id === el.shiftId){
		    							var checkextrashift = false;
		    							if(innerSft.endshiftTime){
		    								el.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
		    							}else{
		    								el.endStr = '';
		    							}
		    							el.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + el.endStr;
		    							el.eventId.shiftslot = sft.timeslot;
		    						}
	    						});
	    					});
	    					if(checkextrashift){
	    						angular.forEach(el.eventId.extrashifts, function(sft){
		    						angular.forEach(sft.shifts, function(innerSft){
			    						if(innerSft._id === el.shiftId){
			    							if(innerSft.endshiftTime){
			    								el.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
			    							}else{
			    								el.endStr = '';
			    							}
			    							el.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + el.endStr;
			    							el.eventId.shiftslot = sft.timeslot;
			    						}
		    						});
		    					});
	    					}
	    				});
	    				$scope.shiftsInfo = response.data.data;
	    			}
				});
			});
			angular.element('#modal-shifts').modal();
		}
	});	

	$scope.chooseTrade = function(tradeInfo){
		var tradecheckjson = {};
		tradecheckjson.employee_id = $scope.user._id;
		tradecheckjson.timeslot = tradeInfo.timeslot;
		tradecheckjson.scheduleDate = tradeInfo.eventScheduleDate;
		$http.post("/api/checkDateShift",tradecheckjson).then(function(response){
			if(200 === response.status && response.data.data){
				toastr.error("You can't select this shift from the board as you are already scheduled for this time slot");
			}else{
				$('#confirm_shift').show();
				$scope.tradeId = tradeInfo._id;
			}
		});
	}

	$scope.shiftTradeConfirm = function(){
		var tradejson = {};
		tradejson.coWorkerUserId = $scope.user._id;
		tradejson.status = 'Waiting';
		tradejson.tradeId = $scope.tradeId;
		$http.post("/api/selectTradeShift",tradejson).then(function(response){
			if(response.status == 200){
				toastr.success("You have successfully accepted a shift, final decision is waiting for management decision!");
				$('#confirm_shift').hide();
				angular.element('#modal-shifts').modal('hide');
				$('#calendar').fullCalendar('refetchEvents');
			}
		});
	}

	$scope.fetchAdminTradeRequests = function() {
        $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType = 'tradeDateTime';
            $scope.sortOrder = -1;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/fetchAminTradeRequests", {
                    count: params.count(),
                    page: params.page(),
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    searchByRole: $scope.typeselect,
                    location : SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    angular.forEach(response.data.data, function(el){
                    	if(el.exchangeshift){
                    		var exchangeStr = el.exchangeshift.split(' - ');
                    		el.exchangeshift = $scope.twentyfour_to_twelve(exchangeStr[0])+' - '+$scope.twentyfour_to_twelve(exchangeStr[1]);
                    	}
                    	var checkextrashift = true;
    					angular.forEach(el.eventId.shift_template_id, function(sft){
    						angular.forEach(sft.shifts, function(innerSft){
	    						if(innerSft._id === el.shiftId){
	    							var checkextrashift = false;
	    							if(innerSft.endshiftTime){
	    								el.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
	    							}else{
	    								el.endStr = '';
	    							}
	    							el.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + el.endStr;
	    							el.eventId.shiftslot = sft.timeslot;
	    						}
    						});
    					});

    					if(checkextrashift){
    						angular.forEach(el.eventId.extrashifts, function(sft){
	    						angular.forEach(sft.shifts, function(innerSft){
		    						if(innerSft._id === el.shiftId){
		    							if(innerSft.endshiftTime){
		    								el.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
		    							}else{
		    								el.endStr = '';
		    							}
		    							el.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + el.endStr;
		    							el.eventId.shiftslot = sft.timeslot;
		    						}
	    						});
	    					});
    					}

	    			});

                    $scope.tradeAdminRequests = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.tradeAdminRequests;
                })
            }
        });
    }

    $scope.fetchAdminRejectedTradeRequests = function() {
        $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType = 'tradeDateTime';
            $scope.sortOrder = -1;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/fetchAminRejectedTradeRequests", {
                    count: params.count(),
                    page: params.page(),
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    searchByRole: $scope.typeselect,
                    location : SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    angular.forEach(response.data.data, function(el){
                    	if(el.exchangeshift){
                    		var exchangeStr = el.exchangeshift.split(' - ');
                    		el.exchangeshift = $scope.twentyfour_to_twelve(exchangeStr[0])+' - '+$scope.twentyfour_to_twelve(exchangeStr[1]);
                    	}
                    	var checkextrashift = true;
    					angular.forEach(el.eventId.shift_template_id, function(sft){
    						angular.forEach(sft.shifts, function(innerSft){
	    						if(innerSft._id === el.shiftId){
	    							var checkextrashift = false;
	    							if(innerSft.endshiftTime){
	    								el.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
	    							}else{
	    								el.endStr = '';
	    							}
	    							el.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + el.endStr;
	    							el.eventId.shiftslot = sft.timeslot;
	    						}
    						});
    					});

    					if(checkextrashift){
    						angular.forEach(el.eventId.extrashifts, function(sft){
	    						angular.forEach(sft.shifts, function(innerSft){
		    						if(innerSft._id === el.shiftId){
		    							if(innerSft.endshiftTime){
		    								el.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
		    							}else{
		    								el.endStr = '';
		    							}
		    							el.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + el.endStr;
		    							el.eventId.shiftslot = sft.timeslot;
		    						}
	    						});
	    					});
    					}

	    			});

                    $scope.tradeAdminRequests = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.tradeAdminRequests;
                })
            }
        });
    }
    
    $scope.reset_page=function() {
        $scope.pageNum = 1;
        $scope.fetchAdminTradeRequests();
    }

    $scope.fetchUserTradeRequests = function() {
        $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType = 'tradeDateTime';
            $scope.sortOrder = -1;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/fetchTradeRequests", {
                    count: params.count(),
                    page: params.page(),
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    coWorkerUserId:$scope.user._id,
                    location : SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    angular.forEach(response.data.data, function(elem){
                    	if(elem.exchangeshift){
                    		var exchangeStr = elem.exchangeshift.split(' - ');
                    		elem.exchangeshift = $scope.twentyfour_to_twelve(exchangeStr[0])+' - '+$scope.twentyfour_to_twelve(exchangeStr[1]);
                    	}
                    	angular.forEach(elem.eventId.shift_template_id, function(sft){
    						angular.forEach(sft.shifts, function(innerSft){
	    						if(innerSft._id === elem.shiftId){
	    							if(innerSft.endshiftTime){
	    								elem.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
	    							}else{
	    								elem.endStr = '';
	    							}
	    							elem.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + elem.endStr;
	    							elem.eventId.shiftslot = sft.timeslot;
	    						}
    						});
    					});
                    });
                    $scope.tradeRequests = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    SocketService.emit('get trade notifications', {user_id: SessionService.currentUser._id});
					SocketService.on('trade notification data', function(data){
						$scope.tradenotificationCount = data;
					});
                    return $scope.tradeRequests;
                })
            }
        });
    }

	if ($scope.currpage == 'tradeAdminRequests') {
		$scope.fetchAdminTradeRequests();
	}

	if ($scope.currpage == 'adminRejectedTrades') {
		$scope.fetchAdminRejectedTradeRequests();
	}

	if ($scope.currpage == 'tradeRequests') {
		$scope.fetchUserTradeRequests();
	}
	var tradeId = $routeParams.id;
	var rejecttradeId = $routeParams.rejectid;
	if (tradeId || rejecttradeId) {
        if (tradeId) {
        	var tradeid_new = tradeId.substr(1);
        	var httpurl = "/api/gettrade/"+tradeid_new;
        }
        if(rejecttradeId){
        	var rejectedtradeid_new = rejecttradeId.substr(1);
        	var httpurl = "/api/getrejectedtrade/"+rejectedtradeid_new;	
        }
        $http.get(httpurl).then(function(response){
            if(response.status == 200) {
            	if(response.data.data.exchangeshift){
                	var exchangeStr = response.data.data.exchangeshift.split(' - ');
                	response.data.data.exchangeshift = $scope.twentyfour_to_twelve(exchangeStr[0])+' - '+$scope.twentyfour_to_twelve(exchangeStr[1]);
            	}
            	var tradeinginfo = response.data.data;
            	var checkextrashift = true;
				angular.forEach(tradeinginfo.eventId.shift_template_id, function(sft){
					angular.forEach(sft.shifts, function(innerSft){
						if(innerSft._id === tradeinginfo.shiftId){
							var checkextrashift = false;
							if(innerSft.endshiftTime){
								tradeinginfo.eventId.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
							}else{
								tradeinginfo.eventId.endStr = '';
							}

							if(innerSft.startShiftTime){
								tradeinginfo.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + tradeinginfo.eventId.endStr;
							}else{
								tradeinginfo.eventId.shifttime = tradeinginfo.eventId.endStr;
							}
							tradeinginfo.eventId.shiftslot = sft.timeslot;
						}
					});
				});
				if(checkextrashift){
					angular.forEach(tradeinginfo.eventId.extrashifts, function(sft){
						angular.forEach(sft.shifts, function(innerSft){
    						if(innerSft._id === tradeinginfo.shiftId){
    							if(innerSft.endshiftTime){
									tradeinginfo.eventId.endStr = ' - '+$scope.twentyfour_to_twelve(innerSft.endshiftTime);
    							}else{
    								tradeinginfo.eventId.endStr = '';
    							}

    							if(innerSft.startShiftTime){
    								tradeinginfo.eventId.shifttime = $scope.twentyfour_to_twelve(innerSft.startShiftTime) + tradeinginfo.eventId.endStr;
    							}else{
    								tradeinginfo.eventId.shifttime = tradeinginfo.eventId.endStr;
    							}
    							tradeinginfo.eventId.shiftslot = sft.timeslot;
	    					}
						});
					});
				}
				var overlapjson = {};
				overlapjson.schDte = response.data.data.eventScheduleDate;
				overlapjson.userId = response.data.data.coWorkerUserId;
				$http.post("/api/checkoverlappings", overlapjson).then(function(response){
					if(response.status == 200){
						$scope.TradeInfo.overlapshifts = '';
						$scope.TradeInfo.timeslotsArr = [];
						angular.forEach(response.data.data, function(el, ke){
							var schDate = '';
							var schDate = moment(new Date(el.scheduleDate)).format("MMM DD, YYYY");
							$scope.TradeInfo.overlapshifts = $scope.TradeInfo.overlapshifts + el.event_account_id.name +' | '+ schDate +' | '+$scope.twentyfour_to_twelve(el.shiftReminderTime)+':'+$scope.twentyfour_to_twelve(el.shiftEndTime) + ' | '+el.timeslot+' shift, ';
							$scope.TradeInfo.timeslotsArr.push(el.timeslot); 
						})
					}
				});
				tradeinginfo.status = 'Approve';
                $scope.TradeInfo = tradeinginfo;
            };
        });
	}

	$scope.tradeResponse = function(tradeSaveInfo) {
		$scope.showloader = true;
		var todaydate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		var tradejson = {};
		tradejson.approveRejectBy = $scope.user._id;
		tradejson.manager_comment = tradeSaveInfo.manager_comment;
		tradejson.status = tradeSaveInfo.status;
		tradejson.tradeId = tradeSaveInfo._id;
		tradejson.tradeapproveDate = todaydate;
		if(tradeSaveInfo.status == "Approve"){
			/*if($scope.TradeInfo.timeslotsArr.indexOf(tradeSaveInfo.timeslot) > -1){
				toastr.error("Co-Worker is already scheduled in the this time slot.");
			}else{*/
				$http.post("/api/updatetrade",tradejson).then(function(response){
					$scope.showloader = false;
					if(response.status == 200){
						toastr.success("Successfully Done !");
						SocketService.emit('getAdminTradeNotifications', {user_id: SessionService.currentUser._id, role : SessionService.currentUser.roles[0]});
						SocketService.on('AdminTradeNotifications',function(data){
							$rootScope.admintradenotifications = data;
						});
						$location.path("/tradeAdminRequests");
					}
				});
			//}
		}else{
				$http.post("/api/updatetrade",tradejson).then(function(response){
					$scope.showloader = false;
					if(response.status == 200){
						toastr.success("Successfully Done !");
						SocketService.emit('getAdminTradeNotifications', {user_id: SessionService.currentUser._id, role : SessionService.currentUser.roles[0]});
						SocketService.on('AdminTradeNotifications',function(data){
							$rootScope.admintradenotifications = data;
						});
						$location.path("/tradeAdminRequests");
					}
				});
		}
	}

	$scope.AcceptRequestConfirmation = function(tradeId){
		$('#confirm_accept').show();
		$scope.tradeTransactionId = tradeId;
	}

	$scope.RejectRequestConfirmation = function(tradeId){
		$('#confirm_reject').show();
		$scope.tradeTransactionId = tradeId;
	}

	$scope.AcceptRequest = function(){
		$('#confirm_accept').hide();
		var tradejson = {};
		tradejson.status = 'Waiting';
		tradejson.tradeId = $scope.tradeTransactionId;
		$http.post("/api/updatetrade",tradejson).then(function(response){
			if(response.status == 200){
				toastr.success("Successfully Done !");
				$scope.fetchUserTradeRequests();
			}
		});
	}

	$scope.RejectRequest = function(){
		$('#confirm_reject').hide();
		var tradejson = {};
		tradejson.status = 'Reject';
		tradejson.tradeId = $scope.tradeTransactionId;
		$http.post("/api/updatetrade", tradejson).then(function(response){
			if(response.status == 200){
				toastr.success("Successfully Done !");
				$scope.fetchUserTradeRequests();
			}
		});
	}

	$scope.checkOldNew = function(eventDate){
		var eventDatenew  = new Date(eventDate);
		if(eventDatenew.getTime() >= $scope.currDate.getTime()){
			return true;
		}else{
			return false;
		}
	}

	$scope.tradeSubscribe = function(){
		$http.post("/api/updateUserTradeSubscription", {_id : SessionService.currentUser._id, tradesubscribe : true}).then(function(response){
			if(response.data.status_code == "200"){
				var userinfo = SessionService.currentUser;
                userinfo.tradesubscribe =  response.data.data.tradesubscribe;
                SessionService.storeUser(userinfo);
				toastr.success("Successfully Done !");
				$route.reload();
			}
		});
	}

	$scope.tradeUnsubscribe = function(){
		$http.post("/api/updateUserTradeSubscription", {_id : SessionService.currentUser._id, tradesubscribe : false}).then(function(response){
			if(response.data.status_code == "200"){
				var userinfo = SessionService.currentUser;
                userinfo.tradesubscribe =  response.data.data.tradesubscribe;
                SessionService.storeUser(userinfo);
				toastr.success("Successfully Done !");
				$route.reload();
			}
		});
	}
});