angular.module("app").controller("ReportsController", function($scope,ports,$filter,$location, SessionService, $http, UserService, $timeout, moment, getweekService, NgTableParams) {
    $scope.user = SessionService.currentUser;
    $scope.sortType='';
    $scope.sortOrder=undefined;
    $scope.searchText={};
    $scope.searchText.val='';
    $("#get-modal").hide();
    var currWeekDates = getweekService.getCurrWeek();
    $scope.currWeekDate = currWeekDates;
    var $modal = $("#get-modal");
    $scope.errormessage = "Please enter your selection to get the report."
    $scope.report = [];
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $scope.getReport = function() {
        $scope.exportData = [];
        $scope.exportData.push(["Emp #", "Username", "Start Date", "End Date", "Comment"]);
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDate = new Date($scope.dateRange.end_date);
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
        if ($scope.sortType == '') {
            $scope.sortType = 'employee.last_name';
            $scope.sortOrder = 1;
        }
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.pageNum = 1;
        $scope.showloader = true;
        var enddDate = new Date($scope.dateRange.end_date);
        console.log('----------',$scope.dateRange);
        var correctEndDate = enddDate.setDate(enddDate.getDate() + 1)
        //$scope.datenewRange = {"start_date" : new Date($scope.dateRange.start_date), "end_date" : new Date(correctEndDate)};
        //$scope.datenewRange = {"start_date" : new Date($scope.dateRange.start_date), "end_date" : new Date($scope.dateRange.end_date)};
        $scope.datenewRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment($scope.dateRange.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        console.log('===========',$scope.datenewRange);
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/getreport", {
                    count: params.count(),
                    page: params.page(),
                    field: $scope.sortType,
                    search: search,
                    sortOrder:$scope.sortOrder,
                    dateRange: $scope.datenewRange,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    location:SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.report = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    if (response.data.data.length < 1) {
                        $scope.errormessage = "No Report found for this selection.";
                    }
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    angular.forEach($scope.report, function(value, key) {
                        var fullname = value.employee[0].first_name + ' ' + value.employee[0].last_name;
                        $scope.exportData.push([value.employee[0].employeeid, fullname, $filter('date')(value.startWeekDate, 'MM/dd/yyyy'), $filter('date')(value.endWeekDate, 'MM/dd/yyyy'), value.comment]);
                    });
                    /*var manArr = [];
                    if ("manager" === SessionService.currentUser.roles[0]) {
                        angular.forEach(response.data.data, function(value, key) {
                            if (value.employee_id.created_by == $scope.user._id) {
                                manArr.push(value);
                            }
                        });
                        $scope.report = manArr
                    }*/
                    return $scope.report;
                })
            }
        });
    }
    $scope.getReport();
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.getReport()
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    
    /*view report in modal*/
    $scope.closemodal = function() {
        $("#get-modal").hide();
    }
    $scope.viewReport = function(data) {
            $scope.modal_data = data;
            $("#get-modal").show();
        }
        /*end*/
});