angular.module("app").controller("TodoListController", function($scope, $timeout, $http, $filter, $location, SessionService, NgTableParams, moment){
    $scope.visibility = false;
    $scope.haveResult = true;
    $scope.sortType = '';
    $scope.sortOrder = undefined;
    $scope.searchText = {};
    $scope.searchText.val = '';
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.todolist = {};
    $scope.listitem = {};
    $scope.selectenable = false;
    $scope.checkAll = {};
    $scope.showloader = true;
    $scope.due_date = new Date(); 
    $scope.todayDate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(); // currentDate
    $scope.markValArr = [];
    $scope.selectedTodolist = {
        todolistArr: []
    };
    $scope.selectedAction = 0;
    $scope.createTodoList = function(){
        $scope.todoListForm.$setPristine();
        $scope.buttondisabled = true;
        var storeSelUserId = [];
        $scope.todolist.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        $scope.todolist.created_by = SessionService.currentUser._id;
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
               if ( value.ticked === true ) {
                   storeSelUserId.push({user_id: value._id, mark_read: false});
               }
            });
            $scope.todolist.assign_to = storeSelUserId;
        }
        if($scope.todolist._id){
            $scope.todolist.listId = $scope.todolist._id;
        }
        if($scope.todolist.due_date){
            $scope.todolist.due_date = $scope.todolist.due_date;
        }
        $scope.todolist.location = SessionService.currentUser.location;
        var tododata = $scope.todolist;
        $http.post("/api/savetodolist", tododata).then(function(response){
            if(response.data.status_code === "200"){
                if($scope.tasklistUpdateType == 'Add')
                    toastr.success("Todo list is created successfully.");
                if($scope.tasklistUpdateType == 'update')
                    toastr.success("Todo list is updated successfully.");
                angular.element('#modal-basic').modal('hide');
                $scope.tableParams.reload();
                $scope.getToDoList();
            }else{
                toastr.error("Somthing Wrong!");
            }
        });
    }
    $scope.pageNum = 1;
    $scope.getToDoList = function() {
      $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType='created_date';
            $scope.sortOrder=-1;
        }    
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/gettodolist", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    location:SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.allTodos = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allTodos;
                })
            }
        });
    }
    $scope.getToDoList();
    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.getToDoList()
              }
          }
    );
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.getToDoList()
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    $scope.goToDoList = function(toDoList){
        angular.forEach($scope.todoItemList, function(x) {
            if (x.done && !x.completion_date){
                x.done = false;;
            }
        });
        $scope.listdetails = toDoList;
        $scope.todoItemList = [];
        $scope.todoItemList = toDoList.todo_items;
    }

    $scope.todoAdd = function(listId) {
        var due_date_param = moment($scope.listitem.due_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        $scope.todoItemList.push({todoText:$scope.listitem.todoInput, due_date : due_date_param, assign_date : $scope.todayDate, done:false});
        $scope.todoitemjson = {};
        $scope.todoitemjson.todo_items = {todoText:$scope.listitem.todoInput, due_date : due_date_param, assign_date : $scope.todayDate, done:false, assign_by : SessionService.currentUser._id};
        $scope.todoitemjson.todolistId = listId;
        $http.post("/api/addItemsInList", $scope.todoitemjson).then(function(response){
            if(response.data.status_code == 200){
                toastr.success("Item is successfully added in this list.");
            }else{
                toastr.error("Something Wrong!");
            }
        });
        $scope.listitem = {};
    };
    $scope.removeCompletedConfirmation = function(listId){
        var oldList = $scope.todoItemList;
        $scope.todoItemNewList = [];
        $scope.removeSelectedIds = [];
        angular.forEach(oldList, function(x) {
            if (x.completion_date){
                $scope.removeSelectedIds.push(x);
            }else{
                $scope.todoItemNewList.push(x);
            }
        });
        if($scope.removeSelectedIds.length){
            $scope.todoitemjson = {};
            $scope.todoitemjson.todolistId = listId;
            $('#clear_completed').show();
        }else{
            toastr.error("There is no completed items to clear.");
        }
        
    }
    $scope.removeConfirmation = function(listId){
        var oldList = $scope.todoItemList;
        $scope.todoItemNewList = [];
        $scope.removeSelectedIds = [];
        angular.forEach(oldList, function(x) {
            if (!x.done || x.completion_date){
                $scope.todoItemNewList.push(x);
            }
            else{
                if(x.done && !x.completion_date){
                    $scope.removeSelectedIds.push(x._id);
                }
            }
        });
        if($scope.removeSelectedIds.length){
            $scope.todoitemjson = {};
            $scope.todoitemjson.todolistId = listId;
            $('#clear_marked').show();
        }else{
            toastr.error("Please mark items to clear.");
        }
    }
    $scope.remove = function() {
        var oldList = $scope.todoItemList;
        $scope.todoItemNewList = [];
        $scope.removeSelectedIds = [];
        angular.forEach(oldList, function(x) {
            if (!x.done || x.completion_date){
                $scope.todoItemNewList.push(x);
            }
            else{
                if(x.done && !x.completion_date){
                    $scope.removeSelectedIds.push(x._id);
                }
            }
        });
        $scope.todoitemjson.todoSelectedIds = $scope.removeSelectedIds;
        $http.post("/api/removeItemsInList", $scope.todoitemjson).then(function(response){
            if(response.data.status_code === "200"){
                $('#clear_marked').hide();
                $scope.todoItemList = $scope.todoItemNewList;
                toastr.success("Marked items are cleared successfully.");
                $scope.tableParams.reload();
                $scope.getToDoList();
            }else{
                toastr.error("Something Wrong!");
            }
        });
    };
    $scope.removeAllConfirmation = function(listId){
        $scope.todoitemjson = {};
        $scope.todoitemjson.todolistId = listId;
        $scope.removeMessage = "Are you sure you want to clear whole list ?";
        $('#clear_all').show();
    };

    $scope.removeAll = function() {
        $http.post("/api/removeAllItemsInList", $scope.todoitemjson).then(function(response){
            if(response.data.status_code === "200"){
                $scope.todoItemList = [];
                toastr.success("List is cleared successfully.");
                $('#clear_all').hide();
                $scope.tableParams.reload();
                $scope.getToDoList();
            }else{
                toastr.error("Something Wrong!");
            }
        });
    };

    $scope.removeCompleted = function(){
        var oldList = $scope.todoItemList;
        $scope.todoItemNewList = [];
        $scope.removeSelectedIds = [];
        angular.forEach(oldList, function(x) {
            if (x.completion_date){
                $scope.removeSelectedIds.push(x);
            }else{
                $scope.todoItemNewList.push(x);
            }
        });
        $scope.todoitemjson.todoSelectedIds = $scope.removeSelectedIds;
        $http.post("/api/removeItemsInList", $scope.todoitemjson).then(function(response){
            if(response.data.status_code === "200"){
                $('#clear_completed').hide();
                $scope.todoItemList = $scope.todoItemNewList;
                toastr.success("Completed items are cleared successfully.");
                $scope.tableParams.reload();
                $scope.getToDoList();
            }else{
                toastr.error("Something Wrong!");
            }
        });
    };

    $scope.clearToDoList = function(){
        $scope.tasklistUpdateType = 'Add';
        $scope.todolist = {};
        $scope.buttondisabled = false;
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                   if ( value.ticked === true ) {
                       value.ticked = false;
                   }
            });
        }
        $scope.todoItemList = [];
    };

    $scope.strikethrough = function(completiondate){
        if(completiondate){
            return 'striketext';
        }else{
            return '';
        }
    }

    $scope.renamelist = function(listInfo) {
        $scope.tasklistUpdateType = 'update';
        $scope.renameEnable = true;
        $scope.todolist = {};
        $scope.todolist.listname = listInfo.listname;
        $scope.todolist.due_date = $filter('date')(listInfo.due_date, 'MM/dd/yyyy');
        $scope.todolist.todo_items = listInfo.todo_items;
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                   if (( listInfo.assign_to.length) && (value._id ===  listInfo.assign_to[0].user_id)) {
                        value.ticked = true;
                   }else{
                        value.ticked = false;
                   }
            });
        }
        $scope.todolist._id = listInfo._id;
    };

    $scope.completeToDo = function(task, listId, listdetails){
        $scope.completelistjson = {};
        $scope.completelistjson.todolistId = listId;
        $scope.completelistjson.completion_date = $scope.todayDate;
        $scope.completelistjson.todo_item = task;
        $scope.completelistjson.completeBy = SessionService.currentUser.first_name+' '+SessionService.currentUser.last_name;
        $http.post("/api/completeTaskInList", $scope.completelistjson).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Updated successfully.");
                $scope.getToDoList();
                $scope.listdetails = response.data.data;
                $scope.todoItemList = [];
                $scope.todoItemList = response.data.data.todo_items;
            }else{
                toastr.error("Somthing Wrong!");
            }
        });
    }

    if(SessionService.currentUser.roles[0] !== "employee"){
        $http.post("/api/getAllUsers", {"location": SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.modernWebBrowsers = response.data.data;
            }
            else {
                console.log('400');
            }
        });
    }

    $scope.checkAll.val= false;
    $scope.selectAllGroup = function(check) {
            $scope.flag = {};
            $scope.flag.enable = false;
            if (check) {
                $scope.selectedTodolist.todolistArr = $scope.allTodos.map(function(item) {
                    return item._id;
                });
                $scope.selectenable = true;
                console.log("$scope.selectenabletrue", $scope.selectenable);
            } else {
                $scope.selectedTodolist.todolistArr = [];
                $scope.checkAll.val= false;
                $scope.selectenable = false;
            }
    }
    $scope.deletelist = function(data){
        $scope.selectedTodolist.todolistArr = [];
        $scope.selectedTodolist.todolistArr.push(data._id);
        $scope.del_todoids=$scope.selectedTodolist.todolistArr;
        $('#confirm_del').show();
    }
    $scope.delete_todolists=function(){
        $http.post("/api/deletetoDoList", {
            "todoid": $scope.selectedTodolist.todolistArr
        }).then(function(response) {
            $scope.allTodos = response.data.data;
            angular.element('#selectedAction').val(0);
            $scope.getToDoList();
            toastr.success("Successfully Deleted.");
            $scope.selectedTodolist.todolistArr = [];
            $scope.checkAll.val = false;
            $('#confirm_del').hide();
        })
    }
    $scope.cnfrm_pwd = function(todolist){
        $('#confirm_del').show();
    }
    $scope.performAction = function() {
        $scope.selectedAction = selectedAction.value;
        if ($scope.selectedAction == 0) {
            toastr.error("Please select some selection.");
        }else if ($scope.selectedTodolist.todolistArr.length == 0) {
            toastr.error("Please select atleast one checkbox.");
        }
        if ($scope.selectedAction == 1 && $scope.selectedTodolist.todolistArr.length > 0) {
            $scope.cnfrm_pwd($scope.selectedTodolist.todolistArr);
        }
    }
});