angular.module("app").controller("availreportController", function($rootScope, $scope, $filter, ports, $location, SessionService, $http, $timeout, getweekService, $window, $route, moment) {
    $scope.weekdays  = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $scope.timeslots = ["morning", "afternoon", "night", "latenight"];
    $scope.buffertimeslots = ["Morning","Afternoon","Night","Late Night"];
    $scope.user = SessionService.currentUser;
    $scope.currpage = $location.absUrl().split('/')[3];
    $scope.scheduleIds = [];
    $scope.start_end = '';
    $scope.shift = 'Morning';
    $scope.searchText = {};
    $scope.haveResult = false;
    $scope.searchOccur = false;
    $scope.showloader = true;
    $scope.searchText.val = '';
    var currWeekDates = getweekService.getCurrWeek();
    $scope.weekdate = $filter('date')(currWeekDates[0], 'MM/dd/yyyy') + ' To '+ $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
    $scope.dateRange = {};
    $scope.dateRange.start_date = $filter('date')(currWeekDates[0], 'MM/dd/yyyy');
    $scope.dateRange.end_date = $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
    $scope.$watch(
        "weekdate",
        function handleWeekChange() {
            $scope.employeeMarkAvail = [];
            $scope.eventAccountList = [];
            $scope.emplist = [];
            $scope.days = [];
            $scope.$on('startendString', function (event, args) {
                $scope.start_end = args.startendstringJson;
            });
            if ($scope.start_end) {
                 var startEndArr = $scope.start_end.split(' / ');
                 $scope.startDate = new Date(startEndArr[0]);
                 $scope.endDate = new Date(startEndArr[1]);
                 $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
            }
            else{
                $scope.startDate = currWeekDates[0];
                $scope.endDate = currWeekDates[1];
                $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
            }

            if($scope.dateRange == null){
                var StartDate = currWeekDates[0];
                var EndDate = currWeekDates[1];
            }else{
                var StartDate = $scope.dateRange.start_date;
                var EndDate = $scope.dateRange.end_date;
            }
            if (StartDate && EndDate) {
                 var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;
                 var diff = 6; //To Resolve day night saving time issue because in this change it return somewhere less than 6 and can be more than 6.
            }

            var sevenWeekDayArr = [];
            for(var i=0; i<= diff; i++) {
                sevenWeekDayArr[i] = new Date(StartDate.getTime() + i*86400000);
                sevenWeekDayArr[i].dayName = $scope.weekdays[sevenWeekDayArr[i].getDay()];
                sevenWeekDayArr[i].differ = Math.floor((new Date(StartDate.getTime() + i*86400000) - new Date().getTime())/86400000);
            }
            $scope.sevenWeekDayArr = sevenWeekDayArr;
            $scope.fetchTimeSlotBuffer();
            $scope.totalEmployees();
            $scope.availShifts();
            $scope.getExtraShifts();
            $scope.noEmployees();
            $scope.yesMaybeEmployees();
            $scope.yesMaybeScrewedEmployees();
        }
    );

    $scope.totalEmployees = function(){
        $scope.showloader = true;
        $http.post('/api/getEmployeeCount', {
            owner_id : SessionService.currentUser.created_by,
            location : SessionService.currentUser.location
        }).then(function(response) {
            $scope.showloader = false;
            $scope.no_of_employees = response.data.data;
        }); 
    }

    $scope.availShifts = function(){
        $scope.showloader = true;
        console.log($scope.sevenWeekDayArr);
        $http.post('/api/getshiftcount', {
            "data" : $scope.sevenWeekDayArr,
            "location" : SessionService.currentUser.location
        }).then(function(response) {
            $scope.showloader = false;
            for (var i = 0; i < $scope.weekdays.length; i++) {
                if (!response.data.data[$scope.weekdays[i]]) {
                    response.data.data[$scope.weekdays[i]] = {
                        'morning': 0,
                        'afternoon': 0,
                        'night': 0,
                        'latenight': 0
                    };
                }
            }
            $scope.shiftGrid = response.data.data;
        });
    }

    $scope.getExtraShifts = function(){
        $scope.showloader = true;
        $http.post('/api/getextrashiftcount', {
            "data": $scope.sevenWeekDayArr,
            "location" : SessionService.currentUser.location
        }).then(function(response) {
            $scope.showloader = false;
            for (var i = 0; i < $scope.weekdays.length; i++) {
                if (!response.data.data[$scope.weekdays[i]]) {
                    response.data.data[$scope.weekdays[i]] = {
                        'morning': 0,
                        'afternoon': 0,
                        'night': 0,
                        'latenight': 0
                    };
                }
            }
            $scope.extrashiftGrid = response.data.data;
        })
    }

    $scope.noEmployees = function(){
        $scope.showloader = true;
        $http.post('/api/getnocount', {
            "data": $scope.sevenWeekDayArr,
            "location" : SessionService.currentUser.location
        }).then(function(response) {
            $scope.showloader = false;
            nodata = [];
            for (var i = 0; i < $scope.sevenWeekDayArr.length; i++) {
                var nestedObj = {};
                for (var j = 0; j < $scope.timeslots.length; j++) {
                    angular.forEach(response.data.data, function(item) {                        
                        if (new Date(item.date).getTime() == new Date($scope.sevenWeekDayArr[i]).getTime()) {
                            if ($scope.timeslots[j] == 'morning') {
                                var noVal = item.shifts.morning;
                            }
                            if ($scope.timeslots[j] == 'afternoon') {
                                var noVal = item.shifts.afternoon;
                            }
                            if ($scope.timeslots[j] == 'night') {
                                var noVal = item.shifts.night;
                            }
                            if ($scope.timeslots[j] == 'latenight') {
                                var noVal = item.shifts.late_night;
                            }
                            nestedObj[$scope.timeslots[j]] = noVal;
                        }
                    });
                    if (!nestedObj[$scope.timeslots[j]]) {
                        nestedObj[$scope.timeslots[j]] = 0;
                    }

                }
                nodata[$scope.sevenWeekDayArr[i]] = nestedObj;
            }
            $scope.nodata = nodata;
        });
    }

    $scope.yesMaybeEmployees = function(){
        $scope.showloader = true;
        $scope.employeeMarkAvail = [];
        $http.post('/api/getYesMaybeCount', {
            "to": $scope.endDate,
            "from": $scope.startDate,
            "owner_id": SessionService.currentUser._id,
            "role": SessionService.currentUser.roles[0],
            "location":SessionService.currentUser.location
        }).then(function(response) {
            $scope.showloader = false;
            yesmaybedata = [];
            for (var i = 0; i < $scope.sevenWeekDayArr.length; i++) {
                var nestedObj = {};
                for (var j = 0; j < $scope.timeslots.length; j++) {
                    angular.forEach(response.data.data, function(item) {                        
                        if (new Date(item.date).getTime() == new Date($scope.sevenWeekDayArr[i]).getTime()) {
                            if ($scope.timeslots[j] == 'morning') {
                                var noVal = item.shifts.morning;
                            }
                            if ($scope.timeslots[j] == 'afternoon') {
                                var noVal = item.shifts.afternoon;
                            }
                            if ($scope.timeslots[j] == 'night') {
                                var noVal = item.shifts.night;
                            }
                            if ($scope.timeslots[j] == 'latenight') {
                                var noVal = item.shifts.late_night;
                            }
                            nestedObj[$scope.timeslots[j]] = noVal;
                        }
                    });
                    if (!nestedObj[$scope.timeslots[j]]) {
                        nestedObj[$scope.timeslots[j]] = 0;
                    }

                }
                yesmaybedata[$scope.sevenWeekDayArr[i]] = nestedObj;
            }
            $scope.yesmaybedata = yesmaybedata;
        });
    }

    $scope.yesMaybeScrewedEmployees = function(){
        $scope.showloader = true;
        $scope.employeeMarkAvail = [];
        $http.post('/api/getYesMaybeScrewedCount', {
            "to": $scope.endDate,
            "from": $scope.startDate,
            "owner_id": SessionService.currentUser._id,
            "role": SessionService.currentUser.roles[0],
            "location":SessionService.currentUser.location
        }).then(function(response) {
            $scope.showloader = false;
            yesmaybescreweddata = [];
            for (var i = 0; i < $scope.sevenWeekDayArr.length; i++) {
                var nestedObj = {};
                for (var j = 0; j < $scope.timeslots.length; j++) {
                    angular.forEach(response.data.data, function(item) {                        
                        if (new Date(item.date).getTime() == new Date($scope.sevenWeekDayArr[i]).getTime()) {
                            if ($scope.timeslots[j] == 'morning') {
                                var noVal = item.shifts.morning;
                            }
                            if ($scope.timeslots[j] == 'afternoon') {
                                var noVal = item.shifts.afternoon;
                            }
                            if ($scope.timeslots[j] == 'night') {
                                var noVal = item.shifts.night;
                            }
                            if ($scope.timeslots[j] == 'latenight') {
                                var noVal = item.shifts.late_night;
                            }
                            nestedObj[$scope.timeslots[j]] = noVal;
                        }
                    });
                    if (!nestedObj[$scope.timeslots[j]]) {
                        nestedObj[$scope.timeslots[j]] = 0;
                    }

                }
                yesmaybescreweddata[$scope.sevenWeekDayArr[i]] = nestedObj;
            }
            $scope.yesmaybescreweddata = yesmaybescreweddata;
        });
    }

    $scope.fetchTimeSlotBuffer = function(){
        $scope.showloader = true;
        $http.post('/api/timeslotbuffer', {locationId : SessionService.currentUser.location}).success(function(fetchresponse){
            if(fetchresponse.data[0]){
                var response = fetchresponse.data[0].buffervalues;
            }else{
                var response = [];
            }
            $scope.showloader = false;
            griddata = [];
            for (var i = 0; i < $scope.weekdays.length; i++) {
                var nestedObj = {};
                for (var j = 0; j < $scope.buffertimeslots.length; j++) {
                    angular.forEach(response, function(item) {
                        if (item.day == $scope.weekdays[i] && item.timeslot == $scope.buffertimeslots[j]) {
                            nestedObj[$scope.timeslots[j]] = item.buffer;
                        }
                    });
                    if(!nestedObj[$scope.timeslots[j]]) {
                        nestedObj[$scope.timeslots[j]] = 0;
                    }
                }
                griddata[$scope.weekdays[i]] = nestedObj;
            }
            $scope.gridData = griddata;
        });
    }
});
angular.module("app").filter('numberFixedLen', function () {
       return function (n, len) {
           var num = parseInt(n, 10);
           len = parseInt(len, 10);
           if (isNaN(num) || isNaN(len)) {
               return n;
           }
           num = '' + num;
           while (num.length < len) {
               num = '0' + num;
           }
           return num;
       };
});
