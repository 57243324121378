angular.module("app").controller("TotalHoursReportController", function($scope,ports,$filter,$location, SessionService, $http, UserService, $timeout, getweekService,NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.searchText = {};
    $scope.searchText.val = '';
    var currWeekDates = getweekService.getCurrWeek();
    $scope.currWeekDate = currWeekDates;
    var $modal = $("#get-modal");
    $scope.errormessage = "Please enter your selection to get the report.";
    $scope.report = [];
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }
    $http.get("/api/getAllInitalzips").then(function(response) {
        if(response.status == 200){
            $scope.AllInitials = response.data.data;
        }
    });
    $scope.matchZipInInitial = function(zipvalue){
        var zipVal = '';
        if(zipvalue){
            for(i = 0; i < $scope.AllInitials.length; i++){
                if($scope.AllInitials[i].zipcodes.indexOf(zipvalue) >= 0){
                    zipVal = $scope.AllInitials[i].initial;
                    break;
                }
            }
        }
        return zipVal;
    }
    $scope.getReport = function() {
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDate = new Date($scope.dateRange.end_date);
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
        $scope.storeSelUserId = '';
        var totalhoursJson = {};
        var enddDate = new Date($scope.dateRange.end_date);
        var correctEndDate = enddDate.setDate(enddDate.getDate() + 1)
        $scope.datenewRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment(correctEndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        totalhoursJson.dateRange = $scope.datenewRange;
        totalhoursJson.owner_id = SessionService.currentUser._id;
        totalhoursJson.role = SessionService.currentUser.roles[0];
        totalhoursJson.location = SessionService.currentUser.location;
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if (value.ticked == true) {
                    $scope.storeSelUserId = value;
                }
            });
        }
        if($scope.storeSelUserId._id){
            totalhoursJson.empId = $scope.storeSelUserId._id;
        }
        $http.post("/api/getTotalHoursReport",totalhoursJson).then(function(response){
            if (!response.data.data.length) {
                $scope.haveResult = false;
            }else{
                $scope.haveResult = true;
                response.data.data.forEach(function(elem, key){
                    elem.totalTimeDiffSum = $scope.timeToDecimal(elem.totalTimeDiffSum);
                })
                $scope.reportData = response.data.data;
            }
        });
    }
    $scope.getReport();
  
});