angular.module("app").controller("AdminProfileController", function($scope, states, patterns, ports, $location, SessionService, $http, UserService, $timeout, fileUpload, $filter) {
    $scope.user = SessionService.currentUser;
    if ($scope.user.phone !== undefined) {
        $scope.user.phone = $filter('tel')($scope.user.phone, false);
    }
   /* $scope.siteurl = $location.absUrl().split(ports.frontEndPort + '/')[0];
    $scope.backendsiteurl = $scope.siteurl + ports.backEndPort;*/
    $scope.states = states.statelist;
    $scope.addressPattern = patterns.address;
    $scope.addressPatternError = patterns.addressPatternError;
    $scope.del_profile_pic = false;
    $scope.update = function(user) {
        if ($scope.del_profile_pic) {
            user.del_profile_pic = true;
        }
        if ($scope.myFile) {
            var file = $scope.myFile;
            var chkData = "";
            var uploadUrl = "/api/fileUpload";
            $scope.showloader = true;
            fileUpload.uploadFileToUrl(file, uploadUrl, function(retData) {
                if (true === retData.success) {
                    $scope.showloader = false;
                    user.prof_image = retData.save_image_name;
                    console.log('.......',user);
                   /* UserService.updateUser(user).then(function(a,c,g){
                        $scope.$emit('fullName','updatefullname');
                        $scope.$broadcast('fullName','updatefullname');
                        toastr.success("Information updated successfully.");
                    });*/
                } else{
                    $scope.showloader = false;
                    toastr.error(retData.msg);
                }
            });
        } else {
            UserService.updateUser(user).then(function(a,c,g){
                $scope.$emit('fullName','updatefullname');
                $scope.$broadcast('fullName','updatefullname');
                toastr.success("Information updated successfully.");
                $scope.del_profile_pic = false;
            });
        }
    };
    $scope.deleteusrimg = function(emp) {
        $('#del_img').show();
    }
    $scope.imgDelete = function(emp) {
        $scope.del_profile_pic = true;
        $('#del_img').hide();
    }

});