angular.module("app").controller("ManagerProfileController", function($scope, states, patterns, ports, $location, SessionService, $http, UserService, $timeout, fileUpload, $filter) {
    $scope.user = SessionService.currentUser;
    if ($scope.user.phone !== undefined) {
        $scope.user.phone = $filter('tel')($scope.user.phone, false);
    }
    $scope.states = states.statelist;
    $scope.addressPattern = patterns.address;
    $scope.changeAddress = false;
    $scope.addressPatternError = patterns.addressPatternError;
    $scope.del_profile_pic = false;
    $scope.user.changeAddress = false;
    $scope.user.locationID = '';
    $scope.update = function(user) {
        if ($scope.del_profile_pic) {
            user.del_profile_pic = true;
        }
        if ($scope.myFile) {
            var file = $scope.myFile;
            var chkData = "";
            var uploadUrl = "/api/fileUpload";
            $scope.showloader = true;
            fileUpload.uploadFileToUrl(file, uploadUrl, function(retData) {
                if (true === retData.success) {
                    user.prof_image = retData.save_image_name;
                    if($scope.changeAddress){
                        user.address.line1 = user.address.line1.formatted_address;
                        user.changeAddress = true;
                        user.locationID = SessionService.currentUser.location;
                    }else{
                        user.address.line1 = user.address.line1;
                    }
                    UserService.updateUser(user).then(function(a,c,g){
                        $scope.$emit('fullName','updatefullname');
                        $scope.$broadcast('fullName','updatefullname');
                        toastr.success("Information updated successfully.");
                    });
                } else if(retData.code==400){
                    $scope.showloader = false;
                    toastr.error(retData.msg)
                }
            });
        } else {
            if($scope.changeAddress){
                user.address.line1 = user.address.line1.formatted_address;
                user.changeAddress = true;
                user.locationID = SessionService.currentUser.location;
            }else{
                user.address.line1 = user.address.line1;
            }
            UserService.updateUser(user).then(function(a,c,g){
                $scope.$emit('fullName','updatefullname');
                $scope.$broadcast('fullName','updatefullname');
                toastr.success("Information updated successfully.");
                $scope.del_profile_pic = false;
            });
        }
    };
   
    $scope.deleteusrimg = function(emp) {
        $('#del_img').show();
    }
    $scope.imgDelete = function(emp) {
        $scope.del_profile_pic = true;
        $('#del_img').hide();
    }
    $scope.changeAdd = function(){
        $scope.changeAddress = true;
        var details = $scope.user.address.line1;
        if(_.isObject(details)){
            for (var i = 0; i < details.address_components.length; i++) {
              var addressType = details.address_components[i].types[0];
              var val = details.address_components[i].long_name;
              if (addressType == 'country') {
                $scope.user.country = val;
              }
              if (addressType == 'administrative_area_level_1' || addressType == 'locality' || addressType == 'postal_code' || addressType == 'street_number' || addressType == 'route') {
                if (addressType == 'administrative_area_level_1') {
                  $scope.user.address.state = val;
                }
                if (addressType == 'locality') {
                  $scope.user.address.city = val;
                }

                if (addressType == 'postal_code') {
                  $scope.user.address.zip = val;
                }
              }
            }
        }
    }
});