angular.module("app").controller("profitLossController", function($scope,ports,$filter,$location,$q,SessionService, $http, getweekService, $timeout, NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.sortType='';
    $scope.haveResult = false;
    $scope.sortOrder=undefined;
    $scope.searchText={};
    $scope.searchText.val='';
    $("#get-modal").hide();
    var currWeekDates = getweekService.getCurrWeek();
    $scope.currWeekDate = currWeekDates;
    var $modal = $("#get-modal");
    $scope.errormessage = "Please enter your selection to get the report."
    $scope.report = [];
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
        if ( "200" === response.data.status_code ) {
            $scope.allEvents = response.data.events;
            angular.forEach( $scope.allEvents, function( value, key ) {
              value.buttonlabel = value.name;
            });
        } 
    });
    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }
    $scope.getReport = function() {
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDate = new Date($scope.dateRange.end_date);
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
        $scope.storeSelEventAccountId = '';
        var profitLossJson = {};
        var cashdepositjson = {};
        var enddDate = new Date($scope.dateRange.end_date);
        var correctEndDate = enddDate.setDate(enddDate.getDate() + 1)
       // $scope.datenewRange = {"start_date" : new Date($scope.dateRange.start_date), "end_date" : new Date(correctEndDate)};
        $scope.datenewRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment(correctEndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        profitLossJson.dateRange = $scope.datenewRange;
        profitLossJson.owner_id = SessionService.currentUser._id;
        profitLossJson.role = SessionService.currentUser.roles[0];
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                   }
            });
        }
        profitLossJson.event_account_id = $scope.storeSelEventAccountId._id;
        cashdepositjson = angular.copy(profitLossJson);
        //cashdepositjson.dateRange = {"start_date" : new Date($scope.dateRange.start_date), "end_date" : new Date($scope.dateRange.end_date)};
        cashdepositjson.dateRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment(correctEndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        if($scope.storeSelEventAccountId._id){
            var i = 1;
            $scope.tpayroll = 0;
            $scope.trevenue = 0;
            $scope.tprofitloss = 0;
            $scope.tcPayroll = 0;
            $scope.tcRevenue = 0;
            $scope.tcProfitloss = 0;
            var promiseOne = $http.post("/api/getProfitLossReport", profitLossJson);
            var promiseTwo = $http.post("/api/getCashDepositControl", cashdepositjson);
            $q.all([promiseOne, promiseTwo]).then(function(data){
                var getProfitLossReportData = data[0].data.data;
                    var cashDepositData = data[1].data.data;
                if (!getProfitLossReportData.length) {
                    $scope.haveResult = false;
                }else{
                    getProfitLossReportData.forEach(function(elem, key){
                        $scope.revenueType = elem._id.revenueType;
                        $scope.revenueRate = elem._id.revenueRate;
                        if($scope.revenueType == "per_car"){
                            var x = 0;
                            elem.revenue = 0;
                            $scope.revenueRate = elem._id.payrate;
                            cashDepositData.forEach(function(el,ke){
                                if((elem._id.year === el._id.year) && (elem._id.month === el._id.month) && (elem._id.day === el._id.day)){
                                     elem.revenue = el.revenue;
                                     $scope.revenueRate = el._id.revenueRate;
                                     return;
                                }
                                if(x === cashDepositData.length-1){
                                    elem.revenue = 0;
                                    return;
                                }
                                x++;
                            })
                        }else if($scope.revenueType == "rate_per_hour"){
                            elem.revenue = ($scope.timeToDecimal(elem.totalTimeDiff) * $scope.revenueRate).toFixed(2);
                        }else if($scope.revenueType == "flat_rate"){
                            elem.revenue = elem._id.revenueFixed;
                        }
                        elem.grossPayroll = (elem.payroll*1.15).toFixed(2);
                        elem.inputDate = elem._id.created_date;
                        elem.profit_loss = (elem.revenue - elem.grossPayroll).toFixed(2);
                        $scope.tpayroll = (parseFloat($scope.tpayroll) + parseFloat(elem.grossPayroll)).toFixed(2);
                        $scope.tprofitloss = parseFloat($scope.tprofitloss) + parseFloat(elem.profit_loss);
                        if($scope.revenueType !== "flat_rate"){
                            $scope.trevenue = (parseFloat($scope.trevenue) + parseFloat(elem.revenue)).toFixed(2);
                        }else{
                            $scope.trevenue = elem.revenue;
                            elem.revenue = 0;
                            elem.profit_loss = 0;
                        }
                        if(i === getProfitLossReportData.length){
                            $scope.tcPayroll = $scope.tpayroll;
                            $scope.tcRevenue = $scope.trevenue;
                            $scope.tcProfitloss = ($scope.tprofitloss).toFixed(2);
                        }
                        i++;
                    });
                    $scope.haveResult = true;
                    $scope.profitLossReport = getProfitLossReportData;
                }
            });
        }else{
            toastr.error('Please select Event/Account from list');
            $scope.haveResult = false;
        }
    }
   
    $scope.showPayroll = function(inputDate){
        var profitLossJson = {};
        profitLossJson.selecteddate = inputDate;
        profitLossJson.owner_id = SessionService.currentUser._id;
        profitLossJson.role = SessionService.currentUser.roles[0];
        profitLossJson.event_account_id = $scope.storeSelEventAccountId._id;
        $http.post("/api/getGrossPayrollDetails",profitLossJson).then(function(response){
            if (!response.data.data.length) {
                $scope.haveResult = false;
            }else{
                $scope.payrollInfo = response.data.data;
            }
        });
    }
    
    /*view report in modal*/
    $scope.closemodal = function() {
        $("#get-modal").hide();
    }
    $scope.viewReport = function(data) {
        $scope.modal_data = data;
        $("#get-modal").show();
    }
        /*end*/
});