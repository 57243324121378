angular.module("app").controller("EventShiftController", function($scope, $filter, SessionService, $http) {
    $scope.user = SessionService.currentUser;
    $scope.gridDataST = {};
    $scope.gridDataET = {};
    $scope.weekdays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    $scope.timeslots = ["Morning","Afternoon","Night","Late Night"];
    $scope.shiftData = [];
    $scope.shiftsArray = [];
    $scope.timeslotsvalue = [
        {name: "01:00 AM", value: "01:00 AM"},
        {name: "01:15 AM", value: "01:15 AM"},
        {name: "01:30 AM", value: "01:30 AM"},
        {name: "01:45 AM", value: "01:45 AM"},
        {name: "02:00 AM", value: "02:00 AM"},
        {name: "02:15 AM", value: "02:15 AM"},
        {name: "02:30 AM", value: "02:30 AM"},
        {name: "02:45 AM", value: "02:45 AM"},
        {name: "03:00 AM", value: "03:00 AM"},
        {name: "03:15 AM", value: "03:15 AM"},
        {name: "03:30 AM", value: "03:30 AM"},
        {name: "03:45 AM", value: "03:45 AM"},
        {name: "04:00 AM", value: "04:00 AM"},
        {name: "04:15 AM", value: "04:15 AM"},
        {name: "04:30 AM", value: "04:30 AM"},
        {name: "04:45 AM", value: "04:45 AM"},
        {name: "05:00 AM", value: "05:00 AM"},
        {name: "05:15 AM", value: "05:15 AM"},
        {name: "05:30 AM", value: "05:30 AM"},
        {name: "05:45 AM", value: "05:45 AM"},
        {name: "06:00 AM", value: "06:00 AM"},
        {name: "06:15 AM", value: "06:15 AM"},
        {name: "06:30 AM", value: "06:30 AM"},
        {name: "06:45 AM", value: "06:45 AM"},
        {name: "07:00 AM", value: "07:00 AM"},
        {name: "07:15 AM", value: "07:15 AM"},
        {name: "07:30 AM", value: "07:30 AM"},
        {name: "07:45 AM", value: "07:45 AM"},
        {name: "08:00 AM", value: "08:00 AM"},
        {name: "08:15 AM", value: "08:15 AM"},
        {name: "08:30 AM", value: "08:30 AM"},
        {name: "08:45 AM", value: "08:45 AM"},
        {name: "09:00 AM", value: "09:00 AM"},
        {name: "09:15 AM", value: "09:15 AM"},
        {name: "09:30 AM", value: "09:30 AM"},
        {name: "09:45 AM", value: "09:45 AM"},
        {name: "10:00 AM", value: "10:00 AM"},
        {name: "10:15 AM", value: "10:15 AM"},
        {name: "10:30 AM", value: "10:30 AM"},
        {name: "10:45 AM", value: "10:45 AM"},
        {name: "11:00 AM", value: "11:00 AM"},
        {name: "11:15 AM", value: "11:15 AM"},
        {name: "11:30 AM", value: "11:30 AM"},
        {name: "11:45 AM", value: "11:45 AM"},
        {name: "12:00 PM", value: "12:00 PM"},
        {name: "12:15 PM", value: "12:15 PM"},
        {name: "12:30 PM", value: "12:30 PM"},
        {name: "12:45 PM", value: "12:45 PM"},
        {name: "01:00 PM", value: "01:00 PM"},
        {name: "01:15 PM", value: "01:15 PM"},
        {name: "01:30 PM", value: "01:30 PM"},
        {name: "01:45 PM", value: "01:45 PM"},
        {name: "02:00 PM", value: "02:00 PM"},
        {name: "02:15 PM", value: "02:15 PM"},
        {name: "02:30 PM", value: "02:30 PM"},
        {name: "02:45 PM", value: "02:45 PM"},
        {name: "03:00 PM", value: "03:00 PM"},
        {name: "03:15 PM", value: "03:15 PM"},
        {name: "03:30 PM", value: "03:30 PM"},
        {name: "03:45 PM", value: "03:45 PM"},
        {name: "04:00 PM", value: "04:00 PM"},
        {name: "04:15 PM", value: "04:15 PM"},
        {name: "04:30 PM", value: "04:30 PM"},
        {name: "04:45 PM", value: "04:45 PM"},
        {name: "05:00 PM", value: "05:00 PM"},
        {name: "05:15 PM", value: "05:15 PM"},
        {name: "05:30 PM", value: "05:30 PM"},
        {name: "05:45 PM", value: "05:45 PM"},
        {name: "06:00 PM", value: "06:00 PM"},
        {name: "06:15 PM", value: "06:15 PM"},
        {name: "06:30 PM", value: "06:30 PM"},
        {name: "06:45 PM", value: "06:45 PM"},
        {name: "07:00 PM", value: "07:00 PM"},
        {name: "07:15 PM", value: "07:15 PM"},
        {name: "07:30 PM", value: "07:30 PM"},
        {name: "07:45 PM", value: "07:45 PM"},
        {name: "08:00 PM", value: "08:00 PM"},
        {name: "08:15 PM", value: "08:15 PM"},
        {name: "08:30 PM", value: "08:30 PM"},
        {name: "08:45 PM", value: "08:45 PM"},
        {name: "09:00 PM", value: "09:00 PM"},
        {name: "09:15 PM", value: "09:15 PM"},
        {name: "09:30 PM", value: "09:30 PM"},
        {name: "09:45 PM", value: "09:45 PM"},
        {name: "10:00 PM", value: "10:00 PM"},
        {name: "10:15 PM", value: "10:15 PM"},
        {name: "10:30 PM", value: "10:30 PM"},
        {name: "10:45 PM", value: "10:45 PM"},
        {name: "11:00 PM", value: "11:00 PM"},
        {name: "11:15 PM", value: "11:15 PM"},
        {name: "11:30 PM", value: "11:30 PM"},
        {name: "11:45 PM", value: "11:45 PM"},
        {name: "00:00 AM", value: "00:00 AM"},
        {name: "12:15 AM", value: "12:15 AM"},
        {name: "12:30 AM", value: "12:30 AM"},
        {name: "12:45 AM", value: "12:45 AM"}
    ];
    
    $scope.AddShift = function(week, tmsl, startshift, endshift){
        var nestedObj = {};
        if (undefined !== startshift && undefined !== endshift) {
            var diff = moment(endshift,"HH:mm A" ).diff(moment(startshift,"HH:mm A"), 'minutes')
            if(diff <= 0){
                toastr.error("Shift end time should be greater than start time.");
                return false;
            }
        }
        if (undefined !== startshift) {
            if($scope.shiftData.length){
                var filtereddata = $filter('filter')($scope.shiftData, {day:week, timeslot :tmsl}, true)[0];
                if (filtereddata) {
                    _.each($scope.shiftData, function(data, idx) { 
                            if (_.isEqual(data, filtereddata)) {
                               mainArrIndex = idx;
                            }
                    });
                    var found = $filter('filter')(filtereddata.shifts, {'startShiftTime' : startshift, 'endshiftTime' : endshift }, true);
                    
                    if (found.length) {
                        toastr.error("This shift is already exists in your selection.");
                        return false;
                    }
                }
                
            }
            
            
            if ($scope.shiftData.length) {
                for (var j = 0; j < $scope.shiftData.length; j++) {
                    if ($scope.shiftData[j].day == week && $scope.shiftData[j].timeslot == tmsl) {
                        $scope.shiftsArray = $scope.shiftData[j].shifts;
                        $scope.shiftData.splice(j,1);
                        $scope.shiftsArray.push({'startShiftTime' : startshift, 'endshiftTime' : endshift });
                        nestedObj = {'day' : week, 'timeslot' :tmsl, 'shifts' : $scope.shiftsArray};
                        $scope.shiftData.push(nestedObj);
                        break;
                    }
                    
                    if (j == $scope.shiftData.length-1) {
                        $scope.shiftsArray = [];
                        $scope.shiftsArray.push({'startShiftTime' : startshift, 'endshiftTime' : endshift });
                        nestedObj = {'day' : week, 'timeslot' :tmsl, 'shifts' : $scope.shiftsArray};
                        $scope.shiftData.push(nestedObj);
                        break;
                    }
                }
            }else{
                $scope.shiftsArray = [];
                $scope.shiftsArray.push({'startShiftTime' : startshift, 'endshiftTime' : endshift });
                nestedObj = {'day' : week, 'timeslot' :tmsl, 'shifts' : $scope.shiftsArray};
                $scope.shiftData.push(nestedObj);
            }
        }else{
            toastr.error("Please Select time.");
        }
    }
    
    $scope.RemoveShift = function(week, tmsl, shift){
        var filtereddata = $filter('filter')($scope.shiftData, {day:week, timeslot :tmsl})[0];
        _.each($scope.shiftData, function(data, idx) { 
                if (_.isEqual(data, filtereddata)) {
                   mainArrIndex = idx;
                }
        });    
        var found = $filter('filter')(filtereddata.shifts, shift, true);
        if (found.length) {
            _.each(filtereddata.shifts, function(data, idx) { 
                if (_.isEqual(data, shift)) {
                   index = idx;
                   $scope.shiftData[mainArrIndex].shifts.splice(index,1);
                }
             });
        }
        else{
            alert('no');
        }
    }
    
    $scope.ClearAllShift = function(week, tmsl){
        var filtereddata = $filter('filter')($scope.shiftData, {day:week, timeslot :tmsl}, true)[0];
        _.each($scope.shiftData, function(data, idx) { 
                if (_.isEqual(data, filtereddata)) {
                   mainArrIndex = idx;
                   $scope.shiftData.splice(mainArrIndex,1);
                }
        });
    }
    
    
    $scope.fetchshiftdata = function(week, tmsl){
        return $filter('filter')($scope.shiftData, {day:week, timeslot :tmsl}, true)[0] || '';
    }
    
    $scope.ShowEndTime = function(){
        $scope.shiftData = [];
        $scope.shiftsArray = [];
    }
   
});
