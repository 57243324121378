angular.module("app").controller("PayrollreportController", function($scope,ports,$filter,$location, SessionService, $http, UserService, $timeout, getweekService,NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.searchText = {};
    $scope.searchText.val = '';
    $scope.reportselect = "all";
    var currWeekDates = getweekService.getCurrWeek();
    $scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $scope.currWeekDate = currWeekDates;
    var $modal = $("#get-modal");
    $scope.shifts = [
      {name: "Morning", value: "Morning"},
      {name: "Afternoon", value: "Afternoon"},
      {name: "Night", value: "Night"},
      {name: "Late Night", value: "Late Night"}
    ];
    $scope.reportTypelist = [{
        name: "Incomplete",
        value: 'incomplete'
    }, {
        name: "Completed",
        value: "complete"
    }, {
        name: "All",
        value: "all"
    }];
    $scope.errormessage = "Please enter your selection to get the report."
    $scope.report = [];
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $http.post("/api/getAdminEmpActiveInactive", {location:SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }
    $http.post("/api/getAllEventAccounts",{location:SessionService.currentUser.location}).then(function(response){
         if ( "200" === response.data.status_code ) {
             $scope.allEvents = response.data.events;
             angular.forEach( $scope.allEvents, function( value, key ) {
              value.buttonlabel = value.name;
             });
         } 
    });

    $scope.getReport = function() {
        $scope.exportData = [];
        $scope.exportData.push(["Emp #", "Account #", "Username", "Account", "Date of Shift", "Pay Rate", "Start Time", "End Time", "Break", "Hours", "How early/late(In Min)"]);
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDate = new Date($scope.dateRange.end_date);
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
        $scope.storeSelEventAccountId = '';
        $scope.storeSelUserId = '';
        var payrollreportJson = {};
        $scope.showloader = true;
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                   }
            });
        }
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if (value.ticked == true) {
                    $scope.storeSelUserId = value;
                }
            });
        }
        if($scope.storeSelEventAccountId._id){
            payrollreportJson.eventId = $scope.storeSelEventAccountId._id;
        }
        if($scope.storeSelUserId._id){
            payrollreportJson.empId = $scope.storeSelUserId._id;
        }
        
        var enddDate = new Date($scope.dateRange.end_date);
        var correctEndDate = enddDate.setDate(enddDate.getDate() + 1)
        $scope.datenewRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment(correctEndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        payrollreportJson.dateRange = $scope.datenewRange;
        payrollreportJson.location = SessionService.currentUser.location;
        payrollreportJson.reportselect = $scope.reportselect;
        $http.post("/api/getpayrollreport", payrollreportJson).then(function(response) {
console.log( SessionService.currentUser.locationtimezone);console.log(response);           
 $scope.showloader = false;
            $scope.visibility = true;
            $scope.totalhours = 0;
            $scope.tTDiff = 0;
            response.data.data.forEach(function(elem, key){
                $scope.tTDiff = $scope.tTDiff + elem.totalTimeDiff; console.log(elem.totalTimeDiff);
                elem.totalTimeDiff = $scope.timeToDecimal(elem.totalTimeDiff);console.log(elem.totalTimeDiff);
                elem.userId.fullname = elem.userId.first_name+' '+elem.userId.last_name;
                elem.date_of_shift = elem.clockInDateTime;
                
                //$scope.totalhours = (parseFloat($scope.totalhours) + parseFloat(elem.totalTimeDiff)).toFixed(2);
                $scope.totalhours = $scope.timeToDecimal($scope.tTDiff);
                elem.clockInDateTime = moment(elem.clockInDateTime).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY HH:mm:ss");
                if(elem.clockOutDateTime){
                    elem.clockOutDateTime = moment(elem.clockOutDateTime).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY HH:mm:ss");
                }
                var clockIntime = elem.clockInDateTime.split(" ");
                var startTime = clockIntime[1];
                if(elem.alignScheduleTime){
                    var endTime = elem.alignScheduleTime + ":00";
                    var start = moment(startTime, "HH:mm");
                    var end = moment(endTime, "HH:mm");
                    var minutes = end.diff(start, 'minutes');
                }else{
                    var minutes = 0;
                }
                elem.howLate_howEarly = minutes;
            });
            $scope.report = response.data.data;
            if (response.data.data.length < 1) {
                $scope.errormessage = "No Report found for this selection.";
            }
            $scope.haveResult = true;
            if (!$scope.report.length) {
                $scope.haveResult = false;
            }
            angular.forEach($scope.report, function(value, key) {
                $scope.exportData.push([value.userId.employeeid, value.eventId.accountId, value.userId.fullname, value.eventId.name, $filter('date')(value.clockInDateTime, 'MM/dd/yyyy'), value.payrate, $filter('date')(value.clockInDateTime, 'MM/dd/yyyy HH:mm'), $filter('date')(value.clockOutDateTime, 'MM/dd/yyyy HH:mm'), $scope.timeToDecimal(value.totalBreakTime), value.totalTimeDiff, value.howLate_howEarly]);
            });
            return $scope.report;
        })
    }

    $scope.reset_page=function() {
        $scope.getReport();
    }

    $scope.editPayrol = function(dataInfo){
        $scope.clocknewdata = dataInfo;
        $scope.clocknewdata.shift = dataInfo.shift;
        $scope.clocknewdata.break = dataInfo.totalBreakTime / 60000;
        $scope.clocknewdata.newclockInDateTime = $filter('date')($scope.clocknewdata.clockInDateTime,'MM/dd/yyyy HH:mm:ss');
        $scope.clocknewdata.newclockOutDateTime = $filter('date')($scope.clocknewdata.clockOutDateTime,'MM/dd/yyyy HH:mm:ss');
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   value.ticked = false;
                   if ( value._id == dataInfo.eventId._id ) {
                       value.ticked = true;
                   }
            });
        }
    }
    $scope.saveclockUpdate = function(clockInfo){
        var StartDate = new Date(clockInfo.newclockInDateTime);
        var EndDate   = new Date(clockInfo.newclockOutDateTime);
        if(!clockInfo.newclockInDateTime && !clockInfo.newclockOutDateTime){
            toastr.error('Please enter clockIn and clockOut time');
            return false;
        }
        var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;     
        if (diff<0) {
             toastr.error('Clock out date should be greater than clock in date.');
             return false;
        }
        $scope.clocjson = {};
        $scope.storeSelEventAccountId = '';
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                       $scope.clocjson.basic_pay_rate = value.basic_pay_rate;
                       $scope.clocjson.pay_rate_list = value.pay_rates;
                   }
            });
        }
        if(clockInfo.break){
            clockInfo.break = clockInfo.break * 60000;//convert minute to Millisecond
        }else{
            clockInfo.break = 0;
        }
        var then = clockInfo.newclockInDateTime;
        var nowtime = clockInfo.newclockOutDateTime;
        var ms = moment(nowtime).diff(moment(then)); // Millisecond
        var timeWithoutBreak = ms - clockInfo.break;
        var d = moment.duration(timeWithoutBreak);
        var s = d.asHours().toFixed(2);
        if($scope.storeSelEventAccountId._id){
            $scope.clocjson.eventId = $scope.storeSelEventAccountId._id;
        }else{
            toastr.error('Please select Event/Account from the list.');
            return false;
        }

        if(!clockInfo.shift){
            toastr.error('Please select shift.');
            return false;
        }
        $scope.clkInDte    = clockInfo.newclockInDateTime;
        $scope.fetchExceptDate = moment($scope.clkInDte).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate(); 
        var today = $scope.fetchExceptDate;
        var day = today.getDay();
        $scope.todayDay = $scope.weekdays[day];
        $scope.clocjson.shift = clockInfo.shift;
        $scope.clocjson.pay_rate_list.forEach(function(elem, key){
            if(elem.timeslot == clockInfo.shift && elem.day == $scope.todayDay){
                $scope.clocjson.basic_pay_rate = elem.price || 0;
            }
        });

        $http.post('/api/fetchExceptionRate',{eventId : $scope.clocjson.eventId, employee:clockInfo.userId._id, fetchExceptionDate : $scope.fetchExceptDate}).then(function(exceptionRes){
            if(exceptionRes.data.status_code == "200"){
                if(exceptionRes.data.data[0] && exceptionRes.data.data[0].rates){
                    $scope.clocjson.basic_pay_rate = exceptionRes.data.data[0].rates.price;                        
                }
            }
        });
        $scope.clocjson.payrate = $scope.clocjson.basic_pay_rate;
        $scope.clocjson.totalTimeDiff = ms;
        $scope.clocjson.payroll = s*$scope.clocjson.payrate;
        //$scope.clocjson.clockInDateTime = new Date(clockInfo.newclockInDateTime);
        $scope.clocjson.clockInDateTime = moment(clockInfo.newclockInDateTime).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        $scope.clocjson.clockOutDateTime = moment(clockInfo.newclockOutDateTime).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        $scope.clocjson.clockInid = clockInfo._id;
        $scope.clocjson.created_by = SessionService.currentUser._id;
        $scope.clocjson.totalBreakTime = clockInfo.break;
        $http.post("/api/updateClockFullInfo",$scope.clocjson).then(function(response){
            if (response.status == 200) {
                toastr.success("Successfully Done !");
                angular.element('#modal-editPayroll').modal('hide');
                if($scope.allEvents){
                    angular.forEach( $scope.allEvents, function( value, key ) {
                          value.ticked = false;
                    });
                }
                $scope.getReport();
            }
            else {
                console.log('400');
            }
        });
    }
    $scope.deletePayrol = function(details){
        $scope.removepayrollData = details;
        $('#confirm_del').show();
    }
    $scope.deletePayrollConfirm=function(){
        $('#confirm_del').hide();
        $scope.clocjson = {};
        $scope.clocjson.clockInid = $scope.removepayrollData._id;
        $http.post("/api/removeClockEntry",$scope.clocjson).then(function(response){
            if (response.status == 200) {
                toastr.success("Successfully Done !");
                $scope.getReport();
            }
            else {
                console.log('400');
            }
        });
    }
   // $scope.getReport();   
});

