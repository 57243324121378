angular.module("app").controller("TimesheetController", function($scope, $filter, SessionService, $routeParams, $http, getweekService, moment) {
	$scope.userRole = SessionService.currentUser.roles[0];
	var currWeekDates = getweekService.getCurrWeek();
	$scope.haveResult = false;
    $scope.clocknewdata = {};
    if($routeParams.id){
        var selectedIdStr = $routeParams.id;
        $scope.userId = selectedIdStr.substr(1);
    }else{
        $scope.userId = SessionService.currentUser._id;
    }
    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $scope.getTimesheetFun = function(userId){
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDateOld = new Date($scope.dateRange.end_date);
        var EndDate = new Date(EndDateOld.setTime( EndDateOld.getTime() + 1 * 86400000 ));
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
        var timesheetInfoJson = {};
        timesheetInfoJson.user =  userId;
        timesheetInfoJson.from =  moment(StartDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        timesheetInfoJson.to = moment(EndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        timesheetInfoJson.location = SessionService.currentUser.location;
    	$http.post('/api/gettimesheet',timesheetInfoJson).then(function(response){
			if("200" === response.data.status_code && response.data.data.length){
				$scope.haveResult = true;
				response.data.data.forEach(function(elem,key){
					if(elem.breaks.length){
						var i = 1;
						$scope.timediff = 0;
						elem.breaks.forEach(function(el,ke){
							if(el.timediff){
								$scope.timediff = $scope.timediff + el.timediff;
							}
							if(i === elem.breaks.length){
								//var s = moment.utc($scope.timediff).format("HH:mm:ss");
								elem.breaktime = $scope.timeToDecimal($scope.timediff);
								elem.totalBreakTime = $scope.timediff;
							}
							i++;
						});
					}else{
						elem.breaktime = $scope.timeToDecimal(0);
					}
                    if(elem.clockOutDateTime){
                        elem.totalTime = $scope.timeToDecimal(elem.totalTimeDiff);
                    }else{
                        elem.totalTime = $scope.timeToDecimal(0);
                    }
				});
				$scope.timesheetRecords = response.data.data;
			}else{
				$scope.haveResult = false;
			}
		});
    }

    $scope.totalBreaks = function(){
    	var i = 1;
    	$scope.tm = 0;
    	$scope.timesheetRecords.forEach(function(e,k){
    		if(e.totalBreakTime){
    			$scope.tm = $scope.tm + e.totalBreakTime;
    		}
    		if(i === $scope.timesheetRecords.length){
				$scope.totalBrTm = $scope.timeToDecimal($scope.tm);
    		}
    		i++;
    	});
		return $scope.totalBrTm;
    }

    $scope.totalRegHours = function(){
    	var i = 1;
    	$scope.regularTotal = 0;
    	$scope.tm = 0;
    	$scope.timesheetRecords.forEach(function(e,k){
    		if(e.clockInDateTime && e.clockOutDateTime){
    			var ms = moment(e.clockOutDateTime).diff(moment(e.clockInDateTime));
    			$scope.regularTotal = $scope.regularTotal + ms;
    		}
    		if(i === $scope.timesheetRecords.length){
				$scope.totalHours = $scope.timeToDecimal($scope.regularTotal);
    		}
    		i++;
    	});
		return $scope.totalHours;
    }

    $scope.withoutBreakHours = function(){
    	var i = 1;
    	$scope.regularTotal = 0;
    	$scope.timesheetRecords.forEach(function(e,k){
    		if(e.clockInDateTime && e.clockOutDateTime){
    			var ms = moment(e.clockOutDateTime).diff(moment(e.clockInDateTime));
    			$scope.regularTotal = $scope.regularTotal + ms;
    		}
    		if(e.totalBreakTime){
    			$scope.tm = $scope.tm + e.totalBreakTime;
    		}
    		if($scope.regularTotal){
    			$scope.withoutBreakTotal = $scope.regularTotal - $scope.tm;
    		}
    		
    		if(i === $scope.timesheetRecords.length && $scope.withoutBreakTotal){
				$scope.withoutBkHours = $scope.timeToDecimal($scope.withoutBreakTotal);
    		}
    		i++;
    	});
    	return $scope.withoutBkHours;
    }

    $scope.checkClockOutDate = function(data){
    	if(!data.clockOutDateTime){
    		return 'clockinActive';
    	}
    }

    $http.post("/api/getemplAndManagers", {location : SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    $scope.viewTimesheet = function(){
    	$scope.adminTimesheet = true;
		if($scope.modernWebBrowsers){
	        angular.forEach( $scope.modernWebBrowsers, function( value, key ){
	           if ( value.ticked === true ) {
	               storeSelUserId = value._id;
	           }
	        });
		}
		if(!storeSelUserId){
			toastr.error("Please select employee.");
		}else{
			$scope.getTimesheetFun(storeSelUserId);
		}
    }

    $scope.editClockin = function(dataInfo){
        $scope.clocknewdata = dataInfo;
        $scope.clocknewdata.newclockInDateTime = $filter('date')($scope.clocknewdata.clockInDateTime,'MM/dd/yyyy HH:mm:ss');
    }

    $scope.saveclockUpdate = function(clockInfo){
        $scope.clocjson = {};
        $scope.clocjson.clockInDateTime = moment(clockInfo.newclockInDateTime).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        $scope.clocjson.clockInid = clockInfo._id;
        $scope.clocjson.created_by = SessionService.currentUser._id;
        $http.post("/api/updateClockInfo",$scope.clocjson).then(function(response){
            if (response.status == 200) {
                toastr.success("Successfully Done !");
                angular.element('#modal-basic').modal('hide');
                $scope.viewTimesheet();
            }
            else {
                console.log('400');
            }
        });
    }

    $scope.getTimesheetFun($scope.userId);
});

angular.module("app").filter('dateDifference', function () {
    return function (then, now) {
       var ms = moment(now).diff(moment(then));
       var d = moment.duration(ms);
       var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
       return s;
   };
});
