angular.module("app").controller("TimeSlotBufferController", function($scope, SessionService, $http) {
    $scope.showloader= true;
    $scope.user = SessionService.currentUser;
    $scope.gridData = {};
    $scope.weekdays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    $scope.timeslots = ["Morning","Afternoon","Night","Late Night"];
    
    $scope.fetchTimeSlotBuffer = function(){
        $http.post('/api/timeslotbuffer', {locationId : SessionService.currentUser.location}).success(function(fetchresponse){
            if(fetchresponse.data[0]){
                var response = fetchresponse.data[0].buffervalues;
            }else{
                var response = [];
            }
            $scope.showloader = false;
            griddata = [];
            for (var i = 0; i < $scope.weekdays.length; i++) {
                var nestedObj = {};
                for (var j = 0; j < $scope.timeslots.length; j++) {
                    angular.forEach(response, function(item) {
                        if (item.day == $scope.weekdays[i] && item.timeslot == $scope.timeslots[j]) {
                            nestedObj[$scope.timeslots[j]] = item.buffer;
                        }
                    });
                    if(!nestedObj[$scope.timeslots[j]]) {
                        nestedObj[$scope.timeslots[j]] = 0;
                    }
                }
                griddata[$scope.weekdays[i]] = nestedObj
            }
            $scope.gridData = griddata;
        });
    }
    
    $scope.fetchTimeSlotBuffer();
    
    // Save time slots Buffer
    $scope.submitBuffer = function(grid_data){
        var postdata = [];
        for (var i = 0; i < $scope.weekdays.length; i++) {
            var nestedObj = {};
            for (var j = 0; j < $scope.timeslots.length; j++) {
                nestedObj = {'day' : $scope.weekdays[i], 'timeslot' :$scope.timeslots[j], 'buffer' : grid_data[$scope.weekdays[i]][$scope.timeslots[j]]};
                postdata.push(nestedObj);
            }
        }
        var inputJson = {
                data: postdata,
                location : SessionService.currentUser.location
        }
        $http.post('/api/savetimeslotbuffer', inputJson).success(function(response){
            $scope.showloader = false;
            toastr.success("Timeslot Buffer updated successfully.");
            $scope.fetchTimeSlotBuffer();
        });
    };
    
    $scope.resetdata = function(){
        toastr.info("You have rollback your resent updates.");
        $scope.fetchTimeSlotBuffer();
    };
    $scope.clearDataConfirmation = function(){
        $('#confirm_clear').show();
    }
    $scope.cleardata = function(){
        $('#confirm_clear').hide();
        griddata = [];
        for (var i = 0; i < $scope.weekdays.length; i++) {
            var nestedObj = {};
            for (var j = 0; j < $scope.timeslots.length; j++) {
                if(!nestedObj[$scope.timeslots[j]]) {
                    nestedObj[$scope.timeslots[j]] = 0;
                }
            }
            griddata[$scope.weekdays[i]] = nestedObj
        }
        $scope.gridData = griddata;
        toastr.info("You cleared the data.");
    };
});
