angular.module("app").controller("TimeSlotController", function($scope, TimeSlotResource, SessionService, $http, sidepanelactiveService, moment) {
    $scope.user = SessionService.currentUser;
    sidepanelactiveService.test();
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.slottime = [
        {name: 1, value: 1},
        {name: 2, value: 2},
        {name: 3, value: 3},
        {name: 4, value: 4},
        {name: 5, value: 5},
        {name: 6, value: 6},
        {name: 7, value: 7},
        {name: 8, value: 8},
        {name: 9, value: 9},
        {name: 10, value: 10},
        {name: 11, value: 11},
        {name: 12, value: 12},
    ];
    
    $scope.visibility = false;
    $scope.period = [{name: "AM", value: "AM"}, {name: "PM", value: "PM"}];
    $scope.fetchslots = function(){
        $scope.showloader = true;
        TimeSlotResource.query({locationId : SessionService.currentUser.location},function(response){
            $scope.showloader = false;
            $scope.slot = response[0];
            $scope.visibility = true;
        });
    }
    $scope.fetchslots();
    $scope.saveTimeSlot = function(slot){
        $scope.slot.last_modified_by = SessionService.currentUser._id;
        $scope.slot.last_modified_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        if ($scope.slot._id) {
            $http.post('/api/timeslot', $scope.slot).success(function(response){
                $scope.slot = response.data;
                toastr.success("Timeslot updated successfully.");
                $scope.fetchslots();
            });
        }
        else {
            slot.location = SessionService.currentUser.location;
            $http.post('/api/timeslot', slot).success(function(response){
                $scope.slot = response;
                toastr.success("Timeslot added successfully.");
                $scope.fetchslots();
            });
        }   
    };
    $scope.resetdata = function(){
        toastr.info("You have rollback your resent updates.");
        $scope.fetchslots();
    };

});