angular.module("app").controller("ViewMemoController", function($scope, $location, SessionService, $http, UserService, $routeParams) {
    $scope.filedata = [];
    $scope.userRole = SessionService.currentUser.roles[0];
    var memoId = $routeParams.id;
    $http.get('/api/memos/' + memoId).then(function(response) {
        var file = response.data.data.my_file;
        if ("200" === response.data.status_code) {
            $scope.title = response.data.data.title;
            $scope.description = response.data.data.description;
            $scope.created_date = response.data.data.created_date;
            $scope.creator_name = response.data.data.creator_name;
            $scope.sent_to = response.data.data.sent_to;
            if (file) {
                file.forEach(function(value, key) {
                    $scope.filedata.push(value)
                });
            }
        }
    });
    $scope.download_memo = function() {
        $scope.file = [];
        var a = $scope.filedata;
        if (a) {
            a.forEach(function(value, key) {
                $scope.file.push(value)
            });
        }
        $http.post("/api/memodownload", {
            'file': $scope.file,
            'id': memoId
        }).then(function(response) {
            if ("200" === response.data.status_code) {
                window.open($scope.backendsiteurl+ '/public/zipFile' + response.data.zipFilePath.substr(response.data.zipFilePath.lastIndexOf('/'), response.data.zipFilePath.length))
            }
        });
    }
    $scope.openJPG = function(file){
        $scope.filename = file;
    }
});