angular.module("app").controller("EditEmployeeController", function($scope, ports, states, patterns, SessionService, $http, $routeParams, fileUpload, $location, $filter, $rootScope, moment) {
    $scope.userrole = SessionService.currentUser.roles[0];
    $scope.currpage  = $location.absUrl().split('/')[3];
    if ($scope.userrole === "admin") {
        $scope.activate = true;
    }
    else {
        $scope.activate = false;
    }
    $http.get("/api/getAllLocations").then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.title;
            });
            $scope.modernWebBrowsers = response.data.data;

            $http.get('/api/users/' + $scope.id).
            success(function(response) {
              if (response.date_of_birth !== undefined ) {
                response.date_of_birth = $filter('date')(response.date_of_birth, 'MM/dd/yyyy');
              }
              if (response.date_of_hiring !== undefined ) {
                response.date_of_hiring = $filter('date')(response.date_of_hiring, 'MM/dd/yyyy');
              }
              if (response.phone !== undefined ) {
                response.phone = $filter('tel')(response.phone, false);
              }
              $scope.employee = response;
              if(response.roles[0] == 'employee'){
                var employeerole = 'Employee';
              }

              if(response.roles[0] == 'hr'){
                var employeerole = 'Hr';
              }
              if(response.roles[0] == 'manager'){
                var employeerole = 'Manager';
              }
              $scope.employee.emp_role = employeerole;
              if($scope.userrole == 'admin'){
                  $scope.modernWebBrowsers.forEach(function(elem, key){
                    if(elem._id == response.location){
                        elem.ticked = true;
                    }
                  })
              }
              
              if( true == $scope.employee.active ) {
                $scope.is_active_yes = true;
                $scope.is_active_no = false;
              }
              else {
                $scope.is_active_yes = false;
                $scope.is_active_no = true;
              }
            });
        }
        else {
            console.log('400');
        }
    });
    $scope.changeAddress = false;
    $scope.emplnamePattern = /^[a-zA-Z0-9\'\-]+( [a-zA-Z0-9\'\-]+)*$/;
    $scope.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    $scope.addressPattern = patterns.address;
    $scope.addressPatternError = patterns.addressPatternError;
    $scope.del_profile_pic = false;
    $scope.employee = {};
    if ($scope.currpage == 'adminprofile') {
         $scope.id = ':'+SessionService.currentUser._id;
         $scope.showrole = false;
    }else{
        $scope.id = $routeParams.id;
        $scope.showrole = true;
    }
    $scope.states = states.statelist;
    $scope.is_active_yes = '';
    $scope.is_active_no = '';
    $scope.employee.phone = '';
    $scope.employee.changeAddress = false;
    $scope.employee.locationID = '';
    $scope.updateEmployee = function(employee) {
    /*    if($scope.userrole == 'admin'){
            $scope.storelocationval = '';
            if($scope.modernWebBrowsers){
                angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                    if ( value.ticked === true ) {
                       $scope.storelocationval = value;
                    }
                });
            }
            employee.location = $scope.storelocationval._id;
            if(!$scope.storelocationval){
                toastr.error("Please choose location to continue");
                return;
            }
        }*/
       
        if($scope.employeeForm.$invalid){
            toastr.error("Please fill all the required fields.");
            angular.element("#field-1").focus();
            angular.element("input.ng-invalid").css("border-color","red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
        }else if($scope.emailVerifyClass == "fa-times c-red"){
            toastr.error("Please correct unique email address.");
            angular.element("#emailAddress").focus();
            angular.element("input.ng-invalid").css("border-color","red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
        }else if($scope.empnoVerifyClass == "fa-times c-red"){
            toastr.error("Please correct unique employee number.");
            angular.element("#empNo").focus();
            angular.element("input.ng-invalid").css("border-color","red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
        }
        else{ 
            if($scope.del_profile_pic){
                employee.del_profile_pic = true;
            } 
            if ($scope.myFile) {
                var file = $scope.myFile;
                var chkData = "";
                var uploadUrl = "/api/fileUpload";
                $scope.showloader = true;
                fileUpload.uploadFileToUrl(file, uploadUrl, function(retData){
                    if ( true === retData.success && retData.code != 400 ) {
                        employee.prof_image = retData.save_image_name;
                        if (employee.date_of_birth != "") {
                            jsDate = moment(employee.date_of_birth).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                        }
                        employee.date_of_birth = jsDate;
                        if (employee.date_of_hiring != "") {
                            employee.date_of_hiring = moment(employee.date_of_hiring).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                        }
                        if($scope.del_profile_pic){
                            employee.del_profile_pic = true;
                        }
                        employee.created_by = SessionService.currentUser._id;
                        if ($scope.role == "admin" && $scope.showrole) {
                            if(employee.emp_role == 'Employee')
                                    employee.roles = ["employee"];
                            if(employee.emp_role == 'Manager')
                                    employee.roles = ["manager"];
                            if(employee.emp_role == 'Hr')
                                    employee.roles = ["hr"];
                        }
                        if ($scope.role == "manager") {
                                employee.roles = ["employee"];
                        }
                        if($scope.changeAddress){
                            employee.address.line1 = employee.address.line1.formatted_address;
                            employee.changeAddress = true;
                            employee.locationID = SessionService.currentUser.location;
                        }else{
                            employee.address.line1 = employee.address.line1;
                        }
                        
                        $http.put("/api/users/", employee).then(function(response){
                            if (response.data && response.data.status_code != 500) {
                                $scope.showloader = false;
                                if (response.data.data.date_of_birth !== undefined ) {
                                    $scope.employee.date_of_birth = $filter('date')(response.data.data.date_of_birth, 'MM/dd/yyyy');
                                }    
                                toastr.success("Employee information updated successfully.");
                                $scope.employeeForm.$setPristine();
                                if ("admin" === SessionService.currentUser.roles[0]) {
                                    $location.path("/viewadminemployee");
                                }
                                else if ("manager" === SessionService.currentUser.roles[0]) {
                                    $location.path("/viewmanageremployee");
                                }
                                else if ("hr" === SessionService.currentUser.roles[0]) {
                                    $location.path("/viewhremployee");
                                }
                                else {
                                    $location.path("/login");
                                }
                            }
                            if (response.data.status_code == 503){
                                toastr.error("Email is Already Exists. Please choose a different one.");
                            }
                            if (response.data.status_code == 500){
                                toastr.error("Something went wrong.");
                            }
                        });
                    } else if(retData.code==400){
                        $scope.showloader = false;
                        toastr.error(retData.msg)
                    }
                    else
                        console.log("Failure");
                });
            }
            else {
                if(SessionService.currentUser.roles.indexOf('admin') > -1)
                        $scope.role = 'admin';
                else if(SessionService.currentUser.roles.indexOf('manager') > -1)
                        $scope.role = 'manager';
                else if(SessionService.currentUser.roles.indexOf('employee') > -1)
                        $scope.role = 'employee';
                else if(SessionService.currentUser.roles.indexOf('hr') > -1)
                        $scope.role = 'hr';
                if (employee.date_of_birth != "") {
                    employee.date_of_birth = moment(employee.date_of_birth).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                }
                if (employee.date_of_hiring != "") {
                    employee.date_of_hiring = moment(employee.date_of_hiring).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                }
                if ($scope.role == "admin" && $scope.showrole) {
                    if(employee.emp_role == 'Employee')
                            employee.roles = ["employee"];
                    if(employee.emp_role == 'Manager')
                            employee.roles = ["manager"];
                    if(employee.emp_role == 'Hr')
                            employee.roles = ["hr"];
                }
                if ($scope.role == "manager") {
                        employee.roles = ["employee"];
                }
                employee.created_by = SessionService.currentUser._id;
                employee.password = "password";
                if($scope.changeAddress){
                    employee.address.line1 = employee.address.line1.formatted_address;
                    employee.changeAddress = true;
                    employee.locationID = SessionService.currentUser.location;
                    
                }else{
                    employee.address.line1 = employee.address.line1;
                } 
                $http.put("/api/users/", employee).then(function(response){
                    if (response.data && response.data.status_code != 500) {
                        if (response.data.date_of_birth !== undefined ) {
                          $scope.employee.date_of_birth = $filter('date')(response.data.date_of_birth, 'MM/dd/yyyy');
                        }

                        if (response.data.date_of_hiring !== undefined ) {
                          $scope.employee.date_of_hiring = $filter('date')(response.data.date_of_hiring, 'MM/dd/yyyy');
                        }
                        toastr.success("Employee information updated successfully.");
                        $scope.employeeForm.$setPristine();
                        if ("admin" === SessionService.currentUser.roles[0]) {
                            $location.path("/viewadminemployee");
                        }
                        else if ("manager" === SessionService.currentUser.roles[0]) {
                            $location.path("/viewmanageremployee");
                        }
                        else if ("hr" === SessionService.currentUser.roles[0]) {
                            $location.path("/viewhremployee");
                        }
                        else {
                            $location.path("/login");
                        }
                    }
                    if (response.data.status_code == 503){
                        toastr.error("Email is Already Exists. Please choose a different one.");
                    }
                    if (response.data.status_code == 500){
                        toastr.error("Something went wrong.");
                    }                
                });
            }
        }

    };
    
    $scope.deleteusrimg = function(emp) {
        $('#del_img').show();
    }
    $scope.imgDelete = function(emp) {
        $scope.del_profile_pic = true;
        $('#del_img').hide();
    }
    $scope.generate_random=function(){
        if($scope.employee.employeeid){
            $scope.showloader = true;
            $http.post("/api/chk_random_edit", {num:$scope.employee.employeeid, editempId : $scope.employee._id}).then(function(response){
                $scope.showloader = false;
                if(response.data.status_code=="200"){
                    angular.element("#empNo").css("border-color","#d3d3d3");
                    $scope.empnoVerifyClass = "fa-check c-green";
                    return 1;
                }
                else{
                    $scope.empnoVerifyClass = "fa-times c-red";
                    angular.element("#empNo").css("border-color","red");
                    return 0;
                }
            });
        }
        else{
            toastr.error("Please enter employee id.");
        }
    };   

    $scope.checkEmailUnique = function($event){
        $scope.showRestoreLink = false;
        if($scope.employee.email){
            $http.post('/api/uniqueEmailEdit',{'email':$scope.employee.email, 'editempId' : $scope.employee._id})
              .success(function(data){
              if(data.message=="already existed"){
                $scope.emailVerifyClass="fa-times c-red";
                angular.element("#emailAddress").css("border-color","red");
                if(data.data.is_deleted==true){
                    $('#confirm_restore').show();
                    $scope.showRestoreLink = true;
                }else{
                    $scope.showRestoreLink = false;
                }
              }else{
                $scope.showRestoreLink = false;
                angular.element("#emailAddress").css("border-color","#d3d3d3");
                $scope.emailVerifyClass="fa-check c-green";
              }
            })
        }
    };

    $scope.changeAdd = function(){
        $scope.changeAddress = true;
        var details = $scope.employee.address.line1;
        if(_.isObject(details)){
            for (var i = 0; i < details.address_components.length; i++) {
              var addressType = details.address_components[i].types[0];
              var val = details.address_components[i].long_name;
              if (addressType == 'country') {
                $scope.employee.country = val;
              }
              if (addressType == 'administrative_area_level_1' || addressType == 'locality' || addressType == 'postal_code' || addressType == 'street_number' || addressType == 'route') {
                if (addressType == 'administrative_area_level_1') {
                  $scope.employee.address.state = val;
                }
                if (addressType == 'locality') {
                  $scope.employee.address.city = val;
                }

                if (addressType == 'postal_code') {
                  $scope.employee.address.zip = val;
                }
              }
            }
        }
    }
});