angular.module("app").controller("ScheduleMsgController", function($scope, $upload, Upload,$timeout,SessionService, $http, $location, SocketService, moment) {
    $scope.userRole = SessionService.currentUser.roles[0];
    var storeSelUserId  = [];
    var phoneNumberList = [];
    $scope.memo = {};
    $scope.weekdays = [
        {value : 0, name : "Sunday"},
        {value : 1, name : "Monday"},
        {value : 2, name : "Tuesday"},
        {value : 3, name : "Wednesday"},
        {value : 4, name : "Thursday"},
        {value : 5, name : "Friday"},
        {value : 6, name : "Saturday"}
    ];
    $http.post("/api/getemplAndManagers", {location : SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    $scope.fetchGroups = function(){
        $http.post("/api/fetchGroups").then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                });
                $scope.groupArr = response.data.data;
            }
            else {
                console.log('400');
            }
        });
    }
    $scope.fetchGroups();
    $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
        $scope.showloader = true;
         if ( "200" === response.data.status_code) {
             $scope.showloader = false;
             $scope.allEvents = response.data.events;
         } 
    });
    $scope.memo.texttime = 'now';
    $scope.memo.texttype = 'EventBased';
    $scope.addMemo = function(memo) {
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ){
               if ( value.ticked === true ) {
                   $scope.storeSelEventAccountId = value._id;
               }
            });
        }

        var x = 0;
        angular.forEach( $scope.groupArr, function( value, key ) {
            if ( value.ticked == true ) {
               angular.forEach(value.employees, function(val, ke){
                    storeSelUserId.push({user_id: val.user_id, mark_read: false});
                    phoneNumberList.push(val.phone);
               })                   
            }
            x++;
        });

        if(x === $scope.groupArr.length){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if ( value.ticked == true ) {
                    if(!_.findWhere(storeSelUserId, {user_id: value._id, mark_read: false})){
                        storeSelUserId.push({user_id: value._id, mark_read: false});
                        phoneNumberList.push(value.phone);
                    }
                }
            });
        }
            
        if($scope.memoForm.$invalid || (($scope.storeSelEventAccountId === undefined) && (memo.texttype === "EventBased"))) {
            if ($scope.storeSelEventAccountId === undefined) {
                toastr.error("Please select an account to send text.");
                angular.element(".multiSelectButton").css("border-color", "red");
                angular.element("input.ng-valid").css("border-color","#d3d3d3");
            }else{
                toastr.error("Please fill all the required fields.");
                angular.element(".multiSelectButton").css("border-color", "#d3d3d3");
                angular.element("input.ng-invalid").css("border-color", "red");
                angular.element("input.ng-valid").css("border-color","#d3d3d3");
                angular.element("input.ng-invalid").focus();
            }
        }else if($scope.memoForm.$invalid || ((storeSelUserId.length < 1) && (memo.texttype === "EmployeeBased"))){
            if(storeSelUserId.length < 1){
                toastr.error("Please select atleast one employee to send text.");
                angular.element(".multiSelectButton").css("border-color", "red");
                angular.element("input.ng-valid").css("border-color","#d3d3d3");
            }else{
                toastr.error("Please fill all the required fields.");
                angular.element(".multiSelectButton").css("border-color", "#d3d3d3");
                angular.element("input.ng-invalid").css("border-color", "red");
                angular.element("input.ng-valid").css("border-color","#d3d3d3");
                angular.element("input.ng-invalid").focus();
            }
        }
        else{
            angular.element(".multiSelectButton").css("border-color", "#d3d3d3");
            var creatorInfo = SessionService.currentUser;
            var first_name, last_name;
            if ( undefined !== SessionService.currentUser.first_name )
                first_name = SessionService.currentUser.first_name;
            else
                first_name = "";          
            if ( undefined !== SessionService.currentUser.last_name )
                last_name = SessionService.currentUser.last_name;
            else
                last_name = "";
            var memos = {
                title: memo.title,
                description: memo.description,
                created_by: SessionService.currentUser._id,
                role: SessionService.currentUser.roles[0],
                created_date: moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
                creator_name: first_name+" "+last_name,
                eventid:$scope.storeSelEventAccountId,
                phoneNumberList : phoneNumberList,
                timetosend:memo.texttime
            };

            if((memo.texttime == 'now') && (memo.texttype == 'EventBased')){
                $http.post("/api/scheduleReminder", memos).then(function(response){
                    if( "200" === response.data.status_code ) {
                        toastr.success("Successfully Done!.");
                        $scope.memo = {};
                        $scope.memo.texttime = 'now';
                        $scope.memo.texttype = 'EventBased';
                        if($scope.allEvents){
                            angular.forEach( $scope.allEvents, function( value, key ){
                               if ( value.ticked === true ) {
                                   value.ticked = false;
                               }
                            });
                        }
                        if($scope.modernWebBrowsers){
                            angular.forEach( $scope.modernWebBrowsers, function( value, key ){
                               if ( value.ticked === true ) {
                                   value.ticked = false;
                               }
                            });
                        } 
                    }
                });
            }
            if((memo.texttime == 'later') && (memo.texttype == 'EventBased')){
                memos.schRemindertime = memo.schRemindertime;
                $http.post("/api/scheduleReminderInFuture", memos).then(function(response){
                    if( "200" === response.data.status_code ) {
                        toastr.success("Schedule reminder is added successfully.");
                        $scope.memo = {};
                        $scope.memo.texttime = 'now';
                        $scope.memo.texttype = 'EventBased';
                        if($scope.allEvents){
                            angular.forEach( $scope.allEvents, function( value, key ){
                               if ( value.ticked === true ) {
                                   value.ticked = false;
                               }
                            });
                        }
                        if($scope.modernWebBrowsers){
                            angular.forEach( $scope.modernWebBrowsers, function( value, key ){
                               if ( value.ticked === true ) {
                                   value.ticked = false;
                               }
                            });
                        } 
                    }
                });
            }
            if((memo.texttime == 'repeat') && (memo.texttype == 'EventBased')){
                memos.schRepeattime = memo.schRepeattime;
                memos.weekdy = memo.weekdy;
                $http.post("/api/scheduleReminderOnWeekDay", memos).then(function(response){
                    if( "200" === response.data.status_code ) {
                        toastr.success("Schedule reminder is added successfully.");
                        $scope.memo = {};
                        $scope.memo.texttime = 'repeat';
                        $scope.memo.texttype = 'EventBased';
                        if($scope.allEvents){
                            angular.forEach( $scope.allEvents, function( value, key ){
                               if ( value.ticked === true ) {
                                   value.ticked = false;
                               }
                            });
                        }
                        if($scope.modernWebBrowsers){
                            angular.forEach( $scope.modernWebBrowsers, function( value, key ){
                               if ( value.ticked === true ) {
                                   value.ticked = false;
                               }
                            });
                        } 
                    }
                });
            }
            if(memo.texttype == 'EmployeeBased'){
                memos.schRemindertime = memo.schRemindertime;
                $http.post("/api/employeeReminderInFuture", memos).then(function(response){
                    if( "200" === response.data.status_code ) {
                        toastr.success("Schedule reminder is added successfully.");
                        $scope.memo = {};
                        $scope.memo.texttime = 'now';
                        $scope.memo.texttype = 'EventBased';
                        if($scope.allEvents){
                            angular.forEach( $scope.allEvents, function( value, key ){
                               if ( value.ticked === true ) {
                                   value.ticked = false;
                               }
                            });
                        }
                        if($scope.modernWebBrowsers){
                            angular.forEach( $scope.modernWebBrowsers, function( value, key ){
                               if ( value.ticked === true ) {
                                   value.ticked = false;
                               }
                            });
                        }                    
                    }
                });
            }
        }
    };
});