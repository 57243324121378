angular.module("app").controller("ViewEmployeeProfileController", function($scope, ports,$location, SessionService, $http, UserService) {
    $scope.tabclass = "page-profil";
    $http.get("/api/users/:"+SessionService.currentUser._id).then(function(response){
    	if(response.status == "200"){
    		$scope.user = response.data;
    	}
    });
    $scope.changeview = function(view){
        $location.path(view);
    };
});