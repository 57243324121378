angular.module("app").controller("timeoffRequestController", function($scope, ports,$location, SessionService, SocketService, $http, UserService,$routeParams, $timeout, fileUpload, NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.shifts = [
        {name: "Morning", value: "Morning"},
        {name: "Afternoon", value: "Afternoon"},
        {name: "Night", value: "Night"},
        {name: "Late Night", value: "Late Night"}
    ];
    $scope.currpage = $location.absUrl().split('/')[3];
    $scope.visibility = false;
    $scope.haveResult = true;
    $scope.sortType='';
    $scope.sortOrder=undefined;
    $scope.searchText={};
    $scope.searchText.val='';
    $scope.typeselect = "All";
    $scope.timeoff = {
        daytype : "Full"
    };
    $scope.typelist = [{
        name: "Approved",
        value: 'Approve'
    }, {
        name: "Rejected",
        value: 'Reject'
    }, {
        name: "Waiting",
        value: "Waiting"
    }, {
        name: "All",
        value: "All"
    }];
    $scope.myrole = '';
   
    $scope.cleardata = function(){
        toastr.info("You cleared the data.");
        $scope.timeOffRequestForm.$setPristine();
        angular.element("input.ng-invalid").css("border", "1px solid #d3d3d3");
        angular.element("textarea.ng-invalid").css("border", "1px solid #d3d3d3");
        $scope.timeoff = {
               daytype : "Full",
               shift : $scope.shifts[0].value
        };
    };
    $scope.reset_page=function() {
        $scope.pageNum = 1;
        $scope.timeofflist();
    }
    $scope.sendTimeOffRequest = function(timeoff){
        if($scope.timeOffRequestForm.$invalid){
            toastr.error("Please fill all the required fields.");
            angular.element("input.ng-invalid").css("border-color", "red");
            angular.element("textarea.ng-invalid").css("border-color", "red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
            angular.element("textarea.ng-valid").css("border-color","#d3d3d3");
            angular.element("input.ng-invalid").focus();
        }else{
            timeoff.employee_id = SessionService.currentUser._id;
            //timeoff.manager_id = SessionService.currentUser.created_by;
            timeoff.applyDate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
            timeoff.startdate = moment(timeoff.startdate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
            timeoff.enddate = moment(timeoff.enddate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
            if(timeoff.startdate){
                var currentDateVar = moment(new Date());
                var nextlimitDate  = moment(currentDateVar, "DD-MM-YYYY").add('days', 17);
                $scope.durationValue = nextlimitDate.diff(new Date(timeoff.startdate), 'days');
                if ($scope.durationValue > 1) {
                    toastr.error("You have to fill timeoff 17 days in advance.");
                    return false;
                }
            }
            if (timeoff.daytype == 'Full') {
                var diff = (new Date(timeoff.enddate.getTime()) - new Date(timeoff.startdate.getTime()))/86400000;
                if (diff<0) {
                     toastr.error('End date should be greater than start date.');
                     angular.element("#enddate").css("border-color","red");
                     angular.element("#startdate").css("border-color","red");
                     return false;
                }else{
                     angular.element("#enddate").css("border-color","#d3d3d3");
                     angular.element("#startdate").css("border-color","#d3d3d3");
                }
            }
            if (timeoff.daytype == 'Partial') {
                timeoff.enddate = new Date(timeoff.startdate);
                var storeShifts = [];
                angular.forEach( $scope.shifts, function( value, key ) {
                   if ( value.ticked === true ) {
                       storeShifts.push(value.name);
                   }
                });
                timeoff.shift = storeShifts;
            }
            $http.post("/api/savetimeoffrequests",timeoff).then(function(response){
                if( "200" === response.data.status_code ) {
                    toastr.success("Successfully sent.");
                    $location.path("/myavailability");
                }
            });
        }
    };

    $scope.addDays = function(date, days) {
        date.setDate(date.getDate() + days);
        return date;
    };

    $scope.getDates = function(currentDate, endDate) {
        var start = currentDate,
        end = endDate,
        currentDate = new Date(start.getTime()),
        between = [];
        while (currentDate <= end) {
            between.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return between;
    };

    $scope.timeoffResponse = function(timeoffdata){
        if (!timeoffdata.status) {
            toastr.error("Please approve or reject it.");
        }
        else{
            timeoffdata.manager_id = SessionService.currentUser._id;
            timeoffdata.accept_reject_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
            $http.post("/api/updatetimeoff",timeoffdata).then(function(response){
                if("200" === response.data.status_code) {
                    if(response.data.data.status == "Approve"){
                        var startdate = new Date(response.data.data.startdate);
                        var enddate = new Date(response.data.data.enddate);
                        $scope.datesArr = $scope.getDates(startdate, enddate);
                        $scope.tmpAvailbilityArr = [];
                        if($scope.datesArr.length){
                            for(var i = 0; i < $scope.datesArr.length; i++){    
                                if(response.data.data.daytype == "Partial"){
                                    var obj = {
                                            'employee_id': response.data.data.employee_id._id,
                                            'currDate': $scope.datesArr[i],
                                            'modified_date': moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
                                            'modified_by': SessionService.currentUser._id
                                    };

                                    if(response.data.data.shift.indexOf("Morning") >= 0){
                                            obj.is_morning_scheduled_type =  'no';
                                            obj.is_morning_scheduled = false;
                                    }
                                    if(response.data.data.shift.indexOf("Afternoon") >= 0){
                                            obj.is_afternoon_scheduled_type =  'no';
                                            obj.is_afternoon_scheduled = false;
                                    }
                                    if(response.data.data.shift.indexOf(",Night") >= 0){
                                            obj.is_night_scheduled_type =  'no';
                                            obj.is_night_scheduled = false;                                           
                                    }
                                    if(response.data.data.shift.indexOf(",Late") >= 0){
                                            obj.is_late_night_scheduled_type =  'no';
                                            obj.is_late_night_scheduled = false;                                                
                                    }
                                    $scope.tmpAvailbilityArr.push(obj);
                                }
                                if(response.data.data.daytype == "Full"){
                                    $scope.tmpAvailbilityArr.push({
                                        'employee_id': response.data.data.employee_id._id,
                                        'currDate': $scope.datesArr[i],
                                        'is_morning_scheduled_type' : 'no',
                                        'is_morning_scheduled': false,
                                        'is_afternoon_scheduled_type': 'no',
                                        'is_afternoon_scheduled': false,
                                        'is_night_scheduled_type': 'no',
                                        'is_night_scheduled': false,
                                        'is_late_night_scheduled_type': 'no',
                                        'is_late_night_scheduled': false,
                                        'modified_date': moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
                                        'modified_by': SessionService.currentUser._id
                                    })
                                }
                            }
                        }
                        $http.post('/api/weekavailability', $scope.tmpAvailbilityArr).then(function(response) {
                            toastr.success("Successfully Confirmed.");
                            $location.path("/timeofflist");
                        });
                    }else{
                        toastr.success("Successfully Confirmed.");
                        $location.path("/timeofflist");
                    }
                    SocketService.emit('getTimeOffNotifications', {user_id: SessionService.currentUser._id, role : SessionService.currentUser.roles[0]});
                }
            });
        }
    }
    
    $scope.timeofflist = function() {
        $scope.showloader = true;
        var search = "";
        if ($scope.sortType == '') {
            $scope.sortType = 'applyDate';
            $scope.sortOrder = -1;
        }
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/gettimeoff", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder: $scope.sortOrder,
                    searchByRole: $scope.typeselect,
                    userId: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    location : SessionService.currentUser.location,
                    myrole : $scope.myrole
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    response.data.data.forEach(function(elem, key){
                        elem.startdate = moment(elem.startdate).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY");
                        elem.enddate = moment(elem.enddate).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY");
                    });
                    $scope.allTimeoffs = response.data.data;

                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allTimeoffs;
                })
            }
        });
    }
    $scope.timeofflist();
    if ($scope.currpage == 'mytimeoffrequest') {
        $scope.myrole = 'personaltimeoff';
        $scope.timeofflist();
    }
    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.timeofflist()
              }
          }
     );
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.timeofflist()
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    
    var timeoffid = $routeParams.id;
    if (timeoffid) {
        var timeoffid_new = timeoffid.substr(1);
        if (timeoffid_new) {
            $http.get("/api/gettimeoff/"+timeoffid_new).then(function(response){
                console.log("GET Timeoff RESPONSE");
                if( "200" === response.data.status_code ) {
                    response.data.data.forEach(function(elem, key){
                        elem.startdatetoview = moment(elem.startdate).utc().tz(SessionService.currentUser.locationtimezone).format("dddd, MMMM Do YYYY");
                        elem.enddatetoview = moment(elem.enddate).utc().tz(SessionService.currentUser.locationtimezone).format("dddd, MMMM Do YYYY");
                        elem.applyDatetoview = moment(elem.applyDate).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY");
                        elem.acceptRejectDatetoview = (elem.accept_reject_date)?moment(elem.accept_reject_date).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY"):'';
                    });
                        $scope.allTimeoffs = response.data.data;

                };
            });
        } 
    }
});