angular.module("app").controller("TodoCalendarController", function($scope, $location, NotificationService,TimeSlotResource, $http,$q,sidepanelactiveService, SessionService, moment) {
	var $modal = $('#event-modal');
	sidepanelactiveService.test();
	$scope.currpage = $location.absUrl().split('/')[3];
	$scope.goback = function(){
		window.history.back();
	}
	$scope.strikethrough = function(completiondate){
        if(completiondate){
            return 'greentext';
        }else{
            return 'redtext';
        }
    }
	$scope.role = SessionService.currentUser.roles[0];
	console.log($scope.currpage);
    if($scope.currpage == "mytodocalendar"){
    	$scope.role = 'employee';
    }

	if( "admin" === $scope.role || "manager" === $scope.role || "hr" === $scope.role) {  
	    var ToDoList = [];
	    // CODE TO FETCH COMPLETE LIST FOR ADMIN
	    var todolistJson = {};
	    todolistJson.owner_id = SessionService.currentUser._id;
        todolistJson.role = $scope.role;
	    $http.post("/api/todoListForCalendar", todolistJson).then(function(response){
		if ( "200" === response.data.status_code) {
			if(response.data.data.length){
			    response.data.data.forEach(function(elem, key){
			    	if(elem.assign_to.length){
				    	elem.todo_items.forEach(function(el,ke){
				    		//var calendaritemcolor = (el.completion_date) ? "bg-completeCalendar" : "bg-dueToDoCalendar";
				    		var objectMatch = {matchDate:el.due_date, empId:elem.assign_to[0].user_id._id};
				    		var objectToPush = {start:moment(el.due_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), matchDate:el.due_date, empId:elem.assign_to[0].user_id._id, TaskItemId:el._id, TaksListId : elem._id, title:elem.assign_to[0].user_id.employeeid+' - '+elem.assign_to[0].user_id.last_name+', '+elem.assign_to[0].user_id.first_name, className: "bg-calendar"};
				    		if(ToDoList.length){
				    		 _.each(ToDoList, function(data, idx) { 
				    		 	if(_.isMatch(data, objectMatch)){
				    		 		ToDoList.splice(idx,1);
				    		 	}
				    		 });
				    		}
				    		ToDoList.push(objectToPush);
				    	});
			    	}
				});
			}
		}
		else {
		    toastr.error("Unable to fetch list this time!!");
		}
		var calendar = $('#calendar').fullCalendar({                                           
		    events : ToDoList,
		    eventClick: function (calEvent, jsEvent, view) {
				$scope.$apply(function () {
					$scope.employeeId = calEvent.empId;
					$scope.empname = calEvent.title;
					$scope.due_date = calEvent.matchDate;
					$scope.due_date_db = calEvent.start;
					$http.post("/api/employeeToDoForThisDate",{employeeid:$scope.employeeId, due_date:$scope.due_date}).then(function(innerresponse){
					    if ( "200" === innerresponse.data.status_code) {
						    if (typeof innerresponse.data!="undefined") {
							    $scope.todoData = innerresponse.data.data;
						    }
					    }
					}); 
				});
				$modal.modal();
		    }
		});
	    })
	}

	if("employee" === $scope.role){
		var ToDoList = [];
	    var todolistJson = {};
	    todolistJson.owner_id = SessionService.currentUser._id;
        todolistJson.role = $scope.role;
	    $http.post("/api/todoListForEmployeeCalendar", todolistJson).then(function(response){
		if ( "200" === response.data.status_code) {
			if(response.data.data.length){
			    response.data.data.forEach(function(elem, key){
			    	if(elem.assign_to.length){
				    	elem.todo_items.forEach(function(el,ke){
				    		//var calendaritemcolor = (el.completion_date) ? "bg-completeCalendar" : "bg-dueToDoCalendar";
				    		var objectMatch = {matchDate:el.due_date, empId:elem.assign_to[0].user_id._id};
				    		var objectToPush = {start:moment(el.due_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), matchDate:el.due_date, empId:elem.assign_to[0].user_id._id, TaskItemId:el._id, TaksListId : elem._id, title:elem.assign_to[0].user_id.employeeid+' - '+elem.assign_to[0].user_id.last_name+', '+elem.assign_to[0].user_id.first_name, className: "bg-calendar"};
				    		if(ToDoList.length){
				    		 _.each(ToDoList, function(data, idx) { 
				    		 	if(_.isMatch(data, objectMatch)){
				    		 		ToDoList.splice(idx,1);
				    		 	}
				    		 });
				    		}
				    		ToDoList.push(objectToPush);
				    	});
			    	}
				});
			}
		}
		else {
		    toastr.error("Unable to fetch list this time!!");
		}
		var calendar = $('#calendar').fullCalendar({                                           
		    events : ToDoList,
		    eventClick: function (calEvent, jsEvent, view) {
				$scope.$apply(function () {
					$scope.employeeId = calEvent.empId;
					$scope.empname = calEvent.title;
					$scope.due_date = calEvent.matchDate;
					$scope.due_date_db = calEvent.start;
					$http.post("/api/employeeToDoForThisDate",{employeeid:$scope.employeeId, due_date:$scope.due_date}).then(function(innerresponse){
					    if ( "200" === innerresponse.data.status_code) {
						    if (typeof innerresponse.data!="undefined") {
							    $scope.todoData = innerresponse.data.data;
						    }
					    }
					}); 
				});
				$modal.modal();
		    }
		});
	    })
	}
});