angular.module("app").controller("schCalendarController", function($scope, $http, $q, sidepanelactiveService, SessionService, moment) {
	var $modal = $('#event-modal');
	$scope.showloader = true;
	$scope.timeslots = ["Morning","Afternoon","Night","Late Night"];
	$scope.availtype = ["Yes","Maybe","No","Screwed"];
	sidepanelactiveService.test();
	$scope.empschedule = {};
	$scope.haveResult = false;
	$scope.twentyfour_to_twelve = function(ti){
		if(ti){
			ti = ti.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [ti];
			if (ti.length > 1) { // If time format correct
				ti = ti.slice (1);  // Remove full string match value
				ti[5] = +ti[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
				ti[0] = +ti[0] % 12 || 12; // Adjust hours
			}
			return ti.join (''); // return adjusted time or original string
		}
	}
	var calendar = $('#calendar').fullCalendar({                                           
	    dayClick: function (calEvent, jsEvent, view) {
			$scope.$apply(function (){
				$scope.showloader = true;
				$scope.employeeDaySchedule = {};
				$scope.employeeDayAvail = {};
				var currDate = moment(calEvent._d).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
				var currIsoDate = currDate.toISOString();
				$scope.clickedDateOld = new Date(currIsoDate);
				$scope.clickedDate = $scope.clickedDateOld.setDate($scope.clickedDateOld.getDate()+1);
				$scope.sendweekday = new Date($scope.clickedDate).toISOString();
				$http.post("/api/dayScheduleForAllEmp",{weekDay:$scope.sendweekday, location : SessionService.currentUser.location}).then(function(innerresponse){
				    if ( 200 === innerresponse.status ) {
					    if (typeof innerresponse.data.data!="undefined") {
						    $scope.employeeDaySchedule = innerresponse.data.data;
					    }
					    $http.post("/api/dayAvailabilityForAllEmp",{weekDay:$scope.sendweekday, location : SessionService.currentUser.location}).then(function(innerresponse){
						    if ( 200 === innerresponse.status ) {
						    	$scope.haveResult = true;
						    	$scope.showloader = false;
							    if (typeof innerresponse.data.data!="undefined") {
								    $scope.employeeDayAvail = innerresponse.data.data;
								    angular.forEach($scope.employeeDayAvail,function(emp){
								    		emp.morningTextclass = (emp.is_morning_scheduled_force) ? emp.is_morning_scheduled_type+'forced' : ((emp.is_morning_scheduled_type) ? emp.is_morning_scheduled_type :'')
								    		emp.afternoonTextclass = (emp.is_afternoon_scheduled_force) ? emp.is_afternoon_scheduled_type+'forced' : ((emp.is_afternoon_scheduled_type) ? emp.is_afternoon_scheduled_type :'');
								    		emp.nightTextclass = (emp.is_night_scheduled_force) ? emp.is_night_scheduled_type+'forced' : ((emp.is_night_scheduled_type) ? emp.is_night_scheduled_type :'');
								    		emp.latenightTextclass = (emp.is_late_night_scheduled_force) ? emp.is_late_night_scheduled_type+'forced' : ((emp.is_late_night_scheduled_type) ? emp.is_late_night_scheduled_type :'');
								    		var shiftsArr = ['morning','afternoon', 'night', 'latenight'];
								    		for (var j = 0; j < $scope.timeslots.length; j++) {
									    		if($scope.employeeDaySchedule.length){
									    			angular.forEach($scope.employeeDaySchedule, function(empSch){
														if((empSch.timeslot === $scope.timeslots[j]) && (empSch.employee_id._id === emp.employee_id._id)){
															if($scope.timeslots[j] == "Late Night"){
																emp['LateNightSchedule'] = empSch;
															}else{
																emp[$scope.timeslots[j]+'Schedule'] = empSch;
															}
														}
													});
									    		}
								    		}
								    		angular.forEach(shiftsArr, function(sft){
								    			var sftClass = sft+'Textclass'
								    			if(emp[sftClass] == 'yes'){
								    				emp[sft+'sortOrder'] = 1;
									    		}
									    		if(emp[sftClass] == 'maybe'){
								    				emp[sft+'sortOrder'] = 2;
									    		}
									    		if(emp[sftClass] == 'no'){
								    				emp[sft+'sortOrder'] = 3;
									    		}
									    		if(emp[sftClass] == 'yesforced'){
								    				emp[sftClass] = 'yes';
								    				emp[sft+'sortOrder'] = 1;
									    		}
									    		if(emp[sftClass] == 'maybeyesforced'){
								    				emp[sft+'sortOrder'] = 5;
									    		}
								    		})							    		
								    });
							    }
						    }
						}); 

				    }
				}); 
				//Fetch availability for dayClick
			});
			$modal.modal();
	    }
	});
	$scope.viewScheduleInfo = function(empId, shift){
		$scope.selectedSchedule = {};
		if($scope.employeeDaySchedule){
			angular.forEach($scope.employeeDaySchedule, function(empSch){
				if((empSch.timeslot === shift) && (empSch.employee_id._id === empId)){
					$scope.selectedSchedule = empSch;
				}
			});
		}
	}
});