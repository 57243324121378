angular.module("app").controller("AddAccountController", function($rootScope, $scope, states, patterns, $filter, SessionService, $http, $location, $window, messages, moment) {
    $window.scrollTo(0, 0);
    $scope.user = SessionService.currentUser;
    $scope.event = {};
    $scope.gridData = {};
    $scope.paygridData = {};
    $scope.gridDataST = {};
    $scope.gridDataET = {};
    $scope.states = states.statelist;
    $scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $scope.timeslots = ["Morning", "Afternoon", "Night", "Late Night"];
    $scope.shiftData = [];
    $scope.shiftsArray = [];
    $scope.daysArr = [];
    $scope.showRevenueAndAmount = true;
    if(SessionService.currentUser.roles[0] == 'manager'){
        $scope.showRevenueAndAmount = false;
    }
    $scope.event = {
        is_active : "yes",
        revenue_type : "per_car",
        ticket_control : "yes",
        text_reminder : "yes",
        event_data: {on_call:"no"}
    };
   
    $scope.event.is_repeat = true;
    $scope.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    $scope.eventnamePattern = /^[a-zA-Z0-9\'\#\@\_\-\`]+( [a-zA-Z0-9\'\#\@\_\-\`]+)*$/;
    $scope.addressPattern = patterns.address;
    $scope.addressPatternError = patterns.addressPatternError;
    $scope.AddShift = function(week, tmsl, startshift, endshift) {
        var nestedObj = {};
        if (undefined == startshift) {
            toastr.error("Please enter start time.");
            return false;
        }
        if (undefined == endshift) {
            toastr.error("Please enter end time.");
            return false;
        }
        if (undefined !== startshift) {
            if ($scope.shiftData.length) {
                var filtereddata = $filter('filter')($scope.shiftData, {
                    day: week,
                    timeslot: tmsl
                }, true)[0];
                if (filtereddata) {
                    _.each($scope.shiftData, function(data, idx) {
                        if (_.isEqual(data, filtereddata)) {
                            mainArrIndex = idx;
                        }
                    });
                    var found = $filter('filter')(filtereddata.shifts, {
                        'startShiftTime': startshift,
                        'endshiftTime': endshift
                    }, true);
                }
            }
            if ($scope.shiftData.length) {
                for (var j = 0; j < $scope.shiftData.length; j++) {
                    if ($scope.shiftData[j].day == week && $scope.shiftData[j].timeslot == tmsl) {
                        $scope.shiftsArray = $scope.shiftData[j].shifts;
                        $scope.shiftData.splice(j, 1);
                        $scope.shiftsArray.push({
                            'startShiftTime': startshift,
                            'endshiftTime': endshift
                        });
                        $scope.shiftsArray = _.sortBy($scope.shiftsArray, 'startShiftTime');
                        nestedObj = {
                            'day': week,
                            'timeslot': tmsl,
                            'shifts': $scope.shiftsArray
                        };
                        $scope.shiftData.push(nestedObj);
                        toastr.success(messages.shiftAddMessage,{"timeOut":500});
                        break;
                    }

                    if (j == $scope.shiftData.length - 1) {
                        $scope.shiftsArray = [];
                        $scope.shiftsArray.push({
                            'startShiftTime': startshift,
                            'endshiftTime': endshift
                        });
                        $scope.shiftsArray = _.sortBy($scope.shiftsArray, 'startShiftTime');
                        nestedObj = {
                            'day': week,
                            'timeslot': tmsl,
                            'shifts': $scope.shiftsArray
                        };
                        $scope.shiftData.push(nestedObj);
                        toastr.success(messages.shiftAddMessage,{"timeOut":500});
                        break;
                    }
                }
            } else {
                $scope.shiftsArray = [];
                $scope.shiftsArray.push({
                    'startShiftTime': startshift,
                    'endshiftTime': endshift
                });
                $scope.shiftsArray = _.sortBy($scope.shiftsArray, 'startShiftTime');
                nestedObj = {
                    'day': week,
                    'timeslot': tmsl,
                    'shifts': $scope.shiftsArray
                };
                $scope.shiftData.push(nestedObj);
                toastr.success(messages.shiftAddMessage,"",{"timeOut":500});
            }
        } else {
            toastr.error(messages.TimeSelect);
        }
    }

    $scope.RemoveShift = function(week, tmsl, shift) {
        var filtereddata = $filter('filter')($scope.shiftData, {
            day: week,
            timeslot: tmsl
        })[0];
        _.each($scope.shiftData, function(data, idx) {
            if (_.isEqual(data, filtereddata)) {
                mainArrIndex = idx;
            }
        });
        var found = $filter('filter')(filtereddata.shifts, shift, true);
        if (found.length) {
            _.each(filtereddata.shifts, function(data, idx) {
                if (_.isEqual(data, shift)) {
                    index = idx;
                    $scope.shiftData[mainArrIndex].shifts.splice(index, 1);
                    if($scope.shiftData[mainArrIndex].shifts.length == 0){
                        $scope.shiftData.splice(mainArrIndex,1);
                    }
                    toastr.success("Shift is successfully removed.","",{"timeOut":500});
                }
            });
        } 
    }

    $scope.ClearAllShift = function(week, tmsl) {
        var filtereddata = $filter('filter')($scope.shiftData, {
            day: week,
            timeslot: tmsl
        }, true)[0];
        _.each($scope.shiftData, function(data, idx) {
            if (_.isEqual(data, filtereddata)) {
                mainArrIndex = idx;
                $scope.shiftData.splice(mainArrIndex, 1);
                toastr.success("All shifts are cleared for this slot and day.");
            }
        });
    }


    $scope.fetchshiftdata = function(week, tmsl) {
        return $filter('filter')($scope.shiftData, {
            day: week,
            timeslot: tmsl
        }, true)[0] || '';
    }

    $scope.addEvent = function(event) {
        $scope.buttonDisabled = true;
        $rootScope.editeventForm = 'end';
        if ($scope.eventForm.$invalid) {
            $scope.buttonDisabled = false;
            toastr.error("Please fill all the required fields.");
            angular.element("#eventname").focus();
            angular.element("input.ng-invalid").css("border-color", "red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
        } else {
            var StartDate = new Date(event.start_date);
            var EndDate = new Date(event.end_date);
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
            if (diff < 0) {
                toastr.error(messages.shiftEndDateGreater);
                $scope.buttonDisabled = false;
                return false;
            }
            if(!event.location_address.formatted_address){
                angular.element("#locationAddress").focus();
                angular.element("#locationAddress").css("border-color","red");
                toastr.error('Please select location address from list.');
                $scope.buttonDisabled = false;
                return false;
            }
            if(!event.billing_address.formatted_address){
                angular.element("#billingAddress").focus();
                angular.element("#billingAddress").css("border-color","red");
                toastr.error('Please select billing address from list.');
                $scope.buttonDisabled = false;
                return false;
            }

            if ($scope.shiftData.length > 0)
            event.shiftData = $scope.shiftData;
            event.event_data.on_call = event.event_data.on_call === "yes" ? true : false;
            event.is_active = event.is_active === "yes" ? true : false;
            event.ticket_control = event.ticket_control === "yes" ? true : false;
            event.text_reminder = event.text_reminder === "yes" ? true : false;
            event.set_up_person = $scope.user._id;

            if (event.start_date === undefined)
                delete event.start_date;
            else
                event.start_date = new Date(event.start_date);

            if (event.end_date === undefined)
                delete event.end_date;
            else
                event.end_date = new Date(event.end_date);
            var postdata = [];
            var paypostdata = [];
            var grid_data = $scope.gridData;
            var pay_grid_data = $scope.paygridData;
            for (var i = 0; i < $scope.weekdays.length; i++) {
                var nestedObj = {};
                if(grid_data[$scope.weekdays[i]]){
                    for (var j = 0; j < $scope.timeslots.length; j++) {
                        nestedObj = {'day' : $scope.weekdays[i], 'timeslot' :$scope.timeslots[j], 'price' : grid_data[$scope.weekdays[i]][$scope.timeslots[j]]};
                        postdata.push(nestedObj);
                    }
                }
                var paynestedObj = {};
                if(pay_grid_data[$scope.weekdays[i]]){
                    for (var j = 0; j < $scope.timeslots.length; j++) {
                        paynestedObj = {'day' : $scope.weekdays[i], 'timeslot' :$scope.timeslots[j], 'price' : pay_grid_data[$scope.weekdays[i]][$scope.timeslots[j]]};
                        paypostdata.push(paynestedObj);
                    }
                }
            }
            event.parking_prices = postdata;
            event.pay_rates = paypostdata;
            if ($scope.user._id) {
                event.location_address.address = event.location_address.formatted_address;
                event.location_address.lat = event.location_address.geometry.location.lat();
                event.location_address.lng = event.location_address.geometry.location.lng();
                event.billing_address.address = event.billing_address.formatted_address;
                event.billing_address.lat = event.billing_address.geometry.location.lat();
                event.billing_address.lng = event.billing_address.geometry.location.lng();
                event.location = SessionService.currentUser.location;
                if(event.start_date){
                    event.start_date = moment(event.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();    
                }
                if(event.end_date){
                    event.end_date = moment(event.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();    
                }
                
                $http.post("/api/events", event).then(function(response) {
                    if (response.data.status_code === "200") {
                        toastr.success("Account added successfully.");
                        $scope.eventForm.$setPristine();
                        $scope.event = {};
                        $scope.event = {
                            is_active: "yes",
                            ticket_control: "yes",
                            text_reminder : "yes"
                        };
                        $location.path("/reoccurringaccount");

                    } else {
                        toastr.error("Something went wrong, check details");
                        $scope.buttonDisabled = false;
                        if (event.start_date !== undefined)
                            $scope.event.start_date = (event.start_date.getMonth() + 1) + "/" + event.start_date.getDate() + "/" + event.start_date.getFullYear();
                        if (event.end_date !== undefined)
                            $scope.event.end_date = (event.end_date.getMonth() + 1) + "/" + event.end_date.getDate() + "/" + event.end_date.getFullYear();
                            $scope.event.is_active = event.is_active === true ? "yes" : "no";
                            $scope.event.ticket_control = event.ticket_control === true ? "yes" : "no";
                            $scope.event.text_reminder = event.text_reminder === true ? "yes" : "no";
                            $scope.event.event_data.on_call = event.event_data.on_call === true ? "yes" : "no";
                    }
                });
            }
        }
    };

    $scope.cleardata = function() {
        toastr.info("You cleared the data.");
        angular.element("input.ng-invalid").css("border", "1px solid #d3d3d3");
        $scope.eventForm.$setPristine();
        $scope.event = {};
        $scope.event = {
            is_active: "yes",
            ticket_control:"yes",
            text_reminder : "yes",
            event_data: {on_call:"no"}
        };
        $scope.shiftData = [];
        $scope.shiftsArray = [];
    };

    $scope.generate_random=function(){
        if($scope.event.accountId){
            $scope.showloader = true;
            $http.post("/api/chk_random_account", {num:$scope.event.accountId}).then(function(response){
                $scope.showloader = false;
                if(response.data.status_code=="200"){
                    angular.element("#accNo").css("border-color","#d3d3d3");
                    $scope.accnoVerifyClass = "fa-check c-green";
                    return 1;
                }
                else{
                    $scope.accnoVerifyClass = "fa-times c-red";
                    angular.element("#accNo").css("border-color","red");
                    return 0;
                }
            });
        }
        else{
            toastr.error("Please enter account id.");
        }
    }; 

    //populate data of location to billing
    $scope.populate = function() {
        if ($scope.event.location_address && $scope.populatecheck) {
            $scope.event.billing_address = angular.copy($scope.event.location_address);
        }
        else{
            $scope.event.billing_address = {};
        }
    };
    //end

    $scope.select_day = function() {
        $rootScope.editeventForm = 'start';
        var a = $scope.event.start_date;
        var b = $scope.event.end_date;
        //var diff = moment(b, "MM-DD-YYYY").diff(moment(a, "MM-DD-YYYY"), 'days')
        $scope.daysArr = []
        for (var i = 0; i <= 6; i++) {
            var c = moment(a).add(i, 'days').format("dddd");
            if ($scope.daysArr.indexOf(c) == -1) {
                $scope.daysArr.push(c);
            }
        }
    }
    
});