angular.module("app").controller("EmpToSchController", function($rootScope, $scope, $filter, ports, $location, SessionService, $http, $q, UserService, $timeout, getweekService, NgTableParams) {
    $scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $scope.timeslots = ["Morning", "Afternoon", "Night", "Late Night"];
    $scope.user = SessionService.currentUser;
    $scope.currpage =  $location.absUrl().split('/')[3];
    $scope.shift = 'Morning';
    $scope.start_end = '';
    $scope.searchText = {};
    $scope.haveResult = false;
    var currWeekDates = getweekService.getCurrWeek();
    $scope.empweekdate = $filter('date')(currWeekDates[0], 'MM/dd/yyyy') + ' To '+ $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
    $scope.$watch(
        "empweekdate",
        function handleWeekChange() {
            $scope.eventAccountList = [];
            $scope.emplist = [];
            $scope.days = [];
            $scope.$on('startendString', function (event, args) {
                $scope.start_end = args.startendstringJson;
            });
            if ($scope.start_end) {
                 var startEndArr = $scope.start_end.split(' / ');
                 $scope.startDate = startEndArr[0];
                 $scope.endDate = startEndArr[1];
            }
            else{
                $scope.startDate = currWeekDates[0];
                $scope.endDate = currWeekDates[1];
            }
            if ($scope.currpage == 'emptoschedule') {
                $scope.showloader = true;
                $scope.empAvailbilityPage = 1;
                $scope.totalEmppages = 1;
                $scope.busy = false;
                $scope.days = [];
                $scope.fetchEmpList = [];
                $http.get("/api/getAllInitalzips").then(function(response) {
                    if(response.status == 200){
                        $scope.AllInitials = response.data.data;
                        $scope.fetchEmployeeAvailability();
                    }
                });
            }
        }
    );
    $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    $scope.fetchEmployeeAvailability = function(){
        $scope.showloader = false;
        if($scope.searchEmployeeId){
            $scope.employeeId = $scope.searchEmployeeId;
        }else{
            $scope.employeeId = '';
        }
        var promiseOne = $http.post("/api/getAvailabilitylist", {
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            "to": $scope.endDate,
            "from": $scope.startDate,
            "searchEmpId" : $scope.employeeId,
            "location":SessionService.currentUser.location
        });
        var promiseTwo = $http.post("/api/getSchedulelist", {
            owner_id: SessionService.currentUser._id,
            role: SessionService.currentUser.roles[0],
            "to": $scope.endDate,
            "from": $scope.startDate,
            "searchEmpId" : $scope.employeeId,
            "location":SessionService.currentUser.location
        });
        $q.all([promiseOne, promiseTwo]).then(function(data){
            $scope.availabilityData = data[0].data.Availabilitydata;
            $scope.scheduleData = data[1].data.scheduledata;
            $scope.availabilityData_status = data[0].status;
            $scope.scheduleData_status = data[1].status;
            var x = 0;
            angular.forEach($scope.availabilityData, function(item) {
                angular.forEach($scope.scheduleData, function(schitem) {
                    if(schitem.date === item.date){
                        item.schedules = schitem.result;
                    }
                }); 
                if(x === $scope.availabilityData.length-1){
                    $scope.updatedAvailData = $scope.availabilityData;
                    $scope.fetchscheduleReportOnChangeShift($scope.shift);
                }
                x++;   
            });
        });
    }

    $scope.fetchEmployeeAvail = function(){
        angular.forEach($scope.modernWebBrowsers, function( value, key ) {
            if ( value.ticked === true ) {
               $scope.empInfo = value;
               $scope.searchEmployeeId = $scope.empInfo._id;
               $scope.showloader = true;
               $scope.empAvailbilityPage = 1;
               $scope.totalEmppages = 1;
               $scope.busy = false;
               $scope.days = [];
               $scope.employeeMarkAvail = [];
               $scope.fetchEmpList = [];
               $scope.fetchEmployeeAvailability();
            }
        });
    }

    $scope.closeEmpAvailSearch = function(){
        $scope.searchEmployeeId = '';
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if ( value.ticked === true ) {
                   $scope.empInfo = value;
                   $scope.searchEmployeeId = $scope.empInfo._id;
                   $scope.showloader = true;                  
                   $scope.fetchEmpList = [];
                }
            });
        }
        if(!$scope.searchEmployeeId){
               $scope.searchEmployeeId = '';
               $scope.showloader = true;
               $scope.fetchEmpList = [];
               $scope.fetchEmployeeAvailability();
        }
    }

    $scope.fetchscheduleReportOnChangeShift = function(){
        $scope.days = [];
        if ($scope.updatedAvailData.length) {
            angular.forEach($scope.updatedAvailData, function(item) {
                $scope.emplist = [];
                angular.forEach(item.result, function(emp) {

                    angular.forEach(item.schedules, function(empSch) {
                        if(emp.employee_id === empSch.employee_id){
                            if(empSch.timeslot == 'Morning'){
                                emp = _.omit(emp, 'is_morning_scheduled_type');
                                emp = _.omit(emp, 'is_morning_scheduled');
                            }
                            if(empSch.timeslot == 'Afternoon'){
                                emp = _.omit(emp, 'is_afternoon_scheduled_type');
                                emp = _.omit(emp, 'is_afternoon_scheduled');
                            }
                            if(empSch.timeslot == 'Night'){
                                emp = _.omit(emp, 'is_night_scheduled_type');
                                emp = _.omit(emp, 'is_night_scheduled');
                            }
                            if(empSch.timeslot == 'Late Night'){
                                emp = _.omit(emp, 'is_late_night_scheduled_type');
                                emp = _.omit(emp, 'is_late_night_scheduled');
                            }
                        }
                    });

                    if ($scope.shift == 'Morning') {
                        var textclass = (emp.is_morning_scheduled_force) ? emp.is_morning_scheduled_type+'forced' : ((emp.is_morning_scheduled_type) ? emp.is_morning_scheduled_type :'')
                    }
                    if ($scope.shift == 'Afternoon') {
                        var textclass = (emp.is_afternoon_scheduled_force) ? emp.is_afternoon_scheduled_type+'forced' : ((emp.is_afternoon_scheduled_type) ? emp.is_afternoon_scheduled_type :'');
                    }
                    if ($scope.shift == 'Night') {
                        var textclass = (emp.is_night_scheduled_force) ? emp.is_night_scheduled_type+'forced' : ((emp.is_night_scheduled_type) ? emp.is_night_scheduled_type :'');
                    }
                    if ($scope.shift == 'LateNight') {
                        var textclass = (emp.is_late_night_scheduled_force) ? emp.is_late_night_scheduled_type+'forced' : ((emp.is_late_night_scheduled_type) ? emp.is_late_night_scheduled_type :'');
                    }
                    if(textclass == 'yes'){
                        var textOrder = 1;
                    }else if(textclass == 'maybe'){
                        var textOrder = 2;
                    }else if(textclass == 'no'){
                        var textOrder = 3;
                    }else if(textclass == 'yesforced'){
                        //textclass = 'yes';
                        //var textOrder = 1;
                        var textOrder = 5;
                    }else if(textclass == 'maybeforced'){
                        var textOrder = 6;
                    }
                    var found = $filter('filter')($scope.emplist, {
                        fname: emp.empFirstName[0],
                        lname: emp.empLastName[0],
                        empRandomGeneratedID_id: emp.employeeid[0],
                        fullname : emp.empFirstName[0]+' '+emp.empLastName[0],
                        textclass: textclass,
                        textOrder : textOrder,
                        zipcode : emp.zipcode[0]
                    }, true);
                    if (!found.length) {
                        if(textclass){
                            $scope.emplist.push({
                                fname: emp.empFirstName[0],
                                lname: emp.empLastName[0],
                                empRandomGeneratedID_id: emp.employeeid[0],
                                fullname : emp.empFirstName[0]+' '+emp.empLastName[0],
                                textclass: textclass,
                                textOrder : textOrder,
                                zipcode : emp.zipcode[0]
                            });
                        }
                    }
                });
                if($scope.emplist.length){
                    var insertedjson = {
                        day: moment(item.date).format("dddd"),
                        employees: $scope.emplist
                    };
                }
                $scope.days.push(insertedjson);
            });
        }
    }

    $scope.matchZipInInitial = function(zipvalue){
        var zipVal = '';
        if(zipvalue){
            for(i = 0; i < $scope.AllInitials.length; i++){
                if($scope.AllInitials[i].zipcodes.indexOf(zipvalue) >= 0){
                    zipVal = $scope.AllInitials[i].initial;
                    break;
                }
            }
        }
        return zipVal;
    }
    $scope.filterByClass = function(empdata){
        if(empdata.textclass !== 'false'){
            return empdata;
        }
    }
    
});

angular.module("app").filter('numberFixedLen', function(){
    return function (n, len) {
       var num = parseInt(n, 10);
       len = parseInt(len, 10);
       if (isNaN(num) || isNaN(len)) {
           return n;
       }
       num = '' + num;
       while (num.length < len) {
           num = '0' + num;
       }
       return num;
    };
});