angular.module("app").factory("getweekService", function(moment, SessionService) {

  return {
    getPrevPrevWeek: function(start) {
      //Calcing the starting point
      start = start || 0;
      var today = new Date(new Date().setHours(0, 0, 0, 0));
      var day = today.getDay() - start;
      var date = today.getDate() - day;
  
      // Grabbing Start/End Dates
      var EndDate = new Date(today.setDate(date));
      var StartDate = new Date(today.setDate(date - 6));
      //return [StartDate, EndDate];
      var startDateWithZone = moment(StartDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
      var endDateWithZone = moment(EndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
      return [startDateWithZone, endDateWithZone];
    },
    getPrevWeek: function(start) {
      //Calcing the starting point
      start = start || 0;
      var today = new Date(new Date().setHours(0, 0, 0, 0));
      var day = today.getDay() - start;
      var date = today.getDate() - day;
  
      // Grabbing Start/End Dates
      var EndDate = new Date(today.setDate(date));
      var StartDate = new Date(today.setDate(date - 6));
      //return [StartDate, EndDate];
      var startDateWithZone = moment(StartDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
      var endDateWithZone = moment(EndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
      return [startDateWithZone, endDateWithZone];
    },
    getCurrWeek: function() {
      var today = new Date(new Date().setHours(0, 0, 0, 0));
      var day = today.getDay();
      var date = today.getDate() - day;
      // Grabbing Start/End Dates
      var StartDate = new Date(today.setDate(date));
      console.log(StartDate);
      var date = StartDate.getDate();
      var EndDate = new Date(today.setDate(date + 6));
      var startDateWithZone = moment(StartDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
      var endDateWithZone = moment(EndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
      console.log('.......',startDateWithZone, endDateWithZone);

      return [startDateWithZone, endDateWithZone];
      //return [StartDate, EndDate];
    },
    getNextWeek: function(start) {
      //Calcing the starting point
      start = start || 0;
      var today = new Date(new Date().setHours(0, 0, 0, 0));
      var day = today.getDay() - start;
      var date = today.getDate() - day;
  
      // Grabbing Start/End Dates
      var StartDate = new Date(today.setDate(date));
      var date = StartDate.getDate();
      var EndDate = new Date(today.setDate(date + 6));
      //return [StartDate, EndDate];
      var startDateWithZone = moment(StartDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
      var endDateWithZone = moment(EndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
      return [startDateWithZone, endDateWithZone];
    }
  };
  
});