angular.module("app").controller("EvalquestionListController", function($scope, $http, $filter, $location, SessionService, NgTableParams, moment){
    $scope.visibility = true;
    $scope.haveResult = true;
    $scope.sortType='';
    $scope.sortOrder=undefined;
    $scope.searchText={};
    $scope.searchText.val='';
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.selectenable = false;
    $scope.checkAll = {};
    $scope.showloader = true;
    $scope.selectedQuestlist = {
        questlistArr: []
    };
    
    $scope.selectedAction = 0;
    $scope.pageNum = 1;
    $scope.clearQuestList = function(){
        $scope.questtitle = '';
        $scope.buttonDisabled = false;
    }
    $scope.questionList = function() {
      $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType='created_date';
            $scope.sortOrder=-1;
        }    
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/Eval/getquestionlist", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0]
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.allQuestions = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allQuestions;
                })
            }
        });
    }

    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.questionList()
              }
          }
    );
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.questionList()
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    $scope.questionList();
    $scope.createQuestion = function(){
        $scope.questionListForm.$setPristine();
        $scope.buttonDisabled = true;
        var questionInfo = {};
        questionInfo.title = $scope.questtitle;
        questionInfo.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        questionInfo.created_by = SessionService.currentUser._id;
        $http.post("/api/Eval/addQuestion", questionInfo).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Question is added successfully in list.");
                angular.element('#modal-basic').modal('hide');
                $scope.tableParams.reload();
                $scope.questionList();
            }else{
                toastr.error("Somthing Wrong!");
                $scope.buttonDisabled = false;
            }
        });
    }
    /*change active/inactive on click*/
    $scope.update_status=function($index,data) {
        $http.post("/api/Eval/update_Question_status", {"data":data}).then(function(response) {
            if(response.data.status_code == "200"){
                $scope.questionList();
                toastr.success("Successfully updated.");
            }
        })
    }
    /*end*/

    $scope.checkAll.val= false;
    $scope.selectAllGroup = function(check) {
            $scope.flag = {};
            $scope.flag.enable = false;
            if (check) {
                $scope.selectedQuestlist.questlistArr = $scope.allQuestions.map(function(item) {
                    return item._id;
                });
                $scope.selectenable = true;
                console.log("$scope.selectenabletrue", $scope.selectenable);
            } else {
                $scope.selectedQuestlist.questlistArr = [];
                $scope.checkAll.val= false;
                $scope.selectenable = false;
            }
    }
   
    $scope.delete_questlists=function(){
        $http.post("/api/Eval/deleteQuestList", {
            "questid": $scope.selectedQuestlist.questlistArr
        }).then(function(response) {
            $scope.allQuestions = response.data.data;
            angular.element('#selectedAction').val(0);
            $scope.questionList();
            toastr.success("Successfully Deleted.");
            $scope.selectedQuestlist.questlistArr = [];
            $scope.checkAll.val = false;
            $('#confirm_del').hide();
        })
    }

    $scope.cnfrm_pwd = function(questlist){
        $('#confirm_del').show();
    }
    
    $scope.performAction = function() {
        $scope.selectedAction = selectedAction.value;
        if ($scope.selectedAction == 0) {
            toastr.error("Please select some selection.");
        }else if ($scope.selectedQuestlist.questlistArr.length == 0) {
            toastr.error("Please select atleast one checkbox.");
        }
        if ($scope.selectedAction != 0 && $scope.selectedAction == 1 && $scope.selectedQuestlist.questlistArr.length > 0) {
            $scope.enbl = true;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 2 && $scope.selectedQuestlist.questlistArr.length > 0) {
            $scope.enbl = false;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 3 && $scope.selectedQuestlist.questlistArr.length > 0) {
            $scope.cnfrm_pwd($scope.selectedQuestlist.questlistArr);
        }

        if( $scope.selectedQuestlist.questlistArr.length > 0 && $scope.selectedAction > 0 && $scope.selectedAction < 3){
            $http.post("/api/Eval/enablequestions", {
                'enabled': $scope.enbl,
                'allChecked': $scope.selectenable,
                "questions": $scope.selectedQuestlist.questlistArr
            }).then(function(response) {
                $scope.allQuestions = response.data.data;
                angular.element('#selectedAction').val(0);
                $scope.questionList();
                toastr.success("Successfully updated.");
                $scope.selectedQuestlist.questlistArr = [];
                $scope.checkAll.val = false;
            })
        }
    }

    $scope.updateQuestion = function(questId, title){
      $scope.updatelist = {};
      $scope.updatelist.questtitle = title;
      $scope.updatelist.questid = questId;
    }

    $scope.questionUpdateSubmit = function(data){
         var updatedData = {};
         updatedData.questtitle = data.questtitle;
         updatedData.questid = data.questid;
         $http.post("/api/Eval/editQuestion", updatedData).then(function(response) {
            if(response.data.status_code == "200"){
                toastr.success("Successfully updated.");
                angular.element('#modal-update').modal('hide');
                $scope.tableParams.reload();
                $scope.questionList();
            }
         })
    }
});