angular.module("app").controller("EditEventController", function($rootScope, $scope, states, patterns, NotificationService, SessionService, $http, $routeParams, $filter, $location, moment) {
    $scope.user = SessionService.currentUser;
    $scope.userrole = SessionService.currentUser.roles[0];
    $scope.id = $routeParams.id;
    $scope.gridData = {};
    $scope.paygridData = {};
    $scope.gridDataST = {};
    $scope.gridDataET = {};
    $scope.states = states.statelist;
    $scope.addressPattern = patterns.address;
    $scope.addressPatternError = patterns.addressPatternError;
    $scope.weekdays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    $scope.timeslots = ["Morning","Afternoon","Night","Late Night"];
    $scope.shiftData = [];
    $scope.shiftsArray = [];
    $scope.removeshiftsArray = [];
    $scope.changelocationAddress = false;
    $scope.changebillingAddress = false;
    $scope.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    $scope.eventnamePattern = /^[a-zA-Z0-9\'\#\@\_\-\`]+( [a-zA-Z0-9\'\#\@\_\-\`]+)*$/;
    $scope.equipPattern = /^[a-zA-Z0-9\,\.\?\!\:\;\"\'\&\_\-]+( [a-zA-Z0-9\,\.\?\!\:\;\"\'\&\_\-]+)*$/;
    $scope.showRevenueAndAmount = true;
    if(SessionService.currentUser.roles[0] == 'manager'){
        $scope.showRevenueAndAmount = false;
    }
    $http.get("/api/getAllLocations").then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.title;
            });
            $scope.modernWebBrowsers = response.data.data;
            $scope.fetchEventDetail();
        }
        else {
            console.log('400');
        }
    });
    $scope.select_day = function() {
            $rootScope.editeventForm = 'start';
            if ($scope.shiftData.length > 0) {
                $scope.shiftData = [];
                $scope.shiftsArray = [];
            }
            var StartDate = new Date($scope.event.start_date);
            var EndDate   = new Date($scope.event.end_date);
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;
            if (!$scope.event.is_repeat && diff<0) {
                 toastr.error('End date should be greater than start date.');
                 angular.element("#enddate").css("border-color","red");
                 angular.element("#startdate").css("border-color","red");
                 return false;
            }else{
                 angular.element("#enddate").css("border-color","#d3d3d3");
                 angular.element("#startdate").css("border-color","#d3d3d3");
            }
            var a = $scope.event.start_date;
            var b = $scope.event.end_date;
            var c = moment(a).add(1, 'days').format("dddd");
            var diff = moment(b, "MM-DD-YYYY").diff(moment(a, "MM-DD-YYYY"), 'days')
            $scope.daysArr = []
            for (var i = 0; i <= diff; i++) {
                var c = moment(a).add(i, 'days').format("dddd");
                if ($scope.daysArr.indexOf(c) == -1) {
                    $scope.daysArr.push(c);
                }
            }
    }
    $scope.AddShift = function(week, tmsl, startshift, endshift){
        var nestedObj = {};
        if (undefined == startshift) {
            toastr.error("Please enter start time.");
            return false;
        }
        if (undefined == endshift) {
            toastr.error("Please enter end time.");
            return false;
        }
        if (undefined !== startshift) {
            if($scope.shiftData.length){
                var filtereddata = $filter('filter')($scope.shiftData, {day:week, timeslot :tmsl}, true)[0];
                if (filtereddata) {
                    _.each($scope.shiftData, function(data, idx) { 
                            if (_.isEqual(data, filtereddata)) {
                               mainArrIndex = idx;
                            }
                    });
                    var found = $filter('filter')(filtereddata.shifts, {'startShiftTime' : startshift, 'endshiftTime' : endshift }, true);
                }
            }
            if ($scope.shiftData.length) {
                for (var j = 0; j < $scope.shiftData.length; j++) {
                    if ($scope.shiftData[j].day == week && $scope.shiftData[j].timeslot == tmsl) {
                        $scope.shiftsArray = $scope.shiftData[j].shifts;
                        $scope.shiftData.splice(j,1);
                        $scope.shiftsArray.push({'startShiftTime' : startshift, 'endshiftTime' : endshift });
                        $scope.shiftsArray = _.sortBy($scope.shiftsArray, 'startShiftTime');
                        nestedObj = {'day' : week, 'timeslot' :tmsl, 'shifts' : $scope.shiftsArray};
                        $scope.shiftData.push(nestedObj);
                        toastr.success("Shift is added successfully.","",{"timeOut":500});
                        break;
                    }
                    
                    if (j == $scope.shiftData.length-1) {
                        $scope.shiftsArray = [];
                        $scope.shiftsArray.push({'startShiftTime' : startshift, 'endshiftTime' : endshift });
                        $scope.shiftsArray = _.sortBy($scope.shiftsArray, 'startShiftTime');
                        nestedObj = {'day' : week, 'timeslot' :tmsl, 'shifts' : $scope.shiftsArray};
                        $scope.shiftData.push(nestedObj);
                        toastr.success("Shift is added successfully.","",{"timeOut":500});
                        break;
                    }
                }
            }else{
                $scope.shiftsArray = [];
                $scope.shiftsArray.push({'startShiftTime' : startshift, 'endshiftTime' : endshift });
                $scope.shiftsArray = _.sortBy($scope.shiftsArray, 'startShiftTime');
                nestedObj = {'day' : week, 'timeslot' :tmsl, 'shifts' : $scope.shiftsArray};
                $scope.shiftData.push(nestedObj);
                toastr.success("Shift is added successfully.","",{"timeOut":500});
            }
        }else{
            toastr.error("Please Select time.");
        }
    }
    
    $scope.RemoveShift = function(week, tmsl, shift){
        var removeObj = {"week" : week, "tmsl" : tmsl, "shift" : shift, "eventId" :$scope.id};
        $scope.removeshiftsArray.push(removeObj);
        var filtereddata = $filter('filter')($scope.shiftData, {day:week, timeslot :tmsl}, true)[0];
        _.each($scope.shiftData, function(data, idx) { 
                if (_.isEqual(data, filtereddata)) {
                   mainArrIndex = idx;
                }
        });    

        var found = $filter('filter')(filtereddata.shifts, shift, true);
        if (found.length) {
            _.each(filtereddata.shifts, function(data, idx) { 
                if (_.isEqual(data, shift)) {
                   index = idx;
                   $scope.shiftData[mainArrIndex].shifts.splice(index,1);
                   if($scope.shiftData[mainArrIndex].shifts.length == 0){
                        $scope.shiftData.splice(mainArrIndex,1);
                   }
                   toastr.success("Shift is successfully removed.","",{"timeOut":500});
                }
            });
        }
        else{
            alert('no');
        }
    }
    
    $scope.ClearAllShift = function(week, tmsl){
        var filtereddata = $filter('filter')($scope.shiftData, {day:week, timeslot :tmsl}, true)[0];
        _.each($scope.shiftData, function(data, idx) { 
                if (_.isEqual(data, filtereddata)) {
                   mainArrIndex = idx;
                   _.each($scope.shiftData[mainArrIndex].shifts, function(da, ix) { 
                        var removeObj = {"week" : week, "tmsl" : tmsl, "shift" : da, "eventId" :$scope.id};
                        $scope.removeshiftsArray.push(removeObj);
                   });
                   $scope.shiftData.splice(mainArrIndex,1);
                   toastr.success("All shifts are cleared for this slot and day.");
                }
        });
    }
    
    $scope.changelocationAdd = function(){
        $scope.changelocationAddress = true;
    }

    $scope.changebillingAdd = function(){
        $scope.changebillingAddress = true;
    }
    
    $scope.fetchshiftdata = function(week, tmsl){
        return $filter('filter')($scope.shiftData, {day:week, timeslot :tmsl}, true)[0] || '';
    }

    $scope.fetchEventDetail = function(){
        $http.get('/api/events/' + $scope.id).success(function(response) {
            if ("200" === response.status_code) {
                if (response.data.is_repeat === false) {
                    var start = response.data.start_date;
                    var a = moment(response.data.start_date).format("MM-DD-YYYY");
                    var b = moment(response.data.end_date).format("MM-DD-YYYY");
                    var diff = moment(b, "MM-DD-YYYY").diff(moment(a, "MM-DD-YYYY"), 'days')
                    $scope.daysArr = []
                    for (var i = 0; i <= diff; i++) {
                        var c = moment(start).add(i, 'days').format("dddd");
                        if ($scope.daysArr.indexOf(c) == -1) {
                            $scope.daysArr.push(c);
                        }
                    }
                }
                if (response.data.start_date) {
                    response.data.start_date = $filter('date')(response.data.start_date, 'MM/dd/yyyy');
                }
                if (response.data.end_date != undefined) {
                    response.data.end_date = $filter('date')(response.data.end_date, 'MM/dd/yyyy');
                }
                if (response.data.event_data.contact_phone != undefined) {
                    response.data.event_data.contact_phone = $filter('tel')(response.data.event_data.contact_phone, false);
                }
                if($scope.userrole == 'admin'){
                    $scope.modernWebBrowsers.forEach(function(elem, key){
                        if(elem._id == response.data.location){
                            elem.ticked = true;
                        }
                    })
                }

                response.data.is_active = response.data.is_active === true ? "yes" : "no";
                response.data.ticket_control = response.data.ticket_control === true ? "yes" : "no";
                response.data.text_reminder = response.data.text_reminder === true ? "yes" : "no";
                response.data.event_data.on_call = response.data.event_data.on_call === true ? "yes" : "no";
                response.data.event_data.equip_assigned = response.data.event_data.equip_assigned === true ? "yes" : "no";

                $scope.event = response.data;
                if ($scope.event.billing_address && $scope.event.billing_address.address){
                    $scope.event.billing_addressstr = $scope.event.billing_address.address;
                    $scope.event.billing_address_lat = $scope.event.billing_address.lat;
                    $scope.event.billing_address_lng = $scope.event.billing_address.lng;
                    $scope.event.billing_address = $scope.event.billing_address.address;
                }
                if ($scope.event.location_address && $scope.event.location_address.address){
                    $scope.event.location_addressstr = $scope.event.location_address.address;
                    $scope.event.location_address_lat = $scope.event.location_address.lat;
                    $scope.event.location_address_lng = $scope.event.location_address.lng;
                    $scope.event.location_address = $scope.event.location_address.address;
                }
                $scope.event.showendtimebox = $scope.event.showendtime;
                if (response.data.shift_template_id.length > 0) {
                    $scope.shiftData = response.data.shift_template_id;
                }
                if (response.data.parking_prices.length > 0) {
                    griddata = [];
                    for (var i = 0; i < $scope.weekdays.length; i++) {
                        var nestedObj = {};
                        for (var j = 0; j < $scope.timeslots.length; j++) {
                            angular.forEach(response.data.parking_prices, function(item) {
                                if (item.day == $scope.weekdays[i] && item.timeslot == $scope.timeslots[j]) {
                                    nestedObj[$scope.timeslots[j]] = item.price;
                                }
                            });
                            if(!nestedObj[$scope.timeslots[j]]) {
                                nestedObj[$scope.timeslots[j]] = 0;
                            }
                        }
                        griddata[$scope.weekdays[i]] = nestedObj;
                    }
                    $scope.gridData = griddata;
                }
                if (response.data.pay_rates.length > 0) {
                    pay_grid_data = [];
                    for (var i = 0; i < $scope.weekdays.length; i++) {
                        var paynestedObj = {};
                        for (var j = 0; j < $scope.timeslots.length; j++) {
                            angular.forEach(response.data.pay_rates, function(item) {
                                if (item.day == $scope.weekdays[i] && item.timeslot == $scope.timeslots[j]) {
                                    paynestedObj[$scope.timeslots[j]] = item.price;
                                }
                            });
                            if(!paynestedObj[$scope.timeslots[j]]) {
                                paynestedObj[$scope.timeslots[j]] = 0;
                            }
                        }
                        pay_grid_data[$scope.weekdays[i]] = paynestedObj;
                    }
                    $scope.paygridData = pay_grid_data;
                }
            }
        });
    }
    
    $scope.updateEvent = function(event) {
        if($scope.userrole == 'admin'){
            $scope.storelocationval = '';
            if($scope.modernWebBrowsers){
                angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                    if ( value.ticked === true ) {
                       $scope.storelocationval = value;
                    }
                });
            }
            event.location = $scope.storelocationval._id;
            if(!$scope.storelocationval){
                toastr.error("Please choose location to continue");
                return;
            }
        }
        $rootScope.editeventForm = 'end';
        if($scope.eventForm.$invalid){
            toastr.error("Please fill all the required fields.");
            angular.element("#eventname").focus();
            angular.element("input.ng-invalid").css("border-color","red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
            var StartDate = new Date(event.start_date);
                StartDate = moment(StartDate).startOf("day").toDate();//updated by anu to save end time along with end date
            var EndDate   = new Date(event.end_date);
                EndDate = moment(EndDate).endOf("day").toDate();//updated by anu to save end time along with end date


            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;
            if (diff<0) {
                 angular.element("#enddate").css("border-color","red");
            }
        }else if($scope.accnoVerifyClass == "fa-times c-red" && $scope.event.is_repeat){
            toastr.error("Please correct unique Account No.");
            angular.element("#accNo").focus();
            angular.element("input.ng-invalid").css("border-color","red");
            angular.element("input.ng-valid").css("border-color","#d3d3d3");
        }else{
            /* SHIFT DATA - START */
            var StartDate = new Date(event.start_date);
                StartDate = moment(StartDate).startOf("day").toDate();//updated by anu to save end time along with end date
            var EndDate   = new Date(event.end_date);
                EndDate = moment(EndDate).endOf("day").toDate();//updated by anu to save end time along with end date

            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;
            if (!event.is_repeat && diff<0) {
                 toastr.error('End date should be greater than start date.');
                 angular.element("#enddate").css("border-color","red");
                 return false;
            }

            if($scope.changelocationAddress && !event.location_address.formatted_address){
                angular.element("#locationAddress").focus();
                angular.element("#locationAddress").css("border-color","red");
                toastr.error('Please select location address from list.');
                return false;
            }
            if($scope.changebillingAddress && !event.billing_address.formatted_address && !$scope.populatecheck){
                angular.element("#billingAddress").focus();
                angular.element("#billingAddress").css("border-color","red");
                toastr.error('Please select billing address from list.');
                return false;
            }
           if ( $scope.shiftData.length > 0 )
            event.shiftData = $scope.shiftData;
            event.event_data.on_call = event.event_data.on_call === "yes" ? true : false;
            event.event_data.equip_assigned = event.event_data.equip_assigned === "yes" ? true : false;
            event.is_active = event.is_active === "yes" ? true : false;
            event.ticket_control = event.ticket_control === "yes" ? true : false;
            event.text_reminder = event.text_reminder === "yes" ? true : false;
            event.set_up_person = $scope.user._id;
            if(event.start_date === undefined )
                delete event.start_date;
            else
                event.start_date = new Date(event.start_date);
                event.start_date = moment(event.start_date).startOf("day").toDate();//updated by anu to save end time along with end date
            if(event.end_date === undefined )
                delete event.end_date;
            else
                event.end_date = new Date(event.end_date);
                event.end_date = moment(event.end_date).endOf("day").toDate();//updated by anu to save end time along with end date

            var postdata = [];
            var paypostdata = [];
            var grid_data = $scope.gridData;
            var pay_grid_data = $scope.paygridData;
            for (var i = 0; i < $scope.weekdays.length; i++) {
                var nestedObj = {};
                if(grid_data[$scope.weekdays[i]]){
                    for (var j = 0; j < $scope.timeslots.length; j++) {
                        nestedObj = {'day' : $scope.weekdays[i], 'timeslot' :$scope.timeslots[j], 'price' : grid_data[$scope.weekdays[i]][$scope.timeslots[j]]};
                        postdata.push(nestedObj);
                    }
                }

                var paynestedObj = {};
                if(pay_grid_data[$scope.weekdays[i]]){
                    for (var j = 0; j < $scope.timeslots.length; j++) {
                        paynestedObj = {'day' : $scope.weekdays[i], 'timeslot' :$scope.timeslots[j], 'price' : pay_grid_data[$scope.weekdays[i]][$scope.timeslots[j]]};
                        paypostdata.push(paynestedObj);
                    }
                }
            }
            event.parking_prices = postdata;
            event.pay_rates = paypostdata;

            if($scope.changebillingAddress && !$scope.populatecheck){
                event.billing_address.address = event.billing_address.formatted_address;
                event.billing_address.lat = event.billing_address.geometry.location.lat();
                event.billing_address.lng = event.billing_address.geometry.location.lng();
                delete event.billing_address['geometry'];
            }else if($scope.changebillingAddress && $scope.populatecheck){
                event.billing_address = {};
                event.billing_address.address = event.location_addressstr;
                event.billing_address.lat = event.location_address_lat;
                event.billing_address.lng = event.location_address_lng;
            }else{
                delete event.billing_address;
            }

            if($scope.changelocationAddress){
                event.location_address.address = event.location_address.formatted_address;
                event.location_address.lat = event.location_address.geometry.location.lat();
                event.location_address.lng = event.location_address.geometry.location.lng();
                delete event.location_address['geometry'];
            }else{
                delete event.location_address;
            }
            if(event.start_date){
                    event.start_date = moment(event.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();    
                }
                if(event.end_date){
                    event.end_date = moment(event.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();    
                }
            $http.put("/api/events/", event).then(function(response){
                if(event.start_date !== undefined )
                    $scope.event.start_date = (event.start_date.getMonth()+1)+"/"+event.start_date.getDate()+"/"+event.start_date.getFullYear();
                if(event.end_date !== undefined )
                    $scope.event.end_date = (event.end_date.getMonth()+1)+"/"+event.end_date.getDate()+"/"+event.end_date.getFullYear();
                if("200" === response.data.status_code){
                    if($scope.removeshiftsArray.length){
                        $http.post("/api/removeshifts", {"removeShifts" : $scope.removeshiftsArray});
                    }
                    if ($scope.event.is_repeat === false){
                        toastr.success("Event information updated successfully.");
                        $scope.eventForm.$setPristine();
                        $location.path("/eventlist");
                    }
                    if ($scope.event.is_repeat === true){
                        toastr.success("Account information updated successfully.");
                        $scope.eventForm.$setPristine();
                        $location.path("/reoccurringaccount");
                    }
                }
                else {
                    toastr.error("Something went wrong!!");
                }
            });
        } 
    };
     //populate data of location to billing
    $scope.populate=function()
    {
        if ($scope.event.location_address && $scope.populatecheck) {

            $scope.event.billing_address_bakup = {};
            $scope.event.billing_address_bakup = $scope.event.billing_address;
            $scope.event.billing_address = angular.copy($scope.event.location_address);
            $scope.changebillingAdd();
        }
        else{

            console.log('elseee');
            $scope.event.billing_address = $scope.event.billing_address_bakup;
        }
    };
    $scope.generate_random=function(){
        if($scope.event.accountId){
            $scope.showloader = true;
            $http.post("/api/chk_random_account_edit", {num:$scope.event.accountId, accountId:$scope.event._id}).then(function(response){
                $scope.showloader = false;
                if(response.data.status_code=="200"){
                    angular.element("#accNo").css("border-color","#d3d3d3");
                    $scope.accnoVerifyClass = "fa-check c-green";
                    return 1;
                }
                else{
                    $scope.accnoVerifyClass = "fa-times c-red";
                    angular.element("#accNo").css("border-color","red");
                    return 0;
                }
            });
        }
        else{
            toastr.error("Please enter account id.");
        }
    }; 
    //end
});
