angular.module("app").controller("HoursworkedController", function($scope,ports,$filter,$location, SessionService, $http, UserService, $timeout, getweekService,NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.searchText = {};
    $scope.searchText.val = '';
    var currWeekDates = getweekService.getCurrWeek();
    $scope.currWeekDate = currWeekDates;
    var $modal = $("#get-modal");
    $scope.errormessage = "Please enter your selection to get the report.";
    $scope.report = [];
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
         if ( "200" === response.data.status_code ) {
             $scope.allEvents = response.data.events;
             angular.forEach( $scope.allEvents, function( value, key ) {
              value.buttonlabel = value.name;
             });
         } 
    });
    $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }
    $scope.getReport = function() {
        $scope.exportData = [];
        $scope.exportData.push(["Emp #", "Username", "Account", "Date of Shift", "Start Time", "End Time", "Break", "Hours"]);
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDate = new Date($scope.dateRange.end_date);
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
                var enddDate = new Date($scope.dateRange.end_date);
        var correctEndDate = enddDate.setDate(enddDate.getDate() + 1)
        $scope.datenewRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment(correctEndDate).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        $scope.storeSelEventAccountId = '';
        $scope.storeSelUserId = '';
        var hoursworkedJson = {};
        hoursworkedJson.dateRange = $scope.datenewRange;
        hoursworkedJson.owner_id = SessionService.currentUser._id;
        hoursworkedJson.role = SessionService.currentUser.roles[0];
        if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ) {
                   if ( value.ticked === true ) {
                       $scope.storeSelEventAccountId = value;
                   }
            });
        }
        if(!$scope.storeSelEventAccountId._id){
            toastr.error('Please choose Event/Account from list.');
            return false;
        }
        hoursworkedJson.eventId = $scope.storeSelEventAccountId._id;
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if (value.ticked == true) {
                    $scope.storeSelUserId = value;
                }
            });
        }
        if($scope.storeSelUserId._id){
            hoursworkedJson.empId = $scope.storeSelUserId._id;
        }
        if($scope.storeSelEventAccountId._id){
            $scope.grosstotalTimeDiff = 0;
            $scope.totalreportHours = 0; 
            $http.post("/api/getHoursworkedReport",hoursworkedJson).then(function(response){
                if (!response.data.data.length) {
                    $scope.haveResult = false;
                }else{
                    $scope.haveResult = true;
                    $scope.hoursworkedReport = response.data.data;
                    angular.forEach($scope.hoursworkedReport, function(val, ke) {
                        angular.forEach(val.Timesheets, function(value, key) {
                            $scope.exportData.push([value.employeeid[0], value.first_name[0], value.accountName[0], $filter('date')(value.created_date, 'MM/dd/yyyy'), $filter('date')(value.clockInDateTime, 'MM/dd/yyyy'),$filter('date')(value.clockOutDateTime, 'MM/dd/yyyy'), $scope.timeToDecimal(value.totalBreakTime), $scope.timeToDecimal(value.totalTimeDiff)]);
                        });   
                        $scope.totalreportHours = $scope.totalreportHours + val.totalTimeDiffSum;
                        $scope.exportData.push(["","","","","","",'Total Hours',$scope.timeToDecimal(val.totalTimeDiffSum)]);
                    });
                }   $scope.exportData.push(["","","","","","",'Total Report Hours',$scope.timeToDecimal($scope.totalreportHours)]);
            });
        }else{
            $scope.haveResult = false;
        }
    }
});