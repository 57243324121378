angular.module("app").controller("ScheduleEmployeesController", function($scope, $location, SessionService, $http,$routeParams, UserService, moment) {
   // alert("ScheduleEmployeesController");

   $scope.data=[];
   $scope.emplist=[];
   $scope.shifts = [
        {name: "morning shift", value: 'morning shift'},
        {name: "afternoon shift", value: 'afternoon shift'},
        {name: "night shift", value:'night shift'},
        {name: "late night shift", value: 'late night shift'}
    ];
   
   //Event Info
   $http.get('/api/events/' + $routeParams.event).success(function(response) {
		if ("200" === response.status_code) {
			$scope.eventInfo = response.data;
		}
   });
   
   //Get employees for availability
	$scope.emplst=function(chkval){
		if((chkval == true) && ($scope.shift!='') && ($scope.selected_date!='')){
			$http.post("/api/availableusers",{shift:$scope.selected_shift,currdate:new Date($scope.selected_date).toUTCString()}).then(function(response){
				if (response.status == 200) {
					response.data.data.forEach(function(elem, key){
						elem.ticked = false;
					});
					$scope.modernWebBrowsers = response.data.data;
				}
				else {
					console.log('400');
				}
			});
		}
	}
   //var assignEvent = {};
   $scope.assign_emp=function(){
                    var assndate = new Date($scope.selected_date);
                    var end_date = new Date($scope.eventInfo.end_date);
                    var start_date = new Date($scope.eventInfo.start_date);
                    if ((assndate < start_date) || (assndate > end_date)) {
                        alert('Date should between event start date and end date.');
                        return false;
                    }
					if($scope.selected_shift=='afternoon shift'){
						$scope.shift='afternoon';
					}
					if($scope.selected_shift=='morning shift'){
						$scope.shift='morning';
					}
					if($scope.selected_shift=='night shift'){
						$scope.shift='night';
					}
					if($scope.selected_shift=='late night shift'){
						$scope.shift='latenight';
					}
					if( $scope.modernWebBrowsers.length==0){
						alert("Please select atleast one employee");
					}
					else{
						var storeUserId = [];
						angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
			                if ( value.ticked === true ) {
			                    storeUserId.push({employee_id: value._id});
			                }
			            });
						var assignEvent = {
			                    event_id: $routeParams.event,
			                    assignDate: new Date($scope.selected_date).toUTCString(),
			                    assignShift: $scope.shift,
			                    employee_id: storeUserId,
								modified_date : moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString(),
								modified_by : SessionService.currentUser._id
			            };
						$http.post("/api/assignmentToMultiple", assignEvent).then(function(response){
			                if( "200" === response.data.status_code ) {
			                    toastr.success("Event Successfully Aligned.");
			                    $location.path('/eventlist');
			                }
			            });
						$scope.data=[];
					}
	}


});