angular.module("app").controller("WhiteboardController",function($scope, $http, SessionService, NgTableParams, SocketService, moment){
    $scope.haveResult = true;
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.postData = {};
    $scope.sortType = '';
    $scope.sortOrder= undefined;
    $scope.topictype = "withouttopic";
    $scope.searchText={};
    $scope.searchText.val='';
    $scope.topicnamePattern = /^[a-zA-Z0-9\'\#\@\_\-\`]+( [a-zA-Z0-9\'\#\@\_\-\`]+)*$/;
    var storeSeltopicId;
    $scope.selectedwhiteboardlist = {
        whiteboardlistArr: []
    };
    $scope.addNewPost = function(){
    	$scope.postData = {};
        $scope.buttondisabled = false;
		angular.forEach( $scope.topicsArr, function( value, key ) {
               if ( value.ticked === true ) {
                   value.ticked = false;
               }
        });
    }
    $scope.addPost = function(postData){
        $scope.showloader = true;
        $scope.postForm.$setPristine();
        $scope.buttondisabled = true;
    	if($scope.topicsArr){
            angular.forEach( $scope.topicsArr, function( value, key ) {
                   if ( value.ticked === true ) {
                       storeSeltopicId = value._id;
                   }
            });
        }
        if(storeSeltopicId){
        	$scope.postData.topicname = storeSeltopicId;
        	$scope.postData = postData;
	    	$scope.postData.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
	        $scope.postData.created_by = SessionService.currentUser._id;
            $scope.postData.location = SessionService.currentUser.location;
	    	$http.post("/api/addPostInWhiteBoard", $scope.postData).then(function(response){
	    		if(response.status == "200"){
	    			angular.element('#modal-post').modal('hide');
                    $scope.showloader = false;
	    			$scope.whiteBoardPosts();
	    		}else{
	    			toastr.error("Something Wrong!");
	    		}
	    	});
    	}else{
    		toastr.error("Please choose topic.");
    	}
    }
    $scope.whiteBoardPosts = function() {
      $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType='modified_date';
            $scope.sortOrder=-1;
        }    
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 20,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/whiteboardListing", {
                    count: params.count(),
                    page: params.page(),
                    field: $scope.sortType,
                    search: search,
                    sortOrder:$scope.sortOrder,
                    location : SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.allPosts = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.whiteboarditemcount = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allPosts;
                })
            }
        });
    }
    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.whiteBoardPosts()
              }
          }
    );
    $scope.whiteBoardPosts();
    $scope.fetchwhiteboardTopics = function(){
        $http.post("/api/WhiteboardtopicList", {location : SessionService.currentUser.location}).then(function(response){
            $scope.topicsArr = response.data.topiclist;
        });
    }
    $scope.fetchwhiteboardTopics();
    $scope.createTopic = function(){
        $scope.topicForm.$setPristine();
        $scope.topicbuttondisabled = true;
		$scope.topicInfo.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		$scope.topicInfo.created_by = SessionService.currentUser._id;
        $scope.topicInfo.location = SessionService.currentUser.location;
		$http.post("/api/addTopic", $scope.topicInfo).then(function(response){
			if(response.data.status_code === "200"){
				toastr.success("Topic is created successfully.","",{"timeOut":500});
				angular.element('#modal-topic').modal('hide');
                $http.post("/api/WhiteboardtopicList", {location : SessionService.currentUser.location}).then(function(response){
			    	$scope.topicsArr = response.data.topiclist;
                    if($scope.topicsArr){
                        angular.forEach( $scope.topicsArr, function( value, key ) {
                           if ( value.topicname === $scope.topicInfo.topicname ) {
                               value.ticked = true;
                           }else{
                                value.ticked = false;
                           }
                        });
                    }
			    });
			}
            else if(response.data.status_code === "400"){
                toastr.error(response.data.message);
            }
            else{
				toastr.error("Somthing Wrong!","",{"timeOut":500});
			}
		});
	}

    $scope.enabletopicbutton = function(){
        $scope.topicbuttondisabled = false;
    }

	$scope.clearWhiteboardConfirmation = function(){
		$('#clear_all').show();
	}
    $scope.removeSelectedConfirmation = function(){
        if($scope.selectedwhiteboardlist.whiteboardlistArr.length){
            $('#clear_selected').show();
        }else{
            toastr.error('Please select an item from Whiteboard.');
        }
    }
	$scope.ClearbyTopic = function(){
        $scope.topicId = '';
		angular.forEach( $scope.topicsArr, function( value, key ) {
           if ( value.ticked === true ) {
               storeSeltopicId = value._id;
           }
	    });
	    $scope.topicId = storeSeltopicId;
        if(!storeSeltopicId){
            toastr.error("Please choose topic first!");
            return false;
        }

	    $http.post("/api/clearWhiteboardByTopic",{'topicId':$scope.topicId, 'topictype' : $scope.topictype}).then(function(response){
	    	if(response.status == "200"){
	    		toastr.success("Whiteboard is cleared successfully.");
	    		angular.element('#modal-clearByTopic').modal('hide');
	    		$scope.whiteBoardPosts();
                $scope.fetchwhiteboardTopics();
	    	}
	    });
	}
	$scope.openClearTopicmodal = function(){
		var storeSeltopicId = '';
		angular.forEach( $scope.topicsArr, function( value, key ) {
               if ( value.ticked === true ) {
                   value.ticked = false;
               }
        });
	}
	$scope.clearWhiteboard = function(){
		$('#clear_all').hide();
		$http.post("/api/clearWhiteboard").then(function(response){
	    	if(response.status == "200"){
	    		toastr.success("Whiteboard is cleared successfully.");
	    		$scope.whiteBoardPosts();
	    	}
	    });
	}
    $scope.clearSelectedWhiteboard = function(){
        $('#clear_selected').hide();
        $http.post("/api/clearWhiteboardbyIds",{'removeIds' : $scope.selectedwhiteboardlist.whiteboardlistArr}).then(function(response){
            if(response.status == "200"){
                toastr.success("Items are removed successfully.");
                $scope.whiteBoardPosts();
            }
        });
    }
    $scope.removeWhiteboardItem = function(mainId, itemId){
        $http.post("/api/clearWhiteboardItem", {'mainId': mainId,'itemId':itemId}).then(function(response){
            if(response.status == "200"){
                toastr.success("Item is removed successfully.");
                $scope.whiteBoardPosts();
            }
        });
    }
    $scope.replyOnTopic = function(topicname){
        $scope.postData = {};
        angular.forEach( $scope.topicsArr, function( value, key ) {
           if ( value.topicname === topicname ) {
               value.ticked = true;
           }else{
                value.ticked = false;
           }
        });
    }
});