angular.module("app").controller("ClockingController", function($scope, $filter, SessionService, $http, moment) {
	$scope.clockdata = {};
	$scope.userRole = SessionService.currentUser.roles[0];
	$scope.showloader = true;
	$scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	$scope.notesNamePattern = /^[a-zA-Z0-9\'\#\@\_\-\`\%\=\&\$]+( [a-zA-Z0-9\'\#\@\_\-\`\%\=\&\$]+)*$/;
	$scope.ticketForm = {};
	$scope.shifts = [
      {name: "Morning", value: "Morning"},
      {name: "Afternoon", value: "Afternoon"},
      {name: "Night", value: "Night"},
      {name: "Late Night", value: "Late Night"}
    ];
	$scope.timeToMilliseconds = function(time){
		var timeParts = time.split(":");
		var msStr = (timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000);
		return msStr;
	}

    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }

    $scope.removeRadius = function(recordId){
    	$http.post("/api/removeGeoEntry", {radiusId:recordId}).then(function(response){
			if(response.data.status_code == 200){
				toastr.success('Removed Successfully.');
				angular.element('#modal-list').modal('hide');
			}
		});
    }

	$scope.findRadius = function(){
		$http.get("/api/GetGeoRadius").then(function(response){
			if(response.data.status_code == 200 && response.data.data){
				$scope.viewVal = response.data.data.radius;
				$scope.startTime = response.data.data.startTime;
				$scope.endTime = response.data.data.endTime;
			}
		});
	}
	$scope.openRadusFolder = function(){
		$scope.radiusForm = {};
		$scope.findRadius();
	}
	$scope.fetchRadiusListing = function(){
		$http.get("/api/GetGeoRadiusList").then(function(response){
			if(response.data.status_code == 200 && response.data.data.length){
				$scope.haveResult = true;
				$scope.radiusList = response.data.data;
			}else{
				$scope.haveResult = true;	
			}
		});
	}
	$scope.saveRadius = function(){
		if($scope.radiusForm.$invalid || !$scope.radiusForm.startTime || !$scope.radiusForm.endTime){
			toastr.error("Please fill all the required fields.");
			return false;
		}
		if (undefined !== $scope.radiusForm.startTime && undefined !== $scope.radiusForm.endTime) {
            var diff = moment($scope.radiusForm.endTime,"HH:mm" ).diff(moment($scope.radiusForm.startTime,"HH:mm"), 'minutes')
            if(diff <= 0){
                toastr.error("End time should be greater than start time.");
                return false;
            }
        }

		$scope.radiusForm.created_by = SessionService.currentUser._id;
		var radiusForm = $scope.radiusForm;
		radiusForm.startTimeMS = $scope.timeToMilliseconds(radiusForm.startTime);
		radiusForm.endTimeMS = $scope.timeToMilliseconds(radiusForm.endTime);
		$http.post("/api/checkExistingRadius", radiusForm).then(function(response){
			if(response.data.status_code === "208"){
				$('#confirm_radiusOverride').show();
				$scope.existRadiusId = response.data.data._id
			}
			if(response.data.status_code === "200"){
				$http.post("/api/GeoAddRadius", radiusForm).then(function(response){
					if(response.data.status_code === "200"){
						toastr.success("Radius is added successfully.","",{"timeOut":500});
						angular.element('#modal-basic').modal('hide');
						$scope.viewVal = response.data.data.radius;
						$scope.startTime = response.data.data.startTime;
						$scope.endTime = response.data.data.endTime;
					}else{
						toastr.error("Somthing Wrong!","",{"timeOut":500});
					}
				});
			}
		});
	}

	$scope.addRadiusConfirm = function(){
		$('#confirm_radiusOverride').hide();
		var radiusForm = $scope.radiusForm;
		radiusForm.startTimeMS = $scope.timeToMilliseconds(radiusForm.startTime);
		radiusForm.endTimeMS = $scope.timeToMilliseconds(radiusForm.endTime);
		radiusForm.existId = $scope.existRadiusId;
		$http.post("/api/GeoAddRadius", radiusForm).then(function(response){
			if(response.data.status_code === "200"){
				toastr.success("Radius is added successfully.","",{"timeOut":500});
				angular.element('#modal-basic').modal('hide');
				$scope.viewVal = response.data.data.radius;
				$scope.startTime = response.data.data.startTime;
				$scope.endTime = response.data.data.endTime;
			}else{
				toastr.error("Somthing Wrong!","",{"timeOut":500});
			}
		});
	}

	$http.post("/api/getAllUsers", {"location": SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
        	$scope.showloader = false;
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });

    $http.post("/api/getEventAccounts", {location:SessionService.currentUser.location}).then(function(response){
    	$scope.showloader = true;
         if ( "200" === response.data.status_code) {
         	 $scope.showloader = false;
             $scope.allEvents = response.data.events;
         } 
    });

	$scope.cleardata = function(){
		$scope.clockin = false;
		$scope.clockout = false;
		$scope.clockdata = {};
		if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ){
               if ( value.ticked === true ) {
                   value.ticked = false;
               }
            });
        }
        if($scope.modernWebBrowsers){
	        angular.forEach( $scope.modernWebBrowsers, function( value, key ){
	           if ( value.ticked === true ) {
	               value.ticked = false;
	           }
	        });
		}
		toastr.info("You cleared the data.");
	}
	
	$scope.clockout = false;
	$scope.chckclockedIn = function(){
		var clockJson = {};
		if($scope.modernWebBrowsers){
	        angular.forEach( $scope.modernWebBrowsers, function( value, key ){
	           if ( value.ticked === true ) {
	               $scope.storeSelUserId = value._id;
	           }
	        });
		}
		clockJson.userId = $scope.storeSelUserId;
		$http.post('/api/clockIncheck',clockJson).then(function(response){
			if("200" === response.data.status_code && response.data.data){
				$scope.clockin 	   = false;
				$scope.clockout    = true;
				$scope.clockinId   = response.data.data._id;
				$scope.clkInDte    = response.data.data.clockInDateTime;
				$scope.shift       = response.data.data.shift;
				//$scope.fetchExceptDateold = new Date(new Date($scope.clkInDte).setHours(0, 0, 0, 0));
				$scope.fetchExceptDate = moment($scope.clkInDte).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate(); 
				$scope.eventInfo   = response.data.data.eventId;
				var today = moment($scope.clkInDte).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
				var day = today.getDay();
				$scope.todayDay = $scope.weekdays[day];
				$scope.eventInfo.pay_rates.forEach(function(elem, key){
					if(elem.timeslot == $scope.shift && elem.day == $scope.todayDay){
						$scope.eventInfo.basic_pay_rate = elem.price || 0;
					}
				});
				$http.post('/api/fetchExceptionRate',{eventId : response.data.data.eventId._id, employee:response.data.data.userId, fetchExceptionDate : $scope.fetchExceptDate}).then(function(exceptionRes){
					$scope.clockinId = response.data.data._id;
					if(exceptionRes.data.status_code == "200"){
						if(exceptionRes.data.data[0] && exceptionRes.data.data[0].rates){
							$scope.eventInfo.basic_pay_rate = exceptionRes.data.data[0].rates.price;						
						}

						if($scope.allEvents){
				            angular.forEach( $scope.allEvents, function( value, key ){
				               if ( value._id === $scope.eventInfo._id ) {
				                   value.ticked = true;
				               }
				            });
				        }
					}
				});
				
				//$scope.clockdata.clockInDateTime = $filter('date')(response.data.data.clockInDateTime,'MM/dd/yyyy HH:mm:ss');
				$scope.clockdata.clockInDateTime = moment(response.data.data.clockInDateTime).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY HH:mm:ss")
				$scope.clockdata.shift = response.data.data.shift;
			}else{
				$scope.clockout = false;
				$scope.clockin = true;
				$scope.clockdata = {};
				if($scope.allEvents){
		            angular.forEach( $scope.allEvents, function( value, key ){
		               if ( value.ticked === true ) {
		                   value.ticked = false;
		               }
		            });
		        }
		        $scope.clockdata.shift = 'Morning';
			}
		});
	}

	$scope.chkTime = function(){
		var StartDate = new Date($scope.clockdata.clockInDateTime);
        var EndDate   = new Date($scope.clockdata.clockOutDateTime);
        var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;     
        if (diff<0) {
             toastr.error('Clock out date should be greater than clock in date.');
             return false;
        }
	}

    $scope.clockInFun = function(){
    	if($scope.modernWebBrowsers){
	        angular.forEach( $scope.modernWebBrowsers, function( value, key ){
	           if ( value.ticked === true ) {
	               $scope.storeSelUserId = value._id;
	           }
	        });
		}
    	if($scope.allEvents){
            angular.forEach( $scope.allEvents, function( value, key ){
               if ( value.ticked === true ) {
               	   $scope.storeSelEventAccountInfo = value;
                   $scope.storeSelEventAccountId = value._id;
                   $scope.storeSelAccountlocationCoordinates = value.location_coordinates;
               }
            });
        }
        if($scope.storeSelEventAccountId === undefined) {
            toastr.error("Please select an account");
            return false;
        }
        if(!$scope.clockdata.clockInDateTime){
        	toastr.error("Please enter clock in time.");
        	return false;
        }
		if($scope.storeSelUserId === undefined) {
            toastr.error("Please select an employee");
            return false;
        }else{
	        var clockInJson = {};
			clockInJson.eventId = $scope.storeSelEventAccountId;
			clockInJson.revenueType = $scope.storeSelEventAccountInfo.revenue_type;
			if($scope.storeSelEventAccountInfo.revenue_type === 'flat_rate'){
	            clockInJson.revenueFixed = $scope.storeSelEventAccountInfo.flat_rate;
	        }
	        if($scope.storeSelEventAccountInfo.revenue_type === 'rate_per_hour'){
	            clockInJson.revenueRate = $scope.storeSelEventAccountInfo.rate_per_hour;
	        }
			clockInJson.shift = $scope.clockdata.shift;
			clockInJson.location_coordinates = $scope.storeSelAccountlocationCoordinates;
			clockInJson.userId = $scope.storeSelUserId;
			clockInJson.created_by = SessionService.currentUser._id;
			clockInJson.clockInDateTime = moment($scope.clockdata.clockInDateTime).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
			//clockInJson.created_date = new Date(new Date($scope.clockdata.clockInDateTime).setHours(0, 0, 0, 0));
			clockInJson.created_date = moment($scope.clockdata.clockInDateTime).tz(SessionService.currentUser.locationtimezone).startOf('day').toDate(); 
			clockInJson.location = SessionService.currentUser.location;
			$http.post('/api/clockIn',clockInJson).then(function(response){
				if("200" === response.data.status_code){
					toastr.success("Successfully Done!");
					$scope.clockout = false;
					$scope.clockdata = {};
					$scope.clockdata.shift = 'Morning';
					if($scope.allEvents){
			            angular.forEach( $scope.allEvents, function( value, key ){
			               if ( value.ticked === true ) {
			                   value.ticked = false;
			               }
			            });
			        }
			        if($scope.modernWebBrowsers){
				        angular.forEach( $scope.modernWebBrowsers, function( value, key ){
				           if ( value.ticked === true ) {
				               value.ticked = false;
				           }
				        });
					}
				}
			});
		}
	}

	$scope.openTicketcontrolOnshift = function(shift){
		$scope.eventInfo.parking_prices.forEach(function(elem, key){
			if(elem.timeslot == shift && elem.day == $scope.todayDay){
				$scope.amountPerCar = elem.price || 0;
			}
		});
	}
	$scope.clockOutFun = function(clockdata){
		var StartDate = new Date($scope.clockdata.clockInDateTime);
        var EndDate   = new Date($scope.clockdata.clockOutDateTime);
        if(!clockdata.clockOutDateTime){
        	toastr.error('Please enter clock out time');
            return false;
        }
        var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;     
        if (diff<0) {
             toastr.error('Clock out date should be greater than clock in date.');
             return false;
        }else{
			if($scope.modernWebBrowsers){
		        angular.forEach( $scope.modernWebBrowsers, function( value, key ){
		           if ( value.ticked === true ) {
		               $scope.storeSelUserId = value._id;
		           }
		        });
			}
			var clockOutJson = {};
			clockOutJson.userId = $scope.storeSelUserId;
			clockOutJson.clockInId = $scope.clockinId;
			clockOutJson.clockOutDateTime = moment(clockdata.clockOutDateTime).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
			if(clockdata.break){
				clockOutJson.breaktime = clockdata.break * 60000;//convert minute to Millisecond
			}else{
				clockOutJson.breaktime = 0;
			}
			var then = $scope.clockdata.clockInDateTime;
			var nowtime = $scope.clockdata.clockOutDateTime;
			var ms = moment(nowtime).diff(moment(then)); // Millisecond
			var timeWithoutBreak = ms - clockOutJson.breaktime;
			var d = moment.duration(timeWithoutBreak);
			var s = d.asHours().toFixed(2);
			clockOutJson.totalBreakTime = clockOutJson.breaktime;
			clockOutJson.totalTimeDiff = timeWithoutBreak;//After breaktime deduction
			clockOutJson.payrate = $scope.eventInfo.basic_pay_rate;
			clockOutJson.payroll = parseFloat(s*$scope.eventInfo.basic_pay_rate).toFixed(2);
			$http.post('/api/clockOutByAdmin', clockOutJson).then(function(response){
				if("200" === response.data.status_code){
					toastr.success("Successfully Done!");
					$scope.clockout = false;
					$scope.clockdata = {};
					$scope.clockdata.shift = 'Morning';
					if($scope.allEvents){
			            angular.forEach( $scope.allEvents, function( value, key ){
			               if ( value.ticked === true ) {
			                   value.ticked = false;
			               }
			            });
			        }
			        if($scope.modernWebBrowsers){
				        angular.forEach( $scope.modernWebBrowsers, function( value, key ){
				           if ( value.ticked === true ) {
				               value.ticked = false;
				           }
				        });
					}
				}
			});
		}
	}
});
