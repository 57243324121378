angular.module("app").controller("TimeslotrangeController", function($scope, SessionService, $http, $window, TimeSlotResource, messages) {
	$scope.addMinutes = function (time, minutes) {
      var date = new Date(new Date('01/01/2015 ' + time).getTime() + minutes * 60000);
      var tempTime = ((date.getHours().toString().length == 1) ? '0' + date.getHours() : date.getHours()) + ':' +
        ((date.getMinutes().toString().length == 1) ? '0' + date.getMinutes() : date.getMinutes()) + ':' +
        ((date.getSeconds().toString().length == 1) ? '0' + date.getSeconds() : date.getSeconds());
      return tempTime;
    }

    TimeSlotResource.query({locationId : SessionService.currentUser.location},function(response){
        $scope.slotInfo = response[0];
        $scope.Morn_Slots = []; 
        $scope.Afte_Slots = [];
        $scope.Nigh_Slots = [];
        $scope.Late_Slots = [];
        var mstime = moment($scope.slotInfo.morning_start_time+':00 '+$scope.slotInfo.morning_start_period, ["h:mm A"]).format("HH:mm:ss");
        var metime = moment($scope.slotInfo.morning_end_time+':00 '+$scope.slotInfo.morning_end_period, ["h:mm A"]).format("HH:mm:ss");
        var mstimeStr = mstime.lastIndexOf(":")
        $scope.Morn_Slots.push(mstime.substring(0, mstimeStr));
        while (mstime != metime) {
          mstime = $scope.addMinutes(mstime, "5");
          var mstimeStr = mstime.lastIndexOf(":")
          $scope.Morn_Slots.push(mstime.substring(0, mstimeStr));
        }

        var astime = moment($scope.slotInfo.afternoon_start_time+':00 '+$scope.slotInfo.afternoon_start_period, ["h:mm A"]).format("HH:mm:ss");
        var aetime = moment($scope.slotInfo.afternoon_end_time+':00 '+$scope.slotInfo.afternoon_end_period, ["h:mm A"]).format("HH:mm:ss");
        var astimeStr = mstime.lastIndexOf(":")
        $scope.Afte_Slots.push(astime.substring(0, astimeStr));
        while (astime != aetime) {
          astime = $scope.addMinutes(astime, "5");
          var astimeStr = astime.lastIndexOf(":")
          $scope.Afte_Slots.push(astime.substring(0, astimeStr));
        }

        var nstime = moment($scope.slotInfo.night_start_time+':00 '+$scope.slotInfo.night_start_period, ["h:mm A"]).format("HH:mm:ss");
        var netime = moment($scope.slotInfo.night_end_time+':00 '+$scope.slotInfo.night_end_period, ["h:mm A"]).format("HH:mm:ss");
        var nstimeStr = mstime.lastIndexOf(":")
        $scope.Nigh_Slots.push(nstime.substring(0, nstimeStr));
        while (nstime != netime) {
          nstime = $scope.addMinutes(nstime, "5");
          var nstimeStr = nstime.lastIndexOf(":")
          $scope.Nigh_Slots.push(nstime.substring(0, nstimeStr));
        }

        var lstime = moment($scope.slotInfo.late_night_start_time+':00 '+$scope.slotInfo.late_night_start_period, ["h:mm A"]).format("HH:mm:ss");
        var letime = moment($scope.slotInfo.late_night_end_time+':00 '+$scope.slotInfo.late_night_end_period, ["h:mm A"]).format("HH:mm:ss");
        var lstimeStr = mstime.lastIndexOf(":")
        $scope.Late_Slots.push(lstime.substring(0, lstimeStr));
        while (lstime != letime) {
          lstime = $scope.addMinutes(lstime, "5");
          var lstimeStr = lstime.lastIndexOf(":")
          $scope.Late_Slots.push(lstime.substring(0, lstimeStr));
        }
    });
});