angular.module("app").controller("ViewEventController", function($scope, $location, SessionService, $http, $timeout, $filter, sidepanelactiveService, NgTableParams, $window, moment) {
    sidepanelactiveService.test();
    $scope.today = moment.tz(moment(), SessionService.currentUser.locationtimezone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    $scope.visibility = false;
    $scope.haveResult = true;
    $scope.sortType = '';
    $scope.sortOrder = undefined;
    $scope.status = "active";
    $scope.searchText = {};
    $scope.searchText.val = '';
    $scope.selectenable = false;
    $scope.showloader = true;
    $scope.searchPattern = /^[a-zA-Z0-9\'\#\@\_\-\`\.\$\,]+( [a-zA-Z0-9\'\#\@\_\-\`\.\$\,]+)*$/;
    $scope.selectedEvent = {
        event: []
    };
    $scope.selectedAction = 0;
    $scope.checkAll = {}
    $scope.showEvent = function(eventid) {
        $scope.showloader = true;
        $timeout(function() {
            $scope.$apply(function() {
                $location.path('/event/edit/' + eventid);
            });
        },100);
        $window.scrollTo(0, 0);
    };
        //listing of active,inactive
    $scope.liststs = [{
        name: "Active",
        value: 'active'
    }, {
        name: "Inactive",
        value: 'inactive'
    }, {
        name: "All",
        value: "all"
    }, ];

 
    $scope.pageNum = 1;
    $scope.listevents = function() {
        $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType='event_data.created_at';
            $scope.sortOrder=-1;
        }    
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.showloader = true;
                $scope.pageNum = params.page(); 
                return $http.post("/api/eventlist", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0],
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    status:$scope.status,
                    eventType : "oneTime",
                    location:SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    response.data.data.forEach(function(elem, key) {
                        //elem.start_date = moment(elem.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                        //elem.end_date = moment(elem.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                        elem.start_date = moment(elem.start_date).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY");
                        elem.end_date = moment(elem.end_date).utc().tz(SessionService.currentUser.locationtimezone).format("MM/DD/YYYY");
                    });
                    $scope.allEvents = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.allEvents;
                })
            }
        });
    }
    $scope.listevents();
    
        /*watch for search*/
    $scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
      function(newValue, oldValue) 
      {
          if(newValue != oldValue){
            $scope.pageNum = 1;
            $scope.listevents();
          }
      }
     );
    /*change active/inactive on click*/
    $scope.update_status = function($index, data) {
            $http.post("/api/update_event_status", {
                "data": data
            }).then(function(response) {
                if (response.data.status_code == "200") {
                    $scope.listevents();
                    toastr.success("Successfully updated.");
                }
            })
        }
        /*end*/
    $scope.reset_page=function() {
    $scope.pageNum = 1;
    $scope.listevents();
    }
    $scope.sortOrderby = function(sortstring, sort) {
       $scope.sortType = sortstring;
       $scope.sortOrder= sort;
       if($scope.sortOrder == false){
        $scope.sortOrder = 1;
       }else{
        $scope.sortOrder = -1;
       }
       $scope.listevents();
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    $scope.checkAll.val= false;
    $scope.selectAllGroup = function(check) {
            $scope.flag = {};
            $scope.flag.enable = false;
            if (check) {
                $scope.selectedEvent.event = $scope.allEvents.map(function(item) {
                    return item._id;
                });
                $scope.selectenable = true;
                console.log("$scope.selectenabletrue", $scope.selectenable);
            } else {
                $scope.selectedEvent.event = [];
                $scope.checkAll.val= false;
                $scope.selectenable = false;
            }
    }

    //save all enable disable
    $scope.performAction = function() {
        $scope.selectedAction = selectedAction.value;
        if ($scope.selectedAction == 0) {
            toastr.error("Please select some selection.");
        }else if ($scope.selectedEvent.event.length == 0) {
            toastr.error("Please select atleast one checkbox.");
        }
        
        if ($scope.selectedAction != 0 && $scope.selectedAction == 1 && $scope.selectedEvent.event.length > 0) {
            $scope.enbl = true;
        }else if ($scope.selectedAction != 0 && $scope.selectedAction == 2 && $scope.selectedEvent.event.length > 0) {
            $scope.enbl = false;
        }
                
        if( $scope.selectedEvent.event.length > 0 && $scope.selectedAction > 0 ){
            $http.post("/api/enableevents", {
                'enabled': $scope.enbl,
                'allChecked': $scope.selectenable,
                "event": $scope.selectedEvent.event,
                "owner_id": SessionService.currentUser._id,
                "role": SessionService.currentUser.roles[0],
                "eventType" : "oneTime"
            }).then(function(response) {
                $scope.allEvents = response.data.data;
                 angular.element('#selectedAction').val(0);
                $scope.listevents();
                toastr.success("Successfully updated.");
                 $scope.selectedEvent.event = [];
                 $scope.checkAll.val = false;
            })
        }
    }

    $scope.havealignResult = true;
    $scope.showemplist = function(eventid, e) {
        $http.post("/api/otherEmpOnEventsNoShift", {
            event: eventid
        }).then(function(innerresponse) {
            if ("200" === innerresponse.data.status_code) {
                if (typeof innerresponse.data != "undefined") {
                    var empidarr = [];
                    innerresponse.data.data.forEach(function(elem, key) {
                        if (empidarr.indexOf(elem.employee_id._id) != -1) {
                            innerresponse.data.data.splice(key, 1);
                        } else {
                            empidarr.push(elem.employee_id._id);
                        }
                    });
                    $scope.sameemployees = innerresponse.data.data;
                    if ($scope.sameemployees.length > 0)
                        $scope.havealignResult = true;
                    else
                        $scope.havealignResult = false;
                } else {
                    var sameemployees = {};
                    $scope.havealignResult = false;
                }
            }
        });
    };

    $scope.showEventdetails = function(eventid, e) {
            $http.get('/api/events/' + eventid).success(function(response) {
                if ("200" === response.status_code) {
                    $scope.showloader = false;
                    if (response.data.start_date) {
                        response.data.start_date = $filter('date')(response.data.start_date, 'MM/dd/yyyy');
                    }
                    if (response.data.end_date != undefined) {
                        response.data.end_date = $filter('date')(response.data.end_date, 'MM/dd/yyyy');
                    }
                     if (response.data.event_data.contact_phone != undefined) {
                        response.data.event_data.contact_phone = $filter('tel')(response.data.event_data.contact_phone, false);
                    }
                    response.data.is_active = response.data.is_active === true ? "yes" : "no";
                    $scope.event = response.data;
                    if (response.data.shift_template_id.length > 0) {
                        $scope.shiftData = response.data.shift_template_id;
                    }
                }
            });
    };

    /*function to addevent*/
    $scope.addevent = function() {
        $scope.showloader = true;
        $timeout(function() {
            $scope.$apply(function() {
                $location.path("/addevent");
            });
        },100);
        $window.scrollTo(0, 0);
    }

    $scope.fetchQuestions = function(selectedQuestions, eventAccountId){
        $http.get('/api/getAllQuestions').success(function(response) {
            if (response.status_code == "200") {
                response.data.forEach(function(elem, key) {
                    if (selectedQuestions.indexOf(elem._id) != -1) {
                        elem.done = true;
                    }else{
                        elem.done = false;
                    }
                });
                $scope.questions = response.data;
                $scope.eventAccountId = eventAccountId;
            }
        });
    }

    $scope.saveQuestion = function(eventAccountId){
        var questionsIds = [];
        angular.forEach($scope.questions, function( elem, key ) {
            if ( elem.done === true ) {
               questionsIds.push(elem._id);
            }
        });
        var eventDataJson = {};
        eventDataJson.questions = questionsIds;
        eventDataJson.event_account_Id = eventAccountId;
        $http.post("/api/addEventAccountQuestions", eventDataJson).then(function(response) {
            toastr.success("Successfully updated.");
            angular.element('#modal-questions').modal('hide');
            $scope.listevents();
        });
    }

    $scope.removeAllConfirmation = function(eventAccountId){
        var eventDataJson = {};
        $scope.event_account_Id = eventAccountId;
        $('#clear_all').show();
    };

    $scope.removeAll = function(){
        var eventDataJson = {};
        var questionsIds = [];
        eventDataJson.questions = questionsIds;
        eventDataJson.event_account_Id = $scope.event_account_Id;
        $http.post("/api/addEventAccountQuestions", eventDataJson).then(function(response) {
            toastr.success("Successfully updated.");
            $('#clear_all').hide();
            angular.element('#modal-questions').modal('hide');
            $scope.listevents();
        });
    }

    //Start Notes Library
    $scope.showNotes = function(event_account){
        $http.get('/api/events/' + event_account).success(function(response) {
          $scope.event_account_id = response.data._id;
        });
        $scope.noteitemjson = {};
        $scope.noteitemjson.event_account = event_account;
        $scope.noteitemjson.createdby = $scope.user._id;
        $scope.noteItemList = [];
        $http.post('/api/accountNotesList',$scope.noteitemjson).success(function(response) {
            if(response.status_code == "200"){
                $scope.noteItemList = response.data.notes;
                $scope.event_account = response.data.event_account;
                $scope.noteItemListId = response.data._id;
            }
        });
    };

    $scope.noteAdd = function(event_account){
        if(!$scope.noteItemList){
            $scope.noteItemList = [];
            $scope.noteItemListLength = 0;
        }else{
            $scope.noteItemListLength = $scope.noteItemList.length; 
        }
        var noteadddate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        $scope.noteItemList.push({noteText:$scope.noteitem.noteInput, added_date : noteadddate});
        $scope.noteitemjson = {};
        $scope.noteitemjson.notesEntry = {noteText:$scope.noteitem.noteInput, added_date : noteadddate};
        $scope.noteitemjson.event_account = event_account;
        $scope.noteitemjson.created_by = $scope.user._id;
        $scope.noteitemjson.noteslistCount = $scope.noteItemListLength;
        $http.post("/api/addAccountNoteInList", $scope.noteitemjson).then(function(response){
            if(response.data.status_code === "200"){
                $scope.event_account = response.data.data.event_account;
                $scope.noteItemListId = response.data.data._id;
                $scope.noteItemList = response.data.data.notes;
                toastr.success("Note is added successfully.");
            }else{
                toastr.error("Something Wrong!");
            }
        });
        $scope.noteitem = {};
    }

    $scope.removeNoteConfirmation = function(eventId, noteMainId, removeNoteId){
        $scope.noteitemjson = {};
        $scope.noteitemjson.event_account = eventId;
        $scope.noteitemjson.created_by = $scope.user._id;
        $scope.noteitemjson.noteMainId = noteMainId;
        $scope.noteitemjson.removeNoteId = removeNoteId;
        $('#confirm_notedel').show();
    }

    $scope.removeNoteListConfirmation = function(noteMainId){
        $scope.noteitemjson = {};
        $scope.noteitemjson.noteMainId = noteMainId;
        $('#confirm_notelistdel').show();
    }

    $scope.removeNote = function(){
        $http.post("/api/removeAccountNoteInList", $scope.noteitemjson).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Note is removed successfully.");
                $scope.noteItemList = response.data.data.notes;
                $scope.employeeid = response.data.data.employee;
                $scope.noteItemListId = response.data.data._id;
                $('#confirm_notedel').hide();
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }

    $scope.removeNoteList = function(){
        $http.post("/api/removeWholeAccountNoteList", $scope.noteitemjson).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("List is cleared successfully.");
                $('#confirm_notelistdel').hide();
                angular.element('#modal-NotesSection').modal('hide');
            }else{
                toastr.error("Something Wrong!");
            }
        });
    }
    //End of Notes Library
});