angular.module("app").controller("LogsController", function($scope, $timeout, $http, $location, SessionService, NgTableParams, $window){
    $scope.userRole = SessionService.currentUser.roles[0];
	$scope.visibility = false;
    $scope.haveResult = true;
    $scope.searchText = {};
    $scope.searchText.val = '';
    $scope.sortType = '';
    $scope.sortOrder = undefined;
    $scope.showloader = true;
	$scope.getNotifications = function(){
		$scope.showloader = true;
		if ($scope.sortType == '') {
            $scope.sortType = 'sent_date';
            $scope.sortOrder = -1;
        }
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 5,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/getEmployeeLogs", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0]
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true
                    params.total(response.data.total); // recal. page nav controls
                    $scope.logsData = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.logsData;
                })
            }
        });
	}
	
    $scope.getNotifications();
    $scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.getNotifications();
    }
    $scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }

}).filter('substring', function(){
    return function(input){
        return(!!input) ? input.substr(input.lastIndexOf("___")+3) : '';
    }
});