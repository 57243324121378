angular.module("app").controller("MissedpunchController", function($scope,ports,$filter,$location, SessionService, $http, UserService, $timeout, getweekService,NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.searchText = {};
    $scope.clockdata = {};
    $scope.searchText.val = '';
    var currWeekDates = getweekService.getCurrWeek();
    $scope.currWeekDate = currWeekDates;
    var $modal = $("#get-modal");
    $scope.errormessage = "Please enter your selection to get the report."
    $scope.report = [];
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $scope.getReport = function() {
        var StartDate = new Date($scope.dateRange.start_date);
        var EndDate = new Date($scope.dateRange.end_date);
        if (StartDate && EndDate) {
            var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime())) / 86400000;
        }
        if (diff < 0) {
            toastr.error('End date should be greater than start date.');
            return false;
        }
        if (StartDate == 'Invalid Date') {
            toastr.error('Please Enter Start Date');
            return false;
        }
        if (EndDate == 'Invalid Date') {
            toastr.error('Please Enter End Date');
            return false;
        }
        $scope.pageNum = 1;
        $scope.showloader = true;
        var enddDate = new Date($scope.dateRange.end_date);
        var correctEndDate = enddDate.setDate(enddDate.getDate() + 1)
        //$scope.datenewRange = {"start_date" : new Date($scope.dateRange.start_date), "end_date" : new Date(correctEndDate)};
        $scope.datenewRange = {"start_date" : moment($scope.dateRange.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(), "end_date" :  moment($scope.dateRange.end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate()};
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                return $http.post("/api/getmissedpunchreport", {
                    count: params.count(),
                    page: params.page(),
                    dateRange: $scope.datenewRange,
                    role: SessionService.currentUser.roles[0],
                    location : SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true;
                    params.total(response.data.total); // recal. page nav controls
                    $scope.totalCountInRange = 0;
                    response.data.data.forEach(function(elem, key){
                    	$scope.totalCountInRange = parseInt($scope.totalCountInRange) + parseInt(elem.missedPunchOut);
                    });
                    $scope.report = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();

                    if (response.data.data.length < 1) {
                        $scope.errormessage = "No Report found for this selection.";
                    }
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.report;
                })
            }
        });
    }
    $scope.getReport();
    $scope.showTimesheets = function(timesheetsData){
        $scope.clockdata = [];
        var timeSheetIds = [];
        var i = 0;
        angular.forEach( timesheetsData, function( value, key ) {
               timeSheetIds.push(value.timesheetId);
               i++;
        });
        if(i == timesheetsData.length){
            $http.post("/api/getTimesheetDataByIds", {timesheetIdsArr:timeSheetIds}).then(function(response) {
                if(response.data.status_code == 200){
                    $scope.timesheetDetailInfo = response.data.data;
                }
            });
        }
    }

    $scope.clockOutFromMissedPunch = function(oneTimeSheetInfo, clockdata){
        if(clockdata){
            $scope.clkInDte        =  oneTimeSheetInfo.clockInDateTime;
            //$scope.fetchExceptDate  =  new Date(new Date($scope.clkInDte).setHours(0, 0, 0, 0));
            $scope.fetchExceptDate =  moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate();
            $http.post('/api/fetchExceptionRate',{eventId : oneTimeSheetInfo.eventId._id, employee:oneTimeSheetInfo.userId._id, fetchExceptionDate : $scope.fetchExceptDate}).then(function(exceptionRes){
                if(exceptionRes.data.status_code == "200"){
                    if(exceptionRes.data.data[0] && exceptionRes.data.data[0].rates){
                        oneTimeSheetInfo.eventId.basic_pay_rate = exceptionRes.data.data[0].rates.price;                        
                    }
                    var clockOutJson = {};
                    clockOutJson.userId = oneTimeSheetInfo.userId._id;
                    clockOutJson.clockInId = oneTimeSheetInfo._id;
                    clockOutJson.clockOutDateTime = moment(clockdata.clockOutDateTime).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
                    clockOutJson.breaktime = clockdata.break * 60000;//convert minute to microseconds
                    var then = oneTimeSheetInfo.clockInDateTime;
                    var nowtime = clockdata.clockOutDateTime;
                    var ms = moment(nowtime).diff(moment(then)); // Millisecond
                    var timeWithoutBreak = ms - clockOutJson.breaktime;
                    var d = moment.duration(timeWithoutBreak);
                    var s = d.asHours().toFixed(2);
                    clockOutJson.totalTimeDiff = timeWithoutBreak;
                    clockOutJson.totalBreakTime = clockOutJson.breaktime;
                    clockOutJson.payrate = oneTimeSheetInfo.eventId.basic_pay_rate;
                    clockOutJson.payroll = parseFloat(s*oneTimeSheetInfo.eventId.basic_pay_rate).toFixed(2);
                    $http.post('/api/clockOutByAdmin',clockOutJson).then(function(response){
                        if("200" === response.data.status_code){
                            toastr.success("Successfully Done!");
                            angular.element('#modal-timesheets').modal('hide');
                            $scope.getReport();
                        }
                    });
                }
            });
        }else{
            toastr.error("Please fill information for clock out");
        }
        
    }
});