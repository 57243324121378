angular.module("app").controller("OverlapreportController", function($scope,ports,$filter,$location, SessionService, $http, UserService, $timeout, getweekService,NgTableParams, moment) {
    $scope.user = SessionService.currentUser;
    $scope.searchText = {};
    $scope.searchText.val = '';
    $scope.sortType='';
    $scope.sortOrder = undefined;
    var $modal = $("#get-modal");
    $scope.errormessage = "Please enter your selection to get the report.";
    $scope.report = [];
    $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
        if (response.status == 200) {
            response.data.data.forEach(function(elem, key){
                elem.ticked = false;
                elem.fullname = elem.first_name+' '+elem.last_name;
            });
            $scope.modernWebBrowsers = response.data.data;
        }
        else {
            console.log('400');
        }
    });
    
    $http.get("/api/getAllInitalzips").then(function(response) {
        if(response.status == 200){
            $scope.AllInitials = response.data.data;
        }
    });

    $scope.matchZipInInitial = function(zipvalue){
        var zipVal = '';
        if(zipvalue){
            for(i = 0; i < $scope.AllInitials.length; i++){
                if($scope.AllInitials[i].zipcodes.indexOf(zipvalue) >= 0){
                    zipVal = $scope.AllInitials[i].initial;
                    break;
                }
            }
        }
        return zipVal;
    }

    $scope.twentyfour_to_twelve = function(ti){
        ti = ti.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [ti];
        if (ti.length > 1) { // If time format correct
            ti = ti.slice (1);  // Remove full string match value
            ti[5] = +ti[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            ti[0] = +ti[0] % 12 || 12; // Adjust hours
        }
        return ti.join (''); // return adjusted time or original string
    }

    $scope.timeToDecimal = function(ms) {
        if(ms){
            var dayVal = parseInt(moment.utc(ms).format("DDD"));
            if(dayVal > 1){
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var dayhours = (parseInt(moment.utc(ms).format("DDD")) - 1) * 24;
                var newhours = parseInt(hours + dayhours);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(newhours + minutes / 60).toFixed(2);
                return decimalVal;
            }else{
                var time = moment.utc(ms).format("HH:mm");
                var hoursMinutes = time.split(/[.:]/);
                var hours = parseInt(hoursMinutes[0], 10);
                var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
                var decimalVal = parseFloat(hours + minutes / 60).toFixed(2);
                return decimalVal;
            }
        }else{
            return 0;
        }
    }

    $scope.getDate = function (time) {
      var today = new Date();
      var _t = time.split(":");
      today.setHours(_t[0], _t[1], 0, 0);
      return today;
    }

    $scope.validate = function (sTime, eTime, timeList) {
      if ($scope.getDate(sTime) < $scope.getDate(eTime)) {
        var len = timeList.length;
        if(len > 0){
            if(($scope.getDate(sTime) > $scope.getDate(timeList[len - 1].startTime)) && ($scope.getDate(sTime) < $scope.getDate(timeList[len - 1].endTime))){
                var returval = false;
            }else if(($scope.getDate(eTime) > $scope.getDate(timeList[len - 1].startTime)) && ($scope.getDate(eTime) < $scope.getDate(timeList[len - 1].endTime))){
                var returval = false;
            }else if(($scope.getDate(sTime) < $scope.getDate(timeList[len - 1].startTime)) && ($scope.getDate(eTime) > $scope.getDate(timeList[len - 1].startTime))){
                var returval = false;
            }else{
                var returval = true;
            }
        }else{
            var returval = true;
        }
      } else {
        var returval = false;
      }
      return returval;
    }

    $scope.pageNum = 1;
    $scope.getReport = function() {
        $scope.showloader = true;
        if ($scope.sortType == '') {
            $scope.sortType = 'scheduleDate';
            $scope.sortOrder = 1;
        }
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/getEmpDaySchedules", {
                    //currDate : new Date(new Date().setHours(0, 0, 0, 0)),
                    currDate: moment().tz(SessionService.currentUser.locationtimezone).startOf('day').toDate(),
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder: $scope.sortOrder,
                    location:SessionService.currentUser.location
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    $scope.report = response.data.data;
                    $scope.correctReport = [];
                    var x = 1;
                    $scope.report.forEach(function(elem, key){
                        $scope.timeList = [];
                        var y = 1;
                        var loopcontinue = true;
                        elem.overlapclass = '';
                        elem.entries.forEach(function(el, ky){
                            if(el.checkInOverlap){
                                elem.overlapclass = "bg-gray";
                            }else{
                                elem.overlapclass = "bg-red";
                            }
                            if(loopcontinue){
                                var startTime = el.shiftReminderTime;
                                var endTime = el.shiftEndTime;
                                if (!$scope.validate(startTime, endTime, $scope.timeList)){
                                    $scope.correctReport.push(elem);
                                    loopcontinue = false;
                                }else{
                                    $scope.timeList.push({
                                      startTime: startTime,
                                      endTime: endTime
                                    });
                                }
                            }
                        });
                        if(x === $scope.report.length){
                            return $scope.correctReport;  
                        }
                        x++;
                    })
                    if (!response.data.total || !$scope.correctReport.length) {
                        $scope.haveResult = false;
                    }
                })
            }
        });
    }

    $scope.showOverlappings = function(entries){
        $scope.shiftsData = entries;
    }

    $scope.fetchOverlap = function(){
        angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
            if ( value.ticked === true ) {
               storeSelEmployeeId = value;
            }
        });
        $scope.searchText.val = storeSelEmployeeId._id;
        $scope.getReport();
    }

    $scope.getReport();   

    $scope.reset_page=function() {
        $scope.searchText.val = '';
        var storeSelEmployeeId = '';
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
                if ( value.ticked === true ) {
                   storeSelEmployeeId = value;
                   $scope.searchText.val = storeSelEmployeeId._id;
                }
            });
            
        }
        if(!$scope.searchText.val){
               $scope.searchText.val = '';
               $scope.showloader = true;
               $scope.getReport();
        }  
    }
    $scope.checkOverlap = function(schDate, empId, overlap){
        var overlapJson = {};
        overlapJson.scheduleDate = schDate;
        overlapJson.employee_id = empId;
        overlapJson.checkOverlapVal = !overlap;
        $http.post("/api/markOverlapReport", overlapJson).then(function(response) {
            if(response.status == 200){
                $scope.getReport();
            }
        });
    }

});