angular.module("app").controller("EventCalendarController", function($scope, NotificationService,TimeSlotResource, $http,$q,sidepanelactiveService, SessionService, moment) {
	var $modal = $('#event-modal');
	sidepanelactiveService.test();
	$scope.goback = function(){
		window.history.back();
	}
    var events = [];
    // CODE TO FETCH COMPLETE LIST FOR ADMIN
    $http.post("/api/eventsByLocation",{location:SessionService.currentUser.location}).then(function(response){
	if ( "200" === response.data.status_code) {
	    response.data.events.forEach(function(elem, key){
			var event_details = [];
			var loc_city = (elem.location_address!=undefined)?elem.location_address.city:'';
			var contact_phone = (elem.event_data!=undefined)?elem.event_data.contact_phone:'';
			event_details.push(elem);
			elem.end_date_copy = elem.end_date;
			var elem_end_date = moment(elem.end_date_copy).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
			//elem.end_date_updated = elem_end_date.setDate(elem_end_date.getDate() + 1);
			elem.end_date_updated = elem_end_date;
			var classVal = "bg-calendar";
			if(elem.event_data.equip_assigned){
				var newborderclass = " fa fa-check";
				var classVal = classVal + newborderclass;	
			}else{
				var newborderclass = "";
				var classVal = classVal + newborderclass;	
			}
			events.push({start:moment(elem.start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(),end: moment(elem.end_date_updated).clone().tz(SessionService.currentUser.locationtimezone, true).toDate(),title:elem.name,className: classVal,"allData":event_details,"shiftData":elem.shift_template_id,eventid:elem._id});
	    });
	}
	else {
	    toastr.error("Unable to fetch list this time!!");
	}
	var calendar = $('#calendar').fullCalendar({
	    events : events,
	    eventClick: function (calEvent, jsEvent, view) {
		$scope.$apply(function () {
			$scope.scheduledData = calEvent.allData;
			$scope.shiftData = calEvent.shiftData;
			$scope.start_date = moment($scope.scheduledData[0].start_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
			$scope.end_date = moment($scope.scheduledData[0].end_date).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
			eventId = calEvent.eventid;
			$http.post("/api/otherEmpOnEventsNoShift",{event:eventId}).then(function(innerresponse){
			    if ( "200" === innerresponse.data.status_code) {
				    if (typeof innerresponse.data!="undefined") {
					    var empidarr = [];
					    innerresponse.data.data.forEach(function(elem, key){
						if (empidarr.indexOf(elem.employee_id._id) != -1) {
						    innerresponse.data.data.splice(key,1);
						}else{
						    empidarr.push(elem.employee_id._id);
						}
					    });
					    $scope.sameemployees = innerresponse.data.data;
				    }else{
					    var sameemployees = {};
				    }
			    }
			}); 
		});
		$modal.modal();
	    }
	});
    })
});