angular.module("app").controller("ViewManagerEmployeeController", function($scope,ports, $location, SessionService, $http, $filter,$rootScope, sidepanelactiveService) {
    // Show side panel active
    sidepanelactiveService.test();
    $scope.showloader= true;
    $scope.searchText={};
    $scope.user = SessionService.currentUser;    
    $(document).ready(function(){
        $("body").removeClass("events");
    });
    
    var orderBy = $filter('orderBy');

    //listing of more dan 5 employees
    $scope.liststs = [
        {name:"active", value:'true'},
        {name:"inactive", value:'false'},
        {name:"All", value:""},
            ];
    
    $scope.listemp = [
        {name: "5", value: 5},
        {name: "10", value: 10},
        {name: "15", value:15},
        {name: "20", value: 20},
        {name: "All", value:'All'},
         ];

    $scope.currentPage=5;
    
    
    $scope.getmanagerempldata=function(){
        // Get user listing
    $http.post("/api/getmanagerempl", {manager_id: SessionService.currentUser._id}).then(function(response){
        if (response.status == 200) {
            $scope.showloader = false;
            $scope.empData = response.data.data;
            if ($scope.empData.length > 0)
                $scope.haveResult = true;
            else
                $scope.haveResult = false;
            setPagingData($scope.empData);         
        }
        else {
            console.log('400');
        }
    });
    };
    $scope.getmanagerempldata();
    
    $scope.reverse = false;
    $scope.order = function(predicate, reverse) {
      $scope.empData = orderBy($scope.empData, predicate, reverse);
    };
    
    /* Filter in employee listing - 15-10-2014 */
    $scope.showEmp = function(empid) {
        $location.path('/employee/edit/:' + empid);
    };
    $scope.filter = {};

    $scope.filterEmp = function() {
        var userRole = SessionService.currentUser.roles[0];
        var userId = SessionService.currentUser._id;
        var filter_start_date = $scope.filter.start_date;
        var filter_end_date = $scope.filter.end_date;
        // Global variable declaration
        $rootScope.filter_start_date = filter_start_date;
        $rootScope.filter_end_date = filter_end_date;
        
        if ( filter_start_date === undefined || filter_start_date === "" ) {
            toastr.error("Please select start date.");
            return false;
        }
        else {
            if ( filter_end_date === undefined || filter_end_date === "" ) {
                //code to fetch data only from start_date
                var filter_start_date = new Date(filter_start_date).toUTCString();
                var filter_end_date = new Date(filter_end_date).toUTCString();
                $http.post('/api/filterEmpByDate', {filter_start_date: filter_start_date, userRole: userRole, userId: userId}).then(function(response){
                    $scope.empData = response.data.data;
                    setPagingData($scope.empData);
                });
            }
            else {
                //code to fetch data from start_date and end_date
                var filter_start_date = new Date(filter_start_date).toUTCString();
                var filter_end_date = new Date(filter_end_date).toUTCString();
                $http.post('/api/filterEmpByDate', {filter_start_date: filter_start_date, filter_end_date: filter_end_date, userRole: userRole, userId: userId}).then(function(response){
                    $scope.empData = response.data.data;
                    setPagingData($scope.empData);
                });
            }
        }
    };

    $scope.perPage = 5;
    /** Pagination Code START **/
    function setPagingData(datam){
        $scope.allData = {};
        // $scope.perPage = 5;
        //$scope.allData = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
        $scope.allData = datam;
        $scope.offset = 0;
        $scope.navButtons = [];
        
        $scope.isPreviousDisabled = false;
        $scope.isNextDisabled = false;

        $scope.buildNavButtons = function () {
            for (var i = 0, len = ($scope.allData.length / $scope.perPage); i < len; i = i + 1) {
                $scope.navButtons.push(i);
            }
            //alert("Nav Buttons : " + $scope.navButtons);
        };

        $scope.paginate = function() {
            $scope.empData = $scope.allData.slice($scope.offset, $scope.offset + $scope.perPage);
            //alert("Sliced Data : " + $scope.data);
        };

        $scope.previous = function() {                    
            $scope.offset = $scope.offset - $scope.perPage;
        };

        $scope.next = function() {
            //alert("offset value : "+os);
            $scope.offset = $scope.offset + $scope.perPage;
            
        };	
        $scope.getoffset = function(offsetval){
            $scope.offset = offsetval;            
        }
        $scope.$watch('offset', function() {
            if( $scope.offset < 0 ){
                $scope.isPreviousDisabled = true;
                $scope.isNextDisabled = false;
                $scope.offset = 0;
                return false;
            }
            else{
                $scope.isPreviousDisabled = false;
            }
            
            //disable Next button
            //if( $scope.offset > (($scope.perPage * ($scope.allData.length/$scope.perPage))-1) ){
            if( $scope.offset > ($scope.allData.length-1) ){
                $scope.isNextDisabled = true;
                $scope.isPreviousDisabled = false;
                //alert("Next button disabled : "+$scope.offset);
                $scope.offset = ($scope.perPage * ($scope.allData.length/$scope.perPage));
                //alert("Offset Value changed to : "+$scope.offset);
                return false;
            }
            else{
                $scope.isNextDisabled = false;
            }
            $scope.paginate();
            //alert("In else...");
        });
        $scope.buildNavButtons();
    }            
    /** Pagination Code ENDS **/

     $scope.$watch(function(scope,$http) 
        { return scope.currentPage },
      function(newValue, oldValue) 
      {
          if(newValue != "All"){
            // alert("notall");
          $scope.perPage = parseInt(newValue);
          }
          else{
                // alert("all");
            $scope.perPage= newValue;
        }
          $scope.updatepagination($http);
      }
     );
        
    $scope.$watch(function(scope,$http) 
        { return scope.status },
      function(newValue, oldValue) 
      {
         if(newValue=='true'){
            $scope.empData=$scope.activedata;
        }
        else if(newValue=='false'){
              $scope.empData=$scope.inactivedata;
        }
        else{
            $scope.empData=$scope.all_Data;
        }
         if ($scope.empData.length > 0)
                $scope.haveResult = true;
         else
                $scope.haveResult = false;
         setPagingData($scope.empData);
      }
     );
    
     $scope.chk_status=function(newValue){
        if(newValue=='true'){
            $scope.empData=$scope.activedata;
        }
        else if(newValue=='false'){
              $scope.empData=$scope.inactivedata;
        }
        else{
            $scope.empData=$scope.all_Data;
        }
        setPagingData($scope.empData);
        $scope.$apply();
    }
    
     $scope.updatepagination=function($http){
        $http.post("/api/getmanagerempl", {manager_id: SessionService.currentUser._id}).then(function(response){
        if (response.status == 200) {
            $scope.empData = response.data.data;
            $scope.all_Data = $scope.empData;
            $scope.activedata=[];
            $scope.inactivedata=[];
            for(i in $scope.empData)
            {
                if($scope.empData[i].active== true){
                    $scope.activedata.push($scope.empData[i]);
                }
                else{
                    $scope.inactivedata.push($scope.empData[i]);
                }
            }
             if ($scope.status) {
                $scope.chk_status($scope.status);
             }
             
             
            if($scope.perPage=="All"){
                $scope.perPage=$scope.empData.length;
            }
            if ($scope.empData.length > 0)
                $scope.haveResult = true;
            else
                $scope.haveResult = false;
            setPagingData($scope.empData);
            $scope.visibility = true;
        }
        else {
            console.log('400');
        }
    });
    }

    


     //to delete user
     $scope.delete_managerempl=function(id){
            $http.post("/api/delmanagerempl",{empid:id} ).then(function(response){
                if(response.data.status_code== "200"){
                    toastr.success("Successfully Deleted");
                }
            });
            $scope.getmanagerempldata();    
        }
    
     $scope.check_usrpwd=function(){
        $http.post("/api/checkPwd",{empid:$scope.user._id,password:$scope.pwd}).then(function(response){
               $('#confirm_del').hide();
                if(response.data.status_code== "200"){
                    
                $scope.delete_managerempl($scope.del_empid);
                }
                else{
                    alert("You are not authorized");
                }

        });
    }
    //password to delete
    $scope.cnfrm_pwd=function(emp,e){
        e.stopPropagation();
        $scope.del_empid=emp;
        $('#confirm_del').show();
    }
    /*function to addemp*/
    $scope.addemp=function() {
        $scope.showloader= true;
        $location.path("/addemployee");
    }

    
});