angular.module("app").controller("DocumentLibraryController", function($scope, $upload, Upload, $timeout, $http, $location, PDFViewerService, SessionService, NgTableParams, $sce, $window, moment){
    $scope.viewer = PDFViewerService.Instance("viewer");
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.filenamePattern = /^[a-zA-Z0-9\#\@\_\-]+( [a-zA-Z0-9\#\@\_\-]+)*$/;
    $scope.foldernamePattern = /^[a-zA-Z0-9\_]+([a-zA-Z0-9\_]+)*$/;
    $scope.linktitlePattern = /^[a-zA-Z0-9\_]+([a-zA-Z0-9\_]+)*$/;
    $scope.link = /^[a-zA-Z0-9\_]+([a-zA-Z0-9\_]+)*$/;
    $scope.tmppdfurl = '';
    $scope.nextPage = function() {
        $scope.viewer.nextPage();
    };
    $scope.prevPage = function() {
        $scope.viewer.prevPage();
    };
    $scope.zoomOut = function() {
        $scope.viewer.zoomOut();
    };
    $scope.zoomIn = function() {
        $scope.viewer.zoomIn();
    };
    $scope.Fit = function() {
        $scope.viewer.fit();
    };
    $scope.gotoPage = function(page) {
        $scope.viewer.gotoPage(page);
    };
    $scope.fullPdf = function(){
        $window.open($scope.pdfurl);
    };
    $scope.$watch("currentPage",function(newValue,oldValue) {
        if(newValue != oldValue){
            $scope.viewer.gotoPage(parseInt($scope.currentPage));
        }
    });
    $scope.loadProgress = function(loaded, total, state) {
        console.log('loaded =', loaded, 'total =', total, 'state =', state)
    }

    $scope.pageLoaded = function(curPage, totalPages) {
        $scope.currentPage = curPage;
        $scope.totalPages = totalPages;
    };

    $scope.checkOnTop = function(docId, onTop){
        var checkJson = {};
        checkJson.doc_id = docId;
        checkJson.checkOnTopVal = !onTop;
        $http.post("/api/checkOnTopDocument", checkJson).then(function(response) {
            if(response.status == 200){
                $scope.getfolders();
            }
        });
    }

	$scope.visibility = false;
    $scope.haveResult = true;
    $scope.searchText={};
    $scope.sortType='';
    $scope.sortOrder=undefined;
    $scope.showloader= true;
	$scope.getfolders = function(){
		$scope.showloader = true;
		if ($scope.sortType == '') {
            $scope.sortType = 'checkOnTop';
            $scope.sortOrder = -1;
        }
        var search = "";
        if ($scope.searchText.val) {
            search = $scope.searchText.val;
        }
        $scope.tableParams = new NgTableParams({
            count: ($scope.count) ? $scope.count : 10,
            page: ($scope.pageNum) ? $scope.pageNum : 1
        }, {
            getData: function(params) {
                $scope.pageNum = params.page(); 
                return $http.post("/api/getfolders", {
                    count: params.count(),
                    page: params.page(),
                    search: search,
                    field: $scope.sortType,
                    sortOrder:$scope.sortOrder,
                    owner_id: SessionService.currentUser._id,
                    role: SessionService.currentUser.roles[0]
                }).then(function(response) {
                    $scope.showloader = false;
                    $scope.visibility = true
                    params.total(response.data.total); // recal. page nav controls
                    response.data.data.forEach(function(elem, key){
                    	/*elem.foldername = elem._id.foldername;
                    	elem.created_date = elem._id.created_date;
                    	elem.created_by = elem._id.created_by;
                    	elem.is_deleted = elem._id.is_deleted;
                    	elem._id = elem._id._id;*/
                        elem.onTopclass = '';
                        if(elem.checkOnTop){
                            elem.onTopclass = "bg-gray";
                        }
                        if(elem.my_file.length && !elem.foldername){
                            var is_deleted = elem.my_file[0].is_deleted ? elem.my_file[0].is_deleted : false;
                            elem.my_file[0].filetype = elem.my_file[0].filepath.slice(-3);
                        }
                        if(elem.my_file.length && elem.foldername){
                            elem.my_file.forEach(function(el, ke){
                                elem.my_file[ke].filetype = el.filepath.slice(-3);
                            });
                        }
                    });
                    $scope.folderData = response.data.data;
                    $scope.totalRecords = response.data.total;
                    $scope.count = params.count();
                    $scope.haveResult = true;
                    if (!response.data.total) {
                        $scope.haveResult = false;
                    }
                    return $scope.folderData;
                })
            }
        });
	}
	$scope.uploadfile = function(folderId){
		if(folderId == "noFile"){
			$scope.folderId = '';
		}else{
			$scope.folderId = folderId;
		}
		angular.element('input[type=file]').click();
	}
    $scope.getfolders();
	$scope.uploadDocs = function (files) {
        var allowedMimeTypes = ["application/pdf", "video/x-flv", "video/mp4"];
        if (files && files.length) {
            var filecount = 0;
            $scope.fileError = false;
            angular.forEach( files, function( value, key ) {
                if(value.size > 20971520){
                    $scope.toasterror = "Max File Size : 20MB";
                    $scope.fileError = true;
                    return;
                    $scope.progress = -1;
                }
                if (allowedMimeTypes.indexOf(value.type) == -1) {
                    $scope.toasterror = "Only pdf documents and videos(flash, mp4) are supported.";
                    $scope.fileError = true;
                    return false;
                    $scope.progress = -1;
                }
                filecount++;
                if(filecount == files.length && !$scope.fileError){
                    $scope.files = files;
                    if (files && files.length) {
                        Upload.upload({
                            url : '/api/docupload',
                            method: $scope.httpMethod,
                            headers: {'my-header': 'my-header-value'},
                            data : {
                                myModel : $scope.myModel
                            },              
                            file: files,
                            fileFormDataName: 'myDoc'
                        }).then(function (response) {
                            $timeout(function () {
                            	$scope.folder = {};
                                $scope.folder.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
								$scope.folder.created_by = SessionService.currentUser._id;
								if($scope.folderId){
									$scope.folder.folderid = $scope.folderId;
								}
								$scope.folder.my_file = response.data.data;
                                $http.post("/api/savedocument", $scope.folder).then(function(response){
                                	toastr.success("Successfully Uploaded.");
                                    $scope.tableParams.reload();
                                });
                            });
                        }, function (response) {
                            if (response.status > 0) {
                                $scope.errorMsg = response.status + ': ' + response.data;
                            }
                        }, function (evt) {
                            $scope.progress = 
                                Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        });
                    }
                }
            });

            if($scope.fileError){
                toastr.error($scope.toasterror);
            }
        }
    };
	
	$scope.$watch(function(scope,$http) 
        { return scope.searchText.val },
          function(newValue, oldValue) 
          {
              if(newValue != oldValue){
                $scope.pageNum = 1;
                $scope.getfolders();
              }
          }
    );
    $scope.createFolder = function(){
        $scope.folderForm.$setPristine();
        $scope.buttondisable = true;
		$scope.folderdata.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
		$scope.folderdata.created_by = SessionService.currentUser._id;
		var folderdata = $scope.folderdata;
		$http.post("/api/folders", folderdata).then(function(response){
			if(response.data.status_code === "200"){
				toastr.success("Folder is created successfully.","",{"timeOut":500});
				angular.element('#modal-basic').modal('hide');
                $scope.tableParams.reload();
				$scope.getfolders();
			}else{
				toastr.error("Somthing Wrong!","",{"timeOut":500});
			}
		});
	}

    $scope.enableFolderOpen = function(){
        $scope.folderdata = {};
        $scope.buttondisable = false;
    }

    $scope.createLink = function(folderId){
        $scope.linkdata.created_date = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
        $scope.linkdata.created_by = SessionService.currentUser._id;
        var linkdata = $scope.linkdata;
        linkdata.folderId = folderId;
        $http.post("/api/link", linkdata).then(function(response){
            if(response.data.status_code === "200"){
                toastr.success("Link is added successfully.","",{"timeOut":500});
                angular.element('#modal-link').modal('hide');
                $scope.tableParams.reload();
                $scope.getfolders();
            }else{
                toastr.error("Somthing Wrong!","",{"timeOut":500});
            }
        });
    }

    $scope.AddLinkToFolder = function(folderId){
        $scope.folderId = folderId;
        $scope.linkdata = '';
    }

    $scope.viewLink = function(dataInfo){
        $scope.linktitle = dataInfo.linktitle;
        $scope.link = dataInfo.link;
    }
	$scope.sortOrderby = function(sortstring, sort) {
           $scope.sortType = sortstring;
           $scope.sortOrder= sort;
           if($scope.sortOrder == false){
            $scope.sortOrder = 1;
           }else{
            $scope.sortOrder = -1;
           }
           $scope.getfolders();
    }
	$scope.getIcon = function(column) {
            if ($scope.sortType == column) {
              return $scope.sortOrder == 1
                ? 'fa-caret-up'
                : 'fa-caret-down';
            }
            return 'fa-sort';
    }
    $scope.goTofolder = function(folderdetails){
    	$scope.folderInfo = folderdetails;
    }

    $scope.download = function(folder){
    	$scope.file = [];
        var a = folder.my_file;
        if (a) {
            a.forEach(function(value, key) {
                $scope.file.push(value)
            });
        }
        $http.post("/api/docdownload", {
            'file': $scope.file,
            'id': folder._id
        }).then(function(response) {
            if ("200" === response.data.status_code) {
                window.open($scope.backendsiteurl+ '/public/zipFile' + response.data.zipFilePath.substr(response.data.zipFilePath.lastIndexOf('/'), response.data.zipFilePath.length))
            }
        });
    }

    $scope.downloadfile = function(fileid, filename){
    	$scope.file = [];
        $scope.file.push(filename)
        $http.post("/api/docdownload", {
            'file': $scope.file,
            'id': fileid
        }).then(function(response) {
            if ("200" === response.data.status_code) {
                window.open($scope.backendsiteurl+ '/public/zipFile' + response.data.zipFilePath.substr(response.data.zipFilePath.lastIndexOf('/'), response.data.zipFilePath.length))
            }
        });
    }
    
    $scope.viewfile = function(fileId, filename){
        angular.element('#modal-folder').modal('hide');
        $scope.pdfurl = '';
        $scope.showloader= true;
        $scope.filename = filename;
        $http.get('/api/viewfile/'+fileId,{responseType: 'arraybuffer'}).success(
            function(response) {
                var file = new Blob([response], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                $scope.useurl = URL.createObjectURL(file);
                $scope.showloader= false;
                angular.element('#modal-view').modal('show');
                $scope.pdfurl = $sce.trustAsResourceUrl(fileURL);
            }
        ); 
    }

    $scope.renameFile = function(details, record){
        $scope.filedetails = details;
        $scope.filedetails.recordId = record;
        $scope.changedname = $scope.filedetails.filename;
    }
    $scope.renameLink = function(details){
        $scope.linkdetails = details;
        $scope.linkdetails.recordId = details._id;
    }

    $scope.renameFolder = function(details){
        $scope.folderdetails = details;
        $scope.changedfoldername = $scope.folderdetails.foldername;
    }

    $scope.renameFileSave = function(){
        $scope.filedetails.filename = $scope.changedname;
        $http.post('/api/renameFilename',$scope.filedetails).then(
            function(response){
                if ("200" === response.data.status_code) {
                    toastr.success("Success!");
                    angular.element('#modal-renamefile').modal('hide');
                }
            }
        );
    }

    $scope.renameLinkSave = function(){
        $http.post('/api/renameLink',$scope.linkdetails).then(
            function(response){
                if ("200" === response.data.status_code) {
                    toastr.success("Success!");
                    angular.element('#modal-renamelink').modal('hide');
                }
            }
        );
    }

    $scope.renameFolderSave = function(){
        $scope.folderdetails.foldername = $scope.changedfoldername;
        $http.post('/api/renameFoldername',$scope.folderdetails).then(
            function(response){
                if ("200" === response.data.status_code) {
                    toastr.success("Success!");
                    angular.element('#modal-renamefolder').modal('hide');
                }
            }
        );
    }

    $scope.removeFile = function(details, record, locationFile){
        $scope.removefileData = details;
        $scope.removefileData.recordId = record;
        $scope.removeType = 'file';
        $scope.filelocation = locationFile;
        $('#confirm_del').show();
    }

    $scope.removeFolder = function(details){
        $scope.removefolderData = details;
        $scope.removeType = 'folder';
        $('#confirm_del').show();
    }

    $scope.removeLink = function(details, record, locationFile){
        $scope.removelinkData = details;
        $scope.removelinkData.recordId = record;
        $scope.filelocation = locationFile;
        $scope.removeType = 'link';
        $('#confirm_del').show();
    }

    $scope.removefileDb = function(removefiledata){
        removefiledata.fileloc = $scope.filelocation;
        $http.post('/api/removeFile',removefiledata).then(
            function(response){
                if ("200" === response.data.status_code) {
                    angular.element('#modal-folder').modal('hide');
                    $scope.getfolders();
                    toastr.success("Success!");
                }
            }
        );
    }
    $scope.removefolderDb = function(removefolderData){
        $http.post('/api/removeFolder',removefolderData).then(
            function(response){
                if ("200" === response.data.status_code) {
                    $scope.getfolders();
                    toastr.success("Success!");
                }
            }
        );
    }
    $scope.removelinkDb = function(removelinkDb){
        removelinkDb.fileloc = $scope.filelocation;
        $http.post('/api/removeLink',removelinkDb).then(
            function(response){
                if ("200" === response.data.status_code) {
                    angular.element('#modal-folder').modal('hide');
                    $scope.getfolders();
                    toastr.success("Success!");
                }
            }
        );
    }


    $scope.deleteDocument=function(){
        $('#confirm_del').hide();
        if($scope.removeType == 'file'){
            $scope.removefileDb($scope.removefileData);
        }
        if($scope.removeType == 'folder'){
            $scope.removefolderDb($scope.removefolderData);
        }
        if($scope.removeType == 'link'){
            $scope.removelinkDb($scope.removelinkData);
        }
    }

    $scope.goTolink = function(link){
        $window.open(link);
    }


}).filter('substring', function(){
    return function(input){
        return(!!input) ? input.substr(input.lastIndexOf("___")+3) : '';
    }
});