angular.module("app").service('fileUpload', ['$http', function ($http) {
    /*
    this.uploadFileToUrl = function(file, uploadUrl){
        var fd = new FormData();
        fd.append('file', file);
        $http.post(uploadUrl, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        })
        .success(function(){
        })
        .error(function(){
        });
    }
    */
    
    return {
      uploadFileToUrl: function(file, uploadUrl, successCb, errorCb){
        var fd = new FormData();
        fd.append('file', file);
        $http.post(uploadUrl, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        })
        .success(function(response){
          if(successCb){
            successCb(response);
          }
        })
        .error(function(){
        });
      }
    }
    
}]);