angular.module("app").controller("DashController", function($scope, $rootScope, $filter, $route, ports, NotificationService, SessionService,AuthenticationService,TimeSlotResource, $location, $timeout, SocketService, sidepanelactiveService, getweekService, $http, $q, $window, User, moment) {
	$scope.welcomemessage = true;
    $scope.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $timeout(function() {
        $rootScope.welcomemessagestatus = true;
    }, 5000);

   $http.get("/api/getAllLocations").then(function(response){
	    if (response.status == 200) {
	        response.data.data.forEach(function(elem, key){
	        	elem.ticked = false;
	        	elem.fullname = elem.title;
	        	if(SessionService.currentUser.location == elem._id){
	        		elem.ticked = true;
	        		$scope.locationshortname = elem.title;   
	        		$scope.fullName = SessionService.currentUser.first_name + ' ' + SessionService.currentUser.last_name + ' (' + $scope.locationshortname + ')';
	        	}              
	        });
	        $scope.locationmodernWebBrowsers = response.data.data;
	    }
	    else {
	        console.log('400');
	    }
    });  

    $scope.updateLocationinStorage = function(){
    	if($scope.locationmodernWebBrowsers){
            angular.forEach( $scope.locationmodernWebBrowsers, function( value, key ) {
                if ( value.ticked === true ) {
                   $scope.locationInfo = value;
                   var userinfo = SessionService.currentUser;
                   userinfo.location =  value._id;
                   userinfo.locationtimezone =  value.locationtimezone;
                   SessionService.storeUser(userinfo);
                   $scope.fullName = SessionService.currentUser.first_name + ' ' + SessionService.currentUser.last_name + ' (' + value.title + ')';
                   angular.element('#modal-changelocation').modal('hide');
				   $route.reload();
                }
            });
        }
    }


    
	// On First Time Login
	var locVal = $location.search();
	if( "true" === locVal.loggedin )
	sidepanelactiveService.test();	
    $scope.backendsiteurl = $location.protocol()+'://'+$location.host();
	$scope.role = '';
    $scope.fullName = SessionService.currentUser.first_name + ' ' + SessionService.currentUser.last_name;
	$scope.userFullData = SessionService.currentUser;
	$scope.password_change = SessionService.currentUser.password_change;
	$scope.prof_image = SessionService.currentUser.prof_image || "test";
	$scope.user = SessionService.currentUser;	
	$scope.pagetitle = function(pagetitle){
		$scope.title = pagetitle;
		$scope.selected = $location.path(); 
	};
	
	if($location.path() === '/dashboard'){
		$scope.title = 'Dashboard';
	}
	else if($location.path() === '/myavailability'){
		$scope.title = 'My Availability';
	}
	else if($location.path() === '/viewschedule'){
		$scope.title = 'My Schedule';
	}
	else if($location.path() === '/memolist'){
		$scope.title = 'Memos';
	}
	else if($location.path() === '/timeslots'){
		$scope.title = 'Time Slot';
	}
	else if($location.path() === '/eventlist'){
		$scope.title = 'Events';
	}
	else if($location.path() === '/reoccurringaccount'){
		$scope.title = 'Regular Accounts';
	}
	else if($location.path() === '/empschedule'){
		$scope.title = 'Employee Availability';
	}
	else if($location.path() === '/emptoschedule'){
		$scope.title = 'Employees Available to Schedule';
	}
	else if($location.path() === '/empAvlscheduled'){
		$scope.title = 'Employee Schedule/Availability';
	}
	else if($location.path() === '/schedule'){
		$scope.title = 'Employee Scheduling';
	}
	else if($location.path() === '/schedulereport'){
		$scope.title = 'Schedule Report';
	}
	else if($location.path() === '/reports'){
		$scope.title = 'Availability Report';
	}
	else if($location.path() === '/totalhoursreport'){
		$scope.title = 'Total Hours Report';
	}
	else if($location.path() === '/timeslotbuffer'){
		$scope.title = 'Weekly Time Slot Buffer';
	}
	else if($location.path() === '/timeofflist'){
		$scope.title = 'Time Off Requests';
	}
	else if($location.path() === '/viewticket'){
		$scope.title = 'Ticket System';
	}
	else if($location.path() === '/viewpayrolllist'){
		$scope.title = 'Payrolls';
	}
	else if($location.path() === '/viewadminemployee' || $location.path() === '/viewmanageremployee'){
		$scope.title = 'Employees';
	}
	else if($location.path() === '/todolist' || $location.path() === '/todocalendar'){
		$scope.title = 'To Do';
	}
	else if($location.path() === '/mytodolist' || $location.path() === '/mytodocalendar'){
		$scope.title = 'My To Do Lists';
	}
	else if($location.path() === '/ticketnotes' || $location.path() === '/ticketmonthlynotes'){
		$scope.title = 'Ticket System Report';
	}
	else if($location.path() === '/ticketcomments'){
		$scope.title = 'Ticket System Comments';
	}
	else if($location.path() === '/payrollreport'){
		$scope.title = 'Payroll Report';
	}
	else if($location.path() === '/missedpunchoutreport'){
		$scope.title = 'Missed PunchOut Report';
	}
	else if($location.path() === '/profitlossreport'){
		$scope.title = 'Profit/Loss Report';
	}
	else if($location.path() === '/hoursworkedreport'){
		$scope.title = 'Hours Worked Report';
	}
	else if($location.path() === '/trackclosereport'){
		$scope.title = 'Clocking Report';
	}
	else if($location.path() === '/tradeAdminRequests' || $location.path() === '/tradedetail' || $location.path() === '/adminRejectedTrades' || $location.path() === '/rejectedtradedetail'){
		$scope.title = 'Trade Requests';
	}
	$scope.isActive = function(route) {
		var path = $location.path();
		return route === $location.path() || path.indexOf(route) != -1;
	};
	$scope.isMainActive = function (group) {
		var path = $location.path();
		switch(group) {
			case "employee_info":
				var childRoutes = ["/viewadminemployee", "/addemployee", "/employee/edit", "/empschedule", "/emptoschedule","/empAvlscheduled","/memolist", "/addmemo", "/memo", "/timeofflist", "/timeoffdetail", "/clocking", "/admintimesheet", "/whiteboard", "/employee/availibility", "/viewschedule", "/tradeAdminRequests", "/adminRejectedTrades", "/tradedetail", "/rejectedtradedetail", "/scheduleMsg"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "SchedulingMenu":
				var childRoutes = ["/schedule", "/reportSchedule", "/timeslots", "/timeslotbuffer", "/schCalendar"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "RegularAccounts":
				var childRoutes = ["/reoccurringaccount", "/addaccount", "/eventlist", "/addevent", "/event/edit", "/eventcalendar", "/accountcalendar", "/questionlist"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "ReportsMenu":
				var childRoutes = ["/reports", "/ShiftAcknowledgement", "/ticketnotes", "/ticketcomments", "/ticketmonthlynotes", "/viewticket", "/addticket", "/profitlossreport","/missedpunchoutreport","/hoursworkedreport","/payrollreport","/overlapreport","/trackclosereport","/totalhoursreport"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "MiscMenu":
				var childRoutes = ["/todolist", "/todocalendar", "/documentslib", "/zipinitials", "/locations"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break;
			case "RecognitionMenu":
				var childRoutes = ["/rankRecog", "/evalquestions", "/evaluation", "/empranking"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break; 
			case "PersonalMenu":
				var childRoutes = ["/mytodolist", "/mytodocalendar","/myavailability", "/mytimeoffrequest","/myschedule"]
				if (childRoutes.indexOf(path) >= 0) {
					angular.element('li.active').children('ul').addClass('collapse in');
					angular.element('li.active').children('ul').css('height','unset');
					return 'new-current active';
				} else {
					return false;
				}
				break; 
		}
	}	
  	/*$scope.$on('event:auth-loginRequired', function() {
	  	if(SessionService.currentUser){
		    AuthenticationService.logoutUser().then(function() {
		      $scope.email    = "";
		      $scope.password = "";
		      NotificationService.notify("Your session has expired.");
		      window.location = "/login";
		    });
		}
  	});*/

	if(SessionService.currentUser.roles.indexOf('admin') > -1){
		$scope.role = 'admin';
		$scope.viewprofilelink = "/viewadminprofile";
	}
	else if(SessionService.currentUser.roles.indexOf('manager') > -1){
		$scope.role = 'manager';
		$scope.viewprofilelink = "/viewmanagerprofile";
	}
	else if(SessionService.currentUser.roles.indexOf('employee') > -1){
		$scope.role = 'employee';
		$scope.viewprofilelink = "/viewemployeeprofile";
	}
	else if(SessionService.currentUser.roles.indexOf('hr') > -1){
		$scope.role = 'hr';
		$scope.viewprofilelink = "/viewhrprofile";
	}
	    
	$scope.fetchdatatype = 'today';
	$scope.worktype = function(ty) {
		$scope.showloader = true;
		$scope.fetchdatatype = ty;    
	}
	
	$scope.toggleSubMenu = function($event){
		angular.element($event.currentTarget).parent('li').toggleClass('active').children('ul').collapse('toggle');
    	angular.element($event.currentTarget).parent('li').siblings().removeClass('active').children('ul.in').collapse('hide');
	}


});

