angular.module("app").config(function($routeProvider, $locationProvider, $httpProvider) {
  var interceptor = function($rootScope, $q) {
    $rootScope.sessionErr = true;
    function success(response) {
      return response;
    }
    function error(response) {
      if (!response.headers("authenticated user")) {
        var deferred = $q.defer();
        if($rootScope.sessionErr){
          $rootScope.$broadcast('event:auth-loginRequired', response);
        }
        return deferred.promise;
      }
      return $q.reject(response);
    }
    return function(promise) {
      return promise.then(success, error);
    };
  };
  $httpProvider.responseInterceptors.push(interceptor);

  var routeRoleChecks = {
    admin: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeCurrentUserForRoute("admin");
      }
    },
    manager: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeCurrentUserForRoute("manager");
      }
    },
    hr: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeCurrentUserForRoute("hr");
      }
    },
    adminHr: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeCurrentUserForRoute("adminHr");
      }
    },
    adminManager: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeCurrentUserForRoute("adminManager");
      }
    },
    adminManagerUser: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeCurrentUserForRoute("adminManagerUser");
      }
    },
    adminManagerHrUser: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeCurrentUserForRoute("adminManagerHrUser");
      }
    },
    adminManagerHr: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeCurrentUserForRoute("adminManagerHr");
      }
    },
    user: {
      auth: function(AuthenticationService) {
        return AuthenticationService.authorizeAuthenticatedUserForRoute();
      }
    },
    checkloginState : {
      auth: function(AuthenticationService) {
        return AuthenticationService.checkloggedIn();
      }
    }
  };

  function checklogIn($rootScope, $http, $location,SessionService,$window) {
    if(SessionService.currentUser){ 
      window.location = "/dashboard?loggedin=true";
      return false ; 
    } else{
      return true;
    }
  }

  $routeProvider
  .when("/login", {
    template: JST["app/templates/login"],
    controller: "LoginController"
    //resolve: {'logged_in': checklogIn}
  })
  .when("/editpassword", {
    template: JST["app/templates/editpassword"],
    controller: "PasswordController",
    resolve: routeRoleChecks.user
  })
  .when("/viewshifts", {
    template: JST["app/templates/viewshifts"],
    controller: "ViewShiftsController"
      // resolve: routeRoleChecks.user
  })
  .when("/dashboard", {
    template: JST["app/templates/dashboard"],
    controller: "DashboardController",
    resolve: routeRoleChecks.user
  })
  .when("/forgetpassword", {
    template: JST["app/templates/forgetpassword"],
    controller: "ForgetController"
  }).
  when("/adminprofile", {
    template: JST["app/templates/editemployee"],
    controller: "EditEmployeeController",
    resolve: routeRoleChecks.admin
  }).
  when("/viewadminprofile", {
    template: JST["app/templates/admins/viewadminprofile"],
    controller: "ViewAdminProfileController",
    resolve: routeRoleChecks.admin
  }).
  when("/viewEmployeesToUser", {
    template: JST["app/templates/employees/viewEmployeesToUser"],
    controller: "ViewAdminEmployeeController",
    resolve: routeRoleChecks.user
  }).
  when("/viewadminemployee", {
    template: JST["app/templates/admins/viewadminemployee"],
    controller: "ViewAdminEmployeeController",
    resolve: routeRoleChecks.admin
  }).
  when("/viewhremployee", {
    template: JST["app/templates/admins/viewadminemployee"],
    controller: "ViewAdminEmployeeController",
    resolve: routeRoleChecks.hr
  }).
  when("/viewmanageremployee", {
    template: JST["app/templates/admins/viewadminemployee"],
    controller: "ViewAdminEmployeeController",
    resolve: routeRoleChecks.manager
  }).
  when("/viewmanagerprofile", {
    template: JST["app/templates/managers/viewmanagerprofile"],
    controller: "ViewManagerProfileController",
    resolve: routeRoleChecks.manager
  }).
  when("/viewemployeeprofile", {
    template: JST["app/templates/employees/viewemployeeprofile"],
    controller: "ViewEmployeeProfileController",
    resolve: routeRoleChecks.user
  }).
  when("/ShiftAcknowledgement", {
    template: JST["app/templates/managers/shiftAcknowledgement"],
    controller: "ShiftAcknowledgementController",
    //resolve: routeRoleChecks.manager
  }).
  when("/viewhrprofile", {
    template: JST["app/templates/hr/viewhrprofile"],
    controller: "ViewHrProfileController",
    resolve: routeRoleChecks.hr
  }).
  when("/hrprofile", {
    template: JST["app/templates/hr/hrprofile"],
    controller: "HrProfileController",
    resolve: routeRoleChecks.hr
  }).
  when("/managerprofile", {
    template: JST["app/templates/managers/managerprofile"],
    controller: "ManagerProfileController",
    resolve: routeRoleChecks.manager
  }).
  when("/addemployee", {
    template: JST["app/templates/addemployee"],
    controller: "AddEmployeeController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/addevent", {
    template: JST["app/templates/addevent"],
    controller: "AddEventController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/eventlist", {
    template: JST["app/templates/eventlist"],
    controller: "ViewEventController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/reoccurringaccount", {
      template: JST["app/templates/reoccurringaccount"],
      controller: "ReoccurringAccountController",
  }).
  when("/addaccount", {
      template: JST["app/templates/addaccount"],
      controller: "AddAccountController",
  }).
  when("/viewempforevents", {
    template: JST["app/templates/viewempforevents"],
    controller: "ViewEmployeesForEventController",
  }).
  when("/scheduleemployees/:event", {
    template: JST["app/templates/scheduleemployees"],
    controller: "ScheduleEmployeesController",
  }).
  when("/employeeprofile", {
    template: JST["app/templates/employees/employeeprofile"],
    controller: "EmployeeProfileController",
    resolve: routeRoleChecks.user
  }).
  when("/viewschedule", {
    template: JST["app/templates/viewschedule"],
    controller: "ScheduleController",
    resolve: routeRoleChecks.user
  }).
  when("/myschedule", {
    template: JST["app/templates/viewschedule"],
    controller: "ScheduleController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/viewschedule/:employeeId/:location", {
    template: JST["app/templates/viewschedule"],
    controller: "ScheduleController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/employee/edit/:id", {
    template: JST["app/templates/editemployee"],
    controller: "EditEmployeeController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/employee/availibility/:id", {
    template: JST["app/templates/view_employee_availability"],
    controller: "EmployeeAvailabilityController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/event/edit/:id", {
    template: JST["app/templates/editevent"],
    controller: "EditEventController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/account/edit/:id", {
    template: JST["app/templates/editevent"],
    controller: "EditEventController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/timeslots", {
    template: JST["app/templates/admins/timeslot"],
    controller: "TimeSlotController",
    resolve: routeRoleChecks.adminManagerHr
  }).
  when("/timeslotbuffer", {
    template: JST["app/templates/admins/timeslotbuffer"],
    controller: "TimeSlotBufferController",
    resolve: routeRoleChecks.adminHr
  }).
  when("/myavailability", {
    template: JST["app/templates/employees/myavailability"],
    controller: "MyavailabilityController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/memolist", {
    template: JST["app/templates/memolist"],
    controller: "MemoListController",
    resolve: routeRoleChecks.adminManagerUser
  })
  .when("/addmemo", {
    template: JST["app/templates/addmemo"],
    controller: "AddMemoController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/scheduleMsg", {
    template: JST["app/templates/scheduleMsg"],
    controller: "ScheduleMsgController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/memo/:id", {
    template: JST["app/templates/viewmemo"],
    controller: "ViewMemoController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/timeoffrequest", {
    template: JST["app/templates/employees/timeoffrequest"],
    controller: "timeoffRequestController",
    resolve: routeRoleChecks.user
  })
  .when("/mytimeoffrequest", {
    template: JST["app/templates/employees/timeoffrequest"],
    controller: "timeoffRequestController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/timeofflist", {
    template: JST["app/templates/managers/timeofflist"],
    controller: "timeoffRequestController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/timeoffdetail/:id", {
    template: JST["app/templates/managers/timeoffdetail"],
    controller: "timeoffRequestController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/profitlossreport", {
    template: JST["app/templates/profitlossreport"],
    controller: "profitLossController",
    resolve: routeRoleChecks.adminHr
  })
  .when("/missedpunchoutreport", {
    template: JST["app/templates/missedpunchoutreport"],
    controller: "MissedpunchController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/hoursworkedreport", {
    template: JST["app/templates/hoursworkedreport"],
    controller: "HoursworkedController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/totalhoursreport", {
    template: JST["app/templates/totalhoursreport"],
    controller: "TotalHoursReportController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/trackclosereport", {
    template: JST["app/templates/trackAndOpenClose"],
    controller: "TrackOcProController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/payrollreport", {
    template: JST["app/templates/payrollreport"],
    controller: "PayrollreportController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/overlapreport", {
    template: JST["app/templates/overlapreport"],
    controller: "OverlapreportController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/availDetailReport", {
    template: JST["app/templates/availDetailReport"],
    controller: "availreportController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/ticketnotes", {
    template: JST["app/templates/ticketnotes"],
    controller: "ticketNotesController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/ticketcomments", {
    template: JST["app/templates/ticketcomments"],
    controller: "ticketCommentsController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/ticketmonthlycomments", {
    template: JST["app/templates/ticketmonthlycomments"],
    controller: "ticketCommentsController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/ticketmonthlynotes", {
    template: JST["app/templates/ticketmonthlynotes"],
    controller: "ticketNotesController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/eventcalendar", {
    template: JST["app/templates/eventcalendar"],
    controller: "EventCalendarController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/accountcalendar", {
    template: JST["app/templates/accountcalendar"],
    controller: "AccountCalendarController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/eventshifts/:event", {
    template: JST["app/templates/eventshifts"],
    controller: "EventShiftController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/reports", {
    template: JST["app/templates/reports"],
    controller: "ReportsController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/empschedule", {
    template: JST["app/templates/empschedule"],
    controller: "EmpSchController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/emptoschedule", {
    template: JST["app/templates/emptoschedule"],
    controller: "EmpToSchController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/empAvlscheduled", {
    template: JST["app/templates/empAvlscheduled"],
    controller: "EmpScheduledController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/schedule", {
    template: JST["app/templates/schedule"],
    controller: "EmpSchController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/reportSchedule", {
    template: JST["app/templates/schedulereport"],
    controller: "EmpSchController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/documentslib", {
    template: JST["app/templates/documentslib"],
    controller: "DocumentLibraryController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/todolist", {
    template: JST["app/templates/todolist"],
    controller: "TodoListController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/mytodolist", {
    template: JST["app/templates/mytodolist"],
    controller: "MyTodoListController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/questionlist", {
    template: JST["app/templates/questionlist"],
    controller: "QuestionListController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/mytodocalendar", {
    template: JST["app/templates/todocalendar"],
    controller: "TodoCalendarController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/whiteboard", {
    template: JST["app/templates/whiteboard"],
    controller: "WhiteboardController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/timesheet", {
    template: JST["app/templates/timesheet"],
    controller: "TimesheetController",
    resolve: routeRoleChecks.user
  })
  .when("/admintimesheet", {
    template: JST["app/templates/admintimesheet"],
    controller: "TimesheetController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/timesheet/:id", {
    template: JST["app/templates/timesheet"],
    controller: "TimesheetController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/clocking", {
    template: JST["app/templates/clocking"],
    controller: "ClockingController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/logs", {
    template: JST["app/templates/logs"],
    controller: "LogsController",
    resolve: routeRoleChecks.user
  })
  .when("/schCalendar", {
    template: JST["app/templates/schCalendar"],
    controller: "schCalendarController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/tbpCalendar", {
    template: JST["app/templates/tbpCalendar"],
    controller: "tbpCalendarController",
    resolve: routeRoleChecks.user
  })
  .when("/tradeRequests", {
    template: JST["app/templates/tradeRequests"],
    controller: "tbpCalendarController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/tradeAdminRequests", {
    template: JST["app/templates/tradeAdminRequests"],
    controller: "tbpCalendarController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/adminRejectedTrades", {
    template: JST["app/templates/tradeAdminRejectedRequests"],
    controller: "tbpCalendarController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/tradedetail/:id", {
    template: JST["app/templates/tradedetail"],
    controller: "tbpCalendarController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/rejectedtradedetail/:rejectid", {
    template: JST["app/templates/rejectedtradedetail"],
    controller: "tbpCalendarController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/rankRecog", {
    template: JST["app/templates/rankRecog"],
    controller: "RecognitionController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/rankRecog/:empid", {
    template: JST["app/templates/rankRecog"],
    controller: "RecognitionController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/evaluation", {
    template: JST["app/templates/evaluation"],
    controller: "EvaluationController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/evalquestions", {
    template: JST["app/templates/evalquestionlist"],
    controller: "EvalquestionListController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/empranking", {
    template: JST["app/templates/empranking"],
    controller: "EmprankingController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/empPanelRanking", {
    template: JST["app/templates/empPanelRanking"],
    controller: "EmprankingController",
    resolve: routeRoleChecks.adminManagerHrUser
  })
  .when("/zipinitials", {
    template: JST["app/templates/zipinitials"],
    controller: "ZipinitialsController",
    resolve: routeRoleChecks.adminManagerHr
  })
  .when("/locations", {
    template: JST["app/templates/locations"],
    controller: "LocationsController",
    resolve: routeRoleChecks.adminManagerHr
  })

  .otherwise({
    redirectTo: '/dashboard'
  });

  $locationProvider.html5Mode(true);

});

angular.module("app").run(function($rootScope, $location, NotificationService) {
  $rootScope.$on("$routeChangeError", function(event, current, previous, rejection) {
    if (rejection === "Not Authorized") {
      NotificationService.notify("You are not authorized to view the content.");
      window.location = "/login";
    }
  });
  $rootScope.$on('$locationChangeStart', function( event, next, current) {
    if ($rootScope.scheduleForm == 'start') {
        var answer = confirm("Are you sure you want to leave this page?");
        if (!answer) {
          event.preventDefault();
        }else {
          $rootScope.scheduleForm = 'end';
        }
    }  
    if ($rootScope.editeventForm == 'start') {
        var answer = confirm("Are you sure you want to leave this page?");
        if (!answer) {
          event.preventDefault();
        }else {
          $rootScope.editeventForm = 'end';
        }
    }  
  });
});

angular.module('app').config(function($sceDelegateProvider) {
  $sceDelegateProvider.resourceUrlWhitelist([
   // Allow same origin resource loads.
   'self',
   // Allow loading from our assets domain.  Notice the difference between * and **.
   'https://localhost:4011/**'
  ])
});
angular.module("app").filter('utc', function() {
  return function(val) {
    var date = new Date(val);
    return new Date(date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds());
  };
});
