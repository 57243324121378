angular.module("app").controller("EvaluationController", function($scope, $http, $rootScope, ports, SessionService, NgTableParams, moment){
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.evaluationData = {};
    $scope.fetchAllManagers = function(){
        $http.post("/api/getAllManagers",{location:SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.allManagers = response.data.data;
            }
        });
    }
    $scope.fetchAllEmployees = function(){
        $http.post('/api/getempl',{location:SessionService.currentUser.location}).then(function(response){
            if (response.status == 200) {
                response.data.data.forEach(function(elem, key){
                    elem.ticked = false;
                    elem.fullname = elem.first_name+' '+elem.last_name;
                });
                $scope.modernWebBrowsers = response.data.data;
            }
        });
    }

    $scope.fetchAllManagers();
    $scope.fetchAllEmployees();
    
	$http.get("/api/Eval/getAllQuestions").then(function(response){
        if (response.status == 200) {
            $scope.allQuestions = response.data.data;
            if(response.data.data.length){
                $scope.haveResult = true;
            }
        }
    });

    $scope.addEvaluation = function(evaluationData){
        var evalData = {};
        var storeSelUserId = {};
        var storeSelManagerId = {};
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
               if ( value.ticked === true ) {
                   storeSelUserId = value;
               }
            });
            evalData.employee = storeSelUserId._id;
        }

        if($scope.allManagers){
            angular.forEach( $scope.allManagers, function( value, key ) {
               if ( value.ticked === true ) {
                   storeSelManagerId = value;
               }
            });
            evalData.manager = storeSelManagerId._id;
        }
        evalData.questions = [];
        evalData.evaluation = 0;
        var currentdteformat  =  new Date().setHours(0, 0, 0, 0);
        evalData.currDate = moment(currentdteformat).clone().tz(SessionService.currentUser.locationtimezone, true).toDate();
        angular.forEach(evaluationData, function( value, key ) {
            evalData.questions.push({questionId : key, evalvalue : value});
            evalData.evaluation = parseInt(value) +  parseInt(evalData.evaluation);

        });

        if(evalData.employee && evalData.manager){
            $http.post("/api/saveEvaluation",evalData).then(function(response){
                if (response.data.status_code == 200) {
                   toastr.success("Successfully Added!");
                   $scope.evaluationData = {};
                   $scope.fetchAllManagers();
                   $scope.fetchAllEmployees();
                }
            });
        }else{
            toastr.error("Please select employee and manager first.");
        }
    }
    $scope.cleardata = function(){
        $scope.evaluationData = {};
        $scope.fetchAllManagers();
        $scope.fetchAllEmployees();
        toastr.info("You cleared the data.");
    };

    $scope.removeEval = function(evalId){
        $http.post('/api/removeEval',{'evalId': evalId}).then(
            function(response){
                if (response.status == 200) {
                    $scope.fetchEvaluation();
                    toastr.success("Success!");
                }
            }
        );
    }

    $scope.fetchEvaluation = function(){
        var evalData = {};
        if($scope.modernWebBrowsers){
            angular.forEach( $scope.modernWebBrowsers, function( value, key ) {
               if ( value.ticked === true ) {
                   storeSelUserId = value;
                    if(storeSelUserId){
                        evalData.employee = storeSelUserId._id;
                    }
               }
            });   
        }
        if(!evalData.employee){
            toastr.info("Please select employee first.");
        }else{
            $http.post("/api/fetchEvaluation",evalData).then(function(response){
                if (response.data.status_code == 200) {
                   angular.element('#modal-evaluationlist').modal('show');
                   $scope.evaluationList = response.data.data;
                }
            });
            
        }
    }

});