angular.module("app").controller("EmployeeAvailabilityController", function($scope, $filter, TimeSlotResource, SessionService, $http, $routeParams,$q, sidepanelactiveService, getweekService,  moment) {
    sidepanelactiveService.test();
    $scope.showloader = true;
    // Get data from TimeSlotResource
    TimeSlotResource.query({locationId : SessionService.currentUser.location},function(response){
        $scope.slot = response[0];
    });
    var currWeekDates = getweekService.getCurrWeek();
    $scope.currWeekDate = currWeekDates;
    $scope.userRole = SessionService.currentUser.roles[0];
    $scope.start_end = '';
    $scope.data = "fds";
    var selectedIdStr = $routeParams.id;
    var selectedId = selectedIdStr.substr(1);
    $scope.currDate = new Date().getTime();
    $scope.todayDate = moment.tz(moment(), SessionService.currentUser.locationtimezone).toISOString();
    $scope.EventAssignment = {};
    $scope.showRemoveButton = true;
    $scope.weekdays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    $scope.timeslots = ["Morning","Afternoon","Night","Late Night"];
    $scope.weekdate = $filter('date')(currWeekDates[0], 'MM/dd/yyyy') + ' To '+ $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
    $scope.today = moment.tz(moment(), SessionService.currentUser.locationtimezone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    $scope.dateRange = {start_date : $filter('date')(currWeekDates[0], 'MM/dd/yyyy'), end_date : $filter('date')(currWeekDates[1], 'MM/dd/yyyy')};
    $scope.$watch(
        "weekdate",
        function handleWeekChange() {
            $scope.$on('startendString', function (event, args) {
                $scope.start_end = args.startendstringJson;
            });
            if ($scope.start_end) {
                 var startEndArr = $scope.start_end.split(' / ');
                 $scope.startDate = startEndArr[0];
                 $scope.endDate = startEndArr[1];
                 $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
            }
            else{
                $scope.startDate = currWeekDates[0];
                $scope.endDate = currWeekDates[1];
                $scope.dateRange = {start_date : $scope.startDate, end_date : $scope.endDate};
            }
            
            $scope.fetchschedule();
        }
    );
    $scope.assignedData = function(dte,shift,chkstatus,position,datestatus){
        $scope.EventAssignment.assigned_date = dte;
        $scope.EventAssignment.assigned_shift = shift;
        $scope.EventAssignment.assigned_index = position;
        if (datestatus < -1) {
            $scope.date_status = false;
        }
        if (datestatus >= -1) {
            $scope.date_status = true;
        }
        if ("admin" === SessionService.currentUser.roles[0]) {
           $http.get("/api/getadminevents").then(function(response){
            var assndate = new Date(dte);
                if ( "200" === response.data.status_code) {
                    $scope.allEvents = [];
                    response.data.events.forEach(function(elem, key){
                       elem.end_date = new Date(elem.end_date);
                       elem.start_date = new Date(elem.start_date);
                        if ((elem.end_date >= $scope.today) && (assndate >= elem.start_date) && (assndate <= elem.end_date) ) {
                            $scope.allEvents.push(elem);
                        }
                    });
                    $scope.showRemoveButton = true;
                    for(var i=0;i<$scope.allEvents.length;i++){
                         if ($scope.allEvents[i]._id == chkstatus) {
                             $scope.EventAssignment.event_id = $scope.allEvents[i];
                             break;
                         }
                    }
                    if (chkstatus == 'new') {
                         $scope.EventAssignment.event_id = $scope.allEvents[0];
                         $scope.showRemoveButton = false;
                    }
                } 
            });
        }
        
        if ("manager" === SessionService.currentUser.roles[0]) {
            $http.post("/api/managerevents",{manager_id:SessionService.currentUser._id}).then(function(response){
                if ( "200" === response.data.status_code) {
                    $scope.allEvents = [];
                    response.data.events.forEach(function(elem, key){
                       elem.end_date = new Date(elem.end_date);
                        if (elem.end_date >= $scope.today) {
                            $scope.allEvents.push(elem);
                        }
                    });
                    $scope.showRemoveButton = true;
                    for(var i=0;i<$scope.allEvents.length;i++){
                         if ($scope.allEvents[i]._id == chkstatus) {
                             $scope.EventAssignment.event_id = $scope.allEvents[i];
                             break;
                         }
                    }
                    if (chkstatus == 'new') {
                         $scope.EventAssignment.event_id = $scope.allEvents[0];
                         $scope.showRemoveButton = false;
                    }
                } 
            });
        }    
    }
    
    /*Update employee availability by manager*/
    $scope.updateEmployeeavailability = function(dte,shift,position,evt){
        var checkedVal = evt;
        $http.post("/api/updateAvailability",{assignDate : dte,assignShift : shift,modified_date :$scope.todayDate, modified_by : SessionService.currentUser._id,employee_availability:checkedVal, employee_id:selectedId}).then(function(response){
            toastr.success("Employee Availability Successfully Updated!","",{"timeOut":500});  
        });
    }

    $http.get('/api/users/:'+selectedId).then(function(response){
        $scope.userdetail = response.data;
    });
    
    $scope.fetchRangeData = function() {
        $scope.showloader=true;
        if($scope.dateRange == null){
            var StartDate = currWeekDates[0];
            var EndDate = currWeekDates[1];
            $scope.dateRange.start_date = $filter('date')(currWeekDates[0], 'MM/dd/yyyy');
            $scope.dateRange.end_date = $filter('date')(currWeekDates[1], 'MM/dd/yyyy');
        }else{
            var StartDate = new Date($scope.dateRange.start_date);
            var EndDate = new Date($scope.dateRange.end_date);
        }
        if (StartDate && EndDate) {
             var diff = (new Date(EndDate.getTime()) - new Date(StartDate.getTime()))/86400000;
             var diff = 6; //To Resolve day night saving time issue because in this change it return somewhere less than 6 and can be more than 6.
        }

        var sevenWeekDayArr = [];
        for(var i=0; i<= diff; i++) {
            sevenWeekDayArr[i] = new Date(StartDate.getTime() + i*86400000);
            sevenWeekDayArr[i].dayName = $scope.weekdays[sevenWeekDayArr[i].getDay()];
            sevenWeekDayArr[i].differ = Math.floor((new Date(StartDate.getTime() + i*86400000) - new Date().getTime())/86400000);
        }
        $scope.sevenWeekDayArr = sevenWeekDayArr;
        
        $scope.dateWiseData = [];
        var x = 0;
        $scope.myData = function() {
            var deferred = $q.defer();
            for (var i=0; i<= diff;i++) {
                $http.post('/api/dayavailability', {_id: selectedId, weekDay: sevenWeekDayArr[i]}).then(function(response){
                    $scope.showloader=false;
                    tmpArr = response.data;
                    if ( x <= diff ) {
                        $scope.dateWiseData.push(tmpArr);
                    }
                    if(x==diff) {
                        deferred.resolve();
                    }
                    x++;
                });
            }
            return deferred.promise;
        };
        $scope.myData().then(function() {
                $scope.dateWiseData.sort(function(a, b){ var dateA = new Date(a.currDate); var dateB = new Date(b.currDate); return dateA-dateB;});
                var lclDateArr = [];
                lclDateArr = $scope.dateWiseData;
                $scope.availability = [];
                var tmpArr = [];
                for(var i=0; i<=diff; i++) {
                    tmpArr.push({currDate: new Date(lclDateArr[i].currDate).toString(), is_morning_scheduled: lclDateArr[i].is_morning_scheduled_type, is_afternoon_scheduled: lclDateArr[i].is_afternoon_scheduled_type, is_night_scheduled: lclDateArr[i].is_night_scheduled_type, is_late_night_scheduled: lclDateArr[i].is_late_night_scheduled_type,is_morning_scheduled_confirmation: false, is_afternoon_scheduled_confirmation: false, is_night_scheduled_confirmation: false, is_late_night_scheduled_confirmation: false});
                }
                tmpArr.sort(function(a, b){ var dateA = new Date(a.currDate); var dateB = new Date(b.currDate); return dateA-dateB;});
                $scope.availability = tmpArr;
        });
        $scope.getWeekReportcomment();
    };

    $scope.submitComment = function(availreport){
        $http.post('/api/weeklyreport', {
            emp_id: selectedId,
            startWeek: $scope.sevenWeekDayArr[0],
            endWeek: $scope.sevenWeekDayArr[6],
            report: availreport,
            created_date :  $scope.todayDate
        }).then(function(response) {
            toastr.success("Successfully Saved.");
        });
    }

    $scope.getWeekReportcomment = function(){
        $http.post('/api/getweeklyreport', {
            emp_id: selectedId,
            startWeek: $scope.sevenWeekDayArr[0],
            endWeek: $scope.sevenWeekDayArr[6]
        }).then(function(response) {
            if ("200" === response.data.status_code) {
                if(response.data.data){
                    $scope.availreport = response.data.data.comment;
                }else{
                    $scope.availreport = "";
                }
            }
        });
    }
    

    $scope.fetchschedule = function(){
        var schedule = {};
        schedule.from = new Date($scope.startDate);
        schedule.to = new Date($scope.endDate);
        schedule.empId = selectedId;
        $http.post('/api/empSchedules', schedule).then(function(response) {
            $scope.showloader=false;
            $scope.schedules = response.data.data;
            griddata = [];
            for (var i = 0; i < $scope.weekdays.length; i++) { 
                var nestedObj = {};
                for (var j = 0; j < $scope.timeslots.length; j++) {
                    angular.forEach(response.data.data, function(item) {
                        if (item.day_of_week == $scope.weekdays[i] && item.timeslot == $scope.timeslots[j]) {
                            nestedObj[$scope.timeslots[j]] = item.shiftReminderTime;
                            nestedObj[$scope.timeslots[j]+'_id'] = item._id;
                        }
                    });
                    if(!nestedObj[$scope.timeslots[j]]) {
                        nestedObj[$scope.timeslots[j]] = '';
                    }
                }
                griddata[$scope.weekdays[i]] = nestedObj
            }
            $scope.gridData = griddata;
            $scope.fetchRangeData();
        });  
    }

    $scope.fetchScheduleDetail = function(schId){
        $http.post("/api/checkScheduleDetails",{schId:schId}).success(function(response){
            if(response.status_code == "200"){
                $scope.eventDetails = response.data.event_account_id;
                $scope.eventStartDate = new Date($scope.eventDetails.start_date);
                $scope.eventEndDate = new Date($scope.eventDetails.end_date);
                $scope.shiftData = $scope.eventDetails.shift_template_id;
                $scope.evtDetail = $scope.eventDetails;
            }
        })
    }

}).filter('capitalize', function(){
    return function(input){
        return(!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    }
})
.filter('split', function() {
        return function(input, splitChar, splitIndex) {
            return input.split(splitChar)[splitIndex];
        }
});

